import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

export default function Spinner () {
  return (
    <span
    //   style={{
    //     textAlign: 'center',
    //     marginTop: '20vh'
    //   }}
    >
      <RotatingLines
        strokeColor='#1d7c7c'
        strokeWidth='4'
        animationDuration='0.75'
        width='25'
        visible={true}
      />
    </span>
  )
}

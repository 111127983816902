import React, { useContext, useState } from "react";
import yourLogo from "../../assets/images/logo.png";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landingicon.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passwordIcon from "../../assets/images/icons/passwordIcon.svg";
import passBox from "../../assets/images/icons/lock.svg";
import login_img from "../../assets/images/login_img.png";
import facebook from "../../assets/images/icons/Facebook.svg";
import apple from "../../assets/images/icons/apple.svg";
import google from "../../assets/images/icons/google.svg";
import doodleRed from "../../assets/images/icons/doodle-red.svg";
import doodleSmall from "../../assets/images/icons/doodle-small.svg";
import doodle from "../../assets/images/icons/doodle-5 2.svg";
import userIcon from "../../assets/images/icons/userIcon.svg";
import phone from "../../assets/images/icons/phone.svg";
import otpshield from "../../assets/images/icons/otpShiled.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  PostCallWithErrorResponse,
  postCallWithFormData,
  postCallWithFormDataWithToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import OtpTimer from "../../sharedComponent/OtpTimer";
import { AppContext } from "../../context/AppContext";
import { ClipLoader } from "react-spinners";
import { checkFileTypeForImages } from "../../constants/commonFunctons";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const Registration = () => {
  const navigate = useNavigate();
  const { isTimeExpired, setIsTimeExpired } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [rgLdr, setRgLdr] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    lat: "",
    lng: "",
    phone: "",
    password: "",
    customerphotos: [],
  });
  console.log("registrationData,", registrationData);
  const convertInFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        object[key].forEach((item, index) => {
          formData.append(`${key}`, item);
        });
      } else {
        formData.append(key, object[key]);
      }
    });
    return formData;
  };
  const [OTP, setOTP] = useState("");

  const submitRegistrationForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setRgLdr(true);
        const formData = convertInFormData(registrationData);
        const res = await postCallWithFormData(
          ApiConfig.REGISTRATION,
          formData
        );
        if (res?.json?.success === true) {
          notifySuccess("Registered successfully");
          navigate("/PetInformation", {
            state: {
              Name: `${registrationData?.first_name} ${registrationData?.last_name}`,
              customer_id: res?.json?.data?.customer_id,
            },
          });
          localStorage.setItem("customer_id", res?.json?.customer_id);
        } else if (res?.json?.success === false) {
          notifyError(res?.json?.message);
        }
      } catch (error) {
        console.log("catch error,", error);
      } finally {
        setRgLdr(false);
      }
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setRegistrationData((prevState) => ({ ...prevState, [name]: value }));
  };
  const [otpLoader, setotpLoader] = useState(false);

  const requestOTP = async () => {
    let payLoad = {
      phone_number: registrationData.phone,
    };
    try {
      setotpLoader(true);
      const res = await PostCallWithErrorResponse(
        ApiConfig.REQUEST_OTP,
        payLoad
      );
      if (res?.json.success === true) {
        res?.json.otp && setOTP(res?.json?.otp ? res?.json?.otp : "");
        setIsTimeExpired(true);
        // setTimeout(() => {
        //   res?.json.otp && varifyOTP(res?.json.otp);
        // }, 1500);
        notifySuccess("OTP sent your mobile number");
      } else if (res?.json.success === false) {
        notifyError(res.json.message);
      }
    } catch (error) {
      console.log("catch error responce,", error);
      notifyError("Something went wrong");
    } finally {
      setotpLoader(false);
    }
  };

  const varifyOTP = async (otp) => {
    let payLoad = {
      phone_number: registrationData?.phone,
      otp: otp,
      // otp: otp ? otp : OTP,
    };
    try {
      const res = await PostCallWithErrorResponse(
        ApiConfig.VARIFY_OTP,
        payLoad
      );
      if (res?.json?.success === true) {
        notifySuccess("OTP verified successfully");
      } else if (res?.json?.success === false) {
        notifyError(res?.json?.message);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      {otpLoader && <BackDropLoader />}
      <div className="authMain registrationMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} alt="" style={{ height: "60px" }} />
            <div className="RegisterForm">
              <div className="belowScroll">
                <h1 className="signInTxt">Sign in</h1>
                <h6 className="signInPara">
                  If you already have an account register
                  <p className="mt-2 youCan">
                    You can
                    <Link to="/" className="registerLink ms-1">
                      Login Here !
                    </Link>
                  </p>
                </h6>
                {/* <form action=""> */}
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={submitRegistrationForm}
                >
                  {/*
                <div className="inputBox">
                <div class="profile-pic">
                  <img
                    alt="User Pic"
                    src="https://d30y9cdsu7xlg0.cloudfront.net/png/138926-200.png"
                    id="profile-image1"
                    height="200"
                  />
                  <input
                    id="profile-image-upload"
                    class="hidden"
                    type="file"
                    onchange="previewFile()"
                  />
                  <div style={{ color: "#999" }}> </div>
                </div>
              </div>
              */}

                  <div className="inputBox">
                    <div class="profile-pic">
                      <label htmlFor="fileInput">
                        <img
                          alt="User Pic"
                          src={
                            registrationData.customerphotos[0]
                              ? URL.createObjectURL(
                                  registrationData.customerphotos[0]
                                )
                              : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                          }
                          // id="profile-image1"
                          height="200"
                        />
                      </label>
                    </div>
                    <label htmlFor="fileInput" className="emailLabel">
                      Profile photo
                    </label>
                    <img src={userIcon} className="mailImg" alt="" />
                    <input
                      required
                      accept="image/png, image/jpeg, image/jpg"
                      type="file"
                      id="fileInput"
                      className="form-control formInput"
                      placeholder="Select your profile photo"
                      onChange={(e) => {
                        console.log(
                          "!checkFileTypeForImages(e.target.files[0]),",
                          !checkFileTypeForImages(e.target.files[0])
                        );
                        if (!checkFileTypeForImages(e.target.files[0])) {
                          notifyError(
                            "'Invalid file type. Please choose a PNG, JPEG, or JPG file.'"
                          );
                        } else {
                          setRegistrationData({
                            ...registrationData,
                            customerphotos: [e.target.files[0]],
                          });
                        }
                      }}
                    />
                    {/* <div className='fileSelectionName'>
                      Please upload profile photo
                    </div> */}
                    <label htmlFor="fileInput" className="file_selection_name">
                      {registrationData.customerphotos[0]?.name
                        ? registrationData.customerphotos[0]?.name
                        : "Please upload profile photo"}
                    </label>
                    <div className="filediv"></div>

                    <Form.Control.Feedback type="invalid">
                      Please upload profile photo
                    </Form.Control.Feedback>
                  </div>

                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      First Name
                    </label>
                    <img src={userIcon} className="mailImg" alt="" />
                    <input
                      required
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your First name"
                      name="first_name"
                      onChange={onChangeHandler}
                    />

                    <Form.Control.Feedback type="invalid">
                      First name is required
                    </Form.Control.Feedback>
                  </div>

                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      Last Name
                    </label>
                    <img src={userIcon} className="mailImg" alt="" />
                    <input
                      required
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your Last name"
                      name="last_name"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Last name is required
                    </Form.Control.Feedback>
                  </div>
                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      Email
                    </label>
                    <img src={mailBox} className="mailImg" alt="" />
                    <input
                      required
                      type="email"
                      className="form-control formInput"
                      placeholder="Enter your Email"
                      name="email"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid email
                    </Form.Control.Feedback>
                  </div>
                  {/*
                  <div className="inputBox">
                  <label htmlFor="" className="emailLabel">
                    Address
                  </label>
                   <img src={mailBox} className="mailImg" alt="" />
                  <AutocompleteGoogleInput
                    required={true}
                    className={"form-control formInput"}
                    placeholder={"Enter your address"}
                    setState={setRegistrationData}
                    state={registrationData}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your address
                  </Form.Control.Feedback>
                  </div>
                  */}

                  <div className="inputBox">
                    <div className="numberRegistration">
                      <label htmlFor="" className="emailLabel">
                        Phone Number
                      </label>
                      <button
                        className="requestBtn"
                        type="button"
                        disabled={!registrationData?.phone ? true : false}
                        onClick={() => {
                          setIsTimeExpired(false);
                          requestOTP();
                        }}
                      >
                        Request OTP
                      </button>
                    </div>
                    <img src={phone} className="mailImg" alt="" />
                    <input
                      required
                      type="tel"
                      className="form-control formInput"
                      placeholder="Enter your Phone Number"
                      name="phone"
                      pattern="[6789][0-9]{9}"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid number
                    </Form.Control.Feedback>
                  </div>
                  {isTimeExpired && <OtpTimer requestOTP={requestOTP} />}

                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      OTP
                    </label>
                    <img src={otpshield} className="mailImg" alt="" />
                    <input
                      required
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter OTP here..."
                      value={OTP && OTP}
                      onChange={(e) => {
                        const { value } = e.target;
                        setOTP(value);
                        if (value.length === 6) {
                          varifyOTP(value);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter OTP
                    </Form.Control.Feedback>
                  </div>

                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      Passward
                    </label>
                    <img src={passwordIcon} className="mailImg" alt="" />
                    <input
                      required
                      type="password"
                      className="form-control formInput"
                      placeholder="Enter your password"
                      name="password"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Password is required
                    </Form.Control.Feedback>
                  </div>

                  {/* <div className="belowChecks">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        class="form-check-label checkLbl"
                        for="flexCheckDefault"
                      >
                        <span className="terms">I agree with</span>
                        <span className="registerLink">
                          {" "}
                          terms and conditions
                        </span>
                      </label>
                    </div>
                  </div> */}

                  {/* Navigate To Get Started */}
                  <div className="login">
                    {/* <Link
                      //  to='/PetInformation'
                      className='loginBtn'
                    > */}
                    <button
                      type="submit"
                      className="loginBtn"
                      style={rgLdr ? { backgroundColor: "#7fa4a4" } : {}}
                      disabled={rgLdr ? true : false}
                    >
                      {rgLdr ? (
                        <ClipLoader color="#fff" size={30} />
                      ) : (
                        <span>Register</span>
                      )}
                    </button>
                    {/* </Link> */}
                  </div>
                </Form>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="mobileNumber">
                <span className="callNumber">
                  <img src={teleCall} height="15" width="15" alt="" /> +91
                  9509932234
                </span>
              </div>
              <div className="insiderIcon">
                <img src={login_img} alt="" />
              </div>
              <div className="landingTxt">
                <div className="d-flex flex-column justify-content-start">
                  <h1>Sign Up to Customer App</h1>
                  <p>Lets get started !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;

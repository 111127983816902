import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import Accordion from "react-bootstrap/Accordion";
import MultiRangeSlider from "multi-range-slider-react";
import FilterImg from "../../assets/images/filterIcon.svg";
import CouponBadge from "../../assets/images/redCouponBadge.svg";
import MediImg from "../../assets/images/medicinesImg.svg";
import heartBorder from "../../assets/images/heartBorder.svg";
import rightBin from "../../assets/images/rightCheckBin.svg";
import dustbinImg from "../../assets/images/dustbinImg.svg";
import BlueHeart from "../../assets/images/blueFilledHeart.svg";
import dummymedicene from "../../assets/images/dummymedicene.jpg";
import ic_rating_start from "../../assets/images/ic_rating_start.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { AppContext } from "../../context/AppContext";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import PopupOverlay from "../Home/PopupOverlay";
import { increaseCartCount } from "../../redux/Slices/cartSlice";
import featured from "../../assets/images/featured.svg";

const Medicines = () => {
  const { cartItemCount, customer_id } = useReduxStore();
  const {
    drocterCallOpen,
    setBkdropLdr,
    medcinePayLoad,
    setMedcinePayLoad,
    dispatch,
  } = useContext(AppContext);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(45);

  const [like1, setlike1] = useState(false);
  const [like2, setlike2] = useState(false);
  const [like3, setlike3] = useState(false);
  const [like4, setlike4] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);

  //STATES
  const [loader, setLoader] = useState(true);
  const [allMedicinesList, setAllMedicinesList] = useState([]);
  const [currentPageData, setCurrentPageData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [filterData, setFilterData] = useState({
    brands: [],
    categories: [],
    symptomsList: [],
    animal_typeList: [],
  });
  //STATES
  useEffect(() => {
    getFilterDataLookupData();
  }, []);

  const getFilterDataLookupData = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_FILTERED_DATA}`
      );

      const { result, data } = res?.json;
      if (result) {
        data &&
          setFilterData({
            brands: data?.Brand,
            categories: data?.Product_Type,
            symptomsList: data?.symptoms,
            animal_typeList: data?.animal_type,
          });
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchMedicineList();
  }, [medcinePayLoad]);

  // MEDICINE_LIST
  const fetchMedicineList = async () => {
    try {
      setLoader(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.MEDICINE_LIST,
        medcinePayLoad
      );

      const {
        pharmacyProduct,
        success,
        current_page,
        total_pages,
        count,
        last_page,
      } = res?.json;
      if (success) {
        pharmacyProduct && setAllMedicinesList(pharmacyProduct);
        setCurrentPageData({ current_page, total_pages, count, last_page });
      } else  {
        setAllMedicinesList([]);
      }
    } catch (error) {
      console.log("catch Error,", error);
    } finally {
      setLoader(false);
    }
  };
  console.log("currentPage,", currentPage);

  const [ldrMoreData, setLdrMoreData] = useState(false);

  const fetchMoreMedicineList = async () => {
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(ApiConfig.MEDICINE_LIST, {
        ...medcinePayLoad,
        page: currentPage,
      });

      const {
        pharmacyProduct,
        success,
        current_page,
        total_pages,
        count,
        last_page,
      } = res?.json;
      if (success) {
        pharmacyProduct &&
          setAllMedicinesList((prev) => [...prev, ...pharmacyProduct]);
        setCurrentPageData({ current_page, total_pages, count, last_page });
      }
    } catch (error) {
      console.log("catch Error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreMedicineList();
    }
  }, [currentPage]);

  const handleInfiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      console.log("isLastPage,", !currentPageData.last_page);

      if (!currentPageData.last_page) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleInfiniteScroll)
  //   return () => window.removeEventListener('scroll', handleInfiniteScroll)
  // }, [])

  const addToBagCart = async (
    product_id,
    product_rate,
    pharmacy_shop_id,
    entity_type_id
  ) => {
    const cartPayLoad = {
      customer_id: customer_id,
      // amount: 180,
      product_id: product_id,
      product_quantity: 1,
      product_rate: product_rate,
      shop_id: pharmacy_shop_id,
      entity_type_id: entity_type_id,
    };
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        dispatch(increaseCartCount(cartItemCount + 1));
        notifySuccess("Medicine Added to cart successfully");
        const tempArr = allMedicinesList.map((item, i) => {
          return item?.product_id === product_id
            ? { ...item, customer_card_id: true }
            : item;
        });
        setAllMedicinesList(tempArr);
        // navigate('/cart')
      }
      // else {
      //   notifyError('This product already added to your cart')
      // }
    } catch (error) {
      console.log("catch error:", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [ldr, setLdr] = useState(false);
  // ADD_MEDICINE_TO_FAVOURITE
  const addToFavourite = async (product_id, entity_type_id, actionType) => {
    try {
      setLdr(true);
      const payLoad = {
        customer_id: customer_id,
        product_id: product_id,
        entity_type_id: entity_type_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_MEDICINE_TO_FAVOURITE,
        payLoad
      );
      console.log("res,", res);
      const { result, message } = res?.json;
      if (result) {
        // notifySuccess(message)
        console.log("actionType,", actionType);
        if (actionType === "like") {
          const tempArr = allMedicinesList.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: true }
              : item;
          });
          setAllMedicinesList(tempArr);
        } else if (actionType === "dislike") {
          const tempArr = allMedicinesList.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: false }
              : item;
          });
          setAllMedicinesList(tempArr);
        }
      }
    } catch (error) {
      console.log("catch Error:,", error);
    } finally {
      setLdr(false);
    }
  };

  return (
    <>
      {ldr && <BackDropLoader />}
      <div className="medicinesList" id="cx-main">
        <Subheader />

        {/* main Cards Content */}
        <div className="row">
          {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}

          {/* Left Menu's Accordians */}
          <div className="col-md-3 colmain3">
            <div id="accordiansTab">
              <div className="mainHeadTxt">
                <h1 className="headName">Filter</h1>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  {/* <Accordion.Header>Symptoms</Accordion.Header> */}
                  <Accordion.Body>
                    <div className="checksPortion">
                      {filterData?.symptomsList.map((item, index) => {
                        return (
                          <div
                            class="form-check checkNlabel"
                            key={"filterData" + item?.product_id + index}
                          >
                            {item?.symptom && (
                              <>
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={`flexCheckDefault${index}${item?.product_id}symptomsList`}
                                  checked={medcinePayLoad?.symptom_id?.includes(
                                    item?.symptom_id
                                  )}
                                  onChange={(e) => {
                                    const selectedSymtopsId = item?.symptom_id
                                      ? item?.symptom_id
                                      : "";
                                    if (e.target.checked) {
                                      setMedcinePayLoad({
                                        ...medcinePayLoad,
                                        symptom_id: [
                                          ...medcinePayLoad?.symptom_id,
                                          item?.symptom_id,
                                        ],
                                      });
                                    } else {
                                      let tempArr =
                                        medcinePayLoad?.symptom_id.filter(
                                          (innerItem, i) =>
                                            innerItem !== item?.symptom_id
                                        );
                                      setMedcinePayLoad({
                                        ...medcinePayLoad,
                                        symptom_id: tempArr,
                                      });
                                    }
                                  }}
                                />
                                <label
                                  class="form-check-label"
                                  htmlFor={`flexCheckDefault${index}${item?.product_id}symptomsList`}
                                >
                                  {item?.symptom}
                                </label>
                              </>
                            )}
                          </div>
                        );
                      })}

                      {/* <div class='form-check checkNlabel'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault2'
                        />
                        <label class='form-check-label' for='flexCheckDefault2'>
                          Bad Breath
                        </label>
                      </div>
                      <div class='form-check checkNlabel'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault3'
                        />
                        <label class='form-check-label' for='flexCheckDefault3'>
                          Chewing or Licking at Skin
                        </label>
                      </div>
                      <div class='form-check checkNlabel'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault4'
                        />
                        <label class='form-check-label' for='flexCheckDefault4'>
                          Dandruff
                        </label>
                      </div>
                      <div class='form-check checkNlabel'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault5'
                        />
                        <label class='form-check-label' for='flexCheckDefault5'>
                          Stomach pain
                        </label>
                      </div> */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Animal Type</Accordion.Header>
                  <Accordion.Body>
                    <div className="checksPortion">
                      {filterData?.animal_typeList.map((item, index) => {
                        return (
                          <div
                            class="form-check checkNlabel"
                            key={item?.type_of_animal_id}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id={`flexCheckDefault11${index}${item?.type_of_animal_id}animal_typeList`}
                              checked={medcinePayLoad?.type_of_animal_id?.includes(
                                item?.type_of_animal_id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setMedcinePayLoad({
                                    ...medcinePayLoad,
                                    type_of_animal_id: [
                                      ...medcinePayLoad.type_of_animal_id,
                                      item?.type_of_animal_id,
                                    ],
                                  });
                                } else {
                                  let tempArr =
                                    medcinePayLoad?.type_of_animal_id.filter(
                                      (innerItem, i) =>
                                        innerItem !== item?.type_of_animal_id
                                    );
                                  setMedcinePayLoad({
                                    ...medcinePayLoad,
                                    type_of_animal_id: tempArr,
                                  });
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              htmlFor={`flexCheckDefault11${index}${item?.type_of_animal_id}animal_typeList`}
                            >
                              {item?.type_of_animal}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {filterData && filterData?.categories?.length > 0 && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Categories</Accordion.Header>
                    <Accordion.Body>
                      <div className="checksPortion">
                        {filterData?.categories?.map((element, index) => {
                          return (
                            <div
                              class="form-check checkNlabel"
                              key={element?.product_type_id}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={
                                  "flexCheckDefault11" +
                                  index +
                                  element?.product_type_id +
                                  "categories"
                                }
                                checked={medcinePayLoad?.product_type_id?.includes(
                                  element?.product_type_id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setMedcinePayLoad({
                                      ...medcinePayLoad,
                                      product_type_id: [
                                        ...medcinePayLoad.product_type_id,
                                        element?.product_type_id,
                                      ],
                                    });
                                  } else {
                                    let tempArr =
                                      medcinePayLoad?.product_type_id.filter(
                                        (innerItem, i) =>
                                          innerItem !== element?.product_type_id
                                      );
                                    setMedcinePayLoad({
                                      ...medcinePayLoad,
                                      product_type_id: tempArr,
                                    });
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                htmlFor={
                                  "flexCheckDefault11" +
                                  index +
                                  element?.product_type_id +
                                  "categories"
                                }
                              >
                                {element?.product_type}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {filterData && filterData?.brands?.length > 0 && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Brand</Accordion.Header>
                    <Accordion.Body>
                      <div className="checksPortion">
                        {filterData?.brands?.map((element, index) => {
                          return (
                            <div
                              class="form-check checkNlabel"
                              key={element?.product_brand_id}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={medcinePayLoad?.product_brand_id?.includes(
                                  element?.product_brand_id
                                )}
                                id={`flexCheckDefault11${index}${element?.product_brand_id}brands`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setMedcinePayLoad({
                                      ...medcinePayLoad,
                                      product_brand_id: [
                                        ...medcinePayLoad.product_brand_id,
                                        element?.product_brand_id,
                                      ],
                                    });
                                  } else {
                                    let tempArr =
                                      medcinePayLoad?.product_brand_id.filter(
                                        (innerItem, i) =>
                                          innerItem !==
                                          element?.product_brand_id
                                      );
                                    setMedcinePayLoad({
                                      ...medcinePayLoad,
                                      product_brand_id: tempArr,
                                    });
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                htmlFor={`flexCheckDefault11${index}${element?.product_brand_id}brands`}
                              >
                                {element?.product_brand}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {/* <Accordion.Item eventKey='4'>
                  <Accordion.Header>Top Selling Medicines</Accordion.Header>
                  <Accordion.Body></Accordion.Body>
                </Accordion.Item> */}
                {/* <Accordion.Item eventKey='5'>
                  <Accordion.Header>Top Discount Offers</Accordion.Header>
                  <Accordion.Body>
                    <div className='sliderAreas'>
                      <MultiRangeSlider
                        min={1}
                        max={9}
                        step={3}
                        minValue={minValue}
                        maxValue={maxValue}
                        onInput={e => {
                          setMinValue(e.minValue)
                          setMaxValue(e.maxValue)
                        }}
                      ></MultiRangeSlider>
                      <div className='belowSteps'>
                        <label htmlFor=''>0 %</label>
                        <label htmlFor=''>25 %</label>
                        <label htmlFor=''>80 %</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </div>
          </div>
          {/* Cards Content Section */}
          <div className="col-md-9 colmain9">
            <div className="topFilterSection">
              {allMedicinesList.length > 0 && (
                <>
                  <div className="txtSec">
                    <h1 className="mainTxt">
                      Medicines{" "}
                      {currentPageData && currentPageData?.count && (
                        <span className="mutedTxt">
                          {`Showing 1-${allMedicinesList.length} of ${currentPageData?.count} Medicines`}
                        </span>
                      )}
                    </h1>
                  </div>
                  <div className="filterImg">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={FilterImg} alt="" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="insdieFLex">
                            <label className="labelSort">Sort By</label>
                            <label
                              className="labelClear"
                              onClick={() => {
                                setMedcinePayLoad({
                                  ...medcinePayLoad,
                                  price: "",
                                });
                              }}
                            >
                              Clear All
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setMedcinePayLoad({
                              ...medcinePayLoad,
                              price: "popular",
                            });
                          }}
                        >
                          <Link to="#">Most Popular</Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setMedcinePayLoad({
                              ...medcinePayLoad,
                              price: "Low",
                            });
                          }}
                        >
                          <Link to="#">Price Low to High</Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setMedcinePayLoad({
                              ...medcinePayLoad,
                              price: "High",
                            });
                          }}
                        >
                          <Link to="#">Price High to Low</Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setMedcinePayLoad({
                              ...medcinePayLoad,
                              price: "",
                            });
                          }}
                        >
                          <Link to="#">Newest First</Link>
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          onClick={() => {
                            setMedcinePayLoad({
                              ...medcinePayLoad,
                              featured: "featured",
                            });
                          }}
                        >
                          <Link to="#">Featured</Link>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
            </div>
            <div className="cardsSections">
              <div className="row">
                {loader ? (
                  <RotatingLoader />
                ) : allMedicinesList.length > 0 ? (
                  allMedicinesList.map((medicine, idx) => {
                    // const splittedPath =
                    //   medicine?.product_image &&
                    //   medicine?.product_image[0]?.product_image_path.split("/");
                    const imagePath =
                      medicine?.product_image &&
                      medicine?.product_image[0]?.product_image_path
                        ? medicine?.product_image[0]?.product_image_path
                        : null;
                    // medicine?.product_image?.product_image_path;
                    return (
                      <div
                        className="col-md-4 colmain4"
                        key={"allMedicinesList" + idx}
                      >
                        <div className="doctors-card">
                          {medicine?.is_featured && (
                            <img
                              src={featured}
                              className="feturedUImg"
                              alt="im"
                            />
                          )}
                          <div className="img-section">
                            <span className="heart">
                              {medicine?.customer_favourite_id &&
                              medicine?.customer_favourite_id ? (
                                <img
                                  src={BlueHeart}
                                  alt=""
                                  onClick={() =>
                                    addToFavourite(
                                      medicine?.product_id,
                                      medicine?.entity_type_id,
                                      "dislike"
                                    )
                                  }
                                />
                              ) : (
                                <img
                                  src={heartBorder}
                                  alt=""
                                  onClick={() =>
                                    addToFavourite(
                                      medicine?.product_id,
                                      medicine?.entity_type_id,
                                      "like"
                                    )
                                  }
                                />
                              )}
                            </span>
                            <img
                              src={imagePath ? imagePath : dummymedicene}
                              alt="Not found"
                              className="img-dr"
                            />
                            {medicine?.customer_card_id &&
                            medicine?.customer_card_id ? (
                              <button
                                className="book-btn darkColor"
                                id="btn-added"
                                onClick={() => setItemAdded(!itemAdded)}
                              >
                                <img src={rightBin} alt="" /> Item Added
                              </button>
                            ) : (
                              <button
                                className="book-btn"
                                // onClick={() => setItemAdded(!itemAdded)}
                                onClick={() => {
                                  addToBagCart(
                                    medicine?.product_id,
                                    medicine?.product_rate,
                                    medicine?.pharmacy_shop_id,
                                    medicine?.entity_type_id
                                  );
                                }}
                              >
                                <img src={dustbinImg} alt="" /> Add to Bag
                              </button>
                            )}
                            {/* <img
                              src={CouponBadge}
                              className='CouponBadge'
                              alt=''
                            /> */}
                          </div>

                          <div className="name-rating">
                            <div className="wrapper">
                              <div
                                className="left"
                                // onClick={() =>
                                //   navigate('/ProductDetails', {
                                //     state: {
                                //       product_id: medicine?.product_id,
                                //       pharmacy_shop_id:
                                //         medicine?.pharmacy_shop_id
                                //     }
                                //   })
                                // }
                              >
                                <Link
                                  to={`/ProductDetails?product_id=${medicine?.product_id}&pharmacy_shop_id=${medicine?.pharmacy_shop_id}&entity_type_id=${medicine?.entity_type_id}`}
                                >
                                  <label htmlFor="" className="name">
                                    {medicine?.product}
                                  </label>
                                </Link>
                              </div>
                              <div className="right">
                                <span className="name">
                                  {medicine?.avg_rating}
                                </span>
                                <img src={ic_rating_start} alt="" />
                              </div>
                            </div>
                            <span className="cr-price">
                              ₹{parseFloat(medicine?.product_rate).toFixed(2)}
                            </span>
                            {/* <span className='price'>
                              ₹ {parseFloat(medicine?.product_rate).toFixed(2)}
                            </span> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoDataMsg />
                )}
                {ldrMoreData && <RotatingLoader />}
                {/* <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>

                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={MediImg} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/ProductDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div> */}
              </div>
              {!loader && !currentPageData?.last_page && (
                <div className="showmoreBtnBox">
                  {allMedicinesList.length > 0 && (
                    <button
                      type="button"
                      className="ShowMore"
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                    >
                      Show more
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Medicines;

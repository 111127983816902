import React, { useContext, useEffect, useState } from 'react'
import yourLogo from '../../assets/images/logo.png'
import teleCall from '../../assets/images/icons/telephone.svg'
import insiderImg from '../../assets/images/icons/Puppy.svg'
import doghand from '../../assets/images/icons/doghand.svg'
import downArrowDropdown from '../../assets/images/icons/downArrowDropdown.svg'
import imageIcon from '../../assets/images/icons/imageIcon.svg'
import dog1 from '../../assets/images/icons/dog1.svg'
import dog2 from '../../assets/images/icons/dog2.svg'
import dog3 from '../../assets/images/icons/dog3.svg'
import dog4 from '../../assets/images/icons/dog4.svg'
import cross from '../../assets/images/icons/cross.svg'

import { Link, useLocation } from 'react-router-dom'
import {
  PostCallWithAuthToken,
  PostCallWithErrorResponse,
  postCallWithFormDataWithToken,
  simpleGetCallWithAuthToken
} from '../../api/ApiServices'
import { ApiConfig } from '../../api/ApiConfig'
import { useReduxStore } from '../../customHooks/useReduxStore'
import { Form } from 'react-bootstrap'
import { notifyError, notifySuccess } from '../../ToastMessages/notify'
import { AppContext } from '../../context/AppContext'
import { ClipLoader } from 'react-spinners'
import BtnLoader from '../../sharedComponent/BtnLoader'
import { animalInfo } from '../../redux/Slices/authSlice'

const PetInformation = () => {
  const location = useLocation()
  const name = location.state?.Name
  const customer_id = location.state?.customer_id
  // DATA FROM CONTEXT
  const { navigate, dispatch } = useContext(AppContext)
  // DATA FROM CONTEXT

  // // DATA FROM REDUX STORE
  // const { customerLoggedInData } = useReduxStore()
  // console.log('customerLoggedInData,', customerLoggedInData)
  // const customer_id =
  //   customerLoggedInData && customerLoggedInData?.loginData?.customer_id
  // // DATA FROM REDUX STORE

  // STATES
  const [showPassword, setShowPassword] = useState(false)
  const [selectedPet, setSelectedPet] = useState('')
  const [validated, setValidated] = useState(false)
  const [petTypes, setPetTypes] = useState([])
  // STATES

  const [petInfo, setPetInfo] = useState({
    animalphotos: [],
    animal_name: '',
    customer_id: customer_id,
    type_of_animal_id: '',
    animal_age: ''
  })
  console.log('petInfo,', petInfo)

  useEffect(() => {
    getPetTypes()
  }, [])

  const getPetTypes = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(ApiConfig.GET_PET_TYPES)
      if (res?.json?.success === true) {
        res?.json?.data && setPetTypes(res?.json?.data)
      }
    } catch (error) {
      console.log('catch error res,', error)
    }
  }

  const convertInFormData = object => {
    const formData = new FormData()
    Object.keys(object).forEach(key => {
      if (Array.isArray(object[key])) {
        object[key].forEach((item, index) => {
          formData.append(`${key}`, item)
        })
      } else {
        formData.append(key, object[key])
      }
    })
    return formData
  }

  const [loader, setLoader] = useState(false)

  const savePetInformation = async event => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else if (petInfo?.animalphotos.length === 0) {
      setValidated(true)
    } else {
      setLoader(true)
      try {
        const formDataPayLoad = convertInFormData(petInfo)
        const res = await postCallWithFormDataWithToken(
          ApiConfig.ADD_PET_INFORMATION,
          formDataPayLoad
        )
        if (res?.json?.success === true) {
          notifySuccess('Pet information saved successfully')
          navigate('/GetStarted', { state: { name: name } })
          // dispatch(animalInfo(true))
        }
      } catch (error) {
        console.log('catch error response')
      } finally {
        setLoader(false)
      }
    }
  }

  const onChangeHandler = e => {
    const { value, name } = e.target
    setPetInfo(prev => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <div className='authMain petinformationMain'>
        <div className='row'>
          <div className='col-md-12 col-lg-6'>
            <img src={yourLogo} alt='' style={{ height: '60px' }} />
            <div className='signinForm RegisterForm'>
              <h1 className='signInTxt'>Pet Information</h1>
              <h6 className='signInPara'>
                If you already have an account register
                <p className='mt-2 youCan'>
                  You can
                  <Link to='/' className='registerLink'>
                    {' '}
                    Login here !
                  </Link>
                </p>
              </h6>
              <Form
                noValidate
                validated={validated}
                onSubmit={savePetInformation}
              >
                <div className='inputBox'>
                  <label htmlFor='' className='emailLabel'>
                    Pet Name
                  </label>
                  <img src={doghand} className='mailImg' alt='' />
                  <input
                    required
                    type='text'
                    className='form-control formInput'
                    placeholder='Enter your animal name'
                    name='animal_name'
                    onChange={onChangeHandler}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Pet name is required
                  </Form.Control.Feedback>
                </div>
                <div className='inputBox'>
                  <label htmlFor='' className='emailLabel'>
                    Type of Pet
                  </label>
                  <img src={doghand} className='mailImg' alt='' />
                  <img src={downArrowDropdown} alt='' className='eyeImg' />

                  <select
                    className='form-control formInput'
                    placeholder='Select your animal type'
                    required
                    name='type_of_animal_id'
                    onChange={onChangeHandler}
                  >
                    <option value='' className='formInput'>
                      Select an option
                    </option>
                    {Array.isArray(petTypes) &&
                      petTypes.map(pet => {
                        return (
                          <option
                            key={pet?.type_of_animal_id}
                            value={pet?.type_of_animal_id}
                          >
                            {pet?.type_of_animal}
                          </option>
                        )
                      })}
                    {/* <option value='Cat'>Cat</option>
                    <option value='Goat'>Goat</option> */}
                  </select>
                  <Form.Control.Feedback type='invalid'>
                    Please select pet type
                  </Form.Control.Feedback>
                </div>

                <div className='inputBox'>
                  <label htmlFor='' className='emailLabel'>
                    Pet Age
                  </label>
                  <img src={doghand} className='mailImg' alt='' />
                  <input
                    type='number'
                    className='form-control formInput'
                    placeholder='Enter Pet age'
                    required
                    name='animal_age'
                    onChange={e => {
                      setPetInfo({
                        ...petInfo,
                        animal_age: Number(e.target.value)
                      })
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Pet age is required
                  </Form.Control.Feedback>
                </div>

                <div className='inputBox'>
                  <label htmlFor='' className='emailLabel'>
                    Pet Photographs
                  </label>
                  <img src={imageIcon} className='mailImg' alt='' />
                  <label
                    htmlFor='selectPhotos'
                    className='photograhphy form-control'
                  >
                    <input
                      required={
                        petInfo?.animalphotos.length === 0 ? true : false
                      }
                      type='file'
                      accept='image/png, image/jpeg, image/jpg'
                      id='selectPhotos'
                      className='form-control formInput hiddenFileInput'
                      placeholder='Select pet photos...'
                      onChange={e => {
                        // const fileWithAdditionalData = {
                        //   file: e.target.files[0],
                        //   additionalData: {
                        //     key1: 'ramakant' // Replace with your actual data
                        //   }
                        // }
                        // formData.append('animalphotos', file, `animal_photo_${i}.jpg`);
                        setPetInfo({
                          ...petInfo,
                          animalphotos: [
                            ...petInfo.animalphotos,
                            // fileWithAdditionalData
                            e.target.files[0]
                          ]
                        })
                      }}
                    />
                    <Form.Control.Feedback
                      type='invalid'
                      style={{
                        position: 'absolute',
                        marginTop: '32px',
                        marginLeft: '-23px'
                      }}
                    >
                      Select pet images
                    </Form.Control.Feedback>
                    Select pet photos...
                  </label>

                  <div className='previewPet'>
                    {Array.isArray(petInfo.animalphotos) &&
                      petInfo.animalphotos?.length > 0 &&
                      petInfo.animalphotos.map(image => {
                        return (
                          <div key={'animalphotos'} className='petImages'>
                            <img
                              src={image && URL.createObjectURL(image)}
                              alt=''
                              className='bullykhan'
                            />
                            <img
                              src={cross}
                              className='cross'
                              alt=''
                              onClick={() => {
                                const newArr = petInfo.animalphotos.filter(
                                  img => img !== image
                                )
                                setPetInfo({
                                  ...petInfo,
                                  animalphotos: newArr
                                })
                              }}
                            />
                          </div>
                        )
                      })}
                    {/* <div className='petImages'>
                      <img src={dog2} alt='' className='bullykhan' />
                      <img src={cross} className='cross' alt='' />
                    </div>
                    <div className='petImages'>
                      <img src={dog3} alt='' className='bullykhan' />
                      <img src={cross} className='cross' alt='' />
                    </div>
                    <div className='petImagesblur'>
                      <div className='backcol'></div>
                      <div className='textback'>+4</div>
                      <img src={dog4} alt='' className='bullykhan' />
                    </div> */}
                  </div>
                </div>
                {/* Navigate To Get Started */}
                <div className='login'>
                  {/* <Link 
                  to='/GetStarted'
                  > */}
                  <button
                    type='submit'
                    className='loginBtn'
                    style={loader ? { backgroundColor: '#7fa4a4' } : {}}
                    disabled={loader ? true : false}
                  >
                    {loader ? <BtnLoader /> : 'Save & Proceed'}
                  </button>
                  {/* </Link> */}
                </div>
              </Form>
            </div>
          </div>
          <div className='col-md-12 col-lg-6'>
            <div className='landingImg'>
              <div className='mobileNumber'>
                <span className='callNumber'>
                  <img src={teleCall} height='15' width='15' alt='' /> +91
                  9509932234
                </span>
              </div>

              <div className='insiderIcon'>
                <img src={insiderImg} alt='' />
              </div>
              <div className='landingTxt'>
                <div className='d-flex flex-column justify-content-start'>
                  <h1>Pet Information</h1>
                  <p>Share your pet with us !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PetInformation

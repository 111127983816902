import React from 'react'

const TermsAndCondition = () => {
    return (
        <>
            <div className="authMain">
                <div className='tAcPage'>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Terms and Conditions for Animalsomatic Clinic - The Animal's App</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Last updated: [24th Jan 2024]</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Thank you for choosing Animalsomatic Clinic's services through The Animal's App. By using our website (</span><a href="https://www.theanimalsapp.com/"><span style={{ fontSize: '16px', color: "green", textDecoration: "none" }}>https://www.theanimalsapp.com</span></a><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>) or the mobile application, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please do not use our services.</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>1. Acceptance of Terms</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>1.1 <strong>Binding Agreement:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>By accessing or using The Animal's App, you agree to these Terms and Conditions, forming a binding agreement between you and Animalsomatic Clinic.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>1.2 <strong>Eligibility:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>You must be at least 18 years old or have parental/guardian consent to use our services.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>2. Services and Use of The Animal's App</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>2.1 <strong>Description of Services:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>The Animal's App provides a platform for booking veterinary appointments, ordering pet medicine, food, accessories, and other related services.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>2.2 <strong>User Responsibilities:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>You are responsible for providing accurate information when using our services.</span></li>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>You agree not to misuse or interfere with the functionality of The Animal's App.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>3. User Accounts and Security</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>3.1 <strong>Account Creation:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>3.2 <strong>Security:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Report any unauthorized access or security breaches promptly to [support@animalsapp.in].</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>4. Payments and Fees</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>4.1 <strong>Payment Authorization:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>By using our services, you authorize Animalsomatic Clinic to charge your provided payment method for the cost of services and any applicable fees.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>4.2 <strong>Refunds:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Refund policies for services, medicines, or products may vary. Please review specific refund terms associated with each service.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>5. Intellectual Property</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>5.1 <strong>Ownership:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Animalsomatic Clinic retains ownership of all intellectual property associated with The Animal's App, including but not limited to logos, trademarks, and content.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>5.2 <strong>License:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Animalsomatic Clinic grants you a limited, non-exclusive, non-transferable license to use the app and services for personal, non-commercial purposes.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>6. Limitation of Liability</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>6.1 <strong>Disclaimer:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Animalsomatic Clinic is not liable for any damages, losses, or injuries resulting from the use of our services.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>6.2 <strong>Third-Party Services:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>We are not responsible for third-party services or products accessed through The Animal's App.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>7. Changes to Terms and Conditions</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Animalsomatic Clinic may update these Terms and Conditions periodically. Continued use of The Animal's App constitutes acceptance of any changes.</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>8. Governing Law</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>These Terms and Conditions are governed by the laws of State of Jharkhand Any disputes shall be resolved in the appropriate courts of the District Court of Ranchi.</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>9. Contact Us</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>If you have any questions or concerns regarding these Terms and Conditions, please contact us at [support@animalsapp.in] or [ +91 9509932234].</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>By using The Animal's App, you agree to abide by these Terms and Conditions.</span></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsAndCondition;

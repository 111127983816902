import yourLogo from "../../assets/images/logo.png";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/getStarted.svg";
import { Link, useLocation } from "react-router-dom";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const GetStarted = () => {
  const { navigate } = useContext(AppContext);
  // DATA FROM REDUX STORE
  const { customerLoggedInData } = useReduxStore();
  console.log("customerLoggedInData,", customerLoggedInData);
  const first_name =
    customerLoggedInData && customerLoggedInData?.loginData?.first_name;
  const last_name =
    customerLoggedInData && customerLoggedInData?.loginData?.last_name;

  const location = useLocation();
  const name = location.state?.name;

  // DATA FROM REDUX STORE
  return (
    <>
      <div className="authMain getStaredmain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} alt="" style={{ height: "60px" }} />
            <div className="getStarted">
              <h3 className="getStartedTitle">
                LET'S BEGIN THE <br />
                JOURNEY
              </h3>
              <p className="getStartedPara">
                Say goodbye to the hassle of finding the right veterinary care
                for your beloved pets. Animal App simplifies the process of
                booking appointments with experienced and compassionate
                veterinarians.
                {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. */}
              </p>

              <div className="login">
                <Link to="/" className="loginBtn">
                  <button>Let’s Start !</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="mobileNumber">
                <span className="callNumber">
                  <img src={teleCall} height="15" width="15" alt="" /> +91
                  9509932234
                </span>
              </div>

              <div className="insiderIcon">
                <img src={insiderImg} alt="" />
              </div>

              <div className="landingTxt">
                <div className="d-flex flex-column justify-content-start">
                  <h1 className="cap">
                    Congratulations ! {`${name ? name : ""}`}
                  </h1>

                  <p>Lets get started !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";

const initialState = {
  loginData: {},
  loading: false,
  isLoggedIn: false,
};
export const submitLoginForm = createAsyncThunk(
  "customer/submitLoginForm",
  async (payLoad, thunkAPI) => {
    try {
      const API_URL =
        payLoad?.loginAction === "login_with_email"
          ? ApiConfig.CUSTOMER_LOGIN
          : ApiConfig.LOGIN_WITH_OTP;
      const response = await PostCallWithErrorResponse(API_URL, payLoad);
      const getData = {
        data: response,
        error: false,
        message: "API called successfully",
      };
      return getData;
    } catch (error) {
      const errorRes = { error: true, message: "Failed to call API" };
      return thunkAPI.rejectWithValue(errorRes);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.loginData = {};
      state.isLoggedIn = false;
      state.loading = false;
    },
    updateLoginData : (state, action) => {
      state.loginData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitLoginForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitLoginForm.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        if (
          data &&
          data?.json?.success &&
          data?.json?.success === true &&
          data?.json?.data
        ) {
          state.loginData = { ...data?.json?.data, token: data?.json?.token, ...data?.json?.customerRequiredDetails };
          state.isLoggedIn =
            data?.json?.token && data?.json?.token ? true : false;
        }
      })
      .addCase(submitLoginForm.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { animalInfo, logout, updateLoginData } = loginSlice.actions;
export default loginSlice.reducer;

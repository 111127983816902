import React, { useContext, useEffect, useState } from "react";
import Subheader from "../../sharedComponent/Subheader";
import { Link, Navigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import liteImgArow from "../../assets/images/lightIcon.svg";
import ic_arrow_right_white from "../../assets/images/ic_right_arrow_circle.svg";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/icons/close.svg";
import Thumbs from "../../assets/images/thumsUpHard.svg";
import {
  PostCallWithAuthToken,
  PutCallWithAuthToken,
  postCallWithFormDataWithToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";
import { ApiConfig } from "../../api/ApiConfig";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import { useReduxStore } from "../../customHooks/useReduxStore";
import moment from "moment";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { decreaseCartCount } from "../../redux/Slices/cartSlice";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";
import {
  convertInFormData,
  convertInFormData2,
  convertObjectToFormData,
} from "../../constants/commonFunctons";
import { checkoutHandler } from "../../paymentCheckout/checkout";
import coupon from "../../assets/images/coupon.svg";
import GreenAxe from "../../assets/images/greenCrossAxe.svg";

function convertCoordinatesStringToObject(coordinatesString) {
  // Remove parentheses and split the string into an array of strings
  const coordinatesArray = coordinatesString.replace(/[()]/g, "").split(",");

  // Convert the array elements to numbers
  const coordinatesObject = {
    x: parseFloat(coordinatesArray[0]),
    y: parseFloat(coordinatesArray[1]),
  };

  return coordinatesObject;
}

const MedicineCheckout = () => {
  // DATA FROM CONTEXT
  const { setBkdropLdr, navigate, setEventKey, dispatch, validNumber } =
    useContext(AppContext);
  // DATA FROM CONTEXT

  const locationData = useLocation();
  const cartList = locationData?.state?.cartList
    ? locationData?.state?.cartList
    : [];

  const discountAmt = locationData?.state?.discountAmt
    ? locationData?.state?.discountAmt
    : "";
  const customer_animal_id = locationData?.state?.customer_animal_id
    ? locationData?.state?.customer_animal_id
    : "";

  const doctor_appointment_id = locationData?.state?.doctor_appointment_id
    ? locationData?.state?.doctor_appointment_id
    : "";

  // DATA FROM REDUX STORE
  const { customer_id, cartItemCount } = useReduxStore();
  // DATA FROM REDUX STORE

  const [checkOne, setCheckOne] = useState(true);
  const [checkTwo, setCheckTwo] = useState(false);
  const [modal, setModal] = useState(false);
  const [billingDetails, setBillingDetails] = useState({});
  console.log("billingDetails,", billingDetails)
  const isBillingDetails = Object.keys(billingDetails).length ? true : false;
  const [animals, setAnimal] = useState([]);
  const subTotal =
    cartList &&
    cartList.length > 0 &&
    cartList.reduce((totalAmt, currentObject) => {
      return (
        totalAmt +
        Number(currentObject?.product_rate) *
          Number(currentObject?.product_quantity)
      );
    }, 0);

  const [totalAmount, setTotalAmount] = useState("");

  useEffect(() => {
    const total =
      cartList &&
      cartList.length > 0 &&
      cartList.reduce((totalAmount, currentObject) => {
        return (
          totalAmount +
          Number(currentObject?.product_rate) *
            Number(currentObject?.product_quantity)
        );
      }, 0);
    total && setTotalAmount(total);
  }, [cartList]);

  const totalItem =
    cartList &&
    cartList.length > 0 &&
    cartList.reduce((qty, currentObject) => {
      return qty + Number(currentObject?.product_quantity);
    }, 0);

  const [placeOrderPayLoad, setPlaceOrderPayLoad] = useState({
    discount_amount: "",
    order_type: "", // med, pet,
    customer_id: customer_id,
    customer_animal_id: customer_animal_id ? customer_animal_id : "",
    doctor_appointment_id: doctor_appointment_id,
    order_date: moment().format("DD-MM-YYYY"),
    delivery_date: moment().add(4, "days").format("DD-MM-YYYY"),
    no_of_items: totalItem,
    total_amount: 0,
    order_status_id: 10,
    mode_of_payment_id: 1,
    transaction_id: "",
    transaction_date: moment().format("DD-MM-YYYY"),
    payment_status_id: 4,
    address_id: "",
    customer_location_coordinates: "",
    phone_number: "",
    med_order: [],
    pet_shop: {},
    pet_order: [],
    prescription_image: "",
  });

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [updateCustomerPayload, setUpdateCustomerPayload] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: [
      {
        address: "",
        lat: "",
        lng: "",
        landmark: "",
        is_default: true,
        postal_code: "",
        street_name: "",
        address_type_id: 2,
      },
    ],
  });

  const [couponModal, setCouponModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [discountDetail, setDiscountDetail] = useState({
    discountAmt: "",
    discountCoupon: "",
  });
  const [couponPayLoad, setCouponPayLoad] = useState({
    total_amount: "",
    coupon_id: "",
    coupon_code: "",
    discountPercentage: 0,
  });

  const passwordHandlerr = (event) => {
    const { value, name } = event.target;
    let tempObj = { ...updateCustomerPayload.address[0] };
    let mod = {
      ...tempObj,
      [name]: value,
    };
    setUpdateCustomerPayload({
      ...updateCustomerPayload,
      address: [mod],
    });
  };

  useEffect(() => {
    fecthMyAnimals();
    fecthBillingDetails();
    fetchCustomerDetails();
    fetchAllDiscountCoupons();
  }, []);

  // FETCH_ALL_COUPONS
  const fetchAllDiscountCoupons = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_ALL_COUPONS}`
      );
      const { success, data } = res?.json;
      if (success) {
        data && setCoupons(data);
      }
    } catch (error) {
      console.log("fecthMyAnimals catch error :", error);
    }
  };

  const fetchCustomerDetails = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_CUSTOMER_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        const resData = data[0];
        data &&
          setUpdateCustomerPayload({
            ...updateCustomerPayload,
            first_name: resData?.first_name,
            last_name: resData?.last_name,
            email: resData?.email,
            phone_number: resData?.phone_number,
            customer_id: customer_id,
          });
      }
    } catch (error) {
      console.log("error,", error);
    }
  };

  useEffect(() => {
    const petShopOrders = cartList
      .filter((item) => "pet_shop_id" in item)
      .map((innerItem) => {
        return {
          product_id: innerItem?.product_id,
          product_quantity: Number(innerItem?.product_quantity),
          product_rate: Number(innerItem?.product_rate),
          amount: Number(innerItem?.amount),
          pet_shop_id: Number(innerItem?.pet_shop_id),
          pet_shop_order_status_id: 10,
          pet_shop_proudct_quantity: Number(
            innerItem?.pet_shop_product_quantity
          ),
        };
      });

    const medicineAmount =
      cartList &&
      cartList.length > 0 &&
      cartList
        .filter((item) => "pharmacy_shop_id" in item)
        .map((element) => {
          return {
            product_rate: element?.product_rate,
            product_quantity: element?.product_quantity,
          };
        })
        .reduce((totalAmount, currentObject) => {
          return (
            totalAmount +
            Number(currentObject?.product_rate) *
              Number(currentObject?.product_quantity)
          );
        }, 0);

    const discountAmtOfMedProducts =
      (Number(couponPayLoad?.discountPercentage) * medicineAmount) / 100;
    const medicinesAmtAfterDiscountDeduction = couponPayLoad?.discountPercentage
      ? medicineAmount - discountAmtOfMedProducts
      : 0;

    const petTotalAmount =
      petShopOrders &&
      petShopOrders.length > 0 &&
      petShopOrders.reduce((totalAmt, currentObject) => {
        return (
          totalAmt +
          Number(currentObject?.product_rate) *
            Number(currentObject?.product_quantity)
        );
      }, 0);
    const discountAmtOfPetProducts =
      (Number(couponPayLoad?.discountPercentage) * petTotalAmount) / 100;
    const petProductsAmtAfterDiscountDeduction =
      couponPayLoad?.discountPercentage
        ? petTotalAmount - discountAmtOfPetProducts
        : 0;

    const totalPetItem =
      petShopOrders &&
      petShopOrders.length > 0 &&
      petShopOrders.reduce((qty, currentObject) => {
        return qty + Number(currentObject?.product_quantity);
      }, 0);

    setPlaceOrderPayLoad({
      ...placeOrderPayLoad,
      pet_shop:
        petShopOrders?.length > 0
          ? {
              pet_delivery_date: moment().add(3, "days").format("DD-MM-YYYY"),
              pet_no_of_items: totalPetItem,
              // Total Amount and Discount Amount For petshop products
              pet_total_amount: petTotalAmount,
              pet_discount_amount: petProductsAmtAfterDiscountDeduction,
              // Total Amount and Discount Amount For petshop products
            }
          : {},

      // Total Amount and Discount Amount For medicine products
      total_amount: medicineAmount,
      discount_amount: medicinesAmtAfterDiscountDeduction,
      // Total Amount and Discount Amount For medicine products
    });
  }, [couponPayLoad]);

  // billing-details
  const fecthBillingDetails = async () => {
    try {
      const payLoad = {
        customer_id: customer_id,
      };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.FETCH_BILLING_DETAILS,
        payLoad
      );

      const { result, personalInfo } = res?.json;
      if (result) {
        personalInfo && setBillingDetails(personalInfo[0]);
        const medicineOrders = cartList
          .filter((item) => "pharmacy_shop_id" in item)
          .map((innerItem) => {
            return {
              product_id: innerItem?.product_id,
              product_quantity: Number(innerItem?.product_quantity),
              product_rate: Number(innerItem?.product_rate),
              amount: Number(innerItem?.amount),
              pharmacy_shop_id: Number(innerItem?.pharmacy_shop_id),
              product_order_status_id: 10,
              pharmacy_shop_product_quantity: Number(
                innerItem?.pharmacy_shop_product_quantity
              ),
            };
          });

        const petShopOrders = cartList
          .filter((item) => "pet_shop_id" in item)
          .map((innerItem) => {
            return {
              product_id: innerItem?.product_id,
              product_quantity: Number(innerItem?.product_quantity),
              product_rate: Number(innerItem?.product_rate),
              amount: Number(innerItem?.amount),
              pet_shop_id: Number(innerItem?.pet_shop_id),
              pet_shop_order_status_id: 10,
              pet_shop_proudct_quantity: Number(
                innerItem?.pet_shop_product_quantity
              ),
            };
          });

        const medicineAmount =
          cartList &&
          cartList.length > 0 &&
          cartList
            .filter((item) => "pharmacy_shop_id" in item)
            .map((element) => {
              return {
                product_rate: element?.product_rate,
                product_quantity: element?.product_quantity,
              };
            })
            .reduce((totalAmount, currentObject) => {
              return (
                totalAmount +
                Number(currentObject?.product_rate) *
                  Number(currentObject?.product_quantity)
              );
            }, 0);

        const discountAmtOfMedProducts =
          (Number(couponPayLoad?.discountPercentage) * medicineAmount) / 100;
        const medicinesAmtAfterDiscountDeduction =
          couponPayLoad?.discountPercentage
            ? medicineAmount - discountAmtOfMedProducts
            : 0;

        const petTotalAmount =
          petShopOrders &&
          petShopOrders.length > 0 &&
          petShopOrders.reduce((totalAmt, currentObject) => {
            return (
              totalAmt +
              Number(currentObject?.product_rate) *
                Number(currentObject?.product_quantity)
            );
          }, 0);
        const discountAmtOfPetProducts =
          (Number(couponPayLoad?.discountPercentage) * petTotalAmount) / 100;
        const petProductsAmtAfterDiscountDeduction =
          couponPayLoad?.discountPercentage
            ? petTotalAmount - discountAmtOfPetProducts
            : 0;

        const totalPetItem =
          petShopOrders &&
          petShopOrders.length > 0 &&
          petShopOrders.reduce((qty, currentObject) => {
            return qty + Number(currentObject?.product_quantity);
          }, 0);
        setPlaceOrderPayLoad({
          ...placeOrderPayLoad,
          med_order: medicineOrders ? medicineOrders : [],
          pet_shop:
            petShopOrders?.length > 0
              ? {
                  pet_delivery_date: moment()
                    .add(3, "days")
                    .format("DD-MM-YYYY"),
                  pet_no_of_items: totalPetItem,
                  // Total Amount and Discount Amount For petshop products
                  pet_total_amount: petTotalAmount,
                  pet_discount_amount: petProductsAmtAfterDiscountDeduction,
                  // Total Amount and Discount Amount For petshop products
                }
              : {},
          pet_order: petShopOrders ? petShopOrders : [],
          address_id: personalInfo[0]?.address
            ? personalInfo[0]?.address[0]?.address_id
            : "",
          customer_location_coordinates: personalInfo[0]?.address
            ? convertCoordinatesStringToObject(
                personalInfo[0]?.address[0]?.customer_location_coordinates
              )
            : "",
          phone_number: Number(personalInfo[0]?.phone_number),
          order_type:
            medicineOrders?.length > 0 && petShopOrders?.length > 0
              ? ""
              : medicineOrders?.length > 0
              ? "med"
              : "pet",
          // Total Amount and Discount Amount For medicine products
          total_amount: medicineAmount,
          discount_amount: medicinesAmtAfterDiscountDeduction,
          // Total Amount and Discount Amount For medicine products
        });
      }
    } catch (error) {
      console.log("fecthBillingDetails catch error :", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  // FETCH_MY_ANIMALS
  const fecthMyAnimals = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_MY_ANIMALS}?customer_id=${customer_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        data && setAnimal(data);
      }
    } catch (error) {
      console.log("fecthMyAnimals catch error :", error);
    }
  };

  const afterPaymentSuccess = () => {
    dispatch(decreaseCartCount(0));
    setModal(true);

    setTimeout(() => {
      navigate("/ProfileSetting");
      setEventKey("55");
    }, 1000);
  };

  const placeOrder = async () => {
    if (placeOrderPayLoad?.customer_animal_id === "") {
      notifyError("Please select your animal");
      return;
    }
    // if (!doctor_appointment_id && placeOrderPayLoad?.prescription_image === "") {
    //   notifyError("Please upload prescription");
    //   return;
    // }
    if (placeOrderPayLoad?.address_id === "") {
      notifyError("Please add address");
      setIsAddressModalOpen(true);
      return;
    }
    try {
      setBkdropLdr(true);
      const formDataForPlaceOrder = convertInFormData2({
        ...placeOrderPayLoad,
      });
      const res = await postCallWithFormDataWithToken(
        ApiConfig.PLACE_ORDER,
        formDataForPlaceOrder
      );
      const { result, orderDetails } = res?.json;
      if (result) {
        const ordeType = placeOrderPayLoad?.order_type;
        checkoutHandler(
          totalAmount,
          orderDetails,
          ordeType,
          afterPaymentSuccess
        );
      } else {
        notifyError("Something went wrong");
      }
    } catch (error) {
      console.log("catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  const [validated, setValidated] = useState(false);
  const updateCustomerProfile = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      try {
        setBkdropLdr(true);
        const res = await PutCallWithAuthToken(
          ApiConfig.UPDATE_CUSTOMER_DETAILS,
          updateCustomerPayload
        );
        const { success } = res?.json;
        if (success) {
          notifySuccess("Profile details updated successfully");
          fecthBillingDetails();
          setIsAddressModalOpen(false);
        }
      } catch (error) {
        console.log("Catch error update customer profile:", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  const applyCoupons = async () => {
    if (!couponPayLoad?.total_amount && !couponPayLoad?.coupon_id) {
      notifyError("Please select coupon");
      return;
    }
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.APPLY_COUPONS,
        couponPayLoad
      );
      const { success, message } = res?.json;
      if (success) {
        const totalAmtAfterDiscountAmtDeduction =
          totalAmount -
          (Number(couponPayLoad?.discountPercentage) * totalAmount) / 100;
        setDiscountDetail({
          discountAmt:
            (Number(couponPayLoad?.discountPercentage) * totalAmount) / 100,
          discountCoupon: couponPayLoad?.coupon_code,
        });
        setTotalAmount(totalAmtAfterDiscountAmtDeduction);
        notifySuccess("Coupon Applied Successfully");
        // setBell(true);
        setCouponModal(false);
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  return (
    <div className="medicine-checkout-main-div">
      {/* {bkdropLdr && <BackDropLoader />} */}
      <div className="medicineCheckout" id="cx-main">
        <Subheader />
        <div className="mainCheckout">
          <div className="row">
            <div className="col-md-8">
              {checkOne ? (
                <>
                  <div className="billingDetailCard">
                    <div className="billingHead">
                      <h1>Billing Details</h1>
                    </div>
                    <h1 className="infoTxt">Personal Information</h1>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="preDefaultDetails">
                          <h1>
                            {billingDetails.first_name &&
                              `${billingDetails.first_name} ${billingDetails.last_name}`}
                          </h1>
                          <h1>
                            {billingDetails.email && `${billingDetails.email}`}
                          </h1>
                          <h1>
                            {billingDetails.phone_number &&
                              `${billingDetails.phone_number}`}
                          </h1>
                        </div>
                      </div>
                      <div
                        className="patientContent"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="SelectBox">
                          {/* <label className='selectLabel'>Animal </label> */}
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              if (e.target.value == "new") {
                                sessionStorage.setItem(
                                  "checkoutData",
                                  JSON.stringify({
                                    cartList,
                                    customer_animal_id,
                                    doctor_appointment_id,
                                    discountAmt,
                                  })
                                );
                                navigate("/ProfileSetting");
                                setEventKey("22");
                                return;
                              }
                              setPlaceOrderPayLoad({
                                ...placeOrderPayLoad,
                                customer_animal_id: Number(e.target.value),
                              });
                            }}
                            value={
                              placeOrderPayLoad?.customer_animal_id
                                ? placeOrderPayLoad?.customer_animal_id
                                : ""
                            }
                            required
                          >
                            <option value={""}>Select your animal</option>
                            {animals &&
                              animals.map((item, idx) => {
                                return (
                                  <option
                                    key={item?.customer_animal_id}
                                    value={item?.customer_animal_id}
                                  >
                                    {item?.animal_name}
                                  </option>
                                );
                              })}
                            <option className="add-new-pet-option" value="new">
                              + Add new animal
                            </option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select animal type
                          </Form.Control.Feedback>
                        </div>
                        {!doctor_appointment_id && (
                          <div className="col-md-6">
                            <div className="detailsFiller">
                              <label htmlFor="" className="Tittle">
                                Upload Prescription
                              </label>
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .png, .pdf"
                                className="form-control capital"
                                placeholder="Enter your First name"
                                onChange={(e) => {
                                  setPlaceOrderPayLoad({
                                    ...placeOrderPayLoad,
                                    prescription_image: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="detailsFiller">
                          <label htmlFor="" className="Tittle">
                            First Name
                          </label>
                          <input
                            disabled
                            style={{ background: "transparent" }}
                            type="text"
                            className="form-control capital"
                            placeholder="Enter your First name"
                            value={
                              billingDetails?.first_name
                                ? billingDetails?.first_name
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="detailsFiller">
                          <label htmlFor="" className="Tittle">
                            Last Name
                          </label>
                          <input
                            disabled
                            style={{ background: "transparent" }}
                            type="text"
                            className="form-control capital"
                            placeholder="Enter your Last name"
                            value={
                              billingDetails?.last_name
                                ? billingDetails?.last_name
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="detailsFiller">
                          <label htmlFor="" className="Tittle">
                            Email
                          </label>
                          <input
                            disabled
                            style={{ background: "transparent" }}
                            type="email"
                            className="form-control"
                            placeholder="Enter your Email"
                            value={
                              billingDetails?.email ? billingDetails?.email : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="detailsFiller">
                          <label htmlFor="" className="Tittle">
                            Phone Number
                          </label>
                          <input
                            disabled
                            style={{ background: "transparent" }}
                            type="tel"
                            className="form-control"
                            placeholder="Enter your Phone Number"
                            value={
                              billingDetails?.phone_number
                                ? billingDetails?.phone_number
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* <div className='col-md-12'>
                        <div className='loginAgain'>
                          <label htmlFor='' className='reLogin'>
                            Existing Customer ?{' '}
                            <Link to='/'>Click here to Login</Link>
                          </label>
                        </div>
                      </div> */}
                      {billingDetails &&
                      isBillingDetails &&
                      billingDetails?.address?.length > 0 ? (
                        <div className="col-md-12">
                          <div className="address-header">
                            <h1 className="infoTxt">Shipping Details</h1>{" "}
                            <button
                              className="address-add-btn"
                              onClick={() => {
                                setIsAddressModalOpen(true);
                              }}
                            >
                              + Add New Address
                            </button>
                          </div>
                          {billingDetails.address.map((item, i) => {
                            return (
                              <div className="checkBoxFlex">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={
                                    placeOrderPayLoad?.address_id ==
                                    item?.address_id
                                  }
                                  onChange={() => {
                                    setPlaceOrderPayLoad({
                                      ...placeOrderPayLoad,
                                      address_id: item?.address_id
                                        ? item?.address_id
                                        : "",
                                      customer_location_coordinates:
                                        convertCoordinatesStringToObject(
                                          item?.customer_location_coordinates
                                        ),
                                    });
                                  }}
                                  id="flexCheckDefault22"
                                />
                                <div className="detailsNtxtarea">
                                  <label
                                    htmlFor="flexCheckDefault22"
                                    className="shipLabel"
                                  >
                                    {item?.address ? item?.address : ""}
                                  </label>
                                  <label
                                    htmlFor="flexCheckDefault22"
                                    className="shipLabel"
                                  >
                                    {item?.street_name ? item?.street_name : ""}
                                  </label>
                                  <label
                                    htmlFor="flexCheckDefault22"
                                    className="shipLabel"
                                  >
                                    {item?.landmark ? item?.landmark : ""}
                                  </label>
                                  <label
                                    htmlFor="flexCheckDefault22"
                                    className="shipLabel"
                                  >
                                    {item?.postal_code ? item?.postal_code : ""}
                                  </label>
                                  {/* <label
                                      htmlFor='flexCheckDefault22'
                                      className='shipLabel'
                                    >
                                      Ship to a different address ?
                                    </label>
                                    <Form.Control
                                      as='textarea'
                                      rows={3}
                                      placeholder='Order Note (Optional)'
                                    />*/}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {/* Payment  */}
                      {/* <div className='paymentOptions'>
                        <div className='paymentHeadin'>
                          <h1 className='headingTxt'>Payment Methods</h1>
                        </div>

                        <div className='optionList'>
                          <div class='form-check'>
                            <input
                              class='form-check-input'
                              type='radio'
                              name='flexRadioDefault41'
                              id='flexRadioDefault41'
                            />
                            <label
                              class='form-check-label'
                              for='flexRadioDefault41'
                            >
                              Debit or Credit Card
                            </label>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='nameDetails'>
                              <label className='NameLabel'>
                                Name on the card
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Enter the name'
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='nameDetails'>
                              <label className='NameLabel'>Card Number</label>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='xxxx - xxxx - xxxx - xxxx'
                              />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='nameDetails'>
                              <label className='NameLabel'>Expiry Month</label>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='MM'
                              />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='nameDetails'>
                              <label className='NameLabel'>Expiry Year</label>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='YYYY'
                              />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='nameDetails'>
                              <label className='NameLabel'>CVV</label>
                              <input
                                type='password'
                                className='form-control'
                                placeholder='***'
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='optionList'>
                              <div class='form-check'>
                                <input
                                  class='form-check-input'
                                  type='radio'
                                  name='flexRadioDefault42'
                                  id='flexRadioDefault22'
                                />
                                <label
                                  class='form-check-label'
                                  for='flexRadioDefault22'
                                >
                                  Net Banking
                                </label>
                              </div>
                              <div class='form-check'>
                                <input
                                  class='form-check-input'
                                  type='radio'
                                  name='flexRadioDefault42'
                                  id='flexRadioDefault23'
                                  checked
                                />
                                <label
                                  class='form-check-label'
                                  for='flexRadioDefault23'
                                >
                                  UPI
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="belowBtns">
                    {/* <Link to='/cart' className='backLink'> */}
                    <button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="backBtn"
                    >
                      Back
                    </button>
                    {/* </Link> */}
                    {/* <Link
                      to='#'
                      className='payLink'
                      onClick={() => {
                        // setCheckOne(false)
                        // setCheckTwo(true)
                      }}
                    > */}

                    <button
                      className="payBtn"
                      onClick={async () => {
                        placeOrder();
                      }}
                    >
                      {/* Proceed to Pay */}
                      Place order
                      <img src={liteImgArow} alt="" />
                    </button>
                    {/* </Link> */}
                  </div>
                </>
              ) : null}
              {/* Check Two */}
              {
                // checkTwo
                false ? (
                  <>
                    <div className="paymentOptions">
                      <div className="paymentHeadin">
                        <h1 className="headingTxt">Payment Methods</h1>
                      </div>
                      <div className="optionList">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Debit or Credit Card
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Net Banking
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            checked
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault3"
                          >
                            UPI
                          </label>
                        </div>
                        <div className="upiServices">
                          <div className="formsSection">
                            <label className="labelName">UPI ID</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your UPI ID"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="belowBtns">
                      {/* <Link
                        to='#'
                        className='backLink'
                        onClick={() => {
                          setCheckOne(true)
                          setCheckTwo(false)
                        }}
                      > */}
                      <button
                        onClick={() => {
                          setCheckOne(true);
                          setCheckTwo(false);
                        }}
                        className="backBtn"
                      >
                        Back
                      </button>
                      {/* </Link> */}
                      {/* <Link
                        to='#'
                        className='payLink'
                        onClick={() => setModal(true)}
                      > */}
                      <button className="payBtn" onClick={() => setModal(true)}>
                        Proceed to Pay
                        {/* Place Order */}
                        <img src={liteImgArow} alt="" />
                      </button>
                      {/* </Link> */}
                    </div>
                  </>
                ) : null
              }
            </div>
            <div className="col-md-4">
              <div className="couponCard">
                <div className="headingFlex">
                  <h1>Best Coupons For you</h1>
                  {/* <label htmlFor="">
                      All Coupons <img src={SideArrow} alt="" />
                    </label> */}
                </div>
                <div className="applyCouponBox">
                  <h1>
                    <img src={coupon} alt="" />
                    {discountDetail?.discountCoupon
                      ? discountDetail?.discountCoupon
                      : "Apply Coupons"}
                  </h1>
                  {discountDetail?.discountCoupon ? (
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-danger"
                      onClick={() => {
                        const total =
                          cartList &&
                          cartList.length > 0 &&
                          cartList.reduce((totalAmount, currentObject) => {
                            return (
                              totalAmount +
                              Number(currentObject?.product_rate) *
                                Number(currentObject?.product_quantity)
                            );
                          }, 0);
                        total && setTotalAmount(total);
                        setDiscountDetail({
                          discountAmt: "",
                          discountCoupon: "",
                        });
                        setCouponPayLoad({
                          total_amount: "",
                          coupon_id: "",
                          coupon_code: "",
                          discountPercentage: 0,
                        });
                      }}
                    >
                      Remove
                    </span>
                  ) : (
                    <button
                      className="applyBtn"
                      onClick={() => setCouponModal(true)}
                    >
                      Apply
                    </button>
                  )}
                </div>
              </div>
              <div className="orderSummaryCard">
                <div className="orderHead">
                  <h1>Order Summary</h1>
                </div>
                <div className="headingFlex">
                  <label htmlFor="" className="keyLite">
                    Product
                  </label>
                  <label htmlFor="" className="keyLite">
                    Amount
                  </label>
                </div>
                {cartList &&
                  cartList.length > 0 &&
                  cartList.map((item, i) => {
                    return (
                      <div className="headingFlex">
                        <label htmlFor="" className="keyDark">
                          {item?.product} X {item?.product_quantity}
                        </label>
                        <label htmlFor="" className="keyDark">
                          ₹ {item?.amount}
                        </label>
                      </div>
                    );
                  })}

                <div className="billlingFlex">
                  <div className="darkNliteLab">
                    <label htmlFor="" className="keyLite">
                      Subtotal
                    </label>
                    <label htmlFor="" className="valueDark">
                      ₹ {parseFloat(subTotal).toFixed(2)}
                    </label>
                  </div>
                  <div className="darkNliteLab">
                    <label htmlFor="" className="keyLite">
                      Shipping
                    </label>
                    <label htmlFor="" className="valueDark">
                      ₹ 0.00
                    </label>
                  </div>
                  <div className="darkNliteLab">
                    <label htmlFor="" className="keyLite">
                      Tax
                    </label>
                    <label htmlFor="" className="valueDark">
                      ₹ 0.00
                    </label>
                  </div>
                  {discountDetail?.discountAmt && (
                    <div className="darkNliteLab">
                      <label htmlFor="" className="keyLite">
                        Coupon Discount
                      </label>
                      <label htmlFor="" className="valueDark">
                        - ₹
                        {discountDetail?.discountAmt
                          ? discountDetail?.discountAmt
                          : "0.00"}
                      </label>
                    </div>
                  )}
                </div>
                <div className="darkNliteLab">
                  <label htmlFor="" className="keyLite">
                    Total
                  </label>
                  <label htmlFor="" className="valueDark">
                    ₹ {parseFloat(totalAmount).toFixed(2)}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal ? (
        <Modal
          size="lg"
          show={modal}
          // show={true}
          onHide={() => setModal(false)}
          centered
        >
          <Modal.Body>
            <div className="thumsBox">
              <img src={Thumbs} alt="" />
              <p>Your Order has been Placed Successfully !</p>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Modal
        // show={false}
        show={isAddressModalOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="address-modal"
        onHide={() => setIsAddressModalOpen(false)}
        // style={{width:"900px"}}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            onClick={() => setIsAddressModalOpen(false)}
          >
            Address
          </Modal.Title>
          <img
            style={{ cursor: "pointer" }}
            src={close}
            alt=""
            onClick={() => setIsAddressModalOpen(false)}
            className="cr-class"
          />
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateCustomerProfile}
          >
            <section className="address-card-modal mt-3">
              <dov className="row">
                <div className="col-md-12 form-group">
                  <Form.Group className="">
                    <Form.Label>
                      Address (Area, Flat No. Street...,){" "}
                    </Form.Label>
                    <AutocompleteGoogleInput
                      // value={ele?.address}
                      required={true}
                      className={"form-control "}
                      placeholder={"Enter your address"}
                      onPlaceSelect={(e, { lat, lng, address }) => {
                        const locationData = { lat, lng, address };
                        let tempObj = { ...updateCustomerPayload.address[0] };
                        let mod = {
                          ...tempObj,
                          address: address,
                          lat: locationData.lat,
                          lng: locationData.lng,
                        };
                        setUpdateCustomerPayload({
                          ...updateCustomerPayload,
                          address: [mod],
                        });
                        // passwordHandlerr(e, locationData);
                      }}
                    />
                    {/* <Form.Control
                      type="text"
                      placeholder="Enter your Address here..."
                      required
                      // value={ele?.address}
                      name="address"
                      onChange={passwordHandlerr}
                    /> */}
                    <Form.Control.Feedback type="invalid">
                      Address is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>Pin Code</Form.Label>
                    <Form.Control
                      type="tel"
                      pattern="[0-9]{6}"
                      placeholder=""
                      // value={ele?.postal_code}
                      name="postal_code"
                      onChange={(e) => {
                        // pattern="[6789][0-9]{9}"
                        if (
                          validNumber.test(e.target.value) &&
                          e.target.value.length < 7
                        ) {
                          passwordHandlerr(e);
                        }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid pincode
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>Street Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      // value={ele?.street_name}
                      name="street_name"
                      onChange={passwordHandlerr}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Street name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>Landmark (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      // value={ele?.landmark}
                      name="landmark"
                      onChange={passwordHandlerr}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>Address Type</Form.Label>
                    <Form.Select
                      aria-label="Work / Home / Other"
                      // value={ele?.address_type_id}
                      name="address_type_id"
                      onChange={passwordHandlerr}
                      required
                    >
                      <option value="">Select address type</option>
                      <option value={1}>Work</option>
                      <option value={2}>Home</option>
                      <option value={3}>Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Landmark is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="submit-btn-group mt-3">
                  <button className="filled-btn" type="submit">
                    Add Address
                    <img src={ic_arrow_right_white} alt="" />
                  </button>
                </div>
              </dov>
            </section>
          </Form>
        </Modal.Body>
      </Modal>

      {couponModal ? (
        <div className="cart-coupon-modal">
          <Modal
            id="couponModalBody"
            size="md"
            show={coupon}
            onHide={() => setCouponModal(false)}
            centered
          >
            <Modal.Body>
              <div className="modalCouponBox">
                <div className="headingFlex">
                  <h1 className="modalName">Apply Coupon</h1>
                  <label
                    htmlFor=""
                    className="CrossImg"
                    onClick={() => setCouponModal(false)}
                  >
                    <img src={GreenAxe} alt="" />
                  </label>
                </div>
                {/* <div className="couponBoxBorder">
                  <label htmlFor="" className="animalPer">
                    ANIMAL20%
                  </label>
                  <label htmlFor="" className="Check">
                    Check
                  </label>
                </div>   */}
                {coupons &&
                  coupons.length &&
                  coupons.map((item, idx) => {
                    const minmumDiscount =
                      (item?.min_amount * item?.coupon_discount_per) / 100;
                    const maxmumDiscount =
                      (item?.max_amount * item?.coupon_discount_per) / 100;
                    return (
                      <div className="checkAndDiscount" key={"jhasgey" + idx}>
                        <div className="checkboxIn">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            // checked={false}
                            checked={
                              couponPayLoad?.coupon_id === item?.coupon_id
                            }
                            id="flexCheckDefault"
                            onChange={() => {
                              setCouponPayLoad({
                                total_amount: Number(subTotal),
                                // total_amount: 2000,
                                coupon_id: item?.coupon_id,
                                discountPercentage: item?.coupon_discount_per,
                                min_amount: item?.min_amount,
                                coupon_code: item?.coupon_code,
                              });
                            }}
                          />
                        </div>
                        <div className="discountBadge">
                          <label
                            className="innBadge"
                            htmlFor="flexCheckDefault"
                          >
                            {item?.coupon_code}
                          </label>
                          <p className="paraPara">
                            Expires on :{" "}
                            {moment(item?.coupon_valid_upto).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                        <div className="savingDetails">
                          {/* <p className="savePara">Save ₹200</p> */}
                          <p className="belowPaara">
                            {item?.coupon_discount_per}% off on purchase between
                            ₹{item?.min_amount} and ₹{item?.max_amount}
                          </p>
                          {/* <p className="belowPaara">
                            ₹{maxmumDiscount} off on maximum purchase of ₹
                            {item?.max_amount}.
                          </p> */}
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="checkAndDiscount">
                  <div className="checkboxIn">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                  <div className="discountBadge">
                    <label className="innBadge" htmlFor="flexCheckDefault">
                      ANIMAL20%
                    </label>
                    <p className="paraPara">
                      Expires on : 31st December 2023 , 11:59 PM
                    </p>
                  </div>
                  <div className="savingDetails">
                    <p className="savePara">Save ₹200</p>
                    <p className="belowPaara">
                      ₹200 off on minimum purchase of ₹2000.
                    </p>
                  </div>
                </div>
                <div className="checkAndDiscount">
                  <div className="checkboxIn">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                  <div className="discountBadge">
                    <label className="innBadge" htmlFor="flexCheckDefault">
                      ANIMAL20%
                    </label>
                    <p className="paraPara">
                      Expires on : 31st December 2023 , 11:59 PM
                    </p>
                  </div>
                  <div className="savingDetails">
                    <p className="savePara">Save ₹200</p>
                    <p className="belowPaara">
                      ₹200 off on minimum purchase of ₹2000.
                    </p>
                  </div>
                </div> */}
                <div className="belowBottom">
                  {/* <div className="savingsTxt">
                    <p>Maximum Savings</p>
                    <label htmlFor="">₹200 </label>
                  </div> */}
                  <div className="applyBox">
                    <button
                      className="applyBtn"
                      onClick={() => {
                        applyCoupons();
                        // setCouponModal(false);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default MedicineCheckout;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import Star from "../../assets/images/Star.svg";
import dustbinImg from "../../assets/images/dustbinImg.svg";
import { PostCallWithAuthToken } from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";

const PetAllSellers = () => {
  // DATA FROM REDUX
  const { customer_id } = useReduxStore();
  // DATA FROM REDUX

  // DATA FROM CONTEXT
  const { setBkdropLdr, navigate } = useContext(AppContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const product_id = parseInt(searchParams.get("product_id"));
  const pet_shop_id = parseInt(searchParams.get("pet_shop_id"));
  const entity_type_id = parseInt(searchParams.get("entity_type_id"));
  // PET_SHOP_SELLER_LIST

  const [isLoading, setIsLoading] = useState(false);
  const [petSellerList, setPetSellerList] = useState([]);
  
  useEffect(() => {
    fetchPetSellerList();
  }, []);

  const fetchPetSellerList = async () => {
    const payLoad = {
      product_id: product_id,
      pet_shop_id: pet_shop_id,
    };
    try {
      setIsLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.PET_SHOP_SELLER_LIST,
        payLoad
      );
      const { result, data } = res?.json;
      if (result && data) {
        setPetSellerList(data);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addToBagCart = async (
    product_id,
    product_rate,
    pet_shop_id,
    entity_type_id,
    actionType
  ) => {
    const cartPayLoad = {
      customer_id: customer_id,
      product_id: product_id,
      product_quantity: 1,
      product_rate: product_rate,
      shop_id: pet_shop_id,
      entity_type_id: entity_type_id,
    };
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        notifySuccess("Added to cart successfully");
        navigate("/cart");
        // if (actionType === 'buy_now') {
        // }
      } else {
        navigate("/cart");
      }
    } catch (error) {
      console.log("catch error:", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <div className="allSellers" id="cx-main">
        <Subheader />
        {isLoading ? (
          <RotatingLoader />
        ) : (
          <div className="innerSellerTab">
            {petSellerList && petSellerList.length > 0 ? (
              <div className="scroller">
                <table className="tableMain">
                  <thead className="tableHead">
                    <tr>
                      <th>Seller</th>
                      <th>Price</th>
                      {/* <th>Delivery</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="tBody">
                    {petSellerList.map((item, idx) => {
                      return (
                        <tr key={item?.pet_shop_id}>
                          <td>
                            <div className="medicalDetailsFlex">
                              <div className="ratingNname">
                                {item?.pet_shop_name && (
                                  <label htmlFor="" className="name">
                                    {item?.pet_shop_name}
                                  </label>
                                )}
                                {item?.avg_rating && (
                                  <label htmlFor="" className="starNreview">
                                    <img src={Star} alt="" /> {item?.avg_rating}
                                  </label>
                                )}
                                {/* <p className='paraTag'>
                                  tempor incididunt ut labore et dolore magna
                                  aliqua. Ut enim ad minim{' '}
                                </p> */}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="priceBox">
                              <label htmlFor="" className="activePrice">
                                ₹{parseFloat(item?.product_rate).toFixed(2)}
                              </label>
                              {/* <label htmlFor='' className='mutedTxt'>
                                ₹50.00
                              </label> */}
                              {/* <p className='paraTag'>
                                10% Instant Discount on UPI Payment
                              </p> */}
                            </div>
                          </td>
                          {/* <td>
                            <div className='insidePara'>
                              <p>
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Iste repudiandae rerum
                                aspernatur velit facilis, impedit odit,
                              </p>
                            </div>
                          </td> */}
                          {item.pet_shop_product_quantity !== "0" ? (
                            <td>
                              <div className="twoBtns">
                                <button
                                  className="addBtn"
                                  onClick={() => {
                                    // if (
                                    //   item?.customer_card_id &&
                                    //   item?.customer_card_id
                                    // ) {
                                    //   navigate('/cart')
                                    //   return
                                    // } else {
                                    addToBagCart(
                                      item?.product_id,
                                      item?.product_rate,
                                      item?.pet_shop_id,
                                      item?.entity_type_id
                                    );
                                    // }
                                  }}
                                >
                                  <img src={dustbinImg} alt="" /> Add to Bag
                                </button>
                                {/* <Link to='/PetCart'>
                              </Link> */}
                                <button
                                  onClick={() => {
                                    addToBagCart(
                                      item?.product_id,
                                      item?.product_rate,
                                      item?.pet_shop_id,
                                      item?.entity_type_id,
                                      "buy_now"
                                    );
                                  }}
                                  className="buyBtn"
                                >
                                  Buy Now
                                </button>
                              </div>
                            </td>
                          ) : (
                            <td>
                              {" "}
                              <span style={{ color: "red" }}>Out of stock</span>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoDataMsg />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PetAllSellers;

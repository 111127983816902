import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import DoctorSymbol from "../../../assets/images/DoctorSymbol.svg";
import sign from "../../../assets/images/sign.png";
import ic_call from "../../../assets/images/ic_call.svg";
import ic_location from "../../../assets/images/ic_location.svg";
import { useDateFormats } from "../../../customHooks/useDateFormats";
import { AppContext } from "../../../context/AppContext";
import ic_mail from "../../../assets/images/ic_mail.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import ic_doc_download from "../../../assets/images/ic_doc_download.svg";

{
  /* <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /> */
}
const VaccinesModal = ({
  pdfExportComponent,
  vaccineModalOpen,
  setVaccineModalOpen,
  vaccineData,
  doctor_appointment_id,
}) => {
  // const {} = useDateFormats()
  const { navigate } = useContext(AppContext);
  const { formatDateToDdMmYyyy } = useDateFormats();

  const downLoadPdf = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div className="prescription-wrapper-main">
      <Modal
        show={vaccineModalOpen ? true : false}
        onHide={() => setVaccineModalOpen(false)}
        size="lg"
        id="prescription-modal"
        centered
      >
        <Modal.Body>
          <div className="petfood-modal">
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              margin={20}
              fileName={"Vaccines"}
              author="KendoReact Team"
            >
              <div className="orderModalInvoice">
                <div className="modalHeading">
                  <div className="upperBlue">
                    <h1 className="docName">
                      {vaccineData?.doctorInfo?.doctor_name
                        ?.toLowerCase()
                        ?.includes("dr")
                        ? ""
                        : "Dr."}{" "}
                      {vaccineData?.doctorInfo?.doctor_name}{" "}
                    </h1>

                    <label className="domainLab">
                      {vaccineData?.doctorInfo?.services
                        ? vaccineData?.doctorInfo?.services
                        : ""}{" "}
                    </label>
                    <label className="certificateLab">
                      Vaccines Prescription
                    </label>
                  </div>
                  <div className="docSymbolBox">
                    <img src={DoctorSymbol} className="docSymImg" alt="" />
                  </div>
                </div>
                <div className="modalBody">
                  <div className="detailsFill">
                    <div className="labelTxt" style={{width : "30%"}}>
                      <label className="widthFixed">Animal Owner:</label>
                    </div>
                    <input
                      style={{ backgroundColor: "#fff", width: "93%" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        vaccineData?.doctorInfo?.first_name
                          ? `${vaccineData?.doctorInfo?.first_name} ${vaccineData?.doctorInfo?.last_name}`
                          : ""
                      }
                    />
                  </div>
                  <div className="detailsFill">
                    <div className="labelTxt">
                      <label className="widthFixed">Patient Name:</label>
                    </div>
                    <input
                      style={{ backgroundColor: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        vaccineData?.doctorInfo?.animal_name
                          ? vaccineData?.doctorInfo?.animal_name
                          : ""
                      }
                    />
                  </div>
                  <div className="addressFlex">
                    <div className="labelTxt">
                      <label className="keyQuestion">Address:</label>
                    </div>
                    <p className="text-para">
                      {vaccineData?.doctorInfo?.address
                        ? `${
                            vaccineData?.doctorInfo?.street_name
                              ? vaccineData?.doctorInfo?.street_name + ","
                              : ""
                          } ${
                            vaccineData?.doctorInfo?.landmark
                              ? vaccineData?.doctorInfo?.landmark + ","
                              : ""
                          } ${vaccineData?.doctorInfo?.address}`
                        : ""}
                    </p>
                    {/* <input
                      style={{ backgroundColor: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        vaccineData?.doctorInfo?.address
                          ? `${vaccineData?.doctorInfo?.street_name ? vaccineData?.doctorInfo?.street_name + "," : ""} ${vaccineData?.doctorInfo?.landmark ? vaccineData?.doctorInfo?.landmark + "," : ""} ${vaccineData?.doctorInfo?.address}`
                          : ""
                      }
                    /> */}
                  </div>
                  <div className="dateNdAgeFlex">
                    <div className="ageBox">
                      <label className="ageLab">Age:</label>
                      <input
                        style={{ backgroundColor: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          vaccineData?.doctorInfo?.animal_age
                            ? vaccineData?.doctorInfo?.animal_age
                            : ""
                        }
                      />
                    </div>
                    <div className="dateBox">
                      <label className="ageLab">Date: </label>
                      <input
                        style={{ backgroundColor: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          vaccineData?.doctorInfo?.date
                            ? formatDateToDdMmYyyy(
                                vaccineData?.doctorInfo?.date
                              )
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="addressFlex">
                    <div className="labelTxt" style={{ width: "30%" }}>
                      <label className="keyQuestion">Reason / Symptoms:</label>
                    </div>
                    <input
                      style={{ backgroundColor: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        vaccineData?.doctorInfo?.disease
                          ? vaccineData?.doctorInfo?.disease
                          : ""
                      }
                    />
                  </div>

                  <div className="tableAndLandingBg">
                    <div className="backGroundPic"></div>
                    {Object.keys(vaccineData)?.length > 0 &&
                      vaccineData?.vaccines?.length > 0 && (
                        <div className="innerTable">
                          <table className="modalTable">
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Prescription</th>
                                {/* <th>Quantity</th> */}
                                <th>Dose (in ml)</th>
                                <th>Duration (in days)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vaccineData?.vaccines.map((item, i) => {
                                return (
                                  <tr key={"vaccineData" + i}>
                                    <td>{i + 1}</td>
                                    <td>
                                      {item?.vaccine ? item?.vaccine : ""}
                                    </td>
                                    <td>{item?.dose}</td>
                                    <td>{item?.duration}</td>
                                    {/* <td>
                                  {item?.prescription_frequency
                                    ? item?.prescription_frequency
                                    : ''}
                                </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>

                  {vaccineData?.doctorInfo?.imageData && (
                    <div className="signatureContent">
                      <div className="sigBox">
                        <div className="line">
                          <img
                            src={`data:image/${vaccineData?.doctorInfo?.imageType};base64,${vaccineData?.doctorInfo?.imageData}`}
                            alt=""
                          />
                        </div>
                        <label className="belowTxt">SIGNATURE</label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="belowDetails">
                  <div className="imgWrapper">
                    <div className="row heightAuto">
                      <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                        <div className="txt">
                          <div className="innerTxt">Contact</div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-12 p-0">
                        <div className="allCOntact">
                          <div className="emailAndCall">
                            {/* <div className="callNnumber">
                              <img src={ic_call} alt="" />
                              <label className="numberInn">
                                +91 9509932234
                                  {vaccineData?.doctorInfo?.doctor_contact_number} 
                              </label>
                            </div> */}
                            <div className="emialInn">
                              <img src={ic_mail} alt="" />
                              <label className="numberInn">
                                info@animalsapp.in
                              </label>
                            </div>
                          </div>

                          {/* <div className="locationInn">
                            <img src={ic_location} alt="" />
                            <label className="locationTxt">
                              Animals App, Animalsomatic clinic Near Ravi Steel,
                              Kamde, Ratu Road, Ranchi, Jharkhand, India 835222
                               {`${vaccineData?.doctorInfo?.city}`} 
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
            <div>
              <button
                type="button"
                onClick={() => {
                  navigate("/vaccinators");
                  sessionStorage.setItem(
                    "bookingData",
                    JSON.stringify({
                      vaccineData,
                      doctor_appointment_id,
                    })
                  );
                }}
                className="filled-btn"
                style={{ width: "162px", bottom: "13%" }}
              >
                Book Appointment
              </button>
            </div>
            <div
              onClick={downLoadPdf}
              // style={{
              //   position: "absolute",
              //   bottom: "57%",
              //   left: "90%",
              //   fontSize: "25px",
              //   cursor: "pointer",
              // }}
            >
              <button type="button" className="btn-blank-green">
                Download
                <img src={ic_doc_download} className="ic_doc_download" alt="" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default VaccinesModal;

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CalendarImg from '../../assets/images/calendarIcon.svg'
import medicineTabsd from '../../assets/images/medicineTabsd.png'
import medicine2 from '../../assets/images/medicine2.png'
import medicine3 from '../../assets/images/medicine3.png'
import Eyes from '../../assets/images/eye.svg'
import WhiteEyes from '../../assets/images/Whiteeye.svg'
import SaveImg from '../../assets/images/saveImg.svg'
import WhiteSaveImg from '../../assets/images/whiteSave.svg'
import Location from '../../assets/images/whiteLocation.svg'
import { simpleGetCallWithAuthToken } from '../../api/ApiServices'
import { ApiConfig } from '../../api/ApiConfig'
import { useReduxStore } from '../../customHooks/useReduxStore'
import NoDataMsg from '../../sharedComponent/NoDataMsg'
import RotatingLoader from '../../sharedComponent/RotatingLoader'
import { useDateFormats } from '../../customHooks/useDateFormats'
import { filter } from 'lodash'

const MyOrders = ({ setTotalOrders }) => {
  const {
    convertWithDateAndDay,
    convertWithMonthAndDay,
    formatDateToDdMmYyyy
  } = useDateFormats()
  const { customer_id } = useReduxStore()
  const [loader, setLoader] = useState(false)
  const [totalorder, setTotalOrder] = useState([])
  const [orders, setOrders] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const filteredData =
      totalorder &&
      totalorder.filter(item => {
        return item.items[0]?.product_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      })
    const arr = []
    filteredData &&
      filteredData.length &&
      filteredData.forEach(item => {
        let orderDate = item.order_date
        let existingObj = arr.find(obj => obj.order_date === orderDate)
        if (existingObj) {
          existingObj.orders.push(item)
        } else {
          arr.push({
            order_date: orderDate,
            orders: [item]
          })
        }
      })

    setOrders(arr)
  }, [searchTerm])

  useEffect(() => {
    fetchOrders()
  }, [])
  // FETCH_ORDERS
  const fetchOrders = async () => {
    try {
      setLoader(true)
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_ORDERS}/${customer_id}`
      )
      const { success, data } = res?.json
      if (success) {
        data && setTotalOrders(data.length)
        data && setTotalOrder(data)
        const arr = []
        // data && data?.sort((a, b) => new Date(a.date) - new Date(b.date))
        // let reverseData = Array.isArray(data) && data.reverse()
        data &&
          data.length &&
          data
            // ?.sort((a, b) => new Date(a.order_date) - new Date(b.order_date))
            .forEach(item => {
              let orderDate = item.order_date
              let existingObj = arr.find(obj => obj.order_date === orderDate)
              if (existingObj) {
                existingObj.orders.push(item)
              } else {
                arr.push({
                  order_date: orderDate,
                  orders: [item]
                })
              }
            })

        setOrders(arr)
      }
    } catch (error) {
      console.log('catch error:,', error)
    } finally {
      setLoader(false)
    }
  }
  const [orderPrescriptionData, setOrderPrescriptionData] = useState({})
  // my-order-prescription
  const fetchPrescriptionData = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig?.MY_ORDER_PRESCRIPTION}`
      )
      const { success, data } = res?.json
      if (success) {
        data && setOrderPrescriptionData(data)
      }
    } catch (error) {
      console.log('catch error,', error)
    }
  }

  return (
    <div className='mainOrderSection'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='headingSearch'>
            <h1 className='headTxt'>My Orders</h1>
            <div className='calendarFiled'>
              <input
                type='text'
                className='form-control'
                placeholder='Search Order’s'
                onChange={e => setSearchTerm(e.target.value)}
              />
              {/* <img src={CalendarImg} alt='' /> */}
            </div>
          </div>
        </div>
        {loader ? (
          <RotatingLoader />
        ) : orders && orders.length > 0 ? (
          orders.map((item, i) => {
            return (
              <div className='col-md-12' key={'orders' + i}>
                <div className='completeOrderTracker'>
                  <div className='dateBorder'>
                    <label htmlFor='' className='DateLab'>
                      {item?.order_date &&
                        convertWithMonthAndDay(item?.order_date)}
                      {/* {item?.order_date} */}
                      {/* 21 July, 2023 */}
                    </label>
                    <label htmlFor='' className='BorderLine'></label>
                  </div>
                  {item?.orders &&
                    item?.orders.length &&
                    item?.orders?.map((innerItem, i) => {
                      const imagePath =
                        innerItem?.items &&
                        innerItem?.items[0]?.product_images &&
                        innerItem?.items[0]?.product_images[0]
                          ?.product_image_path
                          ? innerItem?.items[0]?.product_images[0]
                              ?.product_image_path
                          : null

                      const medicineNames = innerItem?.items
                        ? innerItem?.items.map(name => name.product_name)
                        : // .join(', ')
                          null
                      return (
                        <div className='orderDetails'>
                          <div className='headFlex'>
                            <div className='mediFlex'>
                              <div className='mediBox'>
                                <img
                                  src={imagePath ? imagePath : ''}
                                  onError={e => {
                                    e.target.src = medicineTabsd
                                  }}
                                  alt=''
                                />
                              </div>
                              <div className='detailBox'>
                                {medicineNames &&
                                  medicineNames.length > 0 &&
                                  medicineNames &&
                                  medicineNames.length > 0 &&
                                  medicineNames.map((item, i, arr) => {
                                    return (
                                      <h1>
                                        {item}
                                        {medicineNames.length !== i + 1 && ','}
                                        {/* {innerItem.items[0].product_name} */}
                                      </h1>
                                    )
                                  })}
                                {innerItem?.product_type && (
                                  <label htmlFor=''>
                                    {innerItem?.product_type}
                                  </label>
                                )}
                              </div>
                            </div>
                            {innerItem?.total_amount && (
                              <div className='keyValueFlex'>
                                <label htmlFor='' className='key'>
                                  Amount
                                </label>
                                <label htmlFor='' className='kevalue'>
                                  ₹ {innerItem?.total_amount}
                                </label>
                              </div>
                            )}
                            <div className='keyValueFlex'>
                              <label htmlFor='' className='key'>
                                Order Date
                              </label>
                              {innerItem?.order_date && innerItem?.order_date && (
                                <label htmlFor='' className='kevalue'>
                                  {innerItem?.order_date}
                                  {/* {formatDateToDdMmYyyy(
                                      innerItem?.order_date
                                    )} */}
                                </label>
                              )}
                            </div>
                            {innerItem?.seller_name && (
                              <div className='keyValueFlex'>
                                <label htmlFor='' className='key'>
                                  Seller
                                </label>
                                <label htmlFor='' className='kevalue'>
                                  {innerItem?.seller_name}
                                </label>
                              </div>
                            )}
                            <div className='keyValueFlex'>
                              <label htmlFor='' className='key'>
                                Status
                              </label>
                              {innerItem?.delivery_date &&
                                innerItem?.delivery_date && (
                                  <label htmlFor='' className='kevalue'>
                                    Delivery by {innerItem?.delivery_date}
                                  </label>
                                )}
                            </div>
                          </div>
                          <div className='bottomBtns'>
                            {/* <img src={Eyes} alt='' />
                            <img src={SaveImg} alt='' /> */}
                            <Link
                              to={`/MyOrderDetails?order_id=${innerItem?.order_id}&orderCategory=${innerItem.orderCategory}&entity_type_id=${innerItem?.items[0]?.entity_type_id}`}
                            >
                              <button className='locationBtn'>
                                {' '}
                                <img src={Location} alt='' /> Track Order
                              </button>
                            </Link>
                          </div>
                        </div>
                      )
                    })}

                  {/* <div className='orderDetails'>
                    <div className='headFlex'>
                      <div className='mediFlex'>
                        <div className='mediBox'>
                          <img src={medicine2} alt='' />
                        </div>
                        <div className='detailBox'>
                          <h1>Dog Foos</h1>
                          <label htmlFor=''>Category</label>
                        </div>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Amount
                        </label>
                        <label htmlFor='' className='kevalue'>
                          ₹500
                        </label>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Order Date
                        </label>
                        <label htmlFor='' className='kevalue'>
                          02-02-2023
                        </label>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Seller
                        </label>
                        <label htmlFor='' className='kevalue'>
                          Gayatri Pharmacy
                        </label>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Status
                        </label>
                        <label htmlFor='' className='textSuccess'>
                          Delivered
                        </label>
                      </div>
                    </div>
                    <div className='bottomBtns'>
                      <button className='viewnBtn'>
                        {' '}
                        <img src={WhiteEyes} alt='' /> View
                      </button>
                      <img src={SaveImg} alt='' />
                    </div>
                  </div>
                  <div className='orderDetails'>
                    <div className='headFlex'>
                      <div className='mediFlex'>
                        <div className='mediBox'>
                          <img src={medicine3} alt='' />
                        </div>
                        <div className='detailBox'>
                          <h1>Dog Foos</h1>
                          <label htmlFor=''>Category</label>
                        </div>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Amount
                        </label>
                        <label htmlFor='' className='kevalue'>
                          ₹500
                        </label>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Order Date
                        </label>
                        <label htmlFor='' className='kevalue'>
                          02-02-2023
                        </label>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Seller
                        </label>
                        <label htmlFor='' className='kevalue'>
                          Gayatri Pharmacy
                        </label>
                      </div>
                      <div className='keyValueFlex'>
                        <label htmlFor='' className='key'>
                          Status
                        </label>
                        <label htmlFor='' className='textDanger'>
                          Cancelled
                        </label>
                      </div>
                    </div>
                    <div className='bottomBtns'>
                      <img src={Eyes} alt='' />

                      <button className='locationBtn'>
                        {' '}
                        <img src={WhiteSaveImg} alt='' /> Download
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            )
          })
        ) : (
          <NoDataMsg MSG='No Orders Found' />
        )}{' '}
        {/* <div className='col-md-12'>
         
          <div className='completeOrderTracker'>
           
            <div className='dateBorder'>
             
              <label htmlFor='' className='DateLab'>
                // 21 July, 2023
              </label>
              // <label htmlFor='' className='BorderLine'></label>
             
            </div>
           
            <div className='orderDetails'>
             
              <div className='headFlex'>
               
                <div className='mediFlex'>
                 
                  <div className='mediBox'>
                    // <img src={medicineTabsd} alt='' />
                   
                  </div>
                 
                  <div className='detailBox'>
                    // <h1>Dog Foos</h1>
                    // <label htmlFor=''>Category</label>
                   
                  </div>
                 
                </div>
               
                <div className='keyValueFlex'>
                 
                  <label htmlFor='' className='key'>
                    // Amount
                  </label>
                 
                  <label htmlFor='' className='kevalue'>
                    // ₹500
                  </label>
                 
                </div>
               
                <div className='keyValueFlex'>
                 
                  <label htmlFor='' className='key'>
                    // Order Date
                  </label>
                 
                  <label htmlFor='' className='kevalue'>
                    // 02-02-2023
                  </label>
                 
                </div>
               
                <div className='keyValueFlex'>
                 
                  <label htmlFor='' className='key'>
                    // Seller
                  </label>
                 
                  <label htmlFor='' className='kevalue'>
                    // Gayatri Pharmacy
                  </label>
                 
                </div>
               
                <div className='keyValueFlex'>
                 
                  <label htmlFor='' className='key'>
                    // Status
                  </label>
                 
                  <label htmlFor='' className='kevalue'>
                    // Delivery by 04-02-2023
                  </label>
                 
                </div>
               
              </div>
             
              <div className='bottomBtns'>
                // <img src={Eyes} alt='' />
                // <img src={SaveImg} alt='' />
               
                <button className='locationBtn'>
                  // // <img src={Location} alt='' /> Track Order
                </button>
               
              </div>
             
            </div>
           
          </div>
         
        </div> */}
      </div>
    </div>
  )
}

export default MyOrders

import React, { useState } from 'react'
import Subheader from '../../sharedComponent/Subheader';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import liteImgArow from "../../assets/images/lightIcon.svg";
import Modal from 'react-bootstrap/Modal';
import Thumbs from "../../assets/images/thumsUpHard.svg"

const PetCheckout = () => {

    const [checkOne, setCheckOne] = useState(true);
    const [checkTwo, setCheckTwo] = useState(false);
    const [modal, setModal] = useState(false);

    return (
        <>
            <div className="medicineCheckout" id="cx-main">
                <Subheader />
                <div className="mainCheckout">
                    <div className="row">
                        <div className="col-md-8">
                            {
                                checkOne ?
                                    <>
                                        <div className="billingDetailCard">
                                            <div className="billingHead">
                                                <h1>Billing Details</h1>
                                            </div>
                                            <h1 className='infoTxt'>Personal Information</h1>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="preDefaultDetails">
                                                        <h1>Dhananjay Pakhare</h1>
                                                        <h1>dhananjayp@gmail.com</h1>
                                                        <h1>98989 98989</h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="detailsFiller">
                                                        <label htmlFor="" className='Tittle'>First Name</label>
                                                        <input type="text" className='form-control' placeholder='Enter your First name' />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="detailsFiller">
                                                        <label htmlFor="" className='Tittle'>Last Name</label>
                                                        <input type="text" className='form-control' placeholder='Enter your Last name' />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="detailsFiller">
                                                        <label htmlFor="" className='Tittle'>Email</label>
                                                        <input type="email" className='form-control' placeholder='Enter your Email' />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="detailsFiller">
                                                        <label htmlFor="" className='Tittle'>Phone Number</label>
                                                        <input type="tel" className='form-control' placeholder='Enter your Phone Number' />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="loginAgain">
                                                        <label htmlFor="" className='reLogin'>Existing Customer ? <Link to="/">Click here to Login</Link></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h1 className='infoTxt'>Shipping Details</h1>
                                                    <div className="checkBoxFlex">
                                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault22" />
                                                        <div className="detailsNtxtarea">
                                                            <label htmlFor='flexCheckDefault22' className='shipLabel'>Ship to a different address ?</label>
                                                            <Form.Control as="textarea" rows={3} placeholder='Order Note (Optional)' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="paymentOptions">
                                                    <div className="paymentHeadin">
                                                        <h1 className='headingTxt'>Payment Methods</h1>
                                                    </div>
                                                    <div className="optionList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="flexRadioDefault41" id="flexRadioDefault41" />
                                                            <label class="form-check-label" for="flexRadioDefault41">
                                                                Debit or Credit Card
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="nameDetails">
                                                                <label className="NameLabel">Name on the card</label>
                                                                <input type="text" className="form-control" placeholder='Enter the name' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="nameDetails">
                                                                <label className="NameLabel">Card Number</label>
                                                                <input type="text" className="form-control" placeholder='xxxx - xxxx - xxxx - xxxx' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="nameDetails">
                                                                <label className="NameLabel">Expiry Month</label>
                                                                <input type="text" className="form-control" placeholder='MM' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="nameDetails">
                                                                <label className="NameLabel">Expiry Year</label>
                                                                <input type="text" className="form-control" placeholder='YYYY' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="nameDetails">
                                                                <label className="NameLabel">CVV</label>
                                                                <input type="password" className="form-control" placeholder='***' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="optionList">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="flexRadioDefault42" id="flexRadioDefault22" />
                                                                    <label class="form-check-label" for="flexRadioDefault22">
                                                                        Net Banking
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="flexRadioDefault42" id="flexRadioDefault23" checked />
                                                                    <label class="form-check-label" for="flexRadioDefault23">
                                                                        UPI
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="belowBtns">
                                            <Link to="/PetCart" className='backLink'>
                                                <button className='backBtn'>Back</button>
                                            </Link>
                                            <Link to="#" className='payLink' onClick={() => {
                                                setCheckOne(false)
                                                setCheckTwo(true)
                                            }}>
                                                <button className='payBtn'>Proceed to Pay <img src={liteImgArow} alt="" /></button>
                                            </Link>
                                        </div>
                                    </> : null
                            }
                            {/* Check Two */}
                            {
                                checkTwo ?
                                    <>
                                        <div className="paymentOptions">
                                            <div className="paymentHeadin">
                                                <h1 className='headingTxt'>Payment Methods</h1>
                                            </div>
                                            <div className="optionList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                        Debit or Credit Card
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                        Net Banking
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                                    <label class="form-check-label" for="flexRadioDefault3">
                                                        UPI
                                                    </label>
                                                </div>
                                                <div className="upiServices">
                                                    <div className="formsSection">
                                                        <label className="labelName">UPI ID</label>
                                                        <input type="text" className="form-control" placeholder='Enter your UPI ID' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="belowBtns">
                                            <Link to="#" className='backLink' onClick={() => {
                                                setCheckOne(true)
                                                setCheckTwo(false)
                                            }}>
                                                <button className='backBtn'>Back</button>
                                            </Link>
                                            <Link to="#" className='payLink' onClick={() => setModal(true)}>
                                                <button className='payBtn'>Proceed to Pay <img src={liteImgArow} alt="" /></button>
                                            </Link>
                                        </div>
                                    </> : null
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="orderSummaryCard">
                                <div className="orderHead">
                                    <h1>Order Summary</h1>
                                </div>
                                <div className="headingFlex">
                                    <label htmlFor="" className='keyLite'>Product</label>
                                    <label htmlFor="" className='keyLite'>Amount</label>
                                </div>
                                <div className="headingFlex">
                                    <label htmlFor="" className='keyDark'>Pet Supplements for Dog X 10</label>
                                    <label htmlFor="" className='keyDark'>₹300</label>
                                </div>
                                <div className="headingFlex">
                                    <label htmlFor="" className='keyDark'>Pet Supplements for Dog X 05</label>
                                    <label htmlFor="" className='keyDark'>₹300</label>
                                </div>
                                <div className="headingFlex">
                                    <label htmlFor="" className='keyDark'>Pet Supplements for Dog X 10</label>
                                    <label htmlFor="" className='keyDark'>₹300</label>
                                </div>
                                <div className="headingFlex">
                                    <label htmlFor="" className='keyDark'>Pet Supplements for Dog X 10</label>
                                    <label htmlFor="" className='keyDark'>₹300</label>
                                </div>
                                <div className="billlingFlex">
                                    <div className="darkNliteLab">
                                        <label htmlFor="" className='keyLite'>Subtotal</label>
                                        <label htmlFor="" className='valueDark'>₹ 400:00</label>
                                    </div>
                                    <div className="darkNliteLab">
                                        <label htmlFor="" className='keyLite'>Shipping</label>
                                        <label htmlFor="" className='valueDark'>₹ 20:00</label>
                                    </div>
                                    <div className="darkNliteLab">
                                        <label htmlFor="" className='keyLite'>Tax</label>
                                        <label htmlFor="" className='valueDark'>₹ 0:00</label>
                                    </div>
                                    <div className="darkNliteLab">
                                        <label htmlFor="" className='keyLite'>Coupon Discount</label>
                                        <label htmlFor="" className='valueDark'>₹ 400:00</label>
                                    </div>
                                </div>
                                <div className="darkNliteLab">
                                    <label htmlFor="" className='keyLite'>Total</label>
                                    <label htmlFor="" className='valueDark'>₹ 400:00</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {
                modal ?
                    <Modal
                        size='sm'
                        show={modal}
                        onHide={() => setModal(false)}
                        centered
                    >
                        <Modal.Body >
                            <div className="thumsBox">
                                <img src={Thumbs} alt="" />
                                <p>Your Order has been Placed Successfully !</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }
        </>
    )
}

export default PetCheckout;

import React from 'react'
import { useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { useTimer } from 'react-timer-hook'

const OtpTimer = ({ requestOTP }) => {
  const time = new Date()
  const [isExpire, setIsExpire] = useState(true)
  const { seconds, minutes, isRunning, pause, resume, restart } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      setIsExpire(false)
    }
  })
  time.setSeconds(time.getSeconds() + 60)

  return (
    <div className={isExpire ? 'resendOtpBtn' : 'resendOtpActiveBtn'}>
      <button
        disabled={isExpire ? true : false}
        type='button'
        onClick={requestOTP}
        style={{
          backgroundColor: '#fff',
          color: '#1d7c7c ',
          borderRadius: '2px'
        }}
      >
        Resend OTP {seconds ? `(${seconds})` : ''}
      </button>
    </div>
  )
}

export default OtpTimer

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../sharedComponent/Header";
import Subheader from "../../sharedComponent/Subheader";
import Accordion from "react-bootstrap/Accordion";
import FilterImg from "../../assets/images/filterIcon.svg";
import DoctorSahab from "../../assets/images/DoctorImg.svg";
import Heart from "../../assets/images/heart.svg";
import HeartAct from "../../assets/images/heartActive.svg";
import Location from "../../assets/images/location.svg";
import Watch from "../../assets/images/watch.svg";
import Dollar from "../../assets/images/dollar.svg";
import Star from "../../assets/images/Star.svg";
import LitStar from "../../assets/images/liteStar.svg";
import BlueImgArow from "../../assets/images/darkIcon.svg";
import liteImgArow from "../../assets/images/lightIcon.svg";
import MultiRangeSlider from "multi-range-slider-react";
import {
  PostCallWithAuthToken,
  postCallWithFormDataWithToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import { useReduxStore } from "../../customHooks/useReduxStore";
import NoData from "../../sharedComponent/NoDataMsg";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import moment from "moment";
import PopupOverlay from "../Home/PopupOverlay";
import status_available from "../../assets/images/statusIcons/status_available.svg";
import status_away from "../../assets/images/statusIcons/status_away.svg";
import status_busy from "../../assets/images/statusIcons/status_busy.svg";
import status_offline from "../../assets/images/statusIcons/status_offline.svg";

const DoctorsList = () => {
  // DATA FROM CONTEXT
  const {
    setBkdropLdr,
    drocterCallOpen,
    doctorListPayLoad,
    setDoctorListPayLoad,
    currentPage,
    setCurrentPage,
    navigate,
    setEventKey,
  } = useContext(AppContext);
  // DATA FROM CONTEXT
  const {
    customerLoggedInData,
    customer_animal,
    customer_address,
    customer_details,
  } = useReduxStore();
  const { customer_id } =
    customerLoggedInData?.loginData && customerLoggedInData?.loginData;

  //STATES
  const [click, setClick] = useState(false);
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(45);
  const [specialistCheckList, setSpecialistCheckList] = useState([]);
  const [specialistFetched, setSpecialistFetched] = useState(false);
  const [allDoctors, setAllDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPageData, setCurrentPageData] = useState({});

  //STATES

  useEffect(() => {
    getSpecialist();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    fetchAllDoctors();
  }, [doctorListPayLoad]);

  // GET_DOCTORS_SPECIALIST API
  const getSpecialist = async () => {
    try {
      const payLoad = {
        type: "specializations",
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_DOCTORS_SPECIALIST,
        payLoad
      );
      const { result, data } = res?.json;
      const specialist_id = [data[0]?.specialization_id];

      if (result) {
        data && setSpecialistCheckList(data);
        // setDoctorListPayLoad({
        //   ...doctorListPayLoad,
        //   specialist_id: specialist_id
        // })
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  // GET_ALL_DOCTORS API
  const fetchAllDoctors = async () => {
    try {
      setLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_ALL_DOCTORS,
        doctorListPayLoad
      );
      console.log("res,", res);
      const { data, success, total, current_page, total_pages, last_page } =
        res?.json;
      if (success) {
        data && setAllDoctors(data);
        setCurrentPageData({
          total,
          current_page,
          total_pages,
          last_page,
        });
      } else {
        setAllDoctors([]);
      }
    } catch (error) {
      console.log("Catch Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // GET_ALL_DOCTORS API
  const fetchMoreDoctors = async () => {
    try {
      const payLoad = { ...doctorListPayLoad, page: currentPage };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_ALL_DOCTORS,
        payLoad
      );
      const { data, success, total, current_page, total_pages, last_page } =
        res?.json;
      if (success) {
        data && setAllDoctors((prev) => [...prev, ...data]);
        setCurrentPageData({
          total,
          current_page,
          total_pages,
          last_page,
        });
      }
    } catch (error) {
      console.log("Catch Error:", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreDoctors();
    }
  }, [currentPage]);

  const handleInfiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleInfiniteScroll)
  //   return () => window.removeEventListener('scroll', handleInfiniteScroll)
  // }, [])

  // ADD_FAVOURITE_DOCTOR API AddFavDoc
  const addFavourite = async (doctorId, action) => {
    try {
      const payLoad = {
        customer_id: customer_id,
        doctor_id: doctorId,
      };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_FAVOURITE_DOCTOR,
        payLoad
      );

      const { result, message } = res?.json;
      if (result) {
        // notifySuccess('Added to favourites list')
        if (action === "like") {
          const tempArr = allDoctors.map((item, i) => {
            return item?.doctor_id === doctorId
              ? { ...item, customer_favourite_id: true }
              : item;
          });
          setAllDoctors(tempArr);
        } else {
          const tempArr = allDoctors.map((item, i) => {
            return item?.doctor_id === doctorId
              ? { ...item, customer_favourite_id: false }
              : item;
          });
          setAllDoctors(tempArr);
        }
      } else {
        notifyError("Already added into favourites list");
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <div className="DoctorsList" id="cx-main">
        <Subheader />

        {/* main Cards Content */}
        <div className="row">
          {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}

          {/* Left Menu's Accordians */}
          <div className="col-md-3 ColBox30">
            <div id="accordiansTab">
              <div className="mainHeadTxt">
                <h1 className="headName">Filter</h1>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Specialist</Accordion.Header>
                  <Accordion.Body>
                    <div className="checksPortion">
                      {specialistCheckList &&
                        specialistCheckList.length &&
                        specialistCheckList.map((item, idx) => {
                          return (
                            <div
                              key={item?.specialization_id}
                              className="form-check checkNlabel"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`flexCheckDefault${specialistCheckList}${idx}`}
                                checked={doctorListPayLoad?.specialist_id.includes(
                                  item?.specialization_id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setDoctorListPayLoad({
                                      ...doctorListPayLoad,
                                      specialist_id: [
                                        ...doctorListPayLoad.specialist_id,
                                        item?.specialization_id,
                                      ],
                                    });
                                  } else {
                                    const filteredIds =
                                      doctorListPayLoad.specialist_id.filter(
                                        (spId, i) => {
                                          return (
                                            spId !== item?.specialization_id
                                          );
                                        }
                                      );
                                    console.log("filteredIds,", filteredIds);
                                    setDoctorListPayLoad({
                                      ...doctorListPayLoad,
                                      specialist_id: filteredIds,
                                    });
                                  }
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexCheckDefault${specialistCheckList}${idx}`}
                              >
                                {item?.specialization}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Experience</Accordion.Header>
                  <Accordion.Body>
                    <div className="sliderAreas">
                      <MultiRangeSlider
                        min={0}
                        max={50}
                        step={1}
                        minValue={Number(doctorListPayLoad?.startExperience)}
                        maxValue={Number(doctorListPayLoad?.endExperience)}
                        onInput={(e) => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            startExperience: Number(e.minValue),
                            endExperience: Number(e.maxValue),
                          });
                        }}
                      ></MultiRangeSlider>
                      <div className="belowSteps">
                        <label htmlFor="">0 years</label>
                        <label htmlFor="">50 years</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey='2'>
                  <Accordion.Header>Area</Accordion.Header>
                  <Accordion.Body>
                    <div className='sliderAreas'>
                      <MultiRangeSlider
                        min={1}
                        max={9}
                        step={3}
                        // minValue={0}
                        // maxValue={0}
                        onInput={e => {
                          // setMinValue(e.minValue);
                          // setMaxValue(e.maxValue);
                        }}
                      ></MultiRangeSlider>
                      <div className='belowSteps'>
                        <label htmlFor=''>0 Kms</label>
                        <label htmlFor=''>25 Kms</label>
                        <label htmlFor=''>50 Kms</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Fees</Accordion.Header>
                  <Accordion.Body>
                    <div className="sliderAreas">
                      <MultiRangeSlider
                        min={0}
                        max={3000}
                        step={50}
                        minValue={Number(doctorListPayLoad?.startPrice)}
                        maxValue={Number(doctorListPayLoad?.endPrice)}
                        onInput={(e) => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            startPrice: Number(e.minValue),
                            endPrice: Number(e.maxValue),
                          });
                        }}
                      ></MultiRangeSlider>

                      <div className="belowSteps">
                        <label htmlFor="">0 &#8377;</label>
                        <label htmlFor="">3000 &#8377;</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3666" style={{ display: "none" }}>
                  <Accordion.Header>Fees</Accordion.Header>
                  <Accordion.Body>
                    <div className="sliderAreas">
                      <MultiRangeSlider
                        min={100}
                        max={9999}
                        step={50}
                        minValue={Number(doctorListPayLoad?.startPrice)}
                        maxValue={Number(doctorListPayLoad?.endPrice)}
                        onInput={(e) => {
                          // setDoctorListPayLoad({
                          //   ...doctorListPayLoad,
                          //   startPrice: Number(e.minValue),
                          //   endPrice: Number(e.maxValue)
                          // })
                        }}
                      ></MultiRangeSlider>

                      <div className="belowSteps">
                        <label htmlFor="">100 &#8377;</label>
                        <label htmlFor="">2000 &#8377;</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>Ratings</Accordion.Header>
                  <Accordion.Body>
                    <div className="ratingStars">
                      <img
                        src={doctorListPayLoad?.rating >= 1 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: doctorListPayLoad?.rating ? 0 : 1,
                          });
                        }}
                        className="me-2"
                        alt=""
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 2 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 2,
                          });
                        }}
                        className="me-2"
                        alt=""
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 3 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 3,
                          });
                        }}
                        className="me-2"
                        alt=""
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 4 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 4,
                          });
                        }}
                        className="me-2"
                        alt=""
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 5 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 5,
                          });
                        }}
                        alt=""
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          {/* Cards Content Section */}
          <div className="col-md-9 colBox90">
            <div className="cardsSections">
              <div className="topFilterSection">
                <div className="txtSec">
                  <h1 className="mainTxt">
                    Doctor’s{" "}
                    <span className="mutedTxt">
                      {`Showing ${1}-${allDoctors && allDoctors.length} of ${
                        currentPageData?.total ? currentPageData?.total : 0
                      } Doctor’s`}
                    </span>{" "}
                  </h1>
                </div>
                {/* <div className='filterImg'>
                  <img src={FilterImg} alt='' />
                </div> */}
              </div>
              <div className="scrollerAbove">
                <div className="row">
                  {loading ? (
                    <RotatingLoader />
                  ) : allDoctors.length > 0 ? (
                    allDoctors &&
                    allDoctors.map((item, i) => {
                      const specializations =
                        item?.specialization_details &&
                        item?.specialization_details
                          .map(
                            (specialization) => specialization?.specialization
                          )
                          .join(", ");
                      return (
                        <div className="col-md-6">
                          <div className="cardsInsider" key={"allDoctors" + i}>
                            <div className="detailsCOntent">
                              <div className="imgContainer">
                                <img
                                  style={{ borderRadius: "10px" }}
                                  // doctor_profile_image_path
                                  src={
                                    item?.doctor_profile_image_path
                                      ? item?.doctor_profile_image_path
                                      : dummydoctor
                                  }
                                  className="insideImg"
                                  alt=""
                                />
                                {/* {i + 1} */}
                              </div>
                              <div className="detailsInside">
                                <div className="detailsTop">
                                  <div className="detailsTxt">
                                    {item?.doctor_name && (
                                      <h1 htmlFor="" className="NameTxt">
                                        {`${
                                          item?.doctor_name
                                            .toLowerCase()
                                            .includes("dr")
                                            ? ""
                                            : "Dr."
                                        } ${item?.doctor_name}`}
                                        {/* Dr. {item?.doctor_name} */}
                                      </h1>
                                    )}
                                    <div className="doctor-status capital">
                                      <img
                                        src={
                                          item?.status_id == 6
                                            ? status_available
                                            : item?.status_id == 9
                                            ? status_offline
                                            : item?.status_id == 7
                                            ? status_away
                                            : item?.status_id == 8
                                            ? status_busy
                                            : null
                                        }
                                      />
                                      {item?.status}
                                    </div>
                                    <label htmlFor="" className="domainTxt">
                                      {item?.specialization_details &&
                                        item?.specialization_details[0]
                                          ?.specialization}{" "}
                                      |{" "}
                                      {
                                        item?.specialization_details[0]
                                          ?.experience
                                      }{" "}
                                      years
                                      {/* {item?.specialization_details[0]
                                        ?.experience &&
                                        !item?.specialization_details[0]?.experience
                                          ?.toLowerCase()
                                          .includes('year') &&
                                        'years'} */}
                                    </label>
                                  </div>
                                  <div className="detActionBtn">
                                    <img
                                      onClick={() => {
                                        // setClick(!click)
                                        if (item?.customer_favourite_id) {
                                          addFavourite(
                                            item?.doctor_id,
                                            "dislike"
                                          );
                                        } else {
                                          addFavourite(item?.doctor_id, "like");
                                        }
                                      }}
                                      // src={click ? HeartAct : Heart}
                                      src={
                                        item?.customer_favourite_id
                                          ? HeartAct
                                          : Heart
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>

                                <div className="belowCOntent">
                                  {item?.schedule_details &&
                                    item?.schedule_details[0]
                                      ?.doctor_clinic_name &&
                                    item?.schedule_details[0]
                                      ?.doctor_clinic_address && (
                                      <h1 htmlFor="" className="locationTxt">
                                        <img
                                          src={Location}
                                          className="me-2"
                                          alt=""
                                        />
                                        <span>{`${item?.schedule_details[0]?.doctor_clinic_name}, ${item?.schedule_details[0]?.doctor_clinic_address}`}</span>
                                        {/* Animal’s Clinic, Pune */}
                                      </h1>
                                    )}
                                  {item?.schedule_details && (
                                    <h1 htmlFor="" className="belowTxt">
                                      <img
                                        src={Watch}
                                        className="me-2"
                                        alt=""
                                      />
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_sun &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          S
                                        </span>
                                      }{" "}
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_mon &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          M
                                        </span>
                                      }{" "}
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_tue &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          T
                                        </span>
                                      }{" "}
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_wed &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          W
                                        </span>
                                      }{" "}
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_thu &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          T
                                        </span>
                                      }{" "}
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_fri &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          F
                                        </span>
                                      }{" "}
                                      {
                                        <span
                                          className={
                                            !item?.schedule_details[0]
                                              ?.is_weekend_sat &&
                                            "docAvailableStatus"
                                          }
                                        >
                                          S
                                        </span>
                                      }{" "}
                                      :{" "}
                                      {item?.schedule_details[0] &&
                                        moment(
                                          item?.schedule_details[0]
                                            ?.clinic_morning_start_time,
                                          ["HH:mm"]
                                        ).format("hh:mm a")}{" "}
                                      -{" "}
                                      {item?.schedule_details[0] &&
                                        moment(
                                          item?.schedule_details[0]
                                            ?.clinic_evening_end_time,
                                          ["HH:mm"]
                                        ).format("hh:mm a")}
                                      {/* 10:00 AM - 06:00 PM */}
                                    </h1>
                                  )}

                                  <h1 htmlFor="" className="belowTxt belowMg3">
                                    <img src={Dollar} className="me-2" alt="" />
                                    ₹
                                    {item?.schedule_details[0]
                                      ?.slot_morning_doctor_charge
                                      ? item?.schedule_details[0]
                                          ?.slot_morning_doctor_charge
                                      : 0}{" "}
                                    / Morning I ₹
                                    {item?.schedule_details[0]
                                      ?.slot_evening_doctor_charge
                                      ? item?.schedule_details[0]
                                          ?.slot_evening_doctor_charge
                                      : 0}{" "}
                                    / Evening
                                    {/* {(`₹${item?.schedule_details[0]?.slot_morning_doctor_charge}` /
                                      'Visit') |
                                      (`₹${item?.schedule_details[0]?.slot_morning_doctor_charge}` /
                                        'Video Call')} */}
                                  </h1>
                                  {
                                    <h1 htmlFor="" className="belowTxt DiffClr">
                                      <img src={Star} className="me-2" alt="" />
                                      {item?.rating && item?.rating
                                        ? item?.rating
                                        : 0}{" "}
                                      |{" "}
                                      {item?.reviews_count &&
                                      item?.reviews_count
                                        ? item?.reviews_count
                                        : 0}{" "}
                                      reviews
                                    </h1>
                                   }
                                </div>
                              </div>
                            </div>
                            <div className="detailCardsBtns">
                              <Link
                                to={`/DoctorDetails/${item?.doctor_id}`}
                                className="DetailsBtn"
                              >
                                <button>
                                  View Details <img src={BlueImgArow} alt="" />
                                </button>
                              </Link>
                              {item?.schedule_details &&
                                item?.schedule_details[0]?.doctor_clinic_id && (
                                  // <Link
                                  //   // to={`/BookAppoitment/${item?.doctor_id}`}
                                  //   // to={`/BookAppoitment?doctorId=${item?.doctor_id}&clinic_id=${item?.schedule_details[0]?.doctor_clinic_id}`}
                                  //   className="bookBtn"
                                  // >
                                  <button
                                    className="bookBtn"
                                    onClick={() => {
                                      if (
                                        !customer_address ||
                                        !customer_details
                                      ) {
                                        navigate("/ProfileSetting");
                                        setEventKey("11");
                                        if(!customer_details){
                                          notifyError(
                                            "Please update your profile details and address"
                                          );
                                        } else if (!customer_address) {
                                          notifyError(
                                            "Please add your address"
                                          );
                                        }
                                        sessionStorage.setItem(
                                          "navigationDetails",
                                          JSON.stringify({
                                            isNavigateFromDoctorPage: true,
                                            doctor_id: item?.doctor_id,
                                            clinic_id:
                                              item?.schedule_details[0]
                                                ?.doctor_clinic_id,
                                          })
                                        )
                                      } else {
                                        navigate(
                                          `/BookAppoitment?doctorId=${item?.doctor_id}&clinic_id=${item?.schedule_details[0]?.doctor_clinic_id}`
                                        )
                                      }
                                    }}
                                  >
                                    Book Now <img src={liteImgArow} alt="" />
                                  </button>
                                  // </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <NoDataMsg />
                  )}
                  {/* {bkdropLdr && (
                    <div style={{ marginTop: '-162px' }}>
                      {' '}
                      <RotatingLoader />{' '}
                    </div>
                  )} */}
                  {!loading &&
                    !currentPageData?.last_page &&
                    allDoctors.length > 0 && (
                      <div
                        className="col-md-12 text-center"
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      >
                        <button className="showMoreBtn">Show More</button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorsList;

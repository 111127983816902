import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import Arrow from "../../assets/images/ArrowGreen.svg";
import SaveImg from "../../assets/images/saveImg.svg";
import WhiteSaveImg from "../../assets/images/whiteSave.svg";
import medicineTabsd from "../../assets/images/medicineTabsd.png";
import { Stepper, Step } from "react-form-stepper";
import Star from "../../assets/images/Star.svg";
import { AppContext } from "../../context/AppContext";
import MedicalPrescriptionComp from "./MedicalPrescriptionComp";
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { Form } from "react-bootstrap";
import { notifySuccess } from "../../ToastMessages/notify";
import { useReduxStore } from "../../customHooks/useReduxStore";
import ic_start from "../../assets/images/ic_start.svg";
import { useDateFormats } from "../../customHooks/useDateFormats";

const MyOrderDetails = () => {
  const { convertWithDateAndDay } = useDateFormats();
  // DATA FROM PARAMS
  const params = useParams();
  // const order_id = parseInt(params?.id);
  // DATA FROM PARAMS

  // FROM PARAMS DATA
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const order_id = parseInt(searchParams.get("order_id"));
  const orderCategory = searchParams.get("orderCategory");
  const entity_type_id = parseInt(searchParams.get("entity_type_id")) ? parseInt(searchParams.get("entity_type_id")) : "";
  // FROM PARAMS DATA

  // DATA FROM REDUX STORE
  const { customer_id } = useReduxStore();
  // DATA FROM REDUX STORE

  const { bkdropLdr, setBkdropLdr, Prescription, setPrescription, socket } =
    useContext(AppContext);
  const [isReviewForm, setIsReviewForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [trackingInfo, setTrackingInfo] = useState({});
  const [orderReviewPayLoad, setorderReviewPayLoad] = useState({
    customer_id: customer_id,
    entity_type_id: "",
    // entity_instance_id: "",
    entity_instance_id: "44",
    review_title: "",
    review: "",
    rating: 1,
  });

  //create-review
  const createReview = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setBkdropLdr(true);
        const res = await PostCallWithAuthToken(
          ApiConfig.CREATE_ORDER_REVIEW,
          orderReviewPayLoad
        );
        const { success } = res?.json;
        if (success) {
          notifySuccess("Review submitted successfully");
          setIsReviewForm(false);
        }
      } catch (error) {
        console.log("catch Error : ,", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  useEffect(() => {
    getOrderPrescriptionData();
    getOrderTrackingDetails();
  }, []);

  const [orderPrescriptionData, setOrderPrescriptionData] = useState({});
  const isOrderPrescriptionData = Object.keys(orderPrescriptionData).length
    ? true
    : false;
  // my-order-invoice-prescription
  const getOrderPrescriptionData = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.MY_ORDER_PRESCRIPTION}?order_id=${order_id}&type=${
          entity_type_id === 3 ? "med" : ""
        }`
      );

      const { success, data } = res?.json;
      if (success) {
        data && setOrderPrescriptionData(data);
      }
    } catch (error) {
      console.log("catch error:,", error);
    }
  };

  const getOrderTrackingDetails = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.TRACK_ORDER}?order_id=${order_id}&orderCategory=${orderCategory}`
        // order_id=1&orderCategory=petshop
      );
      const { success, data } = res?.json;
      if (success && data) {
        setTrackingInfo(data);
        socket &&
          data?.invoice_no &&
          socket.emit("join_order", {
            invoice_no: data?.invoice_no,
          });
        const lastOrderStatus =
          data?.order_status_history &&
          data?.order_status_history[data?.order_status_history?.length - 1];
        setorderReviewPayLoad({
          ...orderReviewPayLoad,
          entity_type_id: lastOrderStatus && lastOrderStatus.entity_type_id,
        });
      }
    } catch (error) {
      console.log("Catch error,", error);
    }
  };
  console.log("trackingInfo,,", trackingInfo);

  // ORDER_STATUS
  const isOrderPlaced =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.some((item) => {
      return item.order_status_id === 10;
    });
  // ORDER_PLACED_DATE
  const isOrderPlacedDate =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.find((item) => {
      return item.order_status_id === 10;
    });

  const isOrderConfirmed =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.some((item) => {
      return item.order_status_id === 11;
    });
  // ORDER_CONFIRM_DATE
  const isOrderConfirmedDate =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.find((item) => {
      return item.order_status_id === 11;
    });

  const isOrderShipped =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.some((item) => {
      return item.order_status_id === 12;
    });
  // ORDER_SHIPPED_DATE
  const isOrderShippedDate =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.find((item) => {
      return item.order_status_id === 12;
    });

  const isOrderOutForDelivery =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.some((item) => {
      return item.order_status_id === 14;
    });
  // OUT_FOR_DELIVERY_DATE
  const isOrderOutForDeliveryDate =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.find((item) => {
      return item.order_status_id === 14;
    });

  const isOrderDelivered =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.some((item) => {
      return item.order_status_id === 15;
    });
  // ORDER_DELIVERED_DATE
  const isOrderDeliveredDate =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.find((item) => {
      return item.order_status_id === 15;
    });

  const isOrderReturned =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.some((item) => {
      return item.order_status_id === 17;
    });
  // ORDER_DELIVERED_DATE
  const isOrderReturnedDate =
    trackingInfo?.order_status_history &&
    trackingInfo?.order_status_history.find((item) => {
      return item.order_status_id === 17;
    });
  // ORDER_STATUS
  useEffect(() => {
    socket &&
      socket.on("orderUpdate_customer", (data) => {
        getOrderTrackingDetails();
        if (data) {
          // setTrackingInfo((prevTrackingInfo) => ({
          //   ...prevTrackingInfo,
          //   order_status_history: [
          //     ...prevTrackingInfo.order_status_history,
          //     data,
          //   ],
          // }));
        }
      });
    return () => {
      socket && socket.off("orderUpdate_customer");
    };
  }, [socket]); // Include socket and trackingInfo in the dependency array

  return (
    <>
      <div className="DoctorsList myOrders" id="cx-main">
        <Subheader />
        <div className="personalOrders">
          <div className="row">
            <div className="col-md-12">
              <div className="backAndResult">
                <Link to="/ProfileSetting">
                  <img src={Arrow} className="Arrow" alt="" />
                  {/* <label htmlFor='' className='keyValues'>
                    Showing result for :{' '}
                    <span className='diffColor'>Product Name</span>{' '}
                  </label> */}
                </Link>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mainCard">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="addressDetails">
                      <h1 className="nameOfAddress">Delivery Address</h1>
                      {trackingInfo?.shippingAddress &&
                      Object.keys(trackingInfo?.shippingAddress).length > 0 ? (
                        <label htmlFor="">
                          {trackingInfo?.shippingAddress?.address}
                          {/* 125/2, Sainiketan Colony, kalas Road, */}
                          <br />
                          {trackingInfo?.shippingAddress?.street_name}
                          {/* Vishrantwadi, Pune, */}
                          <br />
                          {trackingInfo?.shippingAddress?.postal_code}
                          {/* Maharashtra 411015 */}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="addressDetails">
                      <h1 className="nameOfAddress">Contact Number</h1>
                      <label htmlFor="">
                        {trackingInfo?.shipping_contact_no
                          ? trackingInfo?.shipping_contact_no
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="moreActionBtns">
                      <h1 className="nameTag">View Invoice</h1>
                      <button
                        type="button"
                        className="reOrderBtn"
                        onClick={() => setPrescription(true)}
                      >
                        {/* <img src={SaveImg} className='SaveImg' alt='' />{' '} */}
                        {/* Re-Order */}
                        View
                      </button>
                      {/*  <button className="downloadBtn">
                        <img
                          src={WhiteSaveImg}
                          className="downloadImg"
                          alt=""
                        />
                        Download Invcoice
                      </button> */}

                      <MedicalPrescriptionComp
                        orderPrescriptionData={orderPrescriptionData}
                        isOrderPrescriptionData={isOrderPrescriptionData}
                        entity_type_id={entity_type_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {trackingInfo?.items && trackingInfo?.items.length > 0 && (
              <div className="col-md-12">
                <div className="completeOrderTracker">
                  <div className="orderDetails">
                    <div className="row">
                      <div className="col-lg-2">
                        {trackingInfo?.items.map((item, i) => {
                          const imgPath =
                            item?.product_images &&
                            item?.product_images[0]?.product_image_path
                              ? item?.product_images[0]?.product_image_path
                              : null;
                          return (
                            <div className="mediFlex" key={"trackingInfo" + i}>
                              <div className="mediBox">
                                <img
                                  src={imgPath ? imgPath : medicineTabsd}
                                  alt=""
                                />
                              </div>
                              <div className="detailBox">
                                {item && <h1>{item?.product_name}</h1>}

                                {item && <p>{item?.product_type}</p>}
                                {/* {item && (
                                  <p>
                                    {item?.sellar?.pharmacy_shop_name
                                      ? item?.sellar?.pharmacy_shop_name
                                      : item?.saller?.pet_shop_name}
                                  </p>
                                )}*/}
                                {/* <p className='Dollar'>
                                ₹{trackingInfo?.total_amount}
                              </p> */}
                              </div>
                            </div>
                          );
                        })}
                        <span className="totalAmt">Total Amount</span> :{" "}
                        <span className="Dollar">
                          ₹{trackingInfo?.total_amount}
                        </span>
                      </div>
                      <div className="col-lg-7">
                        <div className="mainStepper">
                          <ul class="stepper">
                            {/* isOrderPlaced */}
                            <li class={`step ${isOrderPlaced ? "active" : ""}`}>
                              <span class="upper-text">Order Placed</span>
                              <span class="dot"></span>
                              <span class="lower-text">
                                {isOrderPlacedDate
                                  ? convertWithDateAndDay(
                                      isOrderPlacedDate?.status_changed_on
                                    )
                                  : ""}
                              </span>
                            </li>
                            <li
                              class={`step ${isOrderConfirmed ? "active" : ""}`}
                            >
                              <span class="upper-text">Order Confirmed</span>
                              <span class="dot"></span>
                              <span class="lower-text">
                                {isOrderConfirmedDate
                                  ? convertWithDateAndDay(
                                      isOrderConfirmedDate?.status_changed_on
                                    )
                                  : ""}
                              </span>
                            </li>
                            <li
                              class={`step ${isOrderShipped ? "active" : ""}`}
                            >
                              <span class="upper-text">Ready for dispatch</span>
                              <span class="dot"></span>
                              <span class="lower-text">
                                {isOrderShippedDate
                                  ? convertWithDateAndDay(
                                      isOrderShippedDate?.status_changed_on
                                    )
                                  : ""}
                              </span>
                            </li>

                            <li
                              class={`step ${
                                isOrderOutForDelivery ? "active" : ""
                              }`}
                            >
                              <span class="upper-text">Out for Delivery</span>
                              <span class="dot"></span>
                              <span class="lower-text">
                                {isOrderOutForDeliveryDate
                                  ? convertWithDateAndDay(
                                      isOrderOutForDeliveryDate?.status_changed_on
                                    )
                                  : ""}
                              </span>
                            </li>
                            {/* isOrderReturned */}
                            {isOrderReturned ? (
                              <li
                                class={`step ${
                                  isOrderReturned ? "active" : ""
                                }`}
                              >
                                <span class="upper-text">Order Returned</span>
                                <span class="dot"></span>
                                <span class="lower-text">
                                  {isOrderReturnedDate
                                    ? convertWithDateAndDay(
                                        isOrderReturnedDate?.status_changed_on
                                      )
                                    : ""}
                                </span>
                              </li>
                            ) : (
                              <li
                                class={`step ${
                                  isOrderDelivered ? "active" : ""
                                }`}
                              >
                                <span class="upper-text">Delivered</span>
                                <span class="dot"></span>
                                <span class="lower-text">
                                  {isOrderDeliveredDate
                                    ? convertWithDateAndDay(
                                        isOrderDeliveredDate?.status_changed_on
                                      )
                                    : ""}
                                </span>
                              </li>
                            )}
                          </ul>

                          {/* <div className="statusChecker">
                          <label htmlFor="" className="position1">
                            Order Confirmed
                          </label>
                          <label htmlFor="" className="position2">
                            Shipped
                          </label>
                          <label htmlFor="" className="position3">
                            Out for Delivery
                          </label>
                          <label htmlFor="" className="position4">
                            Delivered
                          </label>
                        </div>
                        <Stepper
                          steps={[
                            { label: "Step 1" },
                            { label: "Step 2" },
                            { label: "Step 3" },
                            { label: "Step 4" }
                          ]}
                          activeStep={1}
                        /> */}
                        </div>
                      </div>
                      {isOrderDelivered && (
                        <div className="col-lg-3 text-end">
                          <div className="rateBtn">
                            <button
                              onClick={() => {
                                setIsReviewForm(true);
                              }}
                              className="rateReview"
                            >
                              Rate & Review
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isReviewForm && (
              <div className="col-md-12">
                <Form noValidate validated={validated} onSubmit={createReview}>
                  <div className="reviewWrittingBox">
                    <div className="headingBox">
                      <h1>Write a Review</h1>
                    </div>
                    <div className="contentBox">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="inputBox">
                            <label htmlFor="" className="suggestionHead">
                              Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title goes here..."
                              required
                              onChange={(e) => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  review_title: e.target.value,
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter title
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-end">
                          <div className="starBox">
                            <img
                              src={
                                orderReviewPayLoad?.rating >= 1
                                  ? Star
                                  : ic_start
                              }
                              alt=""
                              onClick={() => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  rating: 1,
                                });
                              }}
                            />
                            <img
                              src={
                                orderReviewPayLoad?.rating >= 2
                                  ? Star
                                  : ic_start
                              }
                              onClick={() => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  rating:
                                    orderReviewPayLoad?.rating !== 2 ? 2 : "",
                                });
                              }}
                              alt=""
                            />
                            <img
                              src={
                                orderReviewPayLoad?.rating >= 3
                                  ? Star
                                  : ic_start
                              }
                              onClick={() => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  rating:
                                    orderReviewPayLoad?.rating !== 3 ? 3 : "",
                                });
                              }}
                              alt=""
                            />
                            <img
                              src={
                                orderReviewPayLoad?.rating >= 4
                                  ? Star
                                  : ic_start
                              }
                              onClick={() => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  rating:
                                    orderReviewPayLoad?.rating !== 4 ? 4 : "",
                                });
                              }}
                              alt=""
                            />
                            <img
                              src={
                                orderReviewPayLoad?.rating >= 5
                                  ? Star
                                  : ic_start
                              }
                              onClick={() => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  rating:
                                    orderReviewPayLoad?.rating !== 5 ? 5 : "",
                                });
                              }}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="inputBox mt-4">
                            <label htmlFor="" className="suggestionHead">
                              Description
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Write your review..."
                              required
                              onChange={(e) => {
                                setorderReviewPayLoad({
                                  ...orderReviewPayLoad,
                                  review: e.target.value,
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter description
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="belowBtns">
                      <button
                        onClick={() => {
                          setIsReviewForm(false);
                          setValidated(false);
                        }}
                        type="button"
                        className="cancel"
                      >
                        Cancel
                      </button>
                      <button className="submit">Submit</button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrderDetails;

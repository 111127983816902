import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Subheader from "../../sharedComponent/Subheader";
import inside_ambulance from "../../assets/images/inside_ambulance.png";
import dr_1 from "../../assets/images/dr_1.png";
import dog_1 from "../../assets/images/dog_1.png";
import ic_star_active from "../../assets/images/ic_star_active.svg";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import ambulance_img from "../../assets/images/ambulance_img.png";
import ic_hospital from "../../assets/images/ic_hospital.svg";
import thumb from "../../assets/images/thumb.png";
import ic_complete from "../../assets/images/ic_complete.svg";
import MyProfileComp from "./MyProfileComp";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MyAnimalProfileComp from "./MyAnimalProfileComp";
import MyAppointmentsComp from "./MyAppointmentsComp";
import RequestAmbulancComp from "./RequestAmbulancComp";
const SupportDetails = () => {
  const [AmbulanceFormCharge, setAmbulanceFormCharge] = useState(true);
  const [Payment, setPayment] = useState(true);
  const [BookedSuccessfull, setBookedSuccessfull] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      <div className="AmbulanceBook-main cx-main-wrapper" id="cx-main">
        <Subheader />

        <div className="appointment-details-wrapper">
          <div className="show-back-heading">
            <Link to="/ProfileSetting">
              <img src={ic_left_green_arrow} alt="" />
            </Link>
            <label htmlFor="">
              Showing result for : <span>Appointment -1</span>
            </label>
          </div>
        </div>
        <div className="booking-card-wrapper support mt-3" id="booking-card-wrapper">
          <div className="rewiew-wrapper">
            

            

            <div className="rewiew-wrapper-content">
              <div className="rewiew-wrapper-left">
                <img src={dog_1} alt="" />
              </div>
              <div className="rewiew-wrapper-right">
                <div className="heading-star">
                  <label htmlFor="">Regular health check up.</label>

                  
                </div>
                <p>
                  Ut enim ad minima veniam, quis nostrum exercitationem ullam
                  corporis suscipit laboriosam, nisi ut al Ut enim ad minima
                  veniam, quis nostrum exercitationem ullam corporis suscipit
                  laboriosam, nisi ut al
                </p>
                <div className="nested-content">
                  <img src={dr_1} alt="" />
                  <div className="nested-right">
                    <label htmlFor="">Regular health check up.</label>
                    <span className="d-block">Driver Name</span>
                    <p>
                      Ut enim ad minima veniam, quis nostrum exercitationem
                      ullam corporis suscipit laboriosam, nisi ut al Ut enim ad
                      minima veniam, quis nostrum exercitationem ullam corporis
                      suscipit laboriosam, nisi ut al
                    </p>
                  </div>
                </div>
              </div>
            </div>
            

            
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportDetails;

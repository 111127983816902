import React, { useContext, useState } from "react";
import yourLogo from "../../assets/images/logo.png";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/key.svg";
import login_img_key from "../../assets/images/login_img_key.png";
import passBox from "../../assets/images/icons/lock.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import eye from "../../assets/images/icons/eye.svg";
import eyeHide from "../../assets/images/icons/hidePassword.svg";
import facebook from "../../assets/images/icons/Facebook.svg";
import apple from "../../assets/images/icons/apple.svg";
import google from "../../assets/images/icons/google.svg";
import doodleRed from "../../assets/images/icons/doodle-red.svg";
import doodleSmall from "../../assets/images/icons/doodle-small.svg";
import doodle from "../../assets/images/icons/doodle-5 2.svg";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { notifySuccess } from "../../ToastMessages/notify";
import { ClipLoader } from "react-spinners";
import { AppContext } from "../../context/AppContext";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const { navigate } = useContext(AppContext);
  const { userData } = useReduxStore();

  const [resetPsdData, setResetPsdData] = useState({
    email: "",
    new_password: "",
    confirm_password: "",
  });
  console.log("resetPsdData,", resetPsdData);
  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setResetPsdData((prev) => ({ ...prev, [name]: value }));
  };
  const [loading, setLoading] = useState(false);
  const [erroMsg, setErroMsg] = useState("");
  const submitHandler = async (event) => {
    event.preventDefault();
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      if (resetPsdData?.confirm_password !== resetPsdData?.new_password) {
        setErroMsg("Password does not match");
        return;
      }
      setLoading(true);
      try {
        const res = await PostCallWithErrorResponse(
          ApiConfig.RESET_PASSWORD,
          resetPsdData
        );
        if (res?.json?.success === true) {
          notifySuccess("Password updated successfully");
          navigate("/");
        }
      } catch (error) {
        console.log("catch error response,", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="authMain resetPassword">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} alt="" style={{ height: "60px" }} />
            <div style={{ display: "flex", marginTop: "35px" }}>
              <div>
                <Link to={"/ForgotPassword"}>
                  <img src={ic_left_green_arrow} alt="" />
                </Link>
              </div>
              <div className="signinForm">
                <h1 className="signInTxt">Reset your password ?</h1>
                <h6 className="signInPara">
                  If you forgot your password, Don’t Worry !
                  <p className="mt-2 youCan">
                    You can reset your password here
                    {/**
              <Link to="/Registration" className="registerLink ms-1">
                    New here !
                  </Link>
              */}
                  </p>
                </h6>
                <Form noValidate validated={validated} onSubmit={submitHandler}>
                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      Email
                    </label>
                    <img src={mailBox} className="mailImg" alt="" />
                    <input
                      type="email"
                      className="form-control formInput"
                      placeholder="Enter your email..."
                      required
                      name="email"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Email id is required
                    </Form.Control.Feedback>
                  </div>

                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      Create New Password
                    </label>
                    <img src={passBox} className="mailImg" alt="" />
                    <img
                      src={confirmPassword ? eye : eyeHide}
                      onClick={() => {
                        if (confirmPassword) {
                          setConfirmPassword(false);
                        } else {
                          setConfirmPassword(true);
                        }
                      }}
                      className="eyeImg"
                      alt=""
                    />
                    <input
                      type={confirmPassword ? "text" : "password"}
                      className="form-control formInput"
                      placeholder="Enter your new password"
                      required
                      name="new_password"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      New password is required
                    </Form.Control.Feedback>
                  </div>
                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      Re-enter Password
                    </label>
                    <img src={passBox} className="mailImg" alt="" />
                    <img
                      src={showPassword ? eye : eyeHide}
                      onClick={() => {
                        if (showPassword) {
                          setShowPassword(false);
                        } else {
                          setShowPassword(true);
                        }
                      }}
                      className="eyeImg"
                      alt=""
                    />
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control formInput"
                      placeholder="Re-enter your Password"
                      required
                      name="confirm_password"
                      onPaste={(e) => {
                        e.preventDefault(); // Prevent the default paste action
                      }}
                      onChange={(e) => {
                        onChangeHandler(e);
                        setErroMsg("");
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      confirm password is required
                    </Form.Control.Feedback>
                    {erroMsg && <div className="erroMsg">{erroMsg}</div>}
                  </div>
                  {/* Navigate To Get Started */}
                  <div className="login">
                    {/* <Link to="#" className="loginBtn"> */}
                    <button
                      type="submit"
                      className="loginBtn"
                      style={loading ? { backgroundColor: "#7fa4a4" } : {}}
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <ClipLoader color="#fff" size={30} />
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="mobileNumber">
                <span className="callNumber">
                  <img src={teleCall} height="15" width="15" alt="" /> +94
                  9509932234
                </span>
              </div>

              <div className="insiderIcon">
                <img src={login_img_key} className="keyImg" alt="" />
              </div>

              <div className="landingTxt">
                <div className="d-flex flex-column justify-content-start ">
                  <h1>Create New Password !</h1>
                  <p>Get the unique password.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

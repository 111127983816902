export const convertInFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key][0].size && Array.isArray(object[key])) {
      object[key].forEach((item, index) => {
        formData.append(`${key}`, item);
      });
    } else if (Array.isArray(object[key])) {
      formData.append(`${object[key]}`, JSON.stringify(object[key]));
    } else {
      formData.append(key, object[key]);
    }
  });
  return formData;
};

export const convertInFormData2 = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      formData.append(key, JSON.stringify(object[key]));
    } else if (object[key].size) {
      formData.append(key, object[key]);
    } else if (
      typeof object[key] === "object" &&
      object[key] !== null &&
      !Array.isArray(object[key])
    ) {
      formData.append(key, JSON.stringify(object[key]));
    } else {
      formData.append(key, object[key]);
    }
  });
  return formData;
};

export const convertObjectToFormData = (
  obj,
  formData = new FormData(),
  parentKey = ""
) => {
  console.log("obj,,", obj);
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let propName = parentKey ? `${parentKey}[${key}]` : key;

      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        convertObjectToFormData(obj[key], formData, propName);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          convertObjectToFormData(item, formData, `${propName}[${index}]`);
        });
      } else {
        formData.append(propName, obj[key]);
      }
    }
  }
  return formData;
};

export const timeToDecimal = (time) => {
  const [hours, minutes] = time.split(":");
  const decimalValue = parseInt(hours, 10) + parseInt(minutes, 10) / 60;
  return decimalValue;
};

export const isTimeValid = (currentTime, selectedTime) => {
  const currentDateTime = new Date();
  const [currentHour, currentMinute] = currentTime.split(":");
  const [selectedHour, selectedMinute] = selectedTime.split(":");

  const currentTimestamp = currentDateTime.setHours(
    currentHour,
    currentMinute,
    0,
    0
  );
  const selectedTimestamp = currentDateTime.setHours(
    selectedHour,
    selectedMinute,
    0,
    0
  );

  return selectedTimestamp >= currentTimestamp;
};

export const checkFileTypeForImages = (file) => {
  if (file) {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedTypes.includes(file.type)) {
      return true;
    } else {
      // alert('Invalid file type. Please choose a PNG, JPEG, or JPG file.');
      return false;
    }
  }
};

export function formatCurrency(amount, locale = "en-US", currencyCode = "INR") {
  if (!amount) {
    return "";
  }
  var amt = Number(amount);
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  }).format(amt);
}

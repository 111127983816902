import React from 'react'

const ReturnAnRefund = () => {
    return (
        <div className='authMain'>
            <div className="tAcPage">
                <div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Return or Refund Policy for Animalsomatic Clinic - The Animal's App</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Last updated: [24th Jan 2024]</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>Thank you for choosing Animalsomatic Clinic's services through The Animal's App. We aim to provide excellent service and ensure your satisfaction with our offerings. Please read the following Return or Refund Policy to understand our practices regarding returns, refunds, and exchanges.</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>1. Return and Refund Eligibility</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>1.1 <strong>Services:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Due to the nature of veterinary services, appointments, and consultations, we generally do not offer refunds for services rendered.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>1.2 <strong>Medicine, Pet Food, Accessories:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Returns or refunds for these products may be considered in the following circumstances:</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '54.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>The product is damaged or defective upon receipt.</span></li>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>The wrong product was delivered.</span></li>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>The product is expired or unfit for use.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>2. How to Request a Return or Refund</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>2.1 <strong>Services:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>If you are dissatisfied with a veterinary service, please contact us at [support@animalsapp.in] to discuss your concerns.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>2.2 <strong>Products:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>To request a return or refund for medicine, pet food, or accessories, please follow these steps:</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '54.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Contact us within [X] days of receiving the product.</span></li>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Provide a clear description of the issue and, if possible, include supporting images.</span></li>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Our customer support team will guide you through the return process.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>3. Refund Processing</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>3.1 <strong>Services:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Refunds for veterinary services will be assessed on a case-by-case basis. If deemed appropriate, the refund will be processed within 15 business days.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>3.2 <strong>Products:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>If your return is approved, we will initiate a refund to your original payment method or provide a replacement product.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>4. Non-Refundable Items</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>4.1 <strong>Digital Products:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Digital products, including downloadable content or electronic prescriptions, are non-refundable.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>5. Cancellations and Missed Appointments</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>5.1 <strong>Cancellations:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>If you need to cancel a booked appointment, please notify us at least 24 hours in advance.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>5.2 <strong>Missed Appointments:</strong></span></p>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm', marginLeft: '18.0pt', marginRight: '0cm' }}>
                        <ul style={{ listStyleType: 'undefined', marginLeft: '8px' }}>
                            <li><span style={{ lineHeight: '115%', fontFamily: 'Roboto' }}>Missed appointments without prior notice may not be eligible for a refund.</span></li>
                        </ul>
                    </div>
                    <div style={{ margin: '0cm', marginBottom: '.0001pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', border: 'none #D9D9E3 1.0pt', padding: '0cm 0cm 0cm 0cm' }}>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><strong><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>6. Contact Us</span></strong></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm', borderBottom: '0cm none #D9D9E3' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>If you have any questions or concerns regarding our Return or Refund Policy, please contact us at [support@animalsapp.in] or [ +91 9509932234].</span></p>
                        <p style={{ margin: '0cm', marginBottom: '15.0pt', lineHeight: '115%', fontSize: '15px', fontFamily: '"Arial",sans-serif', marginTop: '15.0pt', marginRight: '0cm', marginLeft: '0cm', border: 'none', padding: '0cm' }}><span style={{ fontSize: '16px', lineHeight: '115%', fontFamily: 'Roboto' }}>By using The Animal's App, you agree to abide by our Return or Refund Policy.</span></p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ReturnAnRefund

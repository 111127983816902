import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Subheader from "../../sharedComponent/Subheader";
import inside_ambulance from "../../assets/images/inside_ambulance.png";
import dr_1 from "../../assets/images/dr_1.png";
import dog_1 from "../../assets/images/dog_1.png";
import ic_star_active from "../../assets/images/ic_star_active.svg";
import ic_right_arrow_white from "../../assets/images/ic_right_arrow_circle.svg";
import ambulance_img from "../../assets/images/ambulance_img.png";
import ic_hospital from "../../assets/images/ic_hospital.svg";
import thumb from "../../assets/images/thumb.png";
import ic_complete from "../../assets/images/ic_complete.svg";
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifySuccess } from "../../ToastMessages/notify";
import moment from "moment";
import { calculateDistance } from "../../customHooks/useDistance";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import dummyprofile from "../../assets/images/dummyprofile.jpeg";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";
import { checkoutHandler } from "../../paymentCheckout/checkout";

const AmbulanceBook = () => {
  // const [AmbulanceFormCharge, setAmbulanceFormCharge] = useState(true);
  const [Payment, setPayment] = useState(true);
  const [BookedSuccessfull, setBookedSuccessfull] = useState(true);
  const {
    setBkdropLdr,
    setEventKey,
    navigate,
    AmbulanceFormCharge,
    setAmbulanceFormCharge,
  } = useContext(AppContext);

  const { id } = useParams();
  const ambulance_driver_id = id;
  const [details, setDetails] = useState({});
  const [raviews, setReviews] = useState([]);
  const { customer_id, customer_address, customer_details } = useReduxStore();
  const [bookingPayLoad, setBookingPayLoad] = useState({
    ambulance_id: details?.ambulance_id,
    customer_animal_id: "",
    customer_id: customer_id,
    vehicle_type_id: 1,
    from_location_coordinates: "",
    to_location_coordinates: "",
    from_location_address: "",
    to_location_address: "",
    ambulance_booking_reason: "",
    trip_in_km: "",
    ambulance_fees: "",
    booking_fees: 0,
    tax: 0,
    total_charges: "",
    transaction_id: "",
    transaction_date: new Date(),
    // transaction_date: moment().format("DD-MM-YYYY"),
  });
  
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    fetchAmbulanceDetails();
    fetchAnimals();
  }, []);

  const fetchAmbulanceDetails = async () => {
    try {
      const payLoad = {
        ambulance_driver_id: ambulance_driver_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.AMBULANCE_RATINGS_AND_DETAILS,
        payLoad
      );
      const { success, data, reviewsList } = res?.json;
      if (success) {
        data && setDetails(data[0]);
        setBookingPayLoad({
          ...bookingPayLoad,
          ambulance_id: data[0]?.ambulance_id,
        });
        reviewsList && setReviews(reviewsList);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  const [animals, setAnimals] = useState([]);
  const fetchAnimals = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.ANIMAL_LIST}?customer_id=${customer_id}`
      );

      const { success, data } = res?.json;
      if (success && data) {
        setAnimals(data);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  const [resData, setResData] = useState({});
  const afterPaymentSuccess = (data) => {
    notifySuccess("Ambulance booked successfully");
    setPayment(false);
    setResData(data[0]);
  };
  const bookAmbulance = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setBkdropLdr(true);
        const res = await PostCallWithAuthToken(
          ApiConfig.BOOK_AMBULANCE,
          bookingPayLoad
        );

        const { success, data, orderDetails } = res?.json;
        const resData = data;
        if (success) {
          sessionStorage.setItem(
            "ambulance_Request_id",
            orderDetails?.ambulance_request_id
          );
          if (bookingPayLoad?.total_charges) {
            checkoutHandler(
              bookingPayLoad?.total_charges,
              orderDetails,
              "amb",
              afterPaymentSuccess,
              resData
            );
          }
        }
        // onClick={() => setPayment(false)}
      } catch (error) {
        console.log("catch error,", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  return (
    <>
      <div className="AmbulanceBook-main cx-main-wrapper" id="cx-main">
        <Subheader />

        {BookedSuccessfull ? (
          <>
            {AmbulanceFormCharge ? (
              <>
                <div className="booking-card-wrapper">
                  <div className="left">
                    <di className="left-left">
                      <div className="img">
                        <img
                          src={
                            details.vehicle_image_path
                              ? details.vehicle_image_path
                              : ambulance_img
                          }
                          alt=""
                        />
                      </div>
                    </di>
                    <div className="left-right">
                      {details?.ambulance_driver_name && (
                        <h4>{details?.ambulance_driver_name}</h4>
                      )}
                      {details?.driver_address && (
                        <p className="value">{details?.driver_address}</p>
                      )}
                      <p className="value">
                        {details?.avg_rating && (
                          <img src={ic_star_active} alt="" />
                        )}
                        {details?.avg_rating} | {details.review_count} reviews
                      </p>
                      {/*<div className="img-wrapper-all-ambu">
                        <img src={inside_ambulance} alt="" />
                        <img src={inside_ambulance} alt="" />
                        <img src={inside_ambulance} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div className="right">
                    {details?.per_km_charge && (
                      <p>
                        <img src={ic_hospital} alt="" />₹
                        {details?.per_km_charge} / km
                      </p>
                    )}

                    <button
                      className="book-btn"
                      onClick={() => {
                        if (!customer_address || !customer_details) {
                          setEventKey("11");
                          navigate("/ProfileSetting");
                          sessionStorage.setItem(
                            "ambulance_driver_id",
                            ambulance_driver_id
                          );
                        } else {
                          setAmbulanceFormCharge(false);
                        }
                      }}
                    >
                      Book Now <img src={ic_right_arrow_white} alt="" />
                    </button>
                  </div>
                </div>
                {raviews && raviews.length > 0 && (
                  <div
                    className="booking-card-wrapper mt-3"
                    id="booking-card-wrapper"
                  >
                    <div className="rewiew-wrapper">
                      <div className="hdng">
                        <h4>Ratings & Reviews</h4>
                      </div>
                      {raviews.map((item, idx) => {
                        return (
                          <div
                            className="rewiew-wrapper-content"
                            key={item?.rating_and_review_id}
                          >
                            <div className="rewiew-wrapper-left">
                              <img
                                src={
                                  item?.profile_image_path
                                    ? item?.profile_image_path
                                    : dummyprofile
                                }
                                alt=""
                              />
                            </div>

                            <div className="rewiew-wrapper-right">
                              <div style={{ display: "flex" }}>
                                <span
                                  className="d-block"
                                  style={{
                                    display: "flex",
                                    marginRight: "15px",
                                  }}
                                >
                                  {item?.first_name} {item?.last_name}
                                </span>
                                <span className="d-block">
                                  {moment(item?.rating_and_review_on).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </div>

                              <div className="heading-star">
                                <label htmlFor="">{item.review_title}</label>

                                {item?.rating && (
                                  <div className="">
                                    <img src={ic_star_active} alt="" />{" "}
                                    <span>{item?.rating}</span>
                                  </div>
                                )}
                              </div>
                              <p>{item.review}</p>
                            </div>
                          </div>
                        );
                      })}

                      {/*
                 <div className="rewiew-wrapper-content">
                <div className="rewiew-wrapper-left">
                  <img src={dog_1} alt="" />
                </div>
                <div className="rewiew-wrapper-right">
                  <div className="heading-star">
                    <label htmlFor="">
                      Regular health check up.
                    </label>

                    <div className="">
                      <img src={ic_star_active} alt="" />{" "}
                      <span>4.1</span>
                    </div>
                  </div>
                  <p>
                    Ut enim ad minima veniam, quis nostrum
                    exercitationem ullam corporis suscipit
                    laboriosam, nisi ut al Ut enim ad minima veniam,
                    quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut al
                  </p>
                  <div className="nested-content">
                    <img src={dr_1} alt="" />
                    <div className="nested-right">
                      <label htmlFor="">
                        Regular health check up.
                      </label>
                      <span className="d-block">Driver Name</span>
                      <p>
                        Ut enim ad minima veniam, quis nostrum
                        exercitationem ullam corporis suscipit
                        laboriosam, nisi ut al Ut enim ad minima
                        veniam, quis nostrum exercitationem ullam
                        corporis suscipit laboriosam, nisi ut al
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div className="rewiew-wrapper-content">
                <div className="rewiew-wrapper-left">
                  <img src={dog_1} alt="" />
                </div>
                <div className="rewiew-wrapper-right">
                  <div className="heading-star">
                    <label htmlFor="">
                      Regular health check up.
                    </label>

                    <div className="">
                      <img src={ic_star_active} alt="" />{" "}
                      <span>4.1</span>
                    </div>
                  </div>
                  <p>
                    Ut enim ad minima veniam, quis nostrum
                    exercitationem ullam corporis suscipit
                    laboriosam, nisi ut al Ut enim ad minima veniam,
                    quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut al
                  </p>
                </div>
                </div>

                <div className="show-all-btn">
                <button>Show All Review’s (100)</button>
                </div>
                */}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="petient-booking-detail-main">
                <div className="row">
                  <div className="col-md-8">
                    {Payment ? (
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={bookAmbulance}
                      >
                        <div className="">
                          <div
                            className="booking-card-wrapper "
                            id="booking-card-wrapper"
                          >
                            <div className="rewiew-wrapper">
                              <div className="hdng">
                                <h4>Patient Details</h4>
                              </div>

                              <div className="form-section row">
                                <div className="col-md-12 mt-3">
                                  <Form.Label>Patient </Form.Label>
                                  <Form.Select
                                    aria-label="Work / Home / Other"
                                    onChange={(e) => {
                                      if (e.target.value == "new") {
                                        navigate("/ProfileSetting");
                                        setEventKey("22");
                                        sessionStorage.setItem(
                                          "ambulance_driver_id",
                                          ambulance_driver_id
                                        );
                                        return;
                                      }
                                      setBookingPayLoad({
                                        ...bookingPayLoad,
                                        customer_animal_id: Number(
                                          e.target.value
                                        ),
                                      });
                                    }}
                                    required
                                  >
                                    <option value="">Select your animal</option>
                                    {animals &&
                                      animals.map((item, idx) => {
                                        return (
                                          <option
                                            value={item?.customer_animal_id}
                                            key={"animals" + idx}
                                          >
                                            {item?.animal_name}
                                          </option>
                                        );
                                      })}
                                    <option
                                      className="add-new-pet-option"
                                      value={"new"}
                                    >
                                      + Add new animal
                                    </option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please select patient
                                  </Form.Control.Feedback>
                                </div>
                                {/*<div className="col-md-6 mt-3">
                                <Form.Label>Animal Type</Form.Label>
                                <Form.Select aria-label="Work / Home / Other">
                                  <option value="">Select Type</option>
                                  <option value="2">Home</option>
                                  <option value="3">Other</option>
                                </Form.Select>
                              </div> */}

                                <div className="col-lg-6 mt-3">
                                  <Form.Label>Start Location</Form.Label>
                                  {/*<input
                                  type="text"
                                  className="form-control"
                                  placeholder="Start location"
                                />*/}
                                  <AutocompleteGoogleInput
                                    value={
                                      bookingPayLoad.from_location_address
                                        ? bookingPayLoad.from_location_address
                                        : ""
                                    }
                                    required={true}
                                    className={"form-control formInput"}
                                    placeholder={"Enter your address"}
                                    onPlaceSelect={(
                                      event,
                                      { lat, lng, address }
                                    ) => {
                                      const locationData = {
                                        lat,
                                        lng,
                                        address,
                                      };
                                      setBookingPayLoad({
                                        ...bookingPayLoad,
                                        from_location_coordinates: `${locationData?.lat},${locationData?.lng}`,
                                        from_location_address: address,
                                      });
                                    }}
                                  />
                                  {/* <SearchFunction
                                    state={bookingPayLoad}
                                    setState={setBookingPayLoad}
                                    locationkey='from_location_address'
                                    latLngKey='from_location_coordinates'
                                    value={
                                      bookingPayLoad.from_location_address
                                        ? bookingPayLoad.from_location_address
                                        : ''
                                    }
                                    required={true}
                                  /> */}
                                </div>
                                <div className="col-lg-6 mt-3">
                                  <Form.Label>End Location</Form.Label>
                                  <AutocompleteGoogleInput
                                    value={
                                      bookingPayLoad.to_location_address
                                        ? bookingPayLoad.to_location_address
                                        : ""
                                    }
                                    required={true}
                                    className={"form-control formInput"}
                                    placeholder={"Enter your address"}
                                    onPlaceSelect={(
                                      event,
                                      { lat, lng, address }
                                    ) => {
                                      const locationData = {
                                        lat,
                                        lng,
                                        address,
                                      };
                                      const distanceInKm = calculateDistance(
                                        Number(
                                          bookingPayLoad?.from_location_coordinates?.split(
                                            ","
                                          )[0]
                                        ),
                                        Number(
                                          bookingPayLoad?.from_location_coordinates?.split(
                                            ","
                                          )[1]
                                        ),
                                        locationData?.lat,
                                        locationData?.lng
                                      );
                                      setBookingPayLoad({
                                        ...bookingPayLoad,
                                        to_location_coordinates: `${locationData?.lat},${locationData?.lng}`,
                                        to_location_address: address,
                                        trip_in_km: distanceInKm
                                          ? distanceInKm
                                          : "",
                                        ambulance_fees: distanceInKm
                                          ? Number(distanceInKm) *
                                            Number(details?.per_km_charge)
                                          : "",
                                        total_charges: distanceInKm
                                          ? Number(distanceInKm) *
                                              Number(details?.per_km_charge) +
                                            bookingPayLoad.booking_fees +
                                            bookingPayLoad.tax
                                          : "",
                                      });

                                      // setBookingPayLoad({
                                      //   from_location_address : address,
                                      //   from_location_coordinates :
                                      // })
                                      // from_location_coordinates: "",
                                      // to_location_coordinates: "",
                                      // from_location_address: "",
                                      // to_location_address: "",
                                    }}
                                    // currentIndex={idx}
                                    // name={"address"}
                                  />
                                  {/* <SearchFunction
                                    details={details}
                                    state={bookingPayLoad}
                                    setState={setBookingPayLoad}
                                    locationkey="to_location_address"
                                    latLngKey="to_location_coordinates"
                                    ambulance_fees="ambulance_fees"
                                    value={
                                      bookingPayLoad.to_location_address
                                        ? bookingPayLoad.to_location_address
                                        : ""
                                    }
                                    required={true}
                                  /> */}
                                  <Form.Control.Feedback type="invalid">
                                    Please enter reason
                                  </Form.Control.Feedback>
                                  {/*<input
                                    type="text"
                                    className="form-control"
                                    placeholder="End location"
                                  />*/}
                                </div>
                                <div className="col-md-12 mt-3">
                                  <Form.Label>Reason</Form.Label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your reason here..."
                                    onChange={(e) => {
                                      setBookingPayLoad({
                                        ...bookingPayLoad,
                                        ambulance_booking_reason:
                                          e.target.value,
                                      });
                                    }}
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please enter reason
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="btns-grp">
                            <button
                              type="button"
                              onClick={() => setAmbulanceFormCharge(true)}
                              className="btn-blank"
                            >
                              Back
                            </button>
                            <button type="submit" className="btn-filled">
                              Book
                              {/* <img src={ic_right_arrow_white} alt="" /> */}
                            </button>
                          </div>
                        </div>
                      </Form>
                    ) : (
                      <div className="" id="payment-option">
                        <div
                          className="booking-card-wrapper "
                          id="booking-card-wrapper"
                        >
                          <div className="rewiew-wrapper">
                            <div className="hdng">
                              <h4>Payment Methods</h4>
                            </div>

                            <div className="form-section row">
                              <div className="mt-3">
                                <Form.Check
                                  type="radio"
                                  label="Debit or Credit Card"
                                  name="option"
                                />
                              </div>
                              <div className="col-md-12 mt-3">
                                <Form.Label>Name on the card</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter the name"
                                />
                              </div>
                              <div className="col-md-12 mt-3">
                                <Form.Label>Card Number</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="xxxx - xxxx - xxxx - xxxx"
                                />
                              </div>
                              <div className="col-md-4 mt-3">
                                <Form.Label>Expiry Month</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="MM"
                                  maxLength={2}
                                />
                              </div>

                              <div className="col-md-4 mt-3">
                                <Form.Label>Expiry Year</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={4}
                                  placeholder="YYYY"
                                />
                              </div>

                              <div className="col-md-4 mt-3">
                                <Form.Label>CVV</Form.Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="***"
                                />
                              </div>
                              <div className="mt-3">
                                <Form.Check
                                  type="radio"
                                  label="Net Banking"
                                  name="option"
                                />
                              </div>
                              <div className="mt-3">
                                <Form.Check
                                  type="radio"
                                  name="option"
                                  label="UPI"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="btns-grp">
                          <button className="btn-blank" type="button">
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn-filled"
                            onClick={setBookedSuccessfull(false)}
                          >
                            Proceed to Pay
                            <img src={ic_right_arrow_white} alt="" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="booking-card-wrapper " id="booking-right">
                      <div className="rewiew-wrapper">
                        <div className="hdng">
                          <h4>Ambulance Details</h4>
                        </div>
                      </div>
                      <div className="" id="card-item">
                        <div className="left">
                          <di className="left-left">
                            <div className="img">
                              <img
                                src={
                                  details?.vehicle_image_path
                                    ? details?.vehicle_image_path
                                    : ambulance_img
                                }
                                alt=""
                              />
                            </div>
                          </di>
                          <div className="left-right">
                            <h4>{details?.ambulance_driver_name}</h4>
                            <p className="value">{details?.driver_address}</p>
                          </div>
                        </div>
                        <div className="right">
                          <p className="value">
                            <img src={ic_star_active} alt="" />
                            {details?.review_count}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="booking-card-wrapper mt-3"
                      id="booking-right"
                    >
                      <div className="rewiew-wrapper">
                        <div className="hdng">
                          <h4>Booking Charges</h4>
                        </div>
                      </div>
                      <p className="charge-wrapper mt-3 ">
                        <p className="key">Distance</p>
                        {bookingPayLoad?.trip_in_km && (
                          <label htmlFor="" className="value">
                            {bookingPayLoad?.trip_in_km} km
                          </label>
                        )}
                      </p>
                      <p className="charge-wrapper mt-3 ">
                        <p className="key">Per km charges</p>
                        {details?.per_km_charge && (
                          <label htmlFor="" className="value">
                            ₹{details?.per_km_charge}
                          </label>
                        )}
                      </p>
                      {/* total_charges */}

                      <p className="charge-wrapper mt-3 ">
                        <p className="key">Ambulance Fees</p>
                        {bookingPayLoad?.ambulance_fees && (
                          <label htmlFor="" className="value">
                            ₹{bookingPayLoad?.ambulance_fees}
                          </label>
                        )}
                      </p>
                      {/* <p className="charge-wrapper">
                        <p className="key">Booking Fees</p>
                        {bookingPayLoad?.booking_fees && (
                          <label htmlFor="" className="value">
                            ₹{bookingPayLoad?.booking_fees}
                          </label>
                        )}
                      </p> */}
                      <p className="charge-wrapper">
                        <p className="key">Tax</p>
                        {bookingPayLoad?.tax ? (
                          <label htmlFor="" className="value">
                            ₹{bookingPayLoad?.tax}
                          </label>
                        ) : (
                          "-"
                        )}
                      </p>
                      <div className="rewiew-wrapper">
                        <div className="hdng"></div>
                      </div>
                      <p className="charge-wrapper mt-3">
                        <p className="key">Total</p>
                        {bookingPayLoad?.total_charges && (
                          <label htmlFor="" className="value">
                            ₹{bookingPayLoad?.total_charges}
                          </label>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="container-sm" id="sucessfull-wrapper">
            <div className="booking-card-wrapper " id="booking-card-wrapper">
              <div className="top-img">
                <img src={thumb} alt="" />
                <h4>Your Request has been booked Successfully</h4>
              </div>
              <div className="successfull-detail">
                <div className="left">
                  <div className="">
                    <img
                      src={
                        resData?.vehicle_image_path
                          ? resData?.vehicle_image_path
                          : ambulance_img
                      }
                      alt=""
                    />
                  </div>
                  <div className="left-right">
                    {resData?.ambulance_driver_name && (
                      <p className="key">{resData?.ambulance_driver_name}</p>
                    )}
                    {resData?.driver_address && (
                      <p className="value">{resData?.driver_address}</p>
                    )}
                  </div>
                </div>
                <div className="right">
                  {resData?.avg_rating && (
                    <p>
                      <img src={ic_star_active} alt="" /> {resData?.avg_rating}
                    </p>
                  )}
                  <p>
                    <img src={ic_complete} alt="" />{" "}
                    {resData?.status == "success" ? "Paid" : ""}
                  </p>
                </div>
              </div>
              <div className="p-method mt-3">
                <p>Payment Method : </p>
                <p>{resData?.mode_of_payment}</p>
              </div>
              <div className="p-method mt-2">
                <p>Destination Address:</p>
                <label>{resData?.to_location_address}</label>
              </div>
            </div>
            <div id="btns-grp">
              <button
                className="btn-blank"
                type="button"
                onClick={() => navigate("/TrackAmbulance")}
              >
                Track
              </button>
              {/*  <button
              type="button"
              className="btn-filled"
            >
              Reaching in 30:00s
            </button> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AmbulanceBook;

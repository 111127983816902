import React, { useCallback, useContext, useEffect, useState } from "react";
import Subheader from "../../sharedComponent/Subheader";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { simpleGetCallWithAuthToken } from "../../api/ApiServices";
import { ApiConfig, BASE_URL } from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { calculateDistance } from "../../customHooks/useDistance";
import ambulanceIcon from "../../assets/ambulance/ambulance.png";
import io from "socket.io-client";
import { loadCheckoutScript } from "../../paymentCheckout/loadScript";
import locationIcon from "../../assets/trackingIcons/locationIcon.png";

const TrackAmbulance = () => {
  // const scriptLoaded = loadCheckoutScript(
  //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyC54XwzhinpG_gbIq_leD4kvg2i0_oIJv0&libraries=places"
  // );
  // if (!scriptLoaded) {
  //   alert("Please check your internet connection or reload the page");
  //   return;
  // }
  // GOOGLE MAP LOADING
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC54XwzhinpG_gbIq_leD4kvg2i0_oIJv0",
  });
  // GOOGLE MAP LOADING

  // GOOGLE MAP REMOVED
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  // GOOGLE MAP REMOVED

  const [ambulanceDirection, setAmbulanceDirection] = useState(null);
  const [bookingDirection, setBookingDirection] = useState(null);
  const { setBkdropLdr, customerID, option } = useContext(AppContext);
  const ambulance_Request_id = sessionStorage.ambulance_Request_id
    ? sessionStorage.ambulance_Request_id
    : "";
  const [map, setMap] = React.useState(null);
  const containerStyle = {
    width: "100%",
    height: "900px",
    borderRadius: "12px",
  };

  const [center, setCenter] = useState("");
  const [defaultCenter, setDefaultCenter] = useState("");
  const [zoomVal, setZoomVal] = useState(10);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setCenter({
        lat: latitude,
        lng: longitude,
      });
      setDefaultCenter({
        lat: latitude,
        lng: longitude,
      });
    });
  }, [map]);

  const [trackingDetails, setTrackingDetails] = useState({});

  const distanceInKm = calculateDistance(
    trackingDetails?.from_location_coordinates?.x,
    trackingDetails?.from_location_coordinates?.y,
    trackingDetails?.to_location_coordinates?.x,
    trackingDetails?.to_location_coordinates?.y
  );

  // TRACKING_DETAILS
  useEffect(() => {
    fetchTrackingDetails();
  }, []);
  const [ambulanceTracking, setambulanceTracking] = useState({
    current_location: { lat: "", lng: "" },
    angle: 0,
    ambulance_Request_id: "",
    minText: "",
    from_location_coordinates: "",
    to_location_coordinates: "",
  });
  console.log("ambulanceTracking,", ambulanceTracking);
  useEffect(() => {
    let socketConnection = io(
      `${BASE_URL}?UID=${customerID}`,
      option
    );
    
    socketConnection.on("connect", (res) => {
      socketConnection.emit("join ambulance", {
        ambulance_Request_id: Number(ambulance_Request_id),
      });

      socketConnection.on("ambulance track", (data) => {
        // console.log("tracking details data,", data);
        const {
          current_location,
          angle,
          from_location_coordinates,
          to_location_coordinates,
          minText,
          status,
          estimated_time,
        } = data;
        setambulanceTracking({
          ...ambulanceTracking,
          current_location: {
            lat: current_location?.x ? current_location?.x : "",
            lng: current_location?.y ? current_location?.y : "",
          },
          angle: angle,
          from_location_coordinates: from_location_coordinates,
          to_location_coordinates: to_location_coordinates,
          minText: minText,
        });
      });

      return () => {
        socketConnection && socketConnection.off("ambulance track") 
      }
    });
  }, [ambulance_Request_id]);

  const fetchTrackingDetails = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.TRACKING_DETAILS}/${ambulance_Request_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        setTrackingDetails(data);
      }
    } catch (error) {
      console.log("catch error of Tracking Detail API,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const ambulanceIconMarker = {
    url: ambulanceIcon,
    scaledSize: new window.google.maps.Size(30, 30),
    rotation :ambulanceTracking?.angle
  };

  useEffect(() => {
    calculateRoute();
  }, [trackingDetails, ambulanceTracking]);

  async function calculateRoute() {
    const { current_location, to_location_coordinates } = ambulanceTracking;
    // eslint-disable-next-line no-undef
    const directionsService = new window.google.maps.DirectionsService();
    const ambulanceBookedRoute = await directionsService.route({
      origin: trackingDetails?.from_location_address,
      destination: trackingDetails?.to_location_address,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    ambulanceBookedRoute && setBookingDirection(ambulanceBookedRoute);

    // const bookedRouteStartMarker = new window.google.maps.Marker({
    //   position: ambulanceBookedRoute.routes[0].legs[0].start_location,
    //   // map: yourGoogleMapInstance,
    //   icon: locationIcon,
    //   // title: 'Start Point',
    // });

    // const bookedRouteEndMarker = new window.google.maps.Marker({
    //   position: ambulanceBookedRoute.routes[0].legs[0].end_location,
    //   // map: yourGoogleMapInstance,
    //   icon: locationIcon,
    //   // title: 'End Point',
    // });

    const ambulanceRoute = await directionsService.route({
      origin: new window.google.maps.LatLng(
        current_location.lat,
        current_location.lng
      ),
      destination: new window.google.maps.LatLng(
        to_location_coordinates.x,
        to_location_coordinates.y
      ),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    ambulanceRoute && setAmbulanceDirection(ambulanceRoute);
  }

  return (
    <>
      <div className="TrackingMain cx-main-wrapper" id="cx-main">
        <Subheader />
        <div className="trackingBox">
          <div className="row">
            <div className="col">
              <div className="leftCard">
                <div className="headTxt">
                  <h1>Start Location</h1>
                </div>
                <div className="fieldsBox">
                  <div className="formBox">
                    <label className="key">Departure : </label>
                    <label className="value">
                      {trackingDetails?.from_location_address}
                    </label>
                  </div>
                  <div className="formBox">
                    <label className="key">Destination : </label>
                    <label className="value">
                      {trackingDetails?.to_location_address}
                    </label>
                  </div>
                  <div className="formBox">
                    <label className="key">Distance : </label>
                    <label className="value">
                      {distanceInKm ? `${distanceInKm} km` : ""}
                    </label>
                  </div>
                  <div className="formBox">
                    <label className="key">Vehicle No : </label>
                    <label className="value">
                      {trackingDetails?.vehicle_no}
                    </label>
                  </div>
                  <div className="formBox">
                    <label className="key">Phone Number : </label>
                    <label className="value">
                      {trackingDetails?.ambulance_driver_contact_no}
                    </label>
                  </div>
                  <div className="formBox">
                    <label className="key">Vehicle Type: </label>
                    <label className="value">
                      {trackingDetails?.vehicle_type}
                    </label>
                  </div>
                  {ambulanceTracking?.minText && (
                    <div className="formBox">
                      <label className="key">Time to Reach: </label>
                      <label className="value">
                        {ambulanceTracking?.minText}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="rightCard">
                <div className="headTxt">
                  <h1>End Location</h1>
                </div>
                <div className="fieldsBox">
                  <div className="formBox">
                    <label className="key">Location Name : </label>
                    <label className="value">Mumbai</label>
                  </div>
                  <div className="formBox">
                    <label className="key">Distance : </label>
                    <label className="value">220kms</label>
                  </div>
                  <div className="formBox">
                    <label className="key">Vehicle No : </label>
                    <label className="value">1324564564</label>
                  </div>
                  <div className="formBox">
                    <label className="key">Phone Number : </label>
                    <label className="value">1324564564</label>
                  </div>
                  <div className="formBox">
                    <label className="key">Vehicle Type: </label>
                    <label className="value">220kms</label>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="mapCard">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={defaultCenter}
                    zoom={zoomVal}
                    onLoad={(map) => setMap(map)}
                    onUnmount={onUnmount}
                  >
                    <Marker
                      // position={center}
                      icon={ambulanceIconMarker}
                      position={ambulanceTracking?.current_location}
                      // icon={{ url: ambulanceIconMarker, rotation: 270 }}
                    />
                     {/* Child components, such as markers, info windows, etc. */}
                     {ambulanceDirection && (
                      <DirectionsRenderer
                        directions={ambulanceDirection}
                        options={{
                          // strokeColor: "red", 
                          strokeOpacity: 0.8,
                          strokeWeight: 4,
                          suppressMarkers : true
                        }}
                      />
                    )}
                    {bookingDirection && (
                      <DirectionsRenderer
                        directions={bookingDirection}
                        options={{
                          // strokeColor: "red", 
                          strokeOpacity: 0.8,
                          strokeWeight: 4,
                          // suppressMarkers : true
                        }}
                      />
                    )}
                    {/* // ===== NEW =======
                //  Marker for ambulanceBookedRoute start location 
                    {bookingDirection && (
                      <Marker
                        position={
                          bookingDirection.routes[0].legs[0].start_location
                        }
                        icon={{
                          url: locationIcon,
                          scaledSize: new window.google.maps.Size(30, 30),
                        }}
                      />
                    )}
                    // Marker for ambulanceBookedRoute end location 
                    {bookingDirection && (
                      <Marker
                        position={
                          bookingDirection.routes[0].legs[0].end_location
                        }
                        icon={{
                          url: locationIcon,
                          scaledSize: new window.google.maps.Size(30, 30),
                        }}
                      />
                    )}
                    // ===== NEW ======= */}
                   
                    <></>
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackAmbulance;

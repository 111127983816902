import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import ic_doc_download_light from "../../assets/images/ic_doc_download_light.svg";
import ic_view_light from "../../assets/images/ic_view_light.svg";
import ic_repeat from "../../assets/images/ic_repeat.svg";
import ic_repeat_green from "../../assets/images/ic_repeat_green.svg";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import chatting_dummy from "../../assets/images/chatting_dummy.png";
import video_dummy from "../../assets/images/video_dummy.png";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { saveAs } from "file-saver";
import btnSend from "../../assets/images/send_chat.svg";
// MODAL
import ic_call from "../../assets/images/ic_call.svg";
import ic_location from "../../assets/images/ic_location.svg";
import ic_mail from "../../assets/images/ic_mail.svg";
import DoctorSymbol from "../../assets/images/DoctorSymbol.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import sign from "../../assets/images/sign.png";
import ic_add_plus from "../../assets/images/ic_add_plus.svg";
import ic_arrow_right_white from "../../assets/images/ic_right_arrow_circle.svg";
import dr_1 from "../../assets/images/dr_1.png";
import ic_doc_download from "../../assets/images/ic_doc_download.svg";
import ic_view from "../../assets/images/ic_view.svg";
import ic_video from "../../assets/images/ic_video.svg";
import ic_video_green from "../../assets/images/ic_video_green.svg";
//
import ic_calender_green from "../../assets/images/ic_calender_green.svg";
import dog_1 from "../../assets/images/dog_1.png";
import dog_3 from "../../assets/images/dog_3.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ic_progress from "../../assets/images/ic_progress.svg";
import ic_complete from "../../assets/images/ic_complete.svg";
import ic_reject from "../../assets/images/ic_reject.svg";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import ic_star_active from "../../assets/images/ic_star_active.svg";
import ic_start from "../../assets/images/ic_start.svg";
import { toPng } from "html-to-image";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "./PrescriptionComp";
import {
  PostCallWithAuthToken,
  postWithAuthCall,
  simpleGetCall,
  simpleGetCallWithAuthToken,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { useDateFormats } from "../../customHooks/useDateFormats";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { Modal } from "react-bootstrap";
import DoctorPrecription from "./PDF/DoctorPrecription.jsx";
import moment from "moment";

import PopupOverlay from "../Home/PopupOverlay";
import AgoraUIKit from "agora-react-uikit";
import "agora-react-uikit/dist/index.css";
import VaccinesModal from "./VaccineModal.js/VaccinesModal.js";
import PetFoodModal from "./VaccineModal.js/PetFoodModal.js";
import Spinner from "../../sharedComponent/Spinner.js";
import { useCurrentLatLng } from "../../customHooks/useCurrentLatLng.js";

const MyAppointmentsComp = ({ setTotalAppointMents, totalAppointMents }) => {
  const isCalling = sessionStorage.isCalling
    ? JSON.parse(sessionStorage.isCalling)
    : false;
  const isCallReceived = sessionStorage.isCallReceived
    ? JSON.parse(sessionStorage.isCallReceived)
    : false;
  const doctorAppointmentIdFromSessionStorage = sessionStorage.getItem(
    "doctorAppointmentId"
  );
  const { convertWithDateAndDay, convertWithMonthAndDay } = useDateFormats();

  // DATA FROM CONTEXT
  const {
    navigate,
    drocterCallOpen,
    viewAppDetails,
    setViewAppDetails,
    isJoineCall,
    socket,
    setIsJoineCall,
    joinCallDetails,
  } = useContext(AppContext);
  // DATA FROM CONTEXT

  // DATA FROM REDUX STORE
  const { customer_id, customerLoggedInData } = useReduxStore();
  // DATA FROM REDUX STORE

  const customerID = customerLoggedInData.loginData.user_id;

  // DATA FROM CONTEXT
  const { setBkdropLdr, VideoChatting, setVideoChatting } =
    useContext(AppContext);
  // DATA FROM CONTEXT

  // STATES
  const [prescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  const [vaccineModalOpen, setVaccineModalOpen] = useState(false);
  const [petFoodModalOpen, setPetFoodModalOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [StartBtn, setStartBtn] = useState(false);
  const [StartViewBtn, setStartViewBtn] = useState(false);
  const {
    roomId,
    setRoomid,
    callId,
    setCallId,
    drDoctorId,
    setdrDoctorId,
    CallAccepted,
    isCallAccepted,
    setIsCallAccepted,
    viewAppointment,
    setViewAppointment,
    completedAppointment,
    setCompletedAppointment,
    reviewPayLoad,
    setReviewPayLoad,
  } = useContext(AppContext);

  const [ReviewBtn, setReviewBtn] = useState(false);
  const [RewiewForm, setRewiewForm] = useState(false);
  const [RewiewDetails, setRewiewDetails] = useState(false);
  const [appointMents, setAppointMents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const lastMessageRef = useRef(null);

  const [orderPayLoad, setOrderPayLoad] = useState({
    lat: "",
    lng: "",
    priscription: [],
  });

  const { latitude, longitude } = useCurrentLatLng();

  // STATES

  useEffect(() => {
    setOrderPayLoad({
      ...orderPayLoad,
      lat: latitude ? latitude : "",
      lng: longitude ? longitude : "",
    });
  }, [latitude, longitude]);

  useEffect(() => {
    fetchAppointMentList();

    return () => {
      if (AgoraUIKit.client) {
        // Leave the channel or session
        AgoraUIKit.client.leave(
          () => {
            console.log("Agora SDK: Leave channel success");
            // Perform any additional cleanup or actions after leaving the session
          },
          (err) => {
            console.error("Agora SDK: Leave channel failed", err);
            // Handle the error if leaving the session fails
          }
        );
      }
    };
  }, []);

  //getVeterinaryAppointmentList
  const fetchAppointMentList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_APPOINTMENTS}?customer_id=${customer_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        const arr = [];
        data &&
          data.length &&
          data.forEach((item) => {
            let appointmentDate = item.date ? item.date?.split("T")[0] : "";
            let existingObj = arr.find(
              (obj) => obj.appointment_date === appointmentDate
            );
            if (existingObj) {
              existingObj.appointments.push(item);
            } else {
              arr.push({
                appointment_date: appointmentDate,
                date: item.date,
                appointments: [item],
              });
            }
          });
        const tempArray =
          arr &&
          arr.map((ele) => {
            return {
              appointment_date: ele?.appointmentDate,
              date: ele?.date,
              appointments: ele?.appointments,
            };
          });
        tempArray && setAppointMents(tempArray);
        setTotalAppointMents(data.length);
      }
    } catch (error) {
      console.log("catch error:,", error);
    } finally {
      setIsLoading(false);
    }
  };
  const [reviews, setReviews] = useState([]);

  const fetchReviews = async (appointment_id) => {
    try {
      // setBkdropLdr(true)
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_APPOINTMENTS_REVIEWS}/${appointment_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        data && data.length > 0 && setReviews(data);
      }
    } catch (error) {
      console.log("catch error:,", error);
    }
  };

  useEffect(() => {
    const appointMentRefreshHandler = (data) => {
      data.appointment_id && viewAppointMents(data.appointment_id);
      // const updatedArr =
      //   data.appointment_id &&
      //   appointMents &&
      //   appointMents.length > 0 &&
      //   appointMents.map((item) => {
      //     return {
      //       ...item,
      //       appointments: item.appointments.map((innerItem) => {
      //         return {
      //           ...innerItem,
      //           status:
      //             innerItem.doctor_appointment_id == data.appointment_id
      //               ? data.status_id
      //               : innerItem.status,
      //         };
      //       }),
      //     };
      //   });
      // console.log("updatedArr,", updatedArr);
      // updatedArr && updatedArr.length > 0 && setAppointMents(updatedArr);
    };
    socket && socket.on("update_appointment", appointMentRefreshHandler);

    return () => {
      setViewAppDetails(true);
    };
  }, []);

  // my-appointment-view
  const viewAppointMents = async (appointment_id) => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${
          ApiConfig.VIEW_APPOINTMENT
        }?appointment_id=${appointment_id}&customer_id=${customer_id}&status=${5}`
      );

      const { success, currentAppointment, completedAppointments, videoCall } =
        res?.json;
      if (success) {
        setViewAppDetails(false);
        currentAppointment && setViewAppointment(currentAppointment);
        setCompletedAppointment(completedAppointments);
        currentAppointment &&
          setReviewPayLoad({
            ...reviewPayLoad,
            appointment_customer_rating: "1",
            appointment_id: currentAppointment?.doctor_appointment_id,
          });
        setdrDoctorId(currentAppointment.doctorDetails.user_id);
        localStorage.setItem("joinCallDetails", JSON.stringify(videoCall));
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const createReview = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setBkdropLdr(true);
        const res = await PostCallWithAuthToken(
          ApiConfig.CREATE_REVIEW,
          reviewPayLoad
        );

        const { success, message } = res?.json;
        if (success) {
          notifySuccess("Review submitted successfully");
          setReviews([
            {
              appointment_customer_rating:
                reviewPayLoad?.appointment_customer_rating
                  ? reviewPayLoad?.appointment_customer_rating
                  : "",
              review_title: reviewPayLoad?.review_title
                ? reviewPayLoad?.review_title
                : "",
              appointment_customer_review:
                reviewPayLoad?.appointment_customer_review
                  ? reviewPayLoad?.appointment_customer_review
                  : "",
            },
          ]);

          setReviewPayLoad({
            appointment_id: viewAppointment?.doctor_appointment_id,
            appointment_customer_rating: "1",
            review_title: "",
            appointment_customer_review: "",
          });
          setRewiewForm(false);
        }
      } catch (error) {
        console.log("catch Error:", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  // FETCH_PRESCRIPTION_DATA
  const [prescriptionData, setPrescriptionData] = useState({});
  const isPrescription =
    prescriptionData && Object.keys(prescriptionData)?.length > 0
      ? true
      : false;
  // my-appointment-invoice-prescription

  const fecthPrescriptionData = async (doctorAppointmentId) => {
    const appointmentId = doctorAppointmentId
      ? doctorAppointmentId
      : viewAppointment?.doctor_appointment_id;
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_PRESCRIPTION_DATA}?doctor_appointment_id=${appointmentId}`
      );

      const { success, data } = res?.json;
      if (success && data) {
        setPrescriptionData(data);
        setPrescriptionModalOpen(true);

        setOrderPayLoad({
          ...orderPayLoad,
          priscription: data?.prescriptions,
        });
      } else {
        notifyError("Prescription is not available for this appointment");
      }
    } catch (error) {
      console.log("catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [vaccineData, setvaccineData] = useState({});
  const fecthVaccinesData = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_VACCINES_DATA}?doctor_appointment_id=${viewAppointment?.doctor_appointment_id}`
      );
      const { success, vaccineData, infoData } = res?.json;
      if (success) {
        if (vaccineData && vaccineData.length === 0) {
          notifyError("Vaccines are not available for this appointment");
        } else {
          const data = { vaccines: vaccineData, doctorInfo: infoData };
          setvaccineData(data);
          setVaccineModalOpen(true);
        }
      } else {
        notifyError("Vaccines is not available for this appointment");
      }
    } catch (error) {
      console.log("catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [petFoods, setPetFoods] = useState({});

  const fecthPetFoodsData = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_PETFOOD_DATA}?doctor_appointment_id=${viewAppointment?.doctor_appointment_id}`
      );
      const { success, vaccineData, infoData, message } = res?.json;
      if (success) {
        const data = { foodData: vaccineData, doctorInfo: infoData[0] };
        setPetFoods(data);
        setPetFoodModalOpen(true);
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [hidden, setHidden] = useState("none");
  const pdfExportComponent = useRef(null);
  // order-now
  const orderNow = async () => {
    try {
      if (orderPayLoad.lat === "" && orderPayLoad.lng === "") {
        notifyError("Please allow your browser location");
        return;
      }
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ORDER_NOW,
        orderPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        const { data } = res?.json;
        navigate("/MedicineCheckout", {
          state: {
            cartList: data,
            customer_animal_id: prescriptionData?.patient?.animal_id
              ? prescriptionData?.patient?.animal_id
              : "",
            doctor_appointment_id: viewAppointment?.doctor_appointment_id
              ? viewAppointment?.doctor_appointment_id
              : "",
          },
        });
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch Error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [recentChatdata, setRecentChatdata] = useState([]);

  const [message, setMessage] = useState("");
  const [newmessage, setnewmessage] = useState([]);
  const [ChatID, setChatID] = useState("");

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [recentChatdata, newmessage]);

  useEffect(() => {
    socket &&
      socket.on("message created", (value) => {
        setnewmessage((prev) => [...prev, value]);
      });
    return () => {
      socket && socket.off("message created");
    };
  }, [socket]);

  const sendMessage = async () => {
    setMessage("");
    socket &&
      socket.emit("new message", {
        senderId: customerID,
        receiverId: drDoctorId,
        chat_to_entity_type_id: 1,
        chat_from_entity_type_id: 2,
        message: message,
        chat_id: ChatID,
      });
  };

  useEffect(() => {
    handleHistery();
  }, [drDoctorId]);

  // Chat Histery  ///
  const handleHistery = async () => {
    setnewmessage([]);
    let newRequestBody = {
      doctor_id: drDoctorId,
      customer_id: customerID,
      chat_to_entity_type_id: 1,
      chat_from_entity_type_id: 2,
      page: 1,
    };
    simplePostCall(ApiConfig.CHAT_HISTRY, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          var lastmsg = data.chat_data[0].chat_id;
          setChatID(lastmsg);

          setRecentChatdata([...data.chat_data.reverse()]);
          if (data.total_pages - data.current_page === 0) {
          }
        } else {
          setRecentChatdata([]);
        }
      })
      .catch((error) => {
        console.error("api response", error);
      });
  };
  const room_id = sessionStorage.room_id ? sessionStorage.room_id : "";
  const rtcProps = {
    appId: "bc1dec31c1f1414089c89d44f29b9267",
    channel: room_id,
    token: null, // enter your channel token as a string
    role: "host",
    uId: customerID,
  };

  useEffect(() => {
    if (VideoChatting === false) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        var constraints = {
          audio: true,
          video: true,
        };

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            /* use the stream */
            // setIsCallAccepted(true);
          })
          .catch(function (err) {
            /* handle the error */
            notifyError("Camera not accessible");
            // setIsCallAccepted(false);
          });
      } else {
        // Handle the case where getUserMedia is not supported
        notifyError("getUserMedia is not supported in this browser.");
      }
    }
  }, [VideoChatting]);

  // Sender initiates the call
  const initiateCall = () => {
    const requestBody = {
      senderId: customerID,
      receiverId: drDoctorId,
      call_from_entity_type_id: 1,
      call_to_entity_type_id: 2,
    };

    socket && socket.emit("calluser", requestBody);

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
        video: true,
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          /* use the stream */
          setIsCallAccepted(true);
        })
        .catch(function (err) {
          /* handle the error */
          notifyError("Camera not accessible");
          setIsCallAccepted(false);
        });
    } else {
      notifyError("User Media is not supported in this browser.");
    }
  };

  useEffect(() => {
    socket &&
      socket.on("callcreated", (data) => {
        setCallId(data?.call_id);
        setRoomid(data?.room_id);
        if (data.senderId === 1) {
          // You are the sender
          // Handle sender-specific logic here
        } else if (data.receiverId === 1) {
          // You are the receiver
          // Handle receiver-specific logic here
        }
      });

    return () => {
      socket && socket.off("callcreated");
    };
  }, [socket]);

  const endCall = () => {
    const requestBody = {
      sender_id: customerID,
      receiver_id: drDoctorId,
      call_id: callId,
      room_id: roomId,
    };

    socket.emit("callended", requestBody);

    // setIsCallAccepted(false);
  };

  const rtcCallbacks = {
    // EndCall: () => endCall(),
    EndCall: () => [endCall(), setIsCallAccepted(false)],
  };

  const joinCall = () => {
    if (room_id) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        var constraints = {
          audio: true,
          video: true,
        };

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            navigate("/chatcomponent");
            setIsCallAccepted(true);
          })
          .catch(function (err) {
            notifyError("Camera not accessible");
          });
      } else {
        notifyError("User Media is not supported in this browser!");
      }
    }
  };

  return (
    <div id="my-appointment-wrapper">
      {VideoChatting ? (
        <div className="right-section">
          {appointMents && appointMents.length > 0 && (
            <section className="wrapper-cards ">
              <div className="heading-close-btn">
                <h5>Doctor Appointments ({totalAppointMents})</h5>
                {/* <Link >
                <img src={ic_calender_green} alt='' />
              </Link> */}
              </div>
            </section>
          )}
          {viewAppDetails ? (
            isLoading ? (
              <RotatingLoader />
            ) : (
              <div className="appointment">
                {appointMents.length > 0 ? (
                  appointMents &&
                  appointMents.map((item, idx) => {
                    return (
                      <div key={item?.doctor_appointment_id}>
                        <div className="appointment-date-wrapper">
                          <label htmlFor="">
                            {item?.date &&
                              convertWithDateAndDay(item?.date) &&
                              convertWithDateAndDay(item?.date)}
                            {/* 21 July, 2023 */}
                          </label>
                          <div className="line"></div>
                        </div>
                        {item?.appointments?.map((innerItem, idx) => {
                          return (
                            <section
                              style={{ cursor: "pointer" }}
                              className="wrapper-cards"
                              key={"innerItem" + idx}
                              onClick={() => {
                                viewAppointMents(
                                  innerItem?.doctor_appointment_id
                                );
                                fetchReviews(innerItem?.doctor_appointment_id);
                              }}
                            >
                              <div className="app-details-wrapper">
                                <div className="img-dr">
                                  <img
                                    src={
                                      innerItem?.doctorDetails
                                        ?.doctor_profile_image_path
                                        ? innerItem?.doctorDetails
                                            ?.doctor_profile_image_path
                                        : ""
                                    }
                                    onError={(e) => {
                                      e.target.src = dummydoctor;
                                    }}
                                    alt=""
                                  />
                                  <div className="right-dr">
                                    {innerItem?.doctorDetails?.doctor_name && (
                                      <label htmlFor="" className="key">
                                        {innerItem?.doctorDetails?.doctor_name
                                          ?.toLowerCase()
                                          ?.includes("dr")
                                          ? ""
                                          : "Dr."}{" "}
                                        {innerItem?.doctorDetails?.doctor_name}
                                      </label>
                                    )}

                                    <p className="value">
                                      {innerItem?.doctorDetails?.services
                                        ? `${innerItem?.doctorDetails?.services} | `
                                        : ""}
                                      {innerItem?.doctorDetails?.experience}{" "}
                                      years
                                    </p>
                                  </div>
                                </div>

                                <div className="detail">
                                  <label htmlFor="" className="key">
                                    Animal
                                  </label>
                                  {innerItem?.animalDetails?.name && (
                                    <p className="value">
                                      {innerItem?.animalDetails?.name}
                                    </p>
                                  )}
                                </div>

                                <div className="detail">
                                  <label htmlFor="" className="key">
                                    Appointment Type
                                  </label>
                                  <p className="value">
                                    {innerItem?.appointment_type === "2"
                                      ? "Video Consultation"
                                      : "At Clinic"}
                                  </p>
                                </div>

                                <div className="detail">
                                  <label htmlFor="" className="key">
                                    Fees
                                  </label>
                                  <p className="value">
                                    ₹ {innerItem?.charges}
                                  </p>
                                </div>

                                <div className="detail status-part">
                                  <label htmlFor="" className="key">
                                    Status
                                  </label>
                                  {innerItem?.status == "1" ? (
                                    <p className="value" id="progress-status">
                                      <img
                                        src={ic_progress}
                                        alt=""
                                        className="me-1"
                                      />
                                      Pending
                                    </p>
                                  ) : // <p className='value' id='accepted-status'>
                                  //   <svg
                                  //     xmlns='http://www.w3.org/2000/svg'
                                  //     width='21'
                                  //     height='21'
                                  //     viewBox='0 0 21 21'
                                  //     fill='none'
                                  //   >
                                  //     <path
                                  //       d='M2.3291 10.6751C2.3291 6.08346 6.07077 2.3418 10.6624 2.3418C15.2541 2.3418 18.9958 6.08346 18.9958 10.6751C18.9958 15.2668 15.2541 19.0085 10.6624 19.0085C6.07077 19.0085 2.3291 15.2668 2.3291 10.6751Z'
                                  //       fill='#05CD99'
                                  //     />
                                  //   </svg>
                                  //   Penging
                                  // </p>
                                  innerItem?.status == "2" ? (
                                    <p className="value" id="accepted-status">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                      >
                                        <path
                                          d="M2.3291 10.6751C2.3291 6.08346 6.07077 2.3418 10.6624 2.3418C15.2541 2.3418 18.9958 6.08346 18.9958 10.6751C18.9958 15.2668 15.2541 19.0085 10.6624 19.0085C6.07077 19.0085 2.3291 15.2668 2.3291 10.6751Z"
                                          fill="#05CD99"
                                        />
                                      </svg>
                                      Request Accepted
                                    </p>
                                  ) : innerItem?.status == "3" ? (
                                    <p className="value" id="progress-status">
                                      <img
                                        src={ic_reject}
                                        alt=""
                                        className="me-1"
                                      />
                                      Rejected
                                    </p>
                                  ) : innerItem?.status == "4" ? (
                                    <p className="value" id="progress-status">
                                      <img
                                        src={ic_progress}
                                        alt=""
                                        className="me-1"
                                      />
                                      In Progress
                                    </p>
                                  ) : innerItem?.status == "5" ? (
                                    <p className="value" id="progress-status">
                                      <img
                                        src={ic_complete}
                                        alt=""
                                        className="me-1"
                                      />
                                      Completed
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="app-btn-wrapper">
                                {/* <button className="b-c me-2">
                                  <img
                                    src={ic_view}
                                    onClick={() => {
                                      viewAppointMents(
                                        innerItem?.doctor_appointment_id
                                      );
                                      fetchReviews(
                                        innerItem?.doctor_appointment_id
                                      );
                                    }}
                                    alt=""
                                  />
                                </button> */}
                                {/* {innerItem?.status === "5" && (
                                  <button className="b-c mx-4">
                                    <img
                                      src={ic_doc_download}
                                      alt=""
                                      onClick={() => {
                                        pdfExport(
                                          innerItem?.doctor_appointment_id
                                        );
                                      }}
                                    />
                                  </button>
                                )} */}
                                {innerItem.appointment_type == 1 ? (
                                  <></>
                                ) : (
                                  <>
                                    {/* <button
                                      type="button"
                                      className="full-btn grey-btn"
                                      onClick={() => {
                                        viewAppointMents(
                                          innerItem?.doctor_appointment_id
                                        );
                                        setVideoChatting(false);
                                        navigate("/chatcomponent");
                                        // initiateCall();
                                      }}
                                    >
                                      <img src={ic_video} alt="" />
                                      Start Call
                                    </button> */}
                                  </>
                                )}
                              </div>
                            </section>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <>
                    {/* <button
                      // style={{ width: '50px', height: '50px' }}
                      type='button'
                      onClick={() => {
                        setPrescriptionModalOpen(true)
                      }}
                    >
                      {' '}
                      button
                    </button> */}
                    <NoDataMsg MSG="No Appointments Found" />
                  </>
                )}

                {/* Request Accepted */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='accepted-status'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='21'
                        height='21'
                        viewBox='0 0 21 21'
                        fill='none'
                      >
                        <path
                          d='M2.3291 10.6751C2.3291 6.08346 6.07077 2.3418 10.6624 2.3418C15.2541 2.3418 18.9958 6.08346 18.9958 10.6751C18.9958 15.2668 15.2541 19.0085 10.6624 19.0085C6.07077 19.0085 2.3291 15.2668 2.3291 10.6751Z'
                          fill='#05CD99'
                        />
                      </svg>
                      Request Accepted
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='b-c '>
                    <img src={ic_view} alt='' />
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='full-btn green-btn'>
                    <img src={ic_video} alt='' />
                    Start
                  </button>
                </div>
              </section> */}
                {/* Request Accepted */}

                {/* In Progress */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='progress-status'>
                      <img src={ic_progress} alt='' className='me-1' />
                      In Progress
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='full-btn green-btn '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='25'
                      viewBox='0 0 25 25'
                      fill='none'
                      className='me-1'
                    >
                      <path
                        d='M16.1405 12.6796C16.1405 14.6596 14.5405 16.2596 12.5605 16.2596C10.5805 16.2596 8.98047 14.6596 8.98047 12.6796C8.98047 10.6996 10.5805 9.09961 12.5605 9.09961C14.5405 9.09961 16.1405 10.6996 16.1405 12.6796Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M12.5604 20.9504C16.0904 20.9504 19.3804 18.8704 21.6704 15.2704C22.5704 13.8604 22.5704 11.4904 21.6704 10.0804C19.3804 6.48039 16.0904 4.40039 12.5604 4.40039C9.03039 4.40039 5.74039 6.48039 3.45039 10.0804C2.55039 11.4904 2.55039 13.8604 3.45039 15.2704C5.74039 18.8704 9.03039 20.9504 12.5604 20.9504Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    View
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='b-c'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                    >
                      <path
                        d='M11.9697 22.0918C17.4927 22.0918 21.9697 17.6148 21.9697 12.0918C21.9697 6.5688 17.4927 2.0918 11.9697 2.0918C6.44673 2.0918 1.96973 6.5688 1.96973 12.0918C1.96973 17.6148 6.44673 22.0918 11.9697 22.0918Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M8.74023 12.3224V10.6524C8.74023 8.57241 10.2102 7.72241 12.0102 8.76241L13.4602 9.60241L14.9102 10.4424C16.7102 11.4824 16.7102 13.1824 14.9102 14.2224L13.4602 15.0624L12.0102 15.9024C10.2102 16.9424 8.74023 16.0924 8.74023 14.0124V12.3224Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </section> */}
                {/* In Progress */}

                {/* Completed */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='progress-status'>
                      <img src={ic_complete} alt='' className='me-1' />
                      Completed
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='full-btn green-btn '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='25'
                      viewBox='0 0 25 25'
                      fill='none'
                      className='me-1'
                    >
                      <path
                        d='M16.1405 12.6796C16.1405 14.6596 14.5405 16.2596 12.5605 16.2596C10.5805 16.2596 8.98047 14.6596 8.98047 12.6796C8.98047 10.6996 10.5805 9.09961 12.5605 9.09961C14.5405 9.09961 16.1405 10.6996 16.1405 12.6796Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M12.5604 20.9504C16.0904 20.9504 19.3804 18.8704 21.6704 15.2704C22.5704 13.8604 22.5704 11.4904 21.6704 10.0804C19.3804 6.48039 16.0904 4.40039 12.5604 4.40039C9.03039 4.40039 5.74039 6.48039 3.45039 10.0804C2.55039 11.4904 2.55039 13.8604 3.45039 15.2704C5.74039 18.8704 9.03039 20.9504 12.5604 20.9504Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    View
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='b-c'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                    >
                      <path
                        d='M11.9697 22.0918C17.4927 22.0918 21.9697 17.6148 21.9697 12.0918C21.9697 6.5688 17.4927 2.0918 11.9697 2.0918C6.44673 2.0918 1.96973 6.5688 1.96973 12.0918C1.96973 17.6148 6.44673 22.0918 11.9697 22.0918Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M8.74023 12.3224V10.6524C8.74023 8.57241 10.2102 7.72241 12.0102 8.76241L13.4602 9.60241L14.9102 10.4424C16.7102 11.4824 16.7102 13.1824 14.9102 14.2224L13.4602 15.0624L12.0102 15.9024C10.2102 16.9424 8.74023 16.0924 8.74023 14.0124V12.3224Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </section> */}
                {/* Completed */}

                {/* Rejected */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='progress-status'>
                      <img src={ic_reject} alt='' className='me-1' />
                      Rejected
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='full-btn green-btn '>
                    <img src={ic_view_light} alt='' />
                    View
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='b-c'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                    >
                      <path
                        d='M11.9697 22.0918C17.4927 22.0918 21.9697 17.6148 21.9697 12.0918C21.9697 6.5688 17.4927 2.0918 11.9697 2.0918C6.44673 2.0918 1.96973 6.5688 1.96973 12.0918C1.96973 17.6148 6.44673 22.0918 11.9697 22.0918Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M8.74023 12.3224V10.6524C8.74023 8.57241 10.2102 7.72241 12.0102 8.76241L13.4602 9.60241L14.9102 10.4424C16.7102 11.4824 16.7102 13.1824 14.9102 14.2224L13.4602 15.0624L12.0102 15.9024C10.2102 16.9424 8.74023 16.0924 8.74023 14.0124V12.3224Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </section> */}
                {/* Rejected */}
              </div>
            )
          ) : (
            <div className="appointment-details-wrapper">
              <div className="show-back-heading">
                <Link
                  onClick={() => {
                    setViewAppDetails(true);
                    setRewiewForm(false);
                    fetchAppointMentList()
                  }}
                >
                  <img src={ic_left_green_arrow} alt="" />
                </Link>
                <label htmlFor="">
                  Showing result for :{" "}
                  <span>{viewAppointment?.doctorDetails?.doctor_name}</span>
                </label>
              </div>
              <div className="wrapper-cards" id="pdfcontent">
                <h6>Appointment Details</h6>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="" className="key">
                      Animal Name
                    </label>
                    {viewAppointment?.animalDetails?.name && (
                      <span className="value">
                        {/* {viewAppointment?.doctorDetails?.doctor_name
                          ?.toLowerCase()
                          ?.includes("dr")
                          ? ""
                          : "Dr."} */}
                        {viewAppointment?.animalDetails?.name}
                      </span>
                    )}
                  </div>
                  {viewAppointment?.date && (
                    <div className="col-md-4">
                      <label htmlFor="" className="key">
                        Appointment Date & Time
                      </label>
                      <span className="value">
                        {viewAppointment?.date},{" "}
                        {moment(viewAppointment?.time, "hh:mm").format(
                          "hh:mm A"
                        )}
                        {/* {convertWithDataAndTime(viewAppointment?.date)} */}
                        {/* 02-02-2023, 04:30 PM */}
                      </span>
                    </div>
                  )}
                  {viewAppointment?.appointment_type && (
                    <div className="col-md-4 mb-3">
                      <label htmlFor="" className="key">
                        Appointment Type
                      </label>
                      <span className="value">
                        {viewAppointment?.appointment_type === "2"
                          ? "Video Consultation"
                          : "At Clinic"}
                        {/* <img src={ic_complete} alt='' className='mx-1' />
                        <span id='completed'>Paid</span> */}
                      </span>
                    </div>
                  )}
                  {viewAppointment?.appointment_desc && (
                    <div className="col-12 mb-3">
                      <label htmlFor="" className="key">
                        Description
                      </label>
                      <span className="value">
                        {viewAppointment?.appointment_desc}{" "}
                      </span>
                    </div>
                  )}

                  {viewAppointment?.animalDetails &&
                  viewAppointment?.animalDetails?.photos &&
                  viewAppointment?.animalDetails?.photos.length > 0 ? (
                    <div className="col-12 mb-3">
                      <label htmlFor="" className="key">
                        Photos
                      </label>
                      <div className="img-wrapper">
                        {viewAppointment?.animalDetails?.photos.map(
                          (item, i) => {
                            const imagePath = item?.animal_photograph_path;
                            return (
                              <div
                                className="img me-3"
                                key={item?.animal_photograph_id}
                              >
                                <img
                                  src={imagePath ? imagePath : dog_1}
                                  alt="Not found"
                                  className="animal-img"
                                />
                              </div>
                            );
                          }
                        )}

                        {/* <div className='img me-3'>
                          <img src={dog_3} alt='' className='animal-img' />
                          <Link>
                            <img src={ic_video} alt='' className='ic-video' />
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  ) : null}

                  {
                    // RewiewDetails
                    true ? (
                      reviews[0]?.appointment_customer_rating && (
                        <div className="col-12">
                          <label htmlFor="" className="key">
                            Ratings & Review
                          </label>
                          {reviews &&
                            reviews.length > 0 &&
                            reviews.map((item, i) => {
                              return (
                                <div
                                  className="review-details"
                                  key={"reviews" + i}
                                >
                                  <div className="heading-rating">
                                    <div className="left">
                                      <label htmlFor="" className="value">
                                        {item?.review_title}
                                      </label>
                                      {viewAppointment?.doctorDetails
                                        ?.doctor_name && (
                                        <p className="key">
                                          Dr.{" "}
                                          {
                                            viewAppointment?.doctorDetails
                                              ?.doctor_name
                                          }
                                        </p>
                                      )}
                                    </div>
                                    {item?.appointment_customer_rating && (
                                      <div className="right">
                                        <img src={ic_star_active} alt="" />{" "}
                                        <span>
                                          {item?.appointment_customer_rating}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  {item?.appointment_customer_review && (
                                    <div className="value">
                                      {item?.appointment_customer_review}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )
                    ) : (
                      <></>
                    )
                  }

                  {
                    // ReviewBtn
                    viewAppointment?.status == "5" ? (
                      <div className="col-12">
                        {/* <label htmlFor='' className='key'>
                          Ratings & Review
                        </label> */}
                        <div className="review-write-btn-wrapper ">
                          <button
                            onClick={() => {
                              setRewiewForm(true);
                              setReviewBtn(false);
                            }}
                          >
                            {reviews[0]?.appointment_customer_rating ||
                            reviews[0]?.review_title ||
                            reviews[0]?.appointment_customer_review
                              ? "Update"
                              : "Write"}{" "}
                            a Review...
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  <div className="btn-wrapper">
                    {
                      // StartViewBtn
                      true ? (
                        <>
                          {viewAppointment?.status == "5" ? (
                            <>
                              <button
                                className="btn-blank-green"
                                onClick={() => {
                                  fecthPetFoodsData();
                                }}
                              >
                                <img src={ic_view} alt="" />
                                View Pet Foods
                              </button>
                              <button
                                className="btn-blank-green"
                                onClick={() => {
                                  fecthVaccinesData();
                                }}
                              >
                                <img src={ic_view} alt="" />
                                View Vaccines
                              </button>
                              <button
                                className="btn-blank-green"
                                onClick={() => {
                                  fecthPrescriptionData();
                                }}
                              >
                                <img src={ic_view} alt="" />
                                View Medicines
                              </button>
                              {/* <button
                                type="button"
                                className="btn-blank-green"
                                onClick={() => {
                                  pdfExport();
                                  // htmlToImageConvert()
                                }}
                              >
                                <img src={ic_doc_download} alt="" />
                                Download
                              </button> */}
                            </>
                          ) : !joinCallDetails?.result &&
                            isCalling &&
                            isCallReceived ? (
                            <span className="waiting-text">
                              <label>
                                Relax, your vet is writing your prescription...
                              </label>{" "}
                              <Spinner />
                            </span>
                          ) : null}

                          {
                            viewAppointment?.appointment_type == "2" &&
                            joinCallDetails?.result ? (
                              <button
                                className="btn-blank-green"
                                onClick={() => {
                                  joinCall();
                                }}
                              >
                                <img src={ic_repeat_green} alt="" />
                                Join Call
                              </button>
                            ) : null
                            // <button
                            //   className="btn-blank-green"
                            //   onClick={() => {
                            //     navigate("/chatcomponent");
                            //     sessionStorage.setItem(
                            //       "doctor_id",
                            //       viewAppointment?.doctorDetails?.user_id
                            //     );
                            //     sessionStorage.setItem(
                            //       "doctorAppointmentId",
                            //       viewAppointment?.doctor_appointment_id
                            //     );
                            //   }}
                            // >
                            //   <img src={ic_video_green} alt="" />
                            //   Start Call
                            // </button>
                          }
                        </>
                      ) : (
                        <>
                          <button
                            className="blank-btn me-3"
                            onClick={() => setStartBtn(false)}
                          >
                            Back
                          </button>

                          {StartBtn ? (
                            <button
                              className="filled-start"
                              onClick={() => setStartViewBtn(true)}
                            >
                              Start (59s)
                            </button>
                          ) : (
                            <button
                              className="filled-btn"
                              onClick={() => setStartBtn(true)}
                            >
                              Start
                            </button>
                          )}
                        </>
                      )
                    }
                  </div>

                  <PrescriptionComp
                    prescriptionModalOpen={prescriptionModalOpen}
                  />
                </div>
              </div>

              {/* ===================== REWIEW FORM SECTION =========================== */}

              {RewiewForm ? (
                <Form noValidate validated={validated} onSubmit={createReview}>
                  <div className="wrapper-cards mt-3">
                    <h6>Write a Review</h6>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="" className="key">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title goes here..."
                          required
                          onChange={(e) => {
                            setReviewPayLoad({
                              ...reviewPayLoad,
                              review_title: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Title is required
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3 text-end">
                        <Link className="ms-2">
                          <img
                            src={
                              Number(
                                reviewPayLoad?.appointment_customer_rating
                              ) >= 1
                                ? ic_star_active
                                : ic_start
                            }
                            alt=""
                            onClick={() => {
                              setReviewPayLoad({
                                ...reviewPayLoad,
                                appointment_customer_rating: "1",
                              });
                            }}
                          />
                        </Link>
                        <Link className="ms-2">
                          <img
                            src={
                              Number(
                                reviewPayLoad?.appointment_customer_rating
                              ) >= 2
                                ? ic_star_active
                                : ic_start
                            }
                            onClick={() => {
                              setReviewPayLoad({
                                ...reviewPayLoad,
                                appointment_customer_rating:
                                  reviewPayLoad?.appointment_customer_rating !==
                                  2
                                    ? 2
                                    : "",
                              });
                            }}
                            alt=""
                          />
                        </Link>
                        <Link className="ms-2">
                          <img
                            src={
                              Number(
                                reviewPayLoad?.appointment_customer_rating
                              ) >= 3
                                ? ic_star_active
                                : ic_start
                            }
                            onClick={() => {
                              setReviewPayLoad({
                                ...reviewPayLoad,
                                appointment_customer_rating:
                                  reviewPayLoad?.appointment_customer_rating !==
                                  "3"
                                    ? "3"
                                    : "",
                              });
                            }}
                            alt=""
                          />
                        </Link>
                        <Link className="ms-2">
                          <img
                            src={
                              Number(
                                reviewPayLoad?.appointment_customer_rating
                              ) >= 4
                                ? ic_star_active
                                : ic_start
                            }
                            onClick={() => {
                              setReviewPayLoad({
                                ...reviewPayLoad,
                                appointment_customer_rating:
                                  reviewPayLoad?.appointment_customer_rating !==
                                  "4"
                                    ? "4"
                                    : "",
                              });
                            }}
                            alt=""
                          />
                        </Link>
                        <Link className="ms-2">
                          <img
                            src={
                              Number(
                                reviewPayLoad?.appointment_customer_rating
                              ) >= 5
                                ? ic_star_active
                                : ic_start
                            }
                            onClick={() => {
                              setReviewPayLoad({
                                ...reviewPayLoad,
                                appointment_customer_rating:
                                  reviewPayLoad?.appointment_customer_rating !==
                                  "5"
                                    ? "5"
                                    : "",
                              });
                            }}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label htmlFor="" className="key">
                          Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write your review"
                          onChange={(e) => {
                            setReviewPayLoad({
                              ...reviewPayLoad,
                              appointment_customer_review: e.target.value,
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Description is required
                        </Form.Control.Feedback>
                      </div>

                      <div className="btn-wrapper">
                        <>
                          <button
                            type="button"
                            className="btn-blank-green"
                            onClick={() => setRewiewForm(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn-blank-green"
                            onClick={(e) => {
                              setReviewBtn(false);
                              setRewiewDetails(true);
                            }}
                            style={{
                              backgroundColor: "#1D7C7C",
                              color: "#E9F5FB",
                            }}
                          >
                            {reviews[0]?.appointment_customer_rating ||
                            reviews[0]?.review_title ||
                            reviews[0]?.appointment_customer_review
                              ? "Update"
                              : "Submit"}
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </Form>
              ) : (
                <></>
              )}

              {completedAppointment && completedAppointment.length > 0 && (
                <div className="wrapper-cards mt-3 " id="tabs-app-details">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Nav variant="pills" className="flex-row">
                      {completedAppointment &&
                        completedAppointment.length > 0 && (
                          <Nav.Item>
                            <Nav.Link eventKey="first">Appointments</Nav.Link>
                          </Nav.Item>
                        )}
                      {/* <Nav.Item>
                      <Nav.Link eventKey='second'>Invoices</Nav.Link>
                    </Nav.Item> */}
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="first-tab-con">
                          {completedAppointment.map((item, i) => {
                            return (
                              <div key={item?.doctor_appointment_id}>
                                {item?.date &&
                                  convertWithDateAndDay(item?.date) && (
                                    <div className="appointment-date-wrapper mt-4">
                                      <label htmlFor="">
                                        {convertWithDateAndDay(item?.date)}
                                        {/* {convertWithDateAndDay(
                                            item?.date?.split('T')[0]
                                          )} */}
                                      </label>
                                      <div className="line"></div>
                                    </div>
                                  )}
                                <section
                                  className="wrapper-cards mb-3"
                                  id="bg-dark-card"
                                >
                                  <div className="app-details-wrapper">
                                    {item?.animalDetails?.name && (
                                      <div className="detail">
                                        <label htmlFor="" className="key">
                                          Animal
                                        </label>
                                        <p className="value">
                                          {item?.animalDetails?.name}
                                        </p>
                                      </div>
                                    )}
                                    <div className="detail">
                                      <label htmlFor="" className="key">
                                        Appointment Type
                                      </label>
                                      <p className="value">
                                        {item?.appointment_type === "2"
                                          ? "Video Consultation"
                                          : "At clinic"}
                                      </p>
                                    </div>
                                    <div className="detail">
                                      <label htmlFor="" className="key">
                                        Fees
                                      </label>
                                      <p className="value">₹ {item?.charges}</p>
                                    </div>
                                    <div className="detail">
                                      <label htmlFor="" className="key">
                                        Status
                                      </label>
                                      {item?.status === "5" ? (
                                        <p
                                          className="value"
                                          id="progress-status"
                                        >
                                          <img
                                            src={ic_complete}
                                            alt=""
                                            className="me-1"
                                          />
                                          Complete
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="app-btn-wrapper">
                                    <button className="b-c me-2">
                                      <img
                                        src={ic_view_light}
                                        alt=""
                                        onClick={() =>
                                          fecthPrescriptionData(
                                            item?.doctor_appointment_id
                                          )
                                        }
                                      />
                                    </button>
                                    {/* <button className="b-c mx-4">
                                    <img
                                      src={ic_doc_download_light}
                                      alt=""
                                      onClick={() => {
                                        if (item?.status === "5") {
                                          pdfExport(
                                            item?.doctor_appointment_id
                                          );
                                        }
                                      }}
                                      //
                                    />
                                  </button> */}
                                    <button
                                      className="full-btn green-btn"
                                      // to={`/BookAppoitment?doctorId=${item?.doctor_id}&clinic_id=${item?.schedule_details[0]?.doctor_clinic_id}`}

                                      onClick={() => {
                                        navigate(
                                          `/BookAppoitment?doctorId=${viewAppointment?.doctorDetails?.doctor_id}&clinic_id=${viewAppointment?.doctor_clinic_id}`
                                        );
                                        // setVideoChatting(false)
                                      }}
                                    >
                                      <img src={ic_repeat} alt="" /> Re-Schedule
                                    </button>
                                  </div>
                                </section>
                              </div>
                            );
                          })}

                          {/* <div className='appointment-date-wrapper mt-4'>
                          <label htmlFor=''>09 July, 2023</label>
                          <div className='line'></div>
                        </div>
                        <section className='wrapper-cards' id='bg-light-card'>
                          <div className='app-details-wrapper'>
                            <div className='detail'>
                              <label htmlFor='' className='key'>
                                Animal
                              </label>
                              <p className='value'>Bruno | Cat</p>
                            </div>
                            <div className='detail'>
                              <label htmlFor='' className='key'>
                                Appointment Type
                              </label>
                              <p className='value'>Video Consultation</p>
                            </div>
                            <div className='detail'>
                              <label htmlFor='' className='key'>
                                Fees
                              </label>
                              <p className='value'>₹100</p>
                            </div>
                            <div className='detail'>
                              <label htmlFor='' className='key'>
                                Status
                              </label>
                              <p className='value' id='progress-status'>
                                <img
                                  src={ic_complete}
                                  alt=''
                                  className='me-1'
                                />
                                Complete
                              </p>
                            </div>
                          </div>
                          <div className='app-btn-wrapper'>
                            <button className='b-c '>
                              <img src={ic_view} alt='' />
                            </button>
                            <button className='b-c mx-4'>
                              <img src={ic_doc_download} alt='' />
                            </button>
                            <button
                              className='full-btn green-btn'
                              id='reapeat-btn'
                              onClick={() => setVideoChatting(false)}
                            >
                              <img src={ic_repeat} alt='' /> Re-Schedule
                            </button>
                          </div>
                        </section> */}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="first-tab-con">
                          <div className="appointment-date-wrapper mt-4">
                            <label htmlFor="">Today</label>
                            <div className="line"></div>
                          </div>

                          <section
                            className="wrapper-cards mb-3"
                            id="bg-dark-card"
                          >
                            <div className="app-details-wrapper">
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Animal
                                </label>
                                <p className="value">Bruno | Cat</p>
                              </div>
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Appointment Type
                                </label>
                                <p className="value">Video Consultation</p>
                              </div>
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Fees
                                </label>
                                <p className="value">₹100</p>
                              </div>
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Status
                                </label>
                                <p className="value" id="progress-status">
                                  <img
                                    src={ic_complete}
                                    alt=""
                                    className="me-1"
                                  />
                                  Complete
                                </p>
                              </div>
                            </div>
                          </section>
                          <div className="appointment-date-wrapper mt-4">
                            <label htmlFor="">09 July, 2023</label>
                            <div className="line"></div>
                          </div>
                          <section className="wrapper-cards" id="bg-light-card">
                            <div className="app-details-wrapper">
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Animal
                                </label>
                                <p className="value">Bruno | Cat</p>
                              </div>
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Appointment Type
                                </label>
                                <p className="value">Video Consultation</p>
                              </div>
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Fees
                                </label>
                                <p className="value">₹100</p>
                              </div>
                              <div className="detail">
                                <label htmlFor="" className="key">
                                  Status
                                </label>
                                <p className="value" id="progress-status">
                                  <img
                                    src={ic_complete}
                                    alt=""
                                    className="me-1"
                                  />
                                  Complete
                                </p>
                              </div>
                            </div>
                          </section>
                        </div>
                      </Tab.Pane>{" "}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="video-chatting-wrapper" id="ChattingVideoComp">
          <div className="row">
            <div className="col-md-8">
              <div className="left-video">
                <Link onClick={() => setVideoChatting(true)}>
                  <img src={ic_left_green_arrow} alt="" />
                </Link>
                {/* <h4>Appointment - 1</h4> */}
                <div className="video-section">
                  <div className="left-video   heightCalc">
                    {/* <img src={video_dummy} alt='' className='w-100' /> */}

                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "50vh",
                      }}
                    >
                      {isCallAccepted ? (
                        <AgoraUIKit
                          rtcProps={rtcProps}
                          // callbacks={{
                          //   EndCall: () => setIsCallAccepted(false),
                          // }}
                          callbacks={rtcCallbacks}
                          style={{
                            resizeMode: "cover",
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: 10,
                            borderWidth: 2,
                            borderColor: "red",
                          }}
                        />
                      ) : (
                        <>
                          <div className="userCallSec mb-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="80"
                              height="80"
                              fill="#000"
                              class="bi bi-person-video3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2Z" />
                              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783.059-.187.09-.386.09-.593V4a2 2 0 0 0-2-2H2Z" />
                            </svg>
                          </div>
                          <p className="text-center">
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                // initiateCall()
                                navigate("/chatcomponent");
                              }}
                            >
                              Start Call
                            </button>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  {/* <p className='text-center'>
                    <button
                      className='btn btn-danger'
                      onClick={() => {
                        setVideoChatting(true)
                        setReviewBtn(true)
                      }}
                    >
                      End Call
                    </button>
                    {drocterCallOpen === true ?    <PopupOverlay /> :<></>}
                 
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="right-chating">
                <h4>Chat</h4>
                <div className="chatting-section text-center">
                  <div className="message-main">
                    {recentChatdata.map((chatData, i) => {
                      return customerID == chatData.conversation_from ? (
                        <>
                          <div className="send-msg">
                            <div className="msg-with-img">
                              <img
                                src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                alt=""
                              />
                            </div>
                            <div className="send-msg-main">
                              <div className="msg-bg">
                                <span className="senderName">
                                  {chatData.conversation_from_name}
                                </span>

                                <p className="msg-text">
                                  {chatData.conversation_message}
                                  {/* Jane wala */}
                                </p>
                              </div>
                              <div className="time-date-sms-send">
                                {moment(
                                  chatData.conversation_datetime
                                ).fromNow()}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div key={i} className="receive-msg">
                            <div className="msg-with-img">
                              <img
                                src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                alt=""
                              />
                            </div>
                            <div className="right-receive-msg">
                              <div className="msg-bg">
                                <span className="senderName">
                                  {chatData.conversation_from_name}
                                </span>
                                <div className="receive-msg-main">
                                  <p className="msg-text">
                                    {chatData.conversation_message}
                                  </p>
                                </div>
                              </div>
                              <div className="time-date-sms-recieve">
                                {moment(
                                  chatData.conversation_datetime
                                ).fromNow()}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {newmessage.map((chatData, i) => {
                      return customerID == chatData.conversation_from ? (
                        <>
                          <div className="send-msg">
                            <div className="msg-with-img">
                              <img
                                src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                alt=""
                              />
                            </div>
                            <div className="send-msg-main">
                              <div className="msg-bg">
                                <span className="senderName">
                                  {chatData.conversation_from_name}
                                </span>

                                <p className="msg-text">
                                  {chatData.conversation_message}
                                  {/* Jane wala */}
                                </p>
                              </div>
                              <div className="time-date-sms-send">
                                {moment(
                                  chatData.conversation_datetime
                                ).fromNow()}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div key={i} className="receive-msg">
                            <div className="msg-with-img">
                              <img
                                src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                                alt=""
                              />
                            </div>
                            <div className="right-receive-msg">
                              <div className="msg-bg">
                                <span className="senderName">
                                  {chatData.conversation_from_name}
                                </span>
                                <div className="receive-msg-main">
                                  <p className="msg-text">
                                    {/* {chatData.conversation_message} */}
                                    {chatData.conversation_message}
                                  </p>
                                </div>
                              </div>
                              <div className="time-date-sms-recieve">
                                {moment(
                                  chatData.conversation_datetime
                                ).fromNow()}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div ref={lastMessageRef} />
                </div>

                <div className="send-text-sec">
                  <div className="wrap-btn-send">
                    <input
                      type="text"
                      name=""
                      id=""
                      className="form-control"
                      placeholder="Type Something..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key == "Enter") sendMessage();
                      }}
                    />
                    {/* <button>
                      <img src="" alt="" />
                      <img src={btnSend} alt="" />
                    </button> */}
                    <button>
                      <img
                        onClick={sendMessage}
                        onKeyUp={sendMessage}
                        src={btnSend}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className='right-chatting wrapper-cards'>
                <img src={chatting_dummy} alt='' className='w-100' />
              </div> */}
            </div>
          </div>
        </div>
      )}
      {/* Prescription Modal */}
      <div className="prescription-wrapper-main">
        <Modal
          show={prescriptionModalOpen ? true : false}
          onHide={() => setPrescriptionModalOpen(false)}
          size="lg"
          id="prescription-modal"
          centered
        >
          <Modal.Body>
            <div className="petfood-modal">
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={20}
                fileName={`Medicine Prescription`}
                author="KendoReact Team"
              >
                <div className="orderModalInvoice">
                  <div className="modalHeading">
                    <div className="upperBlue">
                      {isPrescription && (
                        <h1 className="docName">
                          {prescriptionData?.doctor?.doctor_name
                            ?.toLowerCase()
                            ?.includes("dr")
                            ? ""
                            : "Dr."}{" "}
                          {prescriptionData?.doctor?.doctor_name}{" "}
                        </h1>
                      )}
                      {isPrescription && (
                        <label className="domainLab">
                          {prescriptionData?.doctor?.doctor_specialization
                            ? prescriptionData?.doctor?.doctor_specialization
                            : ""}{" "}
                        </label>
                      )}

                      <label className="certificateLab">
                        Medicine Prescription
                      </label>
                    </div>
                    <div className="docSymbolBox">
                      <img src={DoctorSymbol} className="docSymImg" alt="" />
                    </div>
                  </div>
                  <div className="modalBody">
                    <div className="detailsFill">
                      <div className="labelTxt" style={{ width: "30%" }}>
                        <label className="widthFixed">Animal Owner :</label>
                      </div>
                      <input
                        style={{ background: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          prescriptionData?.patient?.customer_name
                            ? prescriptionData?.patient?.customer_name
                            : ""
                        }
                      />
                    </div>
                    <div className="detailsFill">
                      <div className="labelTxt">
                        <label className="widthFixed">Patient Name :</label>
                      </div>
                      <input
                        style={{ background: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          prescriptionData?.patient?.name
                            ? prescriptionData?.patient?.name
                            : ""
                        }
                      />
                    </div>
                    <div className="addressFlex">
                      <div className="labelTxt">
                        <label className="keyQuestion">Address:</label>
                      </div>
                      <p className="text-para">
                        {prescriptionData?.patient?.address
                          ? `${
                              prescriptionData?.patient?.street_name
                                ? prescriptionData?.patient?.street_name + ","
                                : ""
                            } ${
                              prescriptionData?.patient?.landmark
                                ? prescriptionData?.patient?.landmark + ","
                                : ""
                            } ${prescriptionData?.patient?.address}`
                          : ""}
                      </p>
                      {/* <input
                        style={{ background: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          prescriptionData?.patient?.address
                            ? `${
                                prescriptionData?.patient?.street_name
                                  ? prescriptionData?.patient?.street_name + ","
                                  : ""
                              } ${
                                prescriptionData?.patient?.landmark
                                  ? prescriptionData?.patient?.landmark + ","
                                  : ""
                              } ${prescriptionData?.patient?.address}`
                            : ""
                        }
                      /> */}
                    </div>
                    <div className="dateNdAgeFlex">
                      <div className="ageBox">
                        <label className="ageLab">Age:</label>
                        <input
                          style={{ background: "#fff" }}
                          disabled
                          type="text"
                          className="form-control formSolution"
                          value={
                            prescriptionData?.patient?.age
                              ? prescriptionData?.patient?.age
                              : ""
                          }
                        />
                      </div>
                      <div className="dateBox">
                        <label className="ageLab">Date:</label>
                        <input
                          style={{ background: "#fff" }}
                          disabled
                          type="text"
                          className="form-control formSolution"
                          value={
                            prescriptionData?.patient?.date
                              ? prescriptionData?.patient?.date &&
                                prescriptionData?.patient?.date
                                  ?.split("-")
                                  .reverse()
                                  .join("-")
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="addressFlex">
                      <div className="labelTxt" style={{ width: "30%" }}>
                        <label className="keyQuestion">
                          Reason / Symptoms:
                        </label>
                      </div>
                      <input
                        style={{ background: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          prescriptionData?.patient?.diagnosis
                            ? prescriptionData?.patient?.diagnosis
                            : ""
                        }
                      />
                    </div>

                    <div className="tableAndLandingBg">
                      <div className="backGroundPic"></div>
                      {Object.keys(prescriptionData)?.length > 0 &&
                        prescriptionData?.prescriptions?.length > 0 && (
                          <div className="innerTable">
                            <table className="modalTable">
                              <thead>
                                <tr>
                                  <th>Sr No.</th>
                                  <th>Prescription</th>
                                  <th>Instruction</th>
                                  <th>Type</th>
                                  <th>Quantity</th>
                                  <th>Frequency</th>
                                  <th>Duration (in days)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {prescriptionData?.prescriptions.map(
                                  (item, i) => {
                                    return (
                                      <tr key={"prescriptionData" + i}>
                                        <td>{i + 1}</td>
                                        <td>
                                          {item?.prescription
                                            ? item?.prescription
                                            : ""}
                                        </td>
                                        <td>{item?.instruction}</td>
                                        <td>{item?.medicine_type_prefix}</td>
                                        <td>{item?.prescription_quantity}</td>
                                        <td>
                                          {item?.prescription_frequency
                                            ? item?.prescription_frequency
                                            : ""}
                                        </td>
                                        <td>
                                          {item?.prescription_duration
                                            ? item?.prescription_duration
                                            : ""}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>

                            {/* <div>
                          <button>Submit</button>
                        </div> */}
                          </div>
                        )}
                    </div>

                    {prescriptionData?.imageData && (
                      <div className="signatureContent">
                        <div className="sigBox">
                          <div className="line">
                            <img
                              // src={prescriptionData?.doctor}
                              src={`data:image/${prescriptionData?.imageType};base64,${prescriptionData?.imageData}`}
                              // src={
                              //   prescriptionData?.doctor?.doctor_signature
                              //     ? prescriptionData?.doctor?.doctor_signature
                              //     : sign
                              // }
                              // src={"https://www.shutterstock.com/image-vector/fake-hand-drawn-autographs-set-260nw-2295145277.jpg"}
                              alt=""
                            />
                          </div>

                          {/* <label htmlFor="sigNatureFor" className="signaturePad">
                          <input type="file" id="sigNatureFor" className="d-none" />
                        </label> */}
                          <label className="belowTxt">SIGNATURE</label>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="belowDetails">
                    <div className="imgWrapper">
                      <div className="row heightAuto">
                        <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                          <div className="txt">
                            <div className="innerTxt">Contact</div>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-12 p-0">
                          <div className="allCOntact">
                            <div className="emailAndCall">
                              {/* <div className="callNnumber">
                                <img src={ic_call} alt="" />
                                <label className="numberInn">
                                  +91 9509932234
                                 {prescriptionData?.doctor?.doctor_contact_number}
                                </label>
                              </div> */}
                              <div className="emialInn">
                                <img src={ic_mail} alt="" />
                                <label className="numberInn">
                                  info@animalsapp.in
                                </label>
                              </div>
                            </div>
                            {/*    {prescriptionData?.doctor?.doctor_city &&
                          prescriptionData?.doctor?.doctor_state &&
                          prescriptionData?.doctor?.doctor_country && (*/}

                            {/* <div className="locationInn">
                              <img src={ic_location} alt="" />
                              <label className="locationTxt">
                                Animals App, Animalsomatic clinic Near Ravi
                                Steel, Kamde, Ratu Road, Ranchi, Jharkhand,
                                India 835222
                              {`${prescriptionData?.doctor?.doctor_city} ${prescriptionData?.doctor?.doctor_state} ${prescriptionData?.doctor?.doctor_country}`}
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PDFExport>
              <div>
                <button
                  // style={{ pointerEvents: 'auto' }}
                  type="button"
                  onClick={() => {
                    orderNow();
                  }}
                  className="filled-btn"
                  // style={{ pointerEvents: 'auto' }}
                >
                  Order now
                </button>
              </div>
              <div
                onClick={() => {
                  if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                  }
                }}
                // style={{
                //   position: "absolute",
                //   bottom: "58%",
                //   left: "90%",
                //   // fontSize: "25px",
                //   cursor: "pointer",
                // }}
              >
                <button
                  type="button"
                  className="btn-blank-green"
                  onClick={() => {
                    // pdfExport();
                  }}
                >
                  Download
                  <img
                    src={ic_doc_download}
                    className="ic_doc_download"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* setVaccineModalOpen */}
      {/* Vaccines Modal */}
      <VaccinesModal
        pdfExportComponent={pdfExportComponent}
        vaccineModalOpen={vaccineModalOpen}
        setVaccineModalOpen={setVaccineModalOpen}
        vaccineData={vaccineData}
        doctor_appointment_id={viewAppointment?.doctor_appointment_id}
      />

      <PetFoodModal
        petFoodModalOpen={petFoodModalOpen}
        setPetFoodModalOpen={setPetFoodModalOpen}
        petFoods={petFoods}
        viewAppointment={viewAppointment}
      />
      {/* petFoodModalOpen */}

      {/* DownLoad PDF*/}

      <DoctorPrecription
        pdfExportComponent={pdfExportComponent}
        setPrescriptionData={setPrescriptionData}
        viewAppointment={viewAppointment}
        prescriptionData={prescriptionData}
        isPrescription={isPrescription}
        hidden={hidden}
        setHidden={setHidden}
        divToPdf="prescription-modal"
      />
    </div>
  );
};

export default MyAppointmentsComp;

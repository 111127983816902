import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import { Carousel } from "react-responsive-carousel";
import pedigree from "../../assets/images/pedigreeImg.svg";
import CouponBadge from "../../assets/images/redCouponBadge.svg";
import heartBorder from "../../assets/images/heartBorder.svg";
import BlueHeart from "../../assets/images/blueFilledHeart.svg";
import plusIco from "../../assets/images/plusIcon.svg";
import minusIco from "../../assets/images/minusIcon.svg";
import dustbinImg from "../../assets/images/dustbinImg.svg";
import Star from "../../assets/images/Star.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Slider from "react-slick";
import Steps from "../../assets/images/footSteps.svg";
import ic_start from "../../assets/images/ic_start.svg";
import { useReduxStore } from "../../customHooks/useReduxStore";
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";
import { useDateFormats } from "../../customHooks/useDateFormats";
import { increaseCartCount } from "../../redux/Slices/cartSlice";
import ReactImageMagnify from "react-image-magnify";

const PetShopDetails = () => {
  // DATA FROM REDUX
  const { customer_id, cartItemCount } = useReduxStore();
  // DATA FROM REDUX
  const { convertWithDateAndDay } = useDateFormats();
  const { setBkdropLdr, navigate, dispatch } = useContext(AppContext);

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const product_id = parseInt(searchParams.get("product_id"));
  const pet_shop_id = parseInt(searchParams.get("pet_shop_id"));
  const entity_type_id = parseInt(searchParams.get("entity_type_id"));

  const [heartClicked, setHeartClicked] = useState(false);
  const [heartClick, setHeartClick] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    fetchPetShopProductDetails();
  }, []);

  const [ratingPercentage, setRatingPercentage] = useState({
    totalReviews: 0,
    ratingPercentage: [],
  });
  const [productDetails, setProductDetails] = useState({});
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recentViewedProducts, setRecentViewedProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const fetchPetShopProductDetails = async () => {
    const payLoad = {
      product_id: product_id,
      pet_shop_id: pet_shop_id,
      customer_id: customer_id,
      entity_type_id: entity_type_id,
    };

    try {
      setIsLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.PET_SHOP_PRODUCT_DETAILS,
        payLoad
      );
      const {
        result,
        data,
        recommendedProduct,
        recentSearchData,
        ratingPercentage,
        totalReviews,
      } = res?.json;
      if (result && data) {
        setProductDetails(data[0]);
        recommendedProduct && setRecommendedProducts(recommendedProduct);
        recentSearchData && setRecentViewedProducts(recentSearchData);

        if (ratingPercentage.length < 5) {
          while (ratingPercentage.length < 5) {
            ratingPercentage.push(0);
          }
        }
        ratingPercentage &&
          totalReviews &&
          setRatingPercentage({
            totalReviews: totalReviews,
            ratingPercentage: ratingPercentage.reverse(),
          });
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [productQty, setProductQty] = useState(1);

  const addToBagCart = async () => {
    const cartPayLoad = {
      customer_id: customer_id,
      product_id: productDetails?.product_id,
      product_quantity: productQty,
      product_rate: productDetails?.product_rate,
      shop_id: productDetails?.pet_shop_id,
      entity_type_id: productDetails?.entity_type_id,
    };
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        dispatch(increaseCartCount(cartItemCount + 1));
        notifySuccess("Added to cart successfully");
      } else {
        navigate("/cart");
      }
    } catch (error) {
      console.log("catch error:", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  //   ADD_TO_PET_FAVORITE
  const addToFavourite = async (
    product_id,
    entity_type_id,
    actionType,
    listType
  ) => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        customer_id: customer_id,
        product_id: product_id ? product_id : productDetails?.product_id,
        entity_type_id: entity_type_id
          ? entity_type_id
          : productDetails?.entity_type_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_PET_FAVORITE,
        payLoad
      );
      const { result, message } = res?.json;
      if (result) {
        if (actionType === "like") {
          if (listType === "singleProduct") {
            setProductDetails({
              ...productDetails,
              customer_favourite_id: true,
            });
          } else if (listType === "recommend") {
            const tempArr = recommendedProducts.map((item) => {
              return item.product_id == product_id
                ? {
                    ...item,
                    customer_favourite_id: true,
                  }
                : item;
            });
            setRecommendedProducts(tempArr);
          } else {
            const tempArr = recentViewedProducts.map((item) => {
              return item.product_id == product_id
                ? {
                    ...item,
                    customer_favourite_id: true,
                  }
                : item;
            });
            setRecentViewedProducts(tempArr);
          }
        } else if (actionType === "dislike") {
          if (listType === "singleProduct") {
            setProductDetails({
              ...productDetails,
              customer_favourite_id: false,
            });
          } else if (listType === "recommend") {
            const tempArr = recommendedProducts.map((item) => {
              return item.product_id == product_id
                ? {
                    ...item,
                    customer_favourite_id: false,
                  }
                : item;
            });
            setRecommendedProducts(tempArr);
          } else {
            const tempArr = recentViewedProducts.map((item) => {
              return item.product_id == product_id
                ? {
                    ...item,
                    customer_favourite_id: false,
                  }
                : item;
            });
            setRecentViewedProducts(tempArr);
          }
        }
      }
    } catch (error) {
      console.log("catch Error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleCarouselChange = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <>
      <div className="medicinesDetails" id="cx-main">
        <Subheader />
        {/* Product Description */}
        {isLoading ? (
          <RotatingLoader />
        ) : (
          <div className="ProductDescriptionmain">
            <div className="row">
              <div className="col-md-9 colMain9">
                <div className="productDescriptionBox">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="innerCarousel">
                        {/* <img src={CouponBadge} className='CouponBadge' alt='' /> */}
                        <Carousel
                          showArrows={
                            productDetails.product_img &&
                            productDetails.product_img.length > 1
                              ? true
                              : false
                          }
                          infiniteLoop={
                            productDetails.product_img &&
                            productDetails.product_img.length > 1
                              ? true
                              : false
                          }
                          onChange={handleCarouselChange}
                        >
                          {productDetails &&
                            productDetails.product_img &&
                            productDetails.product_img.map((item, idx) => {
                              return (
                                <div>
                                  <img
                                    style={{
                                      borderRadius: "20px",
                                      width: "-webkit-fill-available",
                                      padding: "5px",
                                    }}
                                    src={
                                      item?.product_image_path
                                        ? item?.product_image_path
                                        : ""
                                    }
                                    alt=""
                                  />
                                </div>
                              );
                            })}
                        </Carousel>
                        {productDetails &&
                          productDetails.product_img &&
                          productDetails.product_img[selectedImageIndex] && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                // zIndex: 9999,
                              }}
                            >
                              <ReactImageMagnify
                                smallImage={{
                                  alt: "Small Image Alt",
                                  isFluidWidth: true,
                                  src: productDetails.product_img[
                                    selectedImageIndex
                                  ]?.product_image_path
                                    ? productDetails.product_img[
                                        selectedImageIndex
                                      ].product_image_path
                                    : "",
                                }}
                                largeImage={{
                                  src: productDetails.product_img[
                                    selectedImageIndex
                                  ]?.product_image_path
                                    ? productDetails.product_img[
                                        selectedImageIndex
                                      ].product_image_path
                                    : "",
                                  width: 1200,
                                  height: 1800,
                                }}
                                enlargedImageContainerDimensions={{
                                  width: 800,
                                  height: 600,
                                }}
                                lensStyle={
                                  {
                                    // backgroundColor: "rgba(0,0,0,.6)",
                                  }
                                }
                                isHintEnabled
                                shouldHideHintAfterFirstActivation={false}
                                enlargedImageContainerClassName={"custom-image-magnify"}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="contentBox">
                        <div className="flexBox">
                          <div className="heading">
                            {productDetails?.product && (
                              <h1 className="headinTxt">
                                {productDetails?.product}
                              </h1>
                            )}
                            {productDetails?.product_type && (
                              <label className="productLabl">
                                {productDetails?.product_type}
                              </label>
                            )}
                          </div>
                          <div className="actionIcon">
                            <img
                              onClick={() => {
                                // setHeartClicked(!heartClicked)
                                if (
                                  productDetails?.customer_favourite_id &&
                                  productDetails?.customer_favourite_id
                                ) {
                                  addToFavourite(
                                    null,
                                    null,
                                    "dislike",
                                    "singleProduct"
                                  );
                                } else {
                                  addToFavourite(
                                    null,
                                    null,
                                    "like",
                                    "singleProduct"
                                  );
                                }
                              }}
                              src={
                                productDetails?.customer_favourite_id &&
                                productDetails?.customer_favourite_id
                                  ? BlueHeart
                                  : heartBorder
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="paraBox">
                          {productDetails?.product_desc && (
                            <div className="insidePara">
                              <p className="detailPara">
                                {productDetails?.product_desc}
                              </p>
                            </div>
                          )}
                        </div>

                        {/* <div className='useBox'>
                          <h1 className='useTxt'>Direction for use</h1>
                          <div className='belowTxtFlex'>
                            <label htmlFor='' className='key'>
                              Adult Dog :
                            </label>
                            <label htmlFor='' className='value'>
                              {' '}
                              Take 2 tablespoons once in glass full of water.
                            </label>
                          </div>
                          <div className='belowTxtFlex'>
                            <label htmlFor='' className='key'>
                              Puppy :
                            </label>
                            <label htmlFor='' className='value'>
                              {' '}
                              Take 2 tablespoons once in glass full of water.
                            </label>
                          </div>
                        </div>
                        <div className='storageBox'>
                          <h1 className='headTxt'>Storage</h1>
                          <ul className='storeList'>
                            <li>
                              Store this syrup at room temperature protected
                              from sunlight, heat, and moisture.
                            </li>
                            <li> Keep away from reaching out of childrens. </li>
                            <li>
                              Ensure that the unused medicine is disposed of
                              properly
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 colMain3">
                <div className="addToBagBox">
                  <div className="amountDetails">
                    <label className="accAmnt">
                      ₹{parseFloat(productDetails?.product_rate).toFixed(2)}
                    </label>
                    {/* <label className='discountAmnt'>₹50.00</label> */}
                  </div>
                  <div className="availablityStatus">
                    {/* pet_shop_product_quantity */}
                    {productDetails?.pet_shop_product_quantity !== "0" &&
                    productDetails?.pet_shop_product_quantity ? (
                      <label htmlFor="">In Stock</label>
                    ) : (
                      <span style={{ color: "red" }}>Out of stock</span>
                    )}
                  </div>
                  {productDetails?.pet_shop_product_quantity !== "0" && (
                    <>
                      <div className="quantityMeter">
                        <label htmlFor="">
                          <img
                            src={minusIco}
                            alt=""
                            onClick={() => {
                              productQty > 1 && setProductQty(productQty - 1);
                            }}
                          />
                        </label>
                        <label htmlFor="">{productQty}</label>
                        <label htmlFor="">
                          <img
                            src={plusIco}
                            alt=""
                            onClick={() => {
                              if (
                                parseInt(
                                  productDetails?.pet_shop_product_quantity
                                ) >=
                                productQty + 1
                              ) {
                                setProductQty(productQty + 1);
                              } else {
                                notifyError(
                                  "Quantity exceeds available stock!"
                                );
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="addTObag">
                        <button
                          className="bagBtn"
                          onClick={() => {
                            if (
                              productDetails?.customer_card_id &&
                              productDetails?.customer_card_id
                            ) {
                              navigate("/cart");
                              return;
                            }
                            addToBagCart();
                          }}
                        >
                          <img src={dustbinImg} alt="" /> Add to Bag
                        </button>
                      </div>
                    </>
                  )}

                  {/* <div className='deliveryStatus'>
                    <div className='heaqdingBox'>
                      <h1>Delivery</h1>
                    </div>
                    <div className='statusInfoBox'>
                      <h1 className='delieryTxt'>
                        Delivery by 5 Aug, Saturday
                      </h1>
                      <h1 className='couponDetail'>
                        Free <span>₹50</span>{' '}
                      </h1>
                      <label htmlFor=''>If Ordered Today</label>
                    </div>
                  </div> */}

                  {/* <div className="sellerBox">
                    <div className="heaqdingBox">
                      <h1>Seller</h1>
                    </div>
                    <div className="sellerCont">
                      {productDetails?.pet_shop_name && (
                        <h1 className="headTxt">
                          {productDetails?.pet_shop_name}
                        </h1>
                      )}
                      {productDetails?.avg_rating && (
                        <h1 className="ratingTxt">
                          <img src={Star} className="Star" alt="" />{" "}
                          {productDetails?.avg_rating}
                        </h1>
                      )}
                    </div>
                    <p className='paraTxt'>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Dolores tempora est culpa
                    </p>
                    <Link
                      to={`/PetAllSellers?product_id=${product_id}&pet_shop_id=${pet_shop_id}&entity_type_id=${entity_type_id}`}
                      className="seeMoreLink"
                    >
                      See Other Sellers
                    </Link>
                  </div> */}
                </div>
              </div>
              {productDetails?.reviews &&
                productDetails?.reviews.length > 0 && (
                  <div className="col-md-12">
                    <div className="reviewsBox">
                      <div className="row">
                        <div className="col-md-3 rateCol3">
                          <div className="ratingBarBox">
                            <div className="headingBox">
                              <h1>Customer Reviews</h1>
                            </div>
                            <div className="starFlex">
                              <div className="starsFlex">
                                <img
                                  src={
                                    Number(productDetails?.avg_rating) > 1
                                      ? Star
                                      : ic_start
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(productDetails?.avg_rating) > 2
                                      ? Star
                                      : ic_start
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(productDetails?.avg_rating) > 3
                                      ? Star
                                      : ic_start
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(productDetails?.avg_rating) > 4
                                      ? Star
                                      : ic_start
                                  }
                                  alt=""
                                />
                                <img
                                  src={
                                    Number(productDetails?.avg_rating) == 5
                                      ? Star
                                      : ic_start
                                  }
                                  alt=""
                                />
                              </div>
                              <h1 className="starText">
                                {productDetails?.avg_rating} Out Of 5
                              </h1>
                            </div>
                            <div className="ratingBar">
                              {ratingPercentage?.totalReviews ? (
                                <h1 className="ratingTxt">
                                  {ratingPercentage?.totalReviews} Global Rating
                                </h1>
                              ) : null}
                              {ratingPercentage?.ratingPercentage &&
                              ratingPercentage?.ratingPercentage.length > 0
                                ? ratingPercentage?.ratingPercentage.map(
                                    (rating, i) => {
                                      return (
                                        <div className="barsFlex">
                                          <label
                                            htmlFor=""
                                            className="laberate"
                                          >
                                            {5 - i} Star
                                          </label>
                                          <ProgressBar now={rating} />
                                          <label
                                            htmlFor=""
                                            className="labelPercent"
                                          >
                                            {rating}%
                                          </label>
                                        </div>
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                        {/* productDetails */}
                        {productDetails?.reviews &&
                          productDetails?.reviews.length > 0 && (
                            <div className="col-md-9 rateCol9">
                              <div className="reviewBoxInner">
                                <div className="headingBox">
                                  <h1>Top Customer Reviews</h1>
                                </div>
                                {productDetails?.reviews.map((item, idx) => {
                                  return (
                                    <div className="contentReviewBoxInside">
                                      <div
                                        className="flexviewTwocards"
                                        key={"reviews" + idx}
                                      >
                                        {/* <div className='imgHolder'>
                                        <img
                                          src={pedigree}
                                          className='imgInside'
                                          alt=''
                                        />
                                      </div> */}
                                        <div className="contentHolder">
                                          <div className="headingFlex">
                                            <h1 className="headTxt">
                                              {item?.review_title}
                                            </h1>
                                            <h1 className="rateStar">
                                              <img
                                                src={Star}
                                                className="starMargin"
                                                alt=""
                                              />{" "}
                                              {item?.rating}
                                            </h1>
                                          </div>
                                          <div className="nameAndDomain">
                                            <h1 className="daomainNdate">
                                              {`${item?.first_name} ${item?.last_name}`}
                                              ,{" "}
                                              {convertWithDateAndDay(
                                                item?.date
                                              )}
                                              {/* 16 July 2023 */}
                                            </h1>
                                            <p className="domainPara">
                                              {item?.review}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                {/* <div className='contentReviewBoxInside'>
                                <div className='flexviewTwocards'>
                                  <div className='imgHolder'>
                                    <img
                                      src={pedigree}
                                      className='imgInside'
                                      alt=''
                                    />
                                  </div>
                                  <div className='contentHolder'>
                                    <div className='headingFlex'>
                                      <h1 className='headTxt'>Fine Product</h1>
                                      <h1 className='rateStar'>
                                        <img
                                          src={Star}
                                          className='starMargin'
                                          alt=''
                                        />{' '}
                                        4.3
                                      </h1>
                                    </div>
                                    <div className='nameAndDomain'>
                                      <h1 className='daomainNdate'>
                                        Deepak Singh Jadon, 16 July 2023
                                      </h1>
                                      <p className='domainPara'>
                                        Lorem, ipsum dolor sit amet consectetur
                                        adipisicing elit. Officiis culpa similique
                                        ratione aspernatur nesciunt eveniet hic
                                        corporis, voluptatem consequatur pariatur
                                        eius? In, fugit sunt? Optio accusantium
                                        maxime cupiditate modi alias eligendi
                                        velit voluptatum repellat recusandae
                                        error, porro esse soluta officia.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div> */}

                                {/* <div className='showMOreBox'>
                                <button className='showMoreBtn'>
                                  Show All Reviews (100)
                                </button>
                              </div> */}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}

              {recommendedProducts && recommendedProducts.length > 0 ? (
                <div className="col-md-12">
                  <div className="CardsCarousel">
                    <div className="carouselHeading">
                      <h1 className="headTxt">Recommended Products</h1>
                      <img src={Steps} className="Steps" alt="" />
                    </div>
                    <div className="carosuelInside">
                      <Slider
                        slidesToScroll={4}
                        speed={500}
                        infinite={true}
                        slidesToShow={
                          recommendedProducts.length < 4
                            ? recommendedProducts.length
                            : 4
                        }
                        initialSlide={recommendedProducts.length}
                      >
                        {recommendedProducts &&
                          recommendedProducts.map((item, i) => {
                            const imagePath =
                              item?.product_image &&
                              item?.product_image[0]?.product_image_path
                                ? item?.product_image[0]?.product_image_path
                                : null;
                            return (
                              <div className="medicineCardsCarosel ">
                                <div className="innerContent">
                                  <img
                                    style={{ borderRadius: "10px" }}
                                    src={imagePath ? imagePath : pedigree}
                                    className="pedigree"
                                    alt="not found"
                                  />
                                  <img
                                    onClick={() => {
                                      if (
                                        !item?.customer_favourite_id &&
                                        !item?.customer_favourite_id
                                      ) {
                                        addToFavourite(
                                          item?.product_id,
                                          item?.entity_type_id,
                                          "like",
                                          "recommend"
                                        );
                                      } else {
                                        addToFavourite(
                                          item?.product_id,
                                          item?.entity_type_id,
                                          "dislike",
                                          "recommend"
                                        );
                                      }
                                    }}
                                    src={
                                      item?.customer_favourite_id
                                        ? BlueHeart
                                        : heartBorder
                                    }
                                    className="heartBor"
                                    alt=""
                                  />
                                  {/* <img
                                    src={CouponBadge}
                                    className='couponBadge'
                                    alt=''
                                  /> */}
                                </div>
                                <div className="detailsSec">
                                  {item?.product && (
                                    <Link
                                      target="_blank"
                                      to={`/PetShopDetails?product_id=${item?.product_id}&pet_shop_id=${item?.pet_shop_id}&entity_type_id=${item?.entity_type_id}`}
                                    >
                                      <label htmlFor="" className="headName">
                                        {item?.product}
                                      </label>
                                    </Link>
                                  )}
                                  {item?.avg_rating && (
                                    <label htmlFor="" className="flexStar">
                                      <img
                                        src={Star}
                                        className="starImg"
                                        alt=""
                                      />{" "}
                                      {item?.avg_rating}
                                    </label>
                                  )}
                                </div>
                                <div className="rateBox">
                                  {item?.product_rate && (
                                    <label className="amntActive">
                                      ₹
                                      {parseFloat(item?.product_rate).toFixed(
                                        2
                                      )}
                                    </label>
                                  )}
                                  {/* <label htmlFor='' className='mutedTxt'>
                                    ₹50.00
                                  </label> */}
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : null}
              {recentViewedProducts && recentViewedProducts.length > 0 ? (
                <div className="col-md-12">
                  <div className="CardsCarousel noMargin">
                    <div className="carouselHeading">
                      <h1 className="headTxt">Recently Viewed</h1>
                      <img src={Steps} className="Steps2" alt="" />
                    </div>
                    <div className="carosuelInside">
                      <Slider
                        slidesToScroll={4}
                        speed={700}
                        infinite={true}
                        slidesToShow={
                          recentViewedProducts.length < 4
                            ? recentViewedProducts.length
                            : 4
                        }
                        initialSlide={recentViewedProducts.length}
                      >
                        {recentViewedProducts.map((item, i) => {
                          const imagePath =
                            item.product_img &&
                            item.product_img[0]?.product_image_path
                              ? item.product_img[0]?.product_image_path
                              : null;
                          return (
                            <div className="medicineCardsCarosel">
                              <div className="innerContent">
                                <img
                                  style={{ borderRadius: "10px" }}
                                  src={imagePath ? imagePath : pedigree}
                                  className="pedigree"
                                  alt="Product im"
                                />
                                <img
                                  onClick={() => {
                                    if (
                                      !item?.customer_favourite_id &&
                                      !item?.customer_favourite_id
                                    ) {
                                      addToFavourite(
                                        item?.product_id,
                                        item?.entity_type_id,
                                        "like",
                                        "recentViewed"
                                      );
                                    } else {
                                      addToFavourite(
                                        item?.product_id,
                                        item?.entity_type_id,
                                        "dislike",
                                        "recentViewed"
                                      );
                                    }
                                  }}
                                  src={
                                    item?.customer_favourite_id &&
                                    item?.customer_favourite_id
                                      ? BlueHeart
                                      : heartBorder
                                  }
                                  className="heartBor"
                                  alt=""
                                />
                                {/* <img
                                  src={CouponBadge}
                                  className='couponBadge'
                                  alt=''
                                /> */}
                              </div>
                              <div className="detailsSec">
                                {item?.product && (
                                  <Link
                                    target="_blank"
                                    to={`/PetShopDetails?product_id=${item?.product_id}&pet_shop_id=${item?.pet_shop_id}&entity_type_id=${item?.entity_type_id}`}
                                  >
                                    <label htmlFor="" className="headName">
                                      {item?.product}
                                    </label>
                                  </Link>
                                )}
                                {item?.avg_rating && (
                                  <label htmlFor="" className="flexStar">
                                    <img
                                      src={Star}
                                      className="starImg"
                                      alt=""
                                    />{" "}
                                    {item?.avg_rating}
                                  </label>
                                )}
                              </div>
                              <div className="rateBox">
                                <label htmlFor="" className="amntActive">
                                  ₹{parseFloat(item?.product_rate).toFixed(2)}
                                </label>
                                {/* <label htmlFor='' className='mutedTxt'>
                                  ₹50.00
                                </label> */}
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PetShopDetails;

import { createTransform } from 'redux-persist'

const stateTransform = createTransform(
  // Transform state before persisting
  (inboundState, key) => {
    console.log('inboundState,', inboundState)
    console.log('key,', key)
    return {
      loginData: inboundState.loginData,
      isLoggedIn: inboundState.isLoggedIn
    }
  },
  // Transform state before rehydration (optional)
  (outboundState, key) => {
    console.log('outboundState,', outboundState)
    // You can perform transformations here if needed
    return outboundState
  }
)

export default stateTransform

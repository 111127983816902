import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../sharedComponent/Header";
import Subheader from "../../sharedComponent/Subheader";
import Client from "../../assets/images/clientImg.svg";
import liteImgArow from "../../assets/images/lightIcon.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../assets/images/calendar.svg";
import ActiveCgeck from "../../assets/images/greenCheckRight.svg";
import CLock from "../../assets/images/Clock.svg";
import ActCLock from "../../assets/images/whiteCLock.svg";
import Clinic from "../../assets/images/clininc.svg";
import ClinicAct from "../../assets/images/clinicActive.svg";
import VideoCam from "../../assets/images/videoCam.svg";
import VideoCamAct from "../../assets/images/videoCamActive.svg";
import Puppys from "../../assets/images/puppys.svg";
import ThumbsUp from "../../assets/images/thumbsUp.svg";
import successStatus from "../../assets/images/greenTick.svg";
import pendingStatus from "../../assets/images/pendingStatus.svg";
import failedStatus from "../../assets/images/failedStatus.svg";
import GreenCross from "../../assets/images/greenCross.svg";
import CLockDis from "../../assets/images/clockDisable.svg";
import GalleryImg from "../../assets/images/gallery.svg";
import Form from "react-bootstrap/Form";
import DoctorSahab from "../../assets/images/DoctorImg.svg";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import {
  PostCallWithAuthToken,
  postCallWithFormDataWithToken,
  postMultipartWithAuthCall,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useDateFormats } from "../../customHooks/useDateFormats";
import { AppContext } from "../../context/AppContext";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import moment from "moment";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { isTimeValid } from "../../constants/commonFunctons";
import { checkoutHandler } from "../../paymentCheckout/checkout";

const VaccinatorsAppointment = () => {
  // const doctorId = parseInt(params?.id);
  // DATA FROM CONTEXT
  const { setBkdropLdr, navigate, setEventKey } = useContext(AppContext);
  // DATA FROM CONTEXT

  const { formatDateToDdMmYyyy } = useDateFormats();
  const { customer_id, customer_user_id, loginData } = useReduxStore();

  // All Four Stepper States
  const [isSlot, setIsSlot] = useState(true);
  const [isPatientDet, setIsPatientDet] = useState(false);
  const [isConsultationType, setIsConsultationType] = useState(false);
  const [isPaymentForm, setIsPaymentForm] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isACtive, setIsACtive] = useState(true);
  const [isACtiveEve, setIsACtiveEve] = useState(false);
  const [availableSlot, setAvailableSlot] = useState({
    morningAvailableSlot: [],
    eveningAvailableSlot: [],
  });

  const [animals, setAnimals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const bookingData =
    sessionStorage.getItem("bookingData") &&
    JSON.parse(sessionStorage.getItem("bookingData"));

  const { doctor_appointment_id, vaccineData } = bookingData ? bookingData : {};
  const { vaccines } = vaccineData ? vaccineData : {};
  const {
    charges,
    vaccinator_id,
    vaccinator_name,
    vaccinator_profile_image_path,
  } =
    sessionStorage.getItem("vaccinatorCharges") &&
    JSON.parse(sessionStorage.getItem("vaccinatorCharges"));

  const vaccinesIDs =
    vaccines && vaccines.length > 0
      ? vaccines.map((item) => item.vaccine_id)
      : [];
  // Calculate total vaccine charges
  const totalVaccineCharges =
    vaccines &&
    vaccines.reduce((total, item) => {
      const dose = parseFloat(item.dose);
      const price = parseFloat(item.price);

      total += price;

      return total;
    }, 0);

  const [bookAppPayLoad, setBookAppPayLoad] = useState({
    customer_id: customer_id,
    vaccinator_id: vaccinator_id ? vaccinator_id : "",
    date: new Date(),
    time: "",
    customer_animal_id: "",
    charges: charges ? charges : "",
    disease: "",
    appointment_desc: "",
    vaccine_id: vaccinesIDs,
    image_path: [],
    doctor_appointment_id: doctor_appointment_id ? doctor_appointment_id : "",
  });

  const totalVacconatorBookingAmt = !isNaN(
    bookAppPayLoad?.charges * vaccines?.length + totalVaccineCharges
  )
    ? bookAppPayLoad?.charges * vaccines?.length + totalVaccineCharges
    : "";
  // "{
  //   customer_id:71
  //   date:2023-11-24
  //   time:22:00
  //   vaccinator_id:6
  //   customer_animal_id:92
  //   charges:400
  //   disease:asdffds
  //   appointment_desc:asdfsdfsdsd
  //   vaccine_id:[1,2]
  //   doctor_appointment_id:270,
  //   image_path : image.jpg
  //   }"
  useEffect(() => {
    getAnimalInformation();
  }, []);

  const getAnimalInformation = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_ANIMAL_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      const tempArr =
        data &&
        data.length &&
        data.map((item) => {
          return {
            animal_id: item?.customer_animal_id,
            name: item?.name,
          };
        });
      if (success === true) {
        Array.isArray(data) && setAnimals(tempArr);
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  const convertInFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        // if (object[key][0].name) {
        //   object[key].forEach((item, index) => {
        //     formData.append(`${key}`, item);
        //   });
        // } 
        // else {
          formData.append(`${key}`, JSON.stringify(object[key]));
        // }
      } else {
        formData.append(key, object[key]);
      }
    });
    return formData;
  };
  const [validated, setValidated] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const afterPaymentSuccess = () => {
    setIsFormFilled(true);
  };
  // bookDoctorAppointment
  const bookAppointMent = async () => {
    if (
      bookAppPayLoad.appointment_desc === "" ||
      bookAppPayLoad.customer_animal_id === "" 
      // bookAppPayLoad.image_path.length === 0 ||
      // bookAppPayLoad.disease === ""
    ) {
      notifyError("Please fill all required fields");
      setIsPatientDet(true);
      setIsPaymentForm(false);
    } else {
      try {
        const formData = convertInFormData(bookAppPayLoad);
        setBkdropLdr(true);
        const res = await postCallWithFormDataWithToken(
          ApiConfig.BOOK_VACCINATOR_APPOINTMENT,
          formData
        );
        const { success, message, orderDetails } = res?.json;
        if (success) {
          if (totalVacconatorBookingAmt) {
            checkoutHandler(
              totalVacconatorBookingAmt,
              orderDetails,
              "vac",
              afterPaymentSuccess
            );
          } else {
            setIsFormFilled(true);
          }
        } else if (success === false) {
          notifyError(message);
        }
      } catch (error) {
        console.log("catch Error res,", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <>
      <div className="bookApppointment" id="cx-main">
        <Subheader />

        {/* Main Content */}
        {isLoading ? (
          <RotatingLoader />
        ) : (
          <div className="row">
            {isFormFilled ? (
              <>
                <div className="col-md-12 col12Media">
                  <div className="boxModelInside">
                    <div className="appointmentStatusSlip">
                      <div className="headnigStatus">
                        <img src={ThumbsUp} className="ThumbsUp" alt="" />
                        <h1 className="wellcomeTxt">
                          Your Appointment has been booked Successfully
                        </h1>
                      </div>
                      <div className="statusContentSlip">
                        <div className="headingSumarry">
                          <div className="imageNname">
                            <img
                              src={
                                vaccinator_profile_image_path
                                  ? vaccinator_profile_image_path
                                  : ""
                              }
                              onError={(e) => {
                                e.target.src = dummydoctor;
                              }}
                              className="DoctorSahab"
                              alt=""
                            />
                            <div className="nameNdomain">
                              {vaccinator_name && <h1>{vaccinator_name}</h1>}
                            </div>
                          </div>
                          <div className="statusSignal">
                            <label className="greenStatus">
                              <img src={successStatus} alt="" /> Paid
                            </label>
                            {/* <label className='greenStatus'>
                              <img src={pendingStatus} alt='' /> Pending
                            </label>
                            <label className='greenStatus'>
                              <img src={failedStatus} alt='' /> Failed
                            </label> */}
                          </div>
                        </div>
                        <div className="slipDetailsFlex">
                          <div className="keyNvalues">
                            <label htmlFor="" className="key">
                              Booking Date:{" "}
                            </label>
                            <label htmlFor="" className="value">
                              {startDate.toDateString()}
                              {/* Monday, 02 July, 2023 */}
                            </label>
                          </div>
                          <div className="keyNvalues">
                            <label htmlFor="" className="key">
                              Booking Time:{" "}
                            </label>
                            {bookAppPayLoad?.time && (
                              <label htmlFor="" className="value">
                                {moment(bookAppPayLoad?.time, ["HH:mm"]).format(
                                  "hh:mm A"
                                )}
                              </label>
                            )}
                          </div>

                          {/* <div className='keyNvalues'>
                            <label htmlFor='' className='key'>
                              Payment Method :{' '}
                            </label>
                            <label htmlFor='' className='value'>
                              Online
                            </label>
                          </div> */}
                          {/* <div className='keyNvalues'>
                              <label htmlFor='' className='key'>
                                Clinic Address:{' '}
                              </label>
                              <Link to='#' className='LinkInside'>
                                {slotDetails?.doctor_clinic_address}
                              </Link>
                            </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className='belowBtns'>
                      <Link to='/Home'>
                        <button type='button' className='homeBtn'>
                          Home
                        </button>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-7 outerCol7">
                  <Form
                    noValidate
                    validated={validated}
                    // onSubmit={bookAppointMent}
                  >
                    {/* Slot Details 1*/}
                    {isSlot ? (
                      <>
                        <div className="selectedSlots">
                          <div className="slotsHeading">
                            <h1 className="slotsTxt">Select Available Slots</h1>
                          </div>
                          <div className="slotContent">
                            <div className="row">
                              <div className="col-md-12 dateCol12">
                                <div className="chooseDateSec">
                                  <label className="dateLabel">
                                    Choose Date
                                  </label>
                                  <div className="datePicker">
                                    <img
                                      src={Calendar}
                                      className="innerDateImg"
                                      alt=""
                                    />
                                    <DatePicker
                                      selected={startDate}
                                      placeholderText="Select the date for appointment"
                                      value={startDate}
                                      onChange={(date) => {
                                        // getAvailableSlots(date)
                                        setBookAppPayLoad({
                                          ...bookAppPayLoad,
                                          date: date,
                                          // date: formatDateToDdMmYyyy(date)
                                        });
                                        setStartDate(date);
                                      }}
                                      minDate={new Date()}
                                      showYearDropdown
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="slotCards">
                                <label className="slotSelect">
                                  Select Time
                                </label>
                                <div className="row">
                                  <input
                                    id="timePicker"
                                    type="time"
                                    // min={currentTime}
                                    value={
                                      bookAppPayLoad?.time
                                        ? bookAppPayLoad?.time
                                        : ""
                                    }
                                    onChange={(e) => {
                                      const selectedTime = e.target.value;
                                      if (
                                        isTimeValid(currentTime, selectedTime)
                                      ) {
                                        setBookAppPayLoad({
                                          ...bookAppPayLoad,
                                          time: e.target.value,
                                        });
                                      }
                                    }}
                                    // style={{
                                    //   width: '120px',
                                    //   //   backgroundColor: '#fff',
                                    //   //   color: '#1d7c7c',
                                    //   border: 'none',
                                    //   borderBottom: '2px solid #1d7c7c',
                                    //   marginLeft: '12px',
                                    //   outline: 'none'
                                    // }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="belowBtns">
                          {/* <Link
                                    // to='/DoctorDetails'
                                    to='#'
                                    className='backLink'
                                    > */}
                          {/* <button className='backBtn'>Back</button> */}
                          {/* </Link> */}
                          {/* <Link
                                    to='#'
                                    className='nextLink'
                                    onClick={() => {
                                        setIsPatientDet(true)
                                        setIsSlot(false)
                                    }}
                                    > */}
                          <button
                            type="button"
                            className="nextBtn"
                            onClick={() => {
                              setIsPatientDet(true);
                              setIsSlot(false);
                            }}
                          >
                            Next
                            <img src={liteImgArow} alt="" />{" "}
                          </button>
                          {/* </Link> */}
                        </div>
                      </>
                    ) : null}

                    {/* Patient Details 2*/}
                    {isPatientDet ? (
                      <>
                        <div className="patientDetails">
                          <div className="patientHeading">
                            <h1 className="patientTxt">Patient Details </h1>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="patientContent">
                                <div className="SelectBox">
                                  <label className="selectLabel">
                                    Appointment for
                                  </label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      setBookAppPayLoad({
                                        ...bookAppPayLoad,
                                        customer_animal_id: Number(
                                          e.target.value
                                        ),
                                      });
                                    }}
                                    value={
                                      bookAppPayLoad?.customer_animal_id
                                        ? bookAppPayLoad?.customer_animal_id
                                        : ""
                                    }
                                    required
                                  >
                                    <option value={""}>Select your animal</option>
                                    {animals &&
                                      animals.map((item, idx) => {
                                        return (
                                          <option
                                            key={item?.animal_id}
                                            value={item?.animal_id}
                                          >
                                            {item?.name}
                                          </option>
                                        );
                                      })}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please select animal type
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="reasonBox">
                                <label className="reasonLabel">Reason / Symptoms</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your reason or symptoms here..."
                                  onChange={(e) => {
                                    setBookAppPayLoad({
                                      ...bookAppPayLoad,
                                      appointment_desc: e.target.value,
                                    });
                                  }}
                                  value={bookAppPayLoad?.appointment_desc}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter reason or symptoms
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="attachmentPicBox">
                                <label className="attachmentPic">
                                  Attachment (Photo)
                                </label>
                                <label
                                  htmlFor="imgHolder"
                                  className="imgHolderLab"
                                >
                                  Browse...
                                  <img src={GalleryImg} alt="" />
                                  <input
                                    type="file"
                                    className="d-none"
                                    id="imgHolder"
                                    onChange={(e) => {
                                      setBookAppPayLoad({
                                        ...bookAppPayLoad,
                                        image_path: [
                                          ...bookAppPayLoad?.image_path,
                                          e.target.files[0],
                                        ],
                                      });
                                      setErrMsg("");
                                    }}
                                  />
                                </label>
                              </div>
                              {errMsg && <div>{errMsg}</div>}
                            </div> */}
                            {/* <div className="col-md-6">
                              <div className="symptomnsVox">
                                <label className="attachmentPic">
                                  Symptoms
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter symptoms"
                                  onChange={(e) => {
                                    setBookAppPayLoad({
                                      ...bookAppPayLoad,
                                      disease: e.target.value,
                                    });
                                  }}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter symptoms
                                </Form.Control.Feedback>
                              </div>
                            </div> */}
                            <div className="col-md-12">
                              <div className="imageGallery">
                                {bookAppPayLoad?.image_path.length > 0 &&
                                  bookAppPayLoad?.image_path.map((item, i) => {
                                    return (
                                      <div
                                        className="imgGrids"
                                        key={"bookAppPayLoad" + i}
                                      >
                                        <img
                                          src={GreenCross}
                                          className="checkCross"
                                          alt=""
                                          onClick={() => {
                                            const filterData =
                                              bookAppPayLoad?.image_path.filter(
                                                (ele) => ele !== item
                                              );
                                            setBookAppPayLoad({
                                              ...bookAppPayLoad,
                                              image_path: filterData,
                                            });
                                          }}
                                        />
                                        <img
                                          src={
                                            item
                                              ? item &&
                                                URL.createObjectURL(item)
                                              : Puppys
                                          }
                                          className="dogImg"
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          {/* <Link
                          to=''
                          className='backLink'
                          onClick={() => {
                            setIsSlot(true)
                            setIsPatientDet(false)
                          }}
                        > */}
                          <button
                            type="button"
                            className="backBtn"
                            onClick={() => {
                              setIsSlot(true);
                              setIsPatientDet(false);
                            }}
                          >
                            Back
                          </button>
                          {/* </Link> */}
                          {/* <Link
                          to=''
                          className='nextLink'
                          onClick={() => {
                            setIsConsultationType(true)
                            setIsPatientDet(false)
                          }}
                        > */}
                          <button
                            type="button"
                            // className='nextBtn'
                            className="payBtn"
                            onClick={() => {
                              bookAppointMent();
                            }}
                          >
                            Book Appointment <img src={liteImgArow} alt="" />{" "}
                          </button>
                          {/* </Link> */}
                        </div>
                      </>
                    ) : null}

                    {/* Payment Methods (1)  4*/}
                    {isPaymentForm ? (
                      <>
                        <div className="paymentOptions">
                          <div className="paymentHeadin">
                            <h1 className="headingTxt">Payment Methods</h1>
                          </div>
                          <div className="optionList">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault41"
                                id="flexRadioDefault41"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault41"
                              >
                                Debit or Credit Card
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="nameDetails">
                                <label className="NameLabel">
                                  Name on the card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter the name"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="nameDetails">
                                <label className="NameLabel">Card Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="xxxx - xxxx - xxxx - xxxx"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="nameDetails">
                                <label className="NameLabel">
                                  Expiry Month
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="MM"
                                  value={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="nameDetails">
                                <label className="NameLabel">Expiry Year</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="YYYY"
                                  value={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="nameDetails">
                                <label className="NameLabel">CVV</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="***"
                                  value={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="optionList">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault42"
                                    id="flexRadioDefault22"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault22"
                                  >
                                    Net Banking
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault42"
                                    id="flexRadioDefault23"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault23"
                                  >
                                    UPI
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          {/* <Link
                          to='#'
                          className='backLink'
                          onClick={() => {
                            setIsConsultationType(true)
                            setIsPatientDet(false)
                            setIsPaymentForm(false)
                            setIsPaymentMethod(false)
                          }}
                        > */}
                          <button
                            type="button"
                            onClick={() => {
                              setIsConsultationType(true);
                              setIsPatientDet(true);
                              setIsPaymentForm(false);
                              setIsPaymentMethod(false);
                            }}
                            className="backBtn"
                          >
                            Back
                          </button>
                          {/* </Link> */}
                          <button
                            type="submit"
                            className="payBtn"
                            onClick={() => {
                              // setIsPaymentMethod(true)
                              // setIsPaymentForm(false)
                              //   setIsConsultationType(false)
                            }}
                          >
                            Book Appointment
                            {/* Proceed to Pay  */}
                            <img src={liteImgArow} alt="" />
                          </button>
                          {/* <Link
                            to='#'
                            className='payLink'
                            onClick={() => {
                              setIsPaymentMethod(true)
                              setIsPaymentForm(false)
                              setIsConsultationType(false)
                            }}
                          >
                            
                          </Link> */}
                        </div>
                      </>
                    ) : null}

                    {/* Payment Details (2) 5*/}
                    {isPaymentMethod ? (
                      <>
                        <div className="paymentOptions">
                          <div className="paymentHeadin">
                            <h1 className="headingTxt">Payment Methods</h1>
                          </div>
                          <div className="optionList">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Debit or Credit Card
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Net Banking
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault3"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault3"
                              >
                                UPI
                              </label>
                            </div>
                            <div className="upiServices">
                              <div className="formsSection">
                                <label className="labelName">UPI ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your UPI ID"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          {/* <Link
                          to='#'
                          className='backLink'
                          onClick={() => {
                            setIsPaymentForm(true)
                            setIsConsultationType(false)
                            setIsPatientDet(false)
                            setIsPaymentMethod(false)
                          }}
                        > */}
                          <button
                            type="button"
                            className="backBtn"
                            onClick={() => {
                              setIsPaymentForm(true);
                              setIsConsultationType(false);
                              setIsPatientDet(false);
                              setIsPaymentMethod(false);
                            }}
                          >
                            Back
                          </button>
                          {/* </Link> */}
                          <Link
                            to="#"
                            className="payLink"
                            onClick={() => setIsFormFilled(true)}
                          >
                            <button type="button" className="payBtn">
                              Proceed to Pay <img src={liteImgArow} alt="" />
                            </button>
                          </Link>
                        </div>
                      </>
                    ) : null}
                  </Form>
                </div>
                <div className="col-md-5 outerCol5">
                  <div className="bookingSummary">
                    <div className="bookingHeadin">
                      <h1 className="headingTxt">Booking Summary</h1>
                    </div>
                    <div className="cardCOntent">
                      <div className="clientDetail">
                        <div className="clientImgHolder">
                          <img
                            src={
                              vaccinator_profile_image_path
                                ? vaccinator_profile_image_path
                                : dummydoctor
                            }
                            onError={(e) => {
                              e.target.src = dummydoctor;
                            }}
                            // src={Client}
                            className="clientImg"
                            alt=""
                          />
                        </div>
                        <div className="clientDetHolder">
                          <h1 className="clientName">{vaccinator_name}</h1>
                          {/* {slotDetails?.doctor_name && (
                            <h1 className='clientName'>
                              {' '}
                              {`${
                                slotDetails?.doctor_name &&
                                slotDetails?.doctor_name
                                  ?.toLowerCase()
                                  .includes('dr')
                                  ? ''
                                  : 'Dr.'
                              } ${slotDetails?.doctor_name}`}
                            </h1>
                          )} */}
                          {/* <label className='ClientDomain'>
                            Orthopedic | 5 years
                          </label> */}
                        </div>
                      </div>
                      <div className="bookingDetails">
                        <div className="headFlex">
                          <label className="bdTxt">Booking Details</label>
                          {/* <Link to='#' className='EditlInk'> */}
                          <span
                            className="EditlInk"
                            onClick={() => {
                              setIsSlot(true);
                              setIsPatientDet(false);
                              setIsPaymentForm(false);
                              setIsPaymentMethod(false);
                              setIsConsultationType(false);
                            }}
                          >
                            Edit
                          </span>
                          {/* </Link> */}
                        </div>
                        <div className="belowFlex">
                          <label className="BookTime">
                            Booking Date <span> : </span>
                          </label>
                          <label className="BookDay">
                            {startDate.toDateString()}
                            {/* Monday, 02 July, 2023 */}
                          </label>
                        </div>
                        <div className="belowFlex">
                          <label className="BookTime">
                            Booking Time <span> : </span>
                          </label>
                          {bookAppPayLoad?.time && (
                            <label className="BookDay">
                              {bookAppPayLoad?.shift}{" "}
                              {moment(bookAppPayLoad?.time, ["HH:mm"]).format(
                                "hh:mm A"
                              )}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bookingCharges">
                    <div className="bookingHeadin">
                      <h1 className="headingTxt">Booking Charges</h1>
                    </div>
                    <div className="bookingcontent">
                      <div className="bookingFlexin">
                        {vaccines &&
                          vaccines.map((item, idx) => {
                            return (
                              <>
                                <label className="Key">{item?.vaccine}</label>
                                {bookAppPayLoad?.charges !== 0 && (
                                  <label className="Value">₹{item.price}</label>
                                )}
                              </>
                            );
                          })}
                      </div>
                      <div className="bookingFlexin">
                        <label className="Key">Booking Fees</label>
                        {!isNaN(bookAppPayLoad?.charges * vaccines?.length)
                          ? bookAppPayLoad?.charges && (
                              <label className="Value">
                                ₹{bookAppPayLoad?.charges * vaccines?.length}{" "}
                              </label>
                            )
                          : "₹0"}
                      </div>
                      <div className="bookingFlexin">
                        <label className="Key">Tax</label>
                        <label className="Value">-</label>
                      </div>
                    </div>
                    <div className="bookingBottom">
                      <div className="bookingFlexin">
                        <label className="Key">Total</label>
                        <label className="Value">
                          ₹{totalVacconatorBookingAmt}
                        </label>
                        {/* {!isNaN(
                          bookAppPayLoad?.charges * vaccines?.length +
                            totalVaccineCharges
                        )
                          ? bookAppPayLoad?.charges && (
                              <label className="Value">
                                ₹
                                {bookAppPayLoad?.charges * vaccines?.length +
                                  totalVaccineCharges}
                              </label>
                            )
                          : "₹0"} */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VaccinatorsAppointment;

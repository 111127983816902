import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import MediImg from "../../assets/images/medicinesImg.svg";
import plusIco from "../../assets/images/plusIcon.svg";
import minusIco from "../../assets/images/minusIcon.svg";
import failedStatus from "../../assets/images/failedStatus.svg";
import SideArrow from "../../assets/images/accordianArrow.svg";
import coupon from "../../assets/images/coupon.svg";
import Modal from "react-bootstrap/Modal";
import GreenAxe from "../../assets/images/greenCrossAxe.svg";
import Bell from "../../assets/images/hurrayBell.svg";
import close from "../../assets/images/icons/close.svg";
import { useReduxStore } from "../../customHooks/useReduxStore";
import {
  DeleteCallWithAuthToken,
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import moment from "moment";
import { AppContext } from "../../context/AppContext";
import { useDispatch } from "react-redux";
import {
  addToCart,
  decreaseCartCount,
  increaseCartCount,
  removeFromCart,
} from "../../redux/Slices/cartSlice";

const MedicinesCart = () => {
  const dispatch = useDispatch();
  // DATA FROM CONTEXT
  const { navigate, setEventKey } = useContext(AppContext);
  // DATA FROM CONTEXT

  // DATA FROM REDUX STORE
  const {
    cartItemCount,
    cartList,
    customer_id,
    customer_address,
    customer_animal,
    customer_details,
  } = useReduxStore();
  // DATA FROM REDUX STORE

  //STATES
  const [showLogoutModal, setShowLogoutModal] = useState({
    open: false,
    customer_card_id: "",
  });
  const [couponModal, setCouponModal] = useState(false);
  const [bell, setBell] = useState(false);
  const [backDrpLdr, setBackDrpLdr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [discountDetail, setDiscountDetail] = useState({
    discountAmt: "",
    discountCoupon: "",
  });
  const [couponPayLoad, setCouponPayLoad] = useState({
    total_amount: "",
    coupon_id: "",
    coupon_code: "",
  });
  console.log("couponPayLoad,", couponPayLoad);
  //STATES

  const subTotal =
    cartList &&
    cartList.length > 0 &&
    cartList.reduce((totalAmount, currentObject) => {
      return (
        totalAmount +
        Number(currentObject?.product_rate) *
          Number(currentObject?.product_quantity)
      );
    }, 0);
  const [productTotalAmt, setProductTotalAmt] = useState("");
  useEffect(() => {
    const total =
      cartList &&
      cartList.length > 0 &&
      cartList.reduce((totalAmount, currentObject) => {
        return (
          totalAmount +
          Number(currentObject?.product_rate) *
            Number(currentObject?.product_quantity)
        );
      }, 0);
    total && setProductTotalAmt(total);
  }, [cartList]);

  const totalItem =
    cartList &&
    cartList.length > 0 &&
    cartList.reduce((qty, currentObject) => {
      return qty + Number(currentObject?.product_quantity);
    }, 0);

  useEffect(() => {
    setDiscountDetail({
      discountAmt: "",
      discountCoupon: "",
    });
    fetchCartList();
    fetchAllDiscountCoupons();
  }, []);
  // my-cart-list
  const fetchCartList = async () => {
    try {
      setLoader(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_CART_LIST}?customer_id=${customer_id}`
      );
      const { data, result } = res.json;
      if (result && data) {
        // setCartList(data)
        dispatch(addToCart(data));
        dispatch(increaseCartCount(data.length));
      } else {
        dispatch(addToCart([]));
        dispatch(increaseCartCount(0));
      }
    } catch (error) {
      console.log("catch error :,", error);
    } finally {
      setLoader(false);
    }
  };

  // FETCH_ALL_COUPONS
  const fetchAllDiscountCoupons = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_ALL_COUPONS}`
      );
      const { success, data } = res?.json;
      if (success) {
        data && setCoupons(data);
      }
    } catch (error) {
      console.log("fecthMyAnimals catch error :", error);
    }
  };

  // APPLY_COUPONS
  const applyCoupons = async () => {
    if (!couponPayLoad?.total_amount && !couponPayLoad?.coupon_id) {
      notifyError("Please select coupon");
      return;
    }
    try {
      const res = await PostCallWithAuthToken(
        ApiConfig.APPLY_COUPONS,
        couponPayLoad
      );
      const { success, message } = res?.json;
      if (success) {
        // notifySuccess(message);
        const discountAmt =
          productTotalAmt -
          (Number(couponPayLoad?.discountPercentage) * productTotalAmt) / 100;
        setDiscountDetail({
          discountAmt:
            (Number(couponPayLoad?.discountPercentage) * productTotalAmt) / 100,
          discountCoupon: couponPayLoad?.coupon_code,
        });
        setProductTotalAmt(discountAmt);
        notifySuccess("Coupon Applied Successfully");
        // setBell(true);
        setCouponModal(false);
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  const deleteCartItem = async () => {
    try {
      const customer_card_id = showLogoutModal.customer_card_id;
      setBackDrpLdr(true);
      const res = await DeleteCallWithAuthToken(
        `${ApiConfig.REMOVE_MEDICINE_FROM_CART}?customer_card_id=${customer_card_id}`
      );
      const { result, message } = res?.json;
      if (result) {
        notifySuccess(message);
        const filterList = cartList.filter(
          (item) => item?.customer_card_id !== customer_card_id
        );
        dispatch(removeFromCart(filterList));
        dispatch(decreaseCartCount(cartItemCount - 1));
        // setCartList(filterList)
        setShowLogoutModal({
          ...showLogoutModal,
          open: false,
        });
        setProductTotalAmt("")
      }
    } catch (error) {
      console.log("catcj error:,", error);
    } finally {
      setBackDrpLdr(false);
    }
  };
  return (
    <>
      {backDrpLdr && <BackDropLoader />}
      <div className="medicineCart" id="cx-main">
        <Subheader />
        {/*Content Cards */}
        {loader ? (
          <RotatingLoader />
        ) : (
          <div className="mainCartBox">
            <div className="row">
              <div className="col-md-8">
                <div className="cartTable">
                  <div className="scroller">
                    {Array.isArray(cartList) && cartList.length > 0 ? (
                      <table className="table">
                        <thead className="tHead">
                          <tr>
                            <th>Product</th>
                            {/* <th>Seller name</th>*/}
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tBody">
                          {Array.isArray(cartList) &&
                            cartList.map((item, idx) => {
                              return (
                                <tr key={"cartList0" + idx}>
                                  <td style={{ width: "36%" }}>
                                    <div className="imgFlex">
                                      <img
                                        src={
                                          item?.product_image_path
                                            ? item?.product_image_path
                                            : MediImg
                                        }
                                        className="MediImg"
                                        alt=""
                                      />
                                      <label htmlFor="" className="Prdctname">
                                        <Link
                                          style={{ color: "#1D7C7C" }}
                                          // PetShopDetails
                                          // pet_shop_id
                                          to={`/${
                                            item?.entity_type_id == 5
                                              ? "PetShopDetails"
                                              : "ProductDetails"
                                          }?product_id=${item?.product_id}&${
                                            item?.entity_type_id == 5
                                              ? "pet_shop_id"
                                              : "pharmacy_shop_id"
                                          }=${
                                            item?.entity_type_id == 5
                                              ? item?.pet_shop_id
                                              : item?.pharmacy_shop_id
                                          }&entity_type_id=${
                                            item?.entity_type_id
                                          }`}
                                        >
                                          {item?.product}
                                        </Link>
                                      </label>
                                    </div>
                                  </td>
                                  {/*
                                  <td>
                                  <div className="imgFlex">
                                    <label htmlFor="" className="Prdctname">
                                      {item?.pharmacy_shop_name
                                        ? item?.pharmacy_shop_name
                                        : item?.pet_shop_name
                                        ? item?.pet_shop_name
                                        : ""}
                                    </label>
                                  </div>
                                </td>
                                */}

                                  <td>
                                    <div className="qntyBox">
                                      <label htmlFor="">
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={minusIco}
                                          alt=""
                                          onClick={() => {
                                            const tempArr =
                                              cartList &&
                                              cartList.map((ele, i, arr) => {
                                                let productQty =
                                                  item?.product_quantity &&
                                                  Number(
                                                    item?.product_quantity
                                                  ) - 1;
                                                let amount =
                                                  productQty *
                                                  Number(item?.product_rate);

                                                return idx === i
                                                  ? {
                                                      ...ele,
                                                      product_quantity:
                                                        productQty
                                                          ? productQty
                                                          : Number(
                                                              item?.product_quantity
                                                            ),
                                                      amount: amount
                                                        ? amount.toString()
                                                        : item?.product_rate,
                                                    }
                                                  : ele;
                                              });
                                            tempArr &&
                                              Number(item?.amount) &&
                                              dispatch(addToCart(tempArr));
                                            // setCartList(tempArr)
                                          }}
                                        />
                                      </label>
                                      <label htmlFor="" className="value">
                                        {item?.product_quantity}
                                      </label>
                                      <label htmlFor="">
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={plusIco}
                                          alt=""
                                          onClick={() => {
                                            const tempArr =
                                              cartList &&
                                              cartList.map((ele, i) => {
                                                let availableQty =
                                                  item?.entity_type_id === 3
                                                    ? parseInt(
                                                        item?.pharmacy_shop_product_quantity
                                                      )
                                                    : parseInt(
                                                        item?.pet_shop_product_quantity
                                                      );
                                                let productQty =
                                                  item?.product_quantity &&
                                                  Number(
                                                    item?.product_quantity
                                                  ) + 1;
                                                let amount = 0;
                                                if (availableQty > productQty) {
                                                  amount =
                                                    productQty *
                                                    Number(item?.product_rate);
                                                } else {
                                                  amount =
                                                    Number(item?.product_rate) *
                                                    availableQty;
                                                  idx === i &&
                                                    notifyError(
                                                      "Quantity exceeds available stock!"
                                                    );
                                                }
                                                return idx === i
                                                  ? {
                                                      ...ele,
                                                      product_quantity:
                                                        availableQty >
                                                        productQty
                                                          ? productQty
                                                            ? productQty
                                                            : Number(
                                                                item?.product_quantity
                                                              )
                                                          : availableQty,
                                                      amount: amount.toString(),
                                                      // amount: availableQty < productQty ? availableQty.toString() : amount.toString(),
                                                    }
                                                  : ele;
                                              });
                                            tempArr &&
                                              dispatch(addToCart(tempArr));
                                          }}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <label className="rateTag">
                                      ₹{" "}
                                      {parseFloat(item?.product_rate).toFixed(
                                        2
                                      )}
                                    </label>
                                  </td>
                                  <td>
                                    <label className="rateTag">
                                      ₹ {parseFloat(item?.amount).toFixed(2)}
                                    </label>
                                  </td>
                                  <td>
                                    <img
                                      style={{ cursor: "pointer" }}
                                      src={failedStatus}
                                      className="failedStatus"
                                      alt=""
                                      onClick={() =>
                                        // deleteCartItem(item?.customer_card_id)
                                        setShowLogoutModal({
                                          ...showLogoutModal,
                                          open: true,
                                          customer_card_id:
                                            item?.customer_card_id,
                                        })
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <NoDataMsg />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="couponCard">
                  {/* <div className="headingFlex">
                    <h1>Best Coupons For you</h1>
                    <label htmlFor="">
                      All Coupons <img src={SideArrow} alt="" />
                    </label>
                  </div>
                  <div className="applyCouponBox">
                    <h1>
                      <img src={coupon} alt="" />
                      {discountDetail?.discountCoupon
                        ? discountDetail?.discountCoupon
                        : "Apply Coupons"}
                    </h1>
                    {discountDetail?.discountCoupon ? (
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-danger"
                        onClick={() => {
                          const total =
                            cartList &&
                            cartList.length > 0 &&
                            cartList.reduce((totalAmount, currentObject) => {
                              return (
                                totalAmount +
                                Number(currentObject?.product_rate) *
                                  Number(currentObject?.product_quantity)
                              );
                            }, 0);
                          total && setProductTotalAmt(total);
                          setDiscountDetail({
                            discountAmt: "",
                            discountCoupon: "",
                          });
                          setCouponPayLoad({
                            total_amount: "",
                            coupon_id: "",
                            coupon_code: "",
                          });
                        }}
                      >
                        Remove
                      </span>
                    ) : (
                      <button
                        className="applyBtn"
                        onClick={() => setCouponModal(true)}
                      >
                        Apply
                      </button>
                    )}
                  </div> */}
                  <div className="cartSummaryBox">
                    <div className="headingFlex">
                      <h1>Cart Summary</h1>
                      <label htmlFor="">
                        ({cartList && cartList.length} Items )
                      </label>
                    </div>
                    <div className="contentFlex">
                      <div className="keyValueFlex">
                        <label htmlFor="" className="key">
                          Subtotal
                        </label>
                        <label htmlFor="" className="value">
                          ₹{" "}
                          {subTotal ? parseFloat(subTotal).toFixed(2) : "0.00"}
                        </label>
                      </div>
                      <div className="keyValueFlex">
                        <label htmlFor="" className="key">
                          Shipping
                        </label>
                        <label htmlFor="" className="value">
                          ₹ 0.00
                        </label>
                      </div>
                      <div className="keyValueFlex">
                        <label htmlFor="" className="key">
                          Tax
                        </label>
                        <label htmlFor="" className="value">
                          ₹ 0.00
                        </label>
                      </div>
                    </div>
                    {/* discountAmount */}
                    {/* {discountDetail?.discountAmt && (
                      <div className="bottomCard">
                        <label htmlFor="" className="Total">
                          Discount
                        </label>
                        <label htmlFor="" className="TotalAmnt">
                          - ₹{" "}
                          {discountDetail?.discountAmt
                            ? parseFloat(discountDetail?.discountAmt).toFixed(2)
                            : "0.00"}
                        </label>
                      </div>
                    )} */}
                    <div className="bottomCard">
                      <label htmlFor="" className="Total">
                        Total
                      </label>
                      <label htmlFor="" className="TotalAmnt">
                        ₹{" "}
                        {productTotalAmt
                          ? parseFloat(productTotalAmt).toFixed(2)
                          : "0.00"}
                      </label>
                    </div>
                  </div>
                </div>
                {cartList.length > 0 && (
                  <div className="checkoutBtn">
                    {/* <Link to='/MedicineCheckout'> */}
                    <button
                      type="button"
                      className="checkBtn"
                      onClick={() => {
                        if (
                          !customer_address ||
                          !customer_details
                        ) {
                          navigate("/ProfileSetting");
                          setEventKey("11");
                          if (!customer_details) {
                            notifyError(
                              "Please update your profile details and address"
                            );
                          } else if (!customer_address) {
                            notifyError("Please add your address");
                          }
                          sessionStorage.setItem(
                            "isNavigateFromCartPage",
                            true
                          );
                        } else {
                          navigate("/MedicineCheckout", {
                            state: {
                              cartList: cartList,
                              productTotalAmt,
                              discountAmt: discountDetail?.discountAmt,
                            },
                          });
                        }
                      }}
                    >
                      Proceed To Checkout
                    </button>
                    {/* </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* APply coupon Modal */}
      {couponModal ? (
        <div className="cart-coupon-modal">
          <Modal
            id="couponModalBody"
            size="md"
            show={coupon}
            onHide={() => setCouponModal(false)}
            centered
          >
            <Modal.Body>
              <div className="modalCouponBox">
                <div className="headingFlex">
                  <h1 className="modalName">Apply Coupon</h1>
                  <label
                    htmlFor=""
                    className="CrossImg"
                    onClick={() => setCouponModal(false)}
                  >
                    <img src={GreenAxe} alt="" />
                  </label>
                </div>
                {/* <div className="couponBoxBorder">
                  <label htmlFor="" className="animalPer">
                    ANIMAL20%
                  </label>
                  <label htmlFor="" className="Check">
                    Check
                  </label>
                </div>   */}
                {coupons &&
                  coupons.length &&
                  coupons.map((item, idx) => {
                    const minmumDiscount =
                      (item?.min_amount * item?.coupon_discount_per) / 100;
                    return (
                      <div className="checkAndDiscount" key={"jhasgey" + idx}>
                        <div className="checkboxIn">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            // checked={false}
                            checked={
                              couponPayLoad?.coupon_id === item?.coupon_id
                            }
                            id="flexCheckDefault"
                            onChange={() => {
                              setCouponPayLoad({
                                total_amount: Number(subTotal),
                                // total_amount: 2000,
                                coupon_id: item?.coupon_id,
                                discountPercentage: item?.coupon_discount_per,
                                min_amount: item?.min_amount,
                                coupon_code: item?.coupon_code,
                              });
                            }}
                          />
                        </div>
                        <div className="discountBadge">
                          <label
                            className="innBadge"
                            htmlFor="flexCheckDefault"
                          >
                            {item?.coupon_code}
                          </label>
                          <p className="paraPara">
                            Expires on :{" "}
                            {moment(item?.coupon_valid_upto).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                        <div className="savingDetails">
                          {/* <p className="savePara">Save ₹200</p> */}
                          <p className="belowPaara">
                            ₹{minmumDiscount} off on minimum purchase of ₹
                            {item?.min_amount}.
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="checkAndDiscount">
                  <div className="checkboxIn">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                  <div className="discountBadge">
                    <label className="innBadge" htmlFor="flexCheckDefault">
                      ANIMAL20%
                    </label>
                    <p className="paraPara">
                      Expires on : 31st December 2023 , 11:59 PM
                    </p>
                  </div>
                  <div className="savingDetails">
                    <p className="savePara">Save ₹200</p>
                    <p className="belowPaara">
                      ₹200 off on minimum purchase of ₹2000.
                    </p>
                  </div>
                </div>
                <div className="checkAndDiscount">
                  <div className="checkboxIn">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                  <div className="discountBadge">
                    <label className="innBadge" htmlFor="flexCheckDefault">
                      ANIMAL20%
                    </label>
                    <p className="paraPara">
                      Expires on : 31st December 2023 , 11:59 PM
                    </p>
                  </div>
                  <div className="savingDetails">
                    <p className="savePara">Save ₹200</p>
                    <p className="belowPaara">
                      ₹200 off on minimum purchase of ₹2000.
                    </p>
                  </div>
                </div> */}
                <div className="belowBottom">
                  {/* <div className="savingsTxt">
                    <p>Maximum Savings</p>
                    <label htmlFor="">₹200 </label>
                  </div> */}
                  <div className="applyBox">
                    <button
                      className="applyBtn"
                      onClick={() => {
                        applyCoupons();
                        // setCouponModal(false);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
      {/* Bell Modal */}
      {bell ? (
        <>
          <Modal size="sm" show={bell} onHide={() => setBell(false)} centered>
            <Modal.Body>
              <div className="bellBox">
                <img src={Bell} alt="" />
                <p>Coupon Applied Successfully !</p>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLogoutModal.open}
        className="create-workflow-main modal-sm"
        onHide={() =>
          setShowLogoutModal({
            ...showLogoutModal,
            open: false,
          })
        }
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Remove Item
            </label>
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={() =>
              setShowLogoutModal({
                ...showLogoutModal,
                open: false,
              })
            }
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body className="text-center">
          Are you sure you want to remove this item?
        </Modal.Body>
        <div className="text-center" style={{ padding: "15px" }}>
          <button
            className="modal-cancel-btn sm-btn-delete"
            onClick={() =>
              setShowLogoutModal({
                ...showLogoutModal,
                open: false,
              })
            }
          >
            Cancel
          </button>
          <button
            className="modal-create-btn sm-btn-delete"
            onClick={deleteCartItem}
          >
            Remove
          </button>
        </div>
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default MedicinesCart;

import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import ic_close_red from "../../assets/images/ic_close_red.svg";
import ic_add_plus from "../../assets/images/ic_add_plus.svg";
import ic_arrow_right_white from "../../assets/images/ic_right_arrow_circle.svg";
import animal_img from "../../assets/images/animal_img.png";
import imageIcon from "../../assets/images/icons/imageIcon.svg";
import ic_cross_green from "../../assets/images/ic_cross_green.svg";
import {
  PostCallWithAuthToken,
  PutCallWithAuthToken,
  postCallWithFormDataWithToken,
  putCallWithFormDataWithToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { Link } from "react-router-dom";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";
import { updateLoginData } from "../../redux/Slices/authSlice";

const MyAnimalProfileComp = () => {
  const {
    setBkdropLdr,
    validNumber,
    navigate,
    dispatch,
    setAmbulanceFormCharge,
    setIsPatientDet,
    setIsSlot,
  } = useContext(AppContext);
  // FROM REDUX STORE
  const { customerLoggedInData, loginData } = useReduxStore();
  const { customer_id } =
    customerLoggedInData?.loginData && customerLoggedInData?.loginData;
  // FROM REDUX STORE
  const navigationDetails = sessionStorage.navigationDetails
    ? JSON.parse(sessionStorage.navigationDetails)
    : {};

  const ambulance_driver_id = sessionStorage.ambulance_driver_id
    ? sessionStorage.ambulance_driver_id
    : "";

  const checkoutData = sessionStorage.checkoutData
    ? JSON.parse(sessionStorage.checkoutData)
    : false;

  // STATES
  const [validated, setValidated] = useState(false);
  const [petTypes, setPetTypes] = useState([]);
  const [animalUpdatePayLoad, setAnimalUpdatePayLoad] = useState([
    {
      name: "",
      type_of_animal_id: 0,
      age: "",
      photos: [],
      remove_photo_ids: [],
      added_photos_count: 0,
    },
  ]);

  // STATES

  useEffect(() => {
    getAnimalInformation();
    getPetTypes();

    return () => {
      sessionStorage.removeItem("navigationDetails");
      sessionStorage.removeItem("ambulance_driver_id");
      sessionStorage.removeItem("checkoutData");
    };
  }, []);

  const getAnimalInformation = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_ANIMAL_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      const tempArr =
        data &&
        data.length &&
        data.map((item) => {
          return {
            animal_id: item?.customer_animal_id,
            name: item?.name,
            type_of_animal_id: item?.type?.type_of_animal_id,
            age: item?.age,
            photos: item?.photos ? item?.photos : [],
            remove_photo_ids: [],
            added_photos_count: 0,
          };
        });
      if (success === true && Array.isArray(data)) {
        if (tempArr && navigationDetails?.isNavigateFromDoctorPage) {
          setAnimalUpdatePayLoad([
            ...tempArr,
            {
              name: "",
              type_of_animal_id: 0,
              age: "",
              photos: [],
              remove_photo_ids: [],
              added_photos_count: 0,
            },
          ]);
        } else if (ambulance_driver_id) {
          setAnimalUpdatePayLoad([
            ...tempArr,
            {
              name: "",
              type_of_animal_id: 0,
              age: "",
              photos: [],
              remove_photo_ids: [],
              added_photos_count: 0,
            },
          ]);
        } else if (checkoutData) {
          setAnimalUpdatePayLoad([
            ...tempArr,
            {
              name: "",
              type_of_animal_id: 0,
              age: "",
              photos: [],
              remove_photo_ids: [],
              added_photos_count: 0,
            },
          ]);
        } else if (tempArr) {
          setAnimalUpdatePayLoad(tempArr);
        } else {
          setAnimalUpdatePayLoad([
            {
              name: "",
              type_of_animal_id: 0,
              age: "",
              photos: [],
              remove_photo_ids: [],
              added_photos_count: 0,
            },
          ]);
        }
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  const getPetTypes = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(ApiConfig.GET_PET_TYPES);
      if (res?.json?.success === true) {
        res?.json?.data && setPetTypes(res?.json?.data);
      }
    } catch (error) {
      console.log("catch error res,", error);
    }
  };

  const onChangeHandler = (event, index) => {
    const { value, name } = event.target;
    const tempArr = [...animalUpdatePayLoad];
    tempArr[index][name] = value;
    setAnimalUpdatePayLoad(tempArr);
  };
  const newAnimalPhotosToadd =
    animalUpdatePayLoad &&
    animalUpdatePayLoad.map((item, i) => {
      return item?.photos?.find(
        (innerItem) => !innerItem?.hasOwnProperty("animal_photograph_id")
      );
    });

  const updateAnimalProfile = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        setBkdropLdr(true);
        const formData = new FormData();
        newAnimalPhotosToadd.forEach(
          (item) => item && formData.append("animalphotos", item)
        );
        formData.append("costomer_id", customer_id);
        formData.append("animals", JSON.stringify(animalUpdatePayLoad));
        const res = await putCallWithFormDataWithToken(
          ApiConfig.UPDATE_ANIMAL_PROFILE,
          formData
        );
        const { success, message } = res?.json;
        if (success) {
          notifySuccess(message);
          getAnimalInformation();
          dispatch(
            updateLoginData({
              ...loginData,
              customer_animal: 1,
            })
          );
          if (
            navigationDetails?.isNavigateFromDoctorPage &&
            navigationDetails?.doctor_id &&
            navigationDetails?.clinic_id
          ) {
            navigate(
              `/BookAppoitment?doctorId=${navigationDetails?.doctor_id}&clinic_id=${navigationDetails?.clinic_id}`
            );
            setIsPatientDet(true);
            setIsSlot(false);
          }
          if (ambulance_driver_id) {
            navigate(`/AmbulanceBook/${ambulance_driver_id}`);
            setAmbulanceFormCharge(false);
          }
          if (checkoutData) {
            navigate("/MedicineCheckout", {
              state: {
                cartList: checkoutData?.cartList,
                customer_animal_id: checkoutData?.customer_animal_id,
                doctor_appointment_id: checkoutData?.doctor_appointment_id,
              },
            });
          }
        } else {
          notifyError(message);
        }
      } catch (error) {
        notifyError("Something went wrong");
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={updateAnimalProfile}>
        <div className="right-section">
          {Array.isArray(animalUpdatePayLoad) &&
            animalUpdatePayLoad.map((item, idx) => {
              return (
                <section className="wrapper-cards">
                  <div className="heading-close-btn">
                    <h5>{`My Animal Profile ${idx + 1}`}</h5>
                    <Link>
                      {idx !== 0 && (
                        <img
                          src={ic_close_red}
                          alt=""
                          onClick={() => {
                            const duplicateArr = [...animalUpdatePayLoad];
                            duplicateArr.splice(idx, 1);
                            setAnimalUpdatePayLoad(duplicateArr);
                            setValidated(false);
                          }}
                        />
                      )}
                    </Link>
                  </div>

                  <dov className="row">
                    <div className="col-md-6 form-group">
                      <Form.Group className="">
                        <Form.Label>Animal Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter animal name"
                          value={item?.name}
                          name="name"
                          onChange={(e) => onChangeHandler(e, idx)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Animal name is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                      <Form.Group className="">
                        <Form.Label>Type of Animal</Form.Label>
                        <Form.Select
                          aria-label="Work / Home / Other"
                          value={item?.type_of_animal_id}
                          name="type_of_animal_id"
                          onChange={(e) => onChangeHandler(e, idx)}
                          required
                        >
                          <option value="">Please select</option>
                          {Array.isArray(petTypes) &&
                            petTypes.map((pet, idx) => {
                              return (
                                <option
                                  key={pet?.type_of_animal_id}
                                  value={pet?.type_of_animal_id}
                                >
                                  {pet?.type_of_animal}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Animal type is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                      <Form.Group className="">
                        <Form.Label>Animal Age</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Animal Age..."
                          value={item?.age}
                          name="age"
                          onChange={(e) => {
                            if (
                              validNumber.test(e.target.value) &&
                              e.target.value.length < 3
                            )
                              onChangeHandler(e, idx);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Animal type is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                      <Form.Group className="">
                        <Form.Label>Animal Photographs</Form.Label>
                        <div className="img-select">
                          <img src={imageIcon} className="mailImg" alt="" />
                          <Form.Label
                            htmlFor={`selectPhotos${idx + 1}`}
                            className="photograhphy"
                          >
                            <Form.Control
                              id={`selectPhotos${idx + 1}`}
                              type="file"
                              style={{ display: "none" }}
                              name="photos"
                              onChange={(e) => {
                                let tempArr = animalUpdatePayLoad.map(
                                  (ele, innerIdx) => {
                                    const filteredPhotos =
                                      ele?.photos &&
                                      ele?.photos.filter(
                                        (image) =>
                                          !image?.hasOwnProperty(
                                            "animal_photograph_id"
                                          )
                                      );
                                    return idx === innerIdx
                                      ? {
                                          ...ele,
                                          photos: [
                                            ...ele?.photos,
                                            e.target.files[0],
                                          ],
                                          added_photos_count:
                                            filteredPhotos.length + 1,
                                        }
                                      : ele;
                                  }
                                );
                                setAnimalUpdatePayLoad(tempArr);
                              }}
                            />
                            <span> Select photos...</span>
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                      {Array.isArray(item?.photos) &&
                        item?.photos.length > 0 && (
                          <Form.Label>Preview</Form.Label>
                        )}
                      <div className="animal-img-wrapper">
                        {Array.isArray(item?.photos) &&
                          item?.photos.map((img, index) => {
                            const finalPath =
                              img && img.size
                                ? img
                                : img?.animal_photograph_path;
                            return (
                              <div
                                className="img"
                                key={img?.animal_photograph_id}
                              >
                                <img
                                  src={
                                    img.size && img.size
                                      ? URL?.createObjectURL(img)
                                      : finalPath
                                  }
                                  // src={}
                                  // src={animal_img}
                                  alt=""
                                  className="animal"
                                />
                                <button>
                                  <img
                                    src={ic_cross_green}
                                    alt=""
                                    className="cross-icon"
                                    onClick={() => {
                                      const newArr = item.photos.filter(
                                        (element) =>
                                          element?.animal_photograph_id
                                            ? element?.animal_photograph_id !==
                                              img?.animal_photograph_id
                                            : element !== img
                                      );
                                      // FILTERED PHOTOS FOR PHOTO COUNT
                                      const filteredPhotos =
                                        item.photos &&
                                        item.photos?.filter(
                                          (image) =>
                                            !image?.hasOwnProperty(
                                              "animal_photograph_id"
                                            ) && image?.animal_photograph_id
                                        );
                                      // GET ANIMAL ID FOR IDENTIFY REMOVED IMAGE
                                      const animalId =
                                        img?.hasOwnProperty(
                                          "animal_photograph_id"
                                        ) && img?.animal_photograph_id;
                                      const tempArr = animalUpdatePayLoad.map(
                                        (ele, innerIdx) => {
                                          return idx === innerIdx
                                            ? {
                                                ...ele,
                                                photos: newArr,
                                                added_photos_count:
                                                  filteredPhotos?.length - 1 < 0
                                                    ? 0
                                                    : filteredPhotos?.length -
                                                      1,
                                                remove_photo_ids: animalId
                                                  ? [
                                                      ...ele?.remove_photo_ids,
                                                      animalId,
                                                    ]
                                                  : ele?.remove_photo_ids,
                                              }
                                            : ele;
                                        }
                                      );
                                      setAnimalUpdatePayLoad(tempArr);
                                    }}
                                  />
                                </button>
                              </div>
                            );
                          })}
                        {/* <div className='img'>
                        <img src={animal_img} alt='' className='animal' />
                        <button>
                          <img
                            src={ic_cross_green}
                            alt=''
                            className='cross-icon'
                          />
                        </button>
                      </div>
                      <div className='img'>
                        <img src={animal_img} alt='' className='animal' />
                        <button>
                          <img
                            src={ic_cross_green}
                            alt=''
                            className='cross-icon'
                          />
                        </button>
                      </div> */}
                      </div>
                    </div>
                  </dov>
                </section>
              );
            })}

          <div className="buttons-wrapper-main">
            <div className="add-btn-wrapper mt-3">
              <button
                type="button"
                onClick={() => {
                  setAnimalUpdatePayLoad([
                    ...animalUpdatePayLoad,
                    {
                      name: "",
                      type_of_animal_id: 0,
                      age: "",
                      photos: [],
                      remove_photo_ids: [],
                      added_photos_count: 0,
                    },
                  ]);
                }}
              >
                <img src={ic_add_plus} alt="" />
                Add Animal
              </button>
            </div>

            <div className="submit-btn-group mt-3">
              <button type="button" className="blank-btn">
                Cancel
              </button>
              <button className="filled-btn">
                Update
                <img src={ic_arrow_right_white} alt="" />
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default MyAnimalProfileComp;

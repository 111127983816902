import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import ic_doc_download_light from '../../assets/images/ic_doc_download_light.svg'
import ic_view_light from '../../assets/images/ic_view_light.svg'
import ic_repeat from '../../assets/images/ic_repeat.svg'
import ic_repeat_green from '../../assets/images/ic_repeat_green.svg'
import chatting_dummy from '../../assets/images/chatting_dummy.png'
import video_dummy from '../../assets/images/video_dummy.png'

import ic_add_plus from '../../assets/images/ic_add_plus.svg'
import ic_arrow_right_white from '../../assets/images/ic_right_arrow_circle.svg'
import dr_1 from '../../assets/images/dr_1.png'
import ic_doc_download from '../../assets/images/ic_doc_download.svg'
import ic_view from '../../assets/images/ic_view.svg'
import ic_video from '../../assets/images/ic_video.svg'
import ic_calender_green from '../../assets/images/ic_calender_green.svg'
import dog_1 from '../../assets/images/dog_1.png'
import dog_3 from '../../assets/images/dog_3.png'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import ic_progress from '../../assets/images/ic_progress.svg'
import ic_complete from '../../assets/images/ic_complete.svg'
import ic_reject from '../../assets/images/ic_reject.svg'
import ic_left_green_arrow from '../../assets/images/ic_left_green_arrow.svg'
import ic_star_active from '../../assets/images/ic_star_active.svg'
import ic_start from '../../assets/images/ic_start.svg'
import { Link, useNavigate } from 'react-router-dom'
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken
} from '../../api/ApiServices'
import { ApiConfig } from '../../api/ApiConfig'
import NoDataMsg from '../../sharedComponent/NoDataMsg'
import { notifySuccess } from '../../ToastMessages/notify'
import { AppContext } from '../../context/AppContext'
import { useDateFormats } from '../../customHooks/useDateFormats'

const SupportComp = () => {
  const { convertWithDateAndDay } = useDateFormats()
  const [Support, setSupport] = useState(true)
  const navigate = useNavigate()

  const [supportList, setSupportList] = useState([])

  useEffect(() => {
    fetchSupportList()
  }, [])

  // support-list
  const fetchSupportList = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(ApiConfig.FETCH_SUPPORT_LIST)
      const { success, data } = res?.json
      if (success && data) {
        setSupportList(data)
      }
    } catch (error) {
      console.log('catch Error,', error)
    }
  }
  const [validated, setValidated] = useState(false)

  const [supportPayLoad, setSupportPayLoad] = useState({
    subject: '',
    comment: ''
  })
  const { setBkdropLdr } = useContext(AppContext)

  const createSupport = async event => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else {
      try {
        setBkdropLdr(true)
        const res = await PostCallWithAuthToken(
          ApiConfig.CREATE_SUPPORT,
          supportPayLoad
        )
        const { success } = res?.json
        if (success) {
          notifySuccess('support added successfully')
          fetchSupportList()
          setSupport(true)
        }
      } catch (error) {
        console.log('catch error,', error)
      } finally {
        setBkdropLdr(false)
      }
    }
  }

  return (
    <div id='SupportComp'>
      <div id='my-appointment-wrapper' className='SupportComp-main'>
        {Support ? (
          <>
            <section className='wrapper-cards mb-3'>
              <div className='heading-close-btn'>
                <h5>Support</h5>
                <button onClick={() => setSupport(false)}>+ Create New</button>
              </div>
            </section>

            {supportList && supportList.length > 0 ? (
              supportList.map((item, i) => {
                return (
                  <section
                    className='wrapper-cards mb-3'
                    key={item?.customer_support_id}
                  >
                    <div className='app-details-wrapper row'>
                      <div className='col-md-3'>
                        <div className='img-dr'>
                          {/* <img src={dog_3} alt='' /> */}
                          <div className='right-dr'>
                            <label htmlFor='' className='key'>
                              {item?.subject}{' '}
                            </label>
                            <p className='value'>Category</p>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div className='detail'>
                          <label htmlFor='' className='key'>
                            Created On
                          </label>
                          <p className='value'>
                            {convertWithDateAndDay(item?.created_on)}
                            {/* 02, July 2023 */}
                          </p>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='detail'>
                          <label htmlFor='' className='key'>
                            Resolved
                          </label>
                          <p className='value'>
                            {item?.updated_on ? item?.updated_on : '-'}
                            {/* 03, July 2023 */}
                          </p>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='detail'>
                          <label htmlFor='' className='key'>
                            Status
                          </label>
                          <p
                            className='value'
                            id={
                              item?.status === 'in progress'
                                ? 'progress'
                                : 'resolved'
                            }
                          >
                            {item?.status === 'in progress'
                              ? 'in progress'
                              : 'Resolved'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              })
            ) : (
              <NoDataMsg />
            )}

            {/* <section className='wrapper-cards mb-3'>
              <div className='app-details-wrapper row'>
                <div className='col-md-3'>
                  <div className='img-dr'>
                    <img src={dog_3} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Subject{' '}
                      </label>
                      <p className='value'>Category</p>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Created On
                    </label>
                    <p className='value'>02, July 2023</p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Resolved
                    </label>
                    <p className='value'>03, July 2023</p>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='resolved'>
                      Resolved
                    </p>
                  </div>
                </div>
              </div>
            </section> */}
          </>
        ) : (
          <Form noValidate validated={validated} onSubmit={createSupport}>
            <div className='support-form'>
              <div className='wrapper-cards mt-3 form-support'>
                <h6>Create New</h6>
                <div className='row'>
                  {/* <div className='col-md-6 mb-3'>
                  <label htmlFor='' className='key'>
                    Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter name'
                    onChange={e => {
                      setSupportPayLoad({
                        ...supportPayLoad,
                        name: e.target.value
                      })
                    }}
                  />
                </div> */}
                  {/* <div className='col-md-6 mb-3'>
                  <label htmlFor='' className='key'>
                    Email
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter email '
                    onChange={e => {
                      setSupportPayLoad({
                        ...supportPayLoad,
                        email: e.target.value
                      })
                    }}
                  />
                </div> */}

                  {/* <div className='col-md-6 mb-3'>
                  <label htmlFor='' className='key'>
                    Phone Number
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Phone Number'
                    onChange={e => {
                      setSupportPayLoad({
                        ...supportPayLoad,
                        phone_number: e.target.value
                      })
                    }}
                  />
                </div> */}

                  <div className='col-md-6 mb-3'>
                    <label htmlFor='' className='key'>
                      Comment
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter your comment'
                      onChange={e => {
                        setSupportPayLoad({
                          ...supportPayLoad,
                          comment: e.target.value
                        })
                      }}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>
                      Comment is required
                    </Form.Control.Feedback>
                  </div>

                  <div className='col-md-12 mb-3'>
                    <label htmlFor='' className='key'>
                      Subject
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter your subject'
                      onChange={e => {
                        setSupportPayLoad({
                          ...supportPayLoad,
                          subject: e.target.value
                        })
                      }}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>
                      Subject is required
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
              <div className='heading-close-btn msg-submit'>
                <button
                  type='button'
                  className='blank-btn'
                  onClick={() => setSupport(true)}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  //   onClick={() => {
                  //     navigate('/SupportDetails')
                  // }}
                >
                  Submit Message
                </button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  )
}

export default SupportComp

import React from "react";
import { useReduxStore } from "../customHooks/useReduxStore";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const { customerLoggedInData } = useReduxStore();
  const isLoggedIn =
    customerLoggedInData && customerLoggedInData?.isLoggedIn ? true : false;
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoutes;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DoctorSymbol from "../../../assets/images/DoctorSymbol.svg";
import sign from "../../../assets/images/sign.png";
import ic_call from "../../../assets/images/ic_call.svg";
import ic_location from "../../../assets/images/ic_location.svg";
import { useDateFormats } from "../../../customHooks/useDateFormats";
import { notifyError } from "../../../ToastMessages/notify";
import { PostCallWithAuthToken } from "../../../api/ApiServices";
import { AppContext } from "../../../context/AppContext";
import { ApiConfig } from "../../../api/ApiConfig";
import ic_mail from "../../../assets/images/ic_mail.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import ic_doc_download from "../../../assets/images/ic_doc_download.svg";

const PetFoodModal = ({
  petFoodModalOpen,
  setPetFoodModalOpen,
  petFoods,
  viewAppointment,
}) => {
  const { formatDateToDdMmYyyy } = useDateFormats();
  const { setBkdropLdr, navigate } = useContext(AppContext);
  const [orderPayLoad, setOrderPayLoad] = useState({
    lat: "",
    lng: "",
    priscription: [],
    customer_animal_id: "",
    doctor_appointment_id: "",
  });
  // STATES

  useEffect(() => {
    if (petFoods) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        const tempArr =
          petFoods?.foodData &&
          petFoods?.foodData.map((item) => {
            return {
              product_id: item?.product_id,
              product: item?.product,
              product_quantity: item?.quantity,
            };
          });

        setOrderPayLoad({
          ...orderPayLoad,
          lat: latitude ? latitude : "",
          lng: longitude ? longitude : "",
          priscription: tempArr,
          customer_animal_id: petFoods?.doctorInfo?.customer_animal_id
            ? petFoods?.doctorInfo?.customer_animal_id
            : "",
          doctor_appointment_id: viewAppointment?.doctor_appointment_id
            ? viewAppointment?.doctor_appointment_id
            : "",
        });
      });
    }
  }, [petFoods]);

  //   ORDER_PETFOOD
  const orderNow = async () => {
    try {
      if (orderPayLoad.lat === "" && orderPayLoad.lng === "") {
        notifyError("Please allow your browser location");
        return;
      }

      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ORDER_PETFOOD,
        orderPayLoad
      );
      const { result, data, message } = res?.json;
      if (result) {
        navigate("/MedicineCheckout", {
          state: {
            cartList: data,
            customer_animal_id: petFoods?.doctorInfo?.customer_animal_id
              ? petFoods?.doctorInfo?.customer_animal_id
              : "",
            doctor_appointment_id: viewAppointment?.doctor_appointment_id
              ? viewAppointment?.doctor_appointment_id
              : "",
          },
        });
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch Error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const pdfExportComponent = useRef(null);

  const downLoadPdf = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div className="prescription-wrapper-main">
      <Modal
        show={petFoodModalOpen ? true : false}
        onHide={() => setPetFoodModalOpen(false)}
        size="lg"
        id="prescription-modal"
        centered
      >
        <Modal.Body>
          <div className="petfood-modal">
            <PDFExport
              ref={pdfExportComponent}
              paperSize="auto"
              margin={20}
              fileName={"Petfoods"}
              author="KendoReact Team"
            >
              <div className="orderModalInvoice">
                <div className="modalHeading">
                  <div className="upperBlue">
                    <h1 className="docName">
                      {petFoods?.doctorInfo?.doctor_name
                        ?.toLowerCase()
                        ?.includes("dr")
                        ? ""
                        : "Dr."}{" "}
                      {petFoods?.doctorInfo?.doctor_name}{" "}
                    </h1>

                    <label className="domainLab">
                      {petFoods?.doctorInfo?.services
                        ? petFoods?.doctorInfo?.services
                        : ""}{" "}
                    </label>
                    <label className="certificateLab">
                      Pet Food Prescription
                    </label>
                  </div>
                  <div className="docSymbolBox">
                    <img src={DoctorSymbol} className="docSymImg" alt="" />
                  </div>
                </div>
                <div className="modalBody">
                  <div className="detailsFill">
                    <div className="labelTxt" style={{width : "30%"}}>
                      <label className="widthFixed">Animal Owner :</label>
                    </div>
                    <input
                      style={{ backgroundColor: "#fff", width: "95%" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={`${petFoods?.doctorInfo?.first_name} ${petFoods?.doctorInfo?.last_name}`}
                    />
                  </div>
                  <div className="detailsFill">
                    <div className="labelTxt">
                      <label className="widthFixed">Patient Name :</label>
                    </div>
                    <input
                      style={{ backgroundColor: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        petFoods?.doctorInfo?.animal_name
                          ? petFoods?.doctorInfo?.animal_name
                          : ""
                      }
                    />
                  </div>
                  <div className="addressFlex">
                    <div className="labelTxt">
                      <label className="keyQuestion">Address:</label>
                    </div>
                    <p className="text-para">
                      {petFoods?.doctorInfo?.address
                        ? `${
                            petFoods?.doctorInfo?.street_name
                              ? petFoods?.doctorInfo?.street_name + ","
                              : ""
                          } ${
                            petFoods?.doctorInfo?.landmark
                              ? petFoods?.doctorInfo?.landmark + ","
                              : ""
                          } ${petFoods?.doctorInfo?.address}`
                        : ""}
                    </p>
                    {/* <input
                      style={{ backgroundColor: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        petFoods?.doctorInfo?.address
                          ? `${
                              petFoods?.doctorInfo?.street_name
                                ? petFoods?.doctorInfo?.street_name + ","
                                : ""
                            } ${
                              petFoods?.doctorInfo?.landmark
                                ? petFoods?.doctorInfo?.landmark + ","
                                : ""
                            } ${petFoods?.doctorInfo?.address}`
                          : ""
                      }
                    /> */}
                  </div>
                  <div className="dateNdAgeFlex">
                    <div className="ageBox">
                      <label className="ageLab">Age:</label>
                      <input
                        style={{ backgroundColor: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          petFoods?.doctorInfo?.animal_age
                            ? petFoods?.doctorInfo?.animal_age
                            : ""
                        }
                      />
                    </div>
                    <div className="dateBox">
                      <label className="ageLab">Date:</label>
                      <input
                        style={{ backgroundColor: "#fff" }}
                        disabled
                        type="text"
                        className="form-control formSolution"
                        value={
                          petFoods?.doctorInfo?.date
                            ? formatDateToDdMmYyyy(petFoods?.doctorInfo?.date)
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="addressFlex">
                    <div className="labelTxt" style={{ width: "30%" }}>
                      <label className="keyQuestion">Reason / Symptoms:</label>
                    </div>
                    <input
                      style={{ backgroundColor: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        petFoods?.doctorInfo?.disease
                          ? petFoods?.doctorInfo?.disease
                          : ""
                      }
                    />
                  </div>

                  <div className="tableAndLandingBg">
                    <div className="backGroundPic"></div>
                    {Object.keys(petFoods)?.length > 0 &&
                      petFoods?.foodData?.length > 0 && (
                        <div className="innerTable">
                          <table className="modalTable">
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Prescription</th>
                                <th>Instruction</th>
                                <th>Quantity</th>
                                {/* <th>Dose</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {petFoods?.foodData.map((item, i) => {
                                return (
                                  <tr key={"petFoods" + i}>
                                    <td>{i + 1}</td>
                                    <td>
                                      {item?.product ? item?.product : ""}
                                    </td>
                                    <td>{item?.instruction}</td>
                                    <td>{item?.quantity}</td>
                                    {/* <td>
                                  {item?.prescription_frequency
                                    ? item?.prescription_frequency
                                    : ''}
                                </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>

                  {petFoods?.doctorInfo?.imageData && (
                    <div className="signatureContent">
                      <div className="sigBox">
                        <div className="line">
                          <img
                            src={`data:image/${petFoods?.doctorInfo?.imageType};base64,${petFoods?.doctorInfo?.imageData}`}
                            alt=""
                          />
                        </div>
                        <label className="belowTxt">SIGNATURE</label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="belowDetails">
                  <div className="imgWrapper">
                    <div className="row heightAuto">
                      <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                        <div className="txt">
                          <div className="innerTxt">Contact</div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-12 p-0">
                        <div className="allCOntact">
                          <div className="emailAndCall">
                            {/* <div className="callNnumber">
                              <img src={ic_call} alt="" />
                              <label className="numberInn">
                                +91 9509932234
                                {petFoods?.doctorInfo?.doctor_contact_number} 
                              </label>
                            </div> */}
                            <div className="emialInn">
                              <img src={ic_mail} alt="" />
                              <label className="numberInn">
                                info@animalsapp.in
                              </label>
                            </div>
                          </div>

                          {/* <div className="locationInn">
                            <img src={ic_location} alt="" />
                            <label className="locationTxt">
                              Animals App, Animalsomatic clinic Near Ravi Steel,
                              Kamde, Ratu Road, Ranchi, Jharkhand, India 835222
                              {`${petFoods?.doctorInfo?.city}`} 
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
            <div>
              <button
                type="button"
                onClick={() => {
                  orderNow();
                }}
                className="filled-btn"
                style={{ pointerEvents: "auto" }}
              >
                Order Now
              </button>
            </div>
            <div
              onClick={downLoadPdf}
              // style={{
              //   position: "absolute",
              //   bottom: "57%",
              //   left: "90%",
              //   fontSize: "25px",
              //   cursor: "pointer",
              // }}
            >
              <button type="button" className="btn-blank-green">
                Download
                <img src={ic_doc_download} className="ic_doc_download" alt="" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PetFoodModal;

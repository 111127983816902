import { useReduxStore } from "../customHooks/useReduxStore";

export async function getLocationName(latLng) {
  return await fetch(
    `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${Number(
      latLng.lat
    )}&lon=${Number(latLng.lng)}`,
    {}
  )
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      console.log("error,", error);
    });
}

// ============================ API functions WITHOUT Token ======================= //
export async function PostCallWithErrorResponse(url, requestBody) {
  var resp;

  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Access-Control-Allow-Credentials': "*"
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      throw error;
    });
}

export async function postCallWithFormData(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Access-Control-Allow-Credentials': "*"
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function simpleGetCallWithErrorResponse(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Access-Control-Allow-Credentials': "*"
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      console.log("catch error response from ApiService:,", error);
    });
}

export async function simpleGetCall(url, access_token) {
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      console.log("catch error,", error);
    });
}
// ============================ API functions WITH OUT Token END ======================= //

// ============================ API functions WITH Auth_Token START =======================
// GET API CALL
export async function simpleGetCallWithAuthToken(url) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
      // 'Access-Control-Allow-Credentials': "*"
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      console.log('"catch error from ApiService":,', error);
    });
}
// POST API CALL
export async function PostCallWithAuthToken(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      throw error;
    });
}
// PUT API CALL
export async function PutCallWithAuthToken(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      throw error;
    });
}

export async function PutCallWithAuthTokenAndFormData(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      throw error;
    });
}
// DELETE API CALL
export async function DeleteCallWithAuthToken(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    })
    .catch((error) => {
      throw error;
    });
}
// POST API CALL WITH FORMDATA
export async function postCallWithFormDataWithToken(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
// PUT API CALL WITH FORMDATA
export async function putCallWithFormDataWithToken(url, requestBody) {
  const access_token =
    localStorage.getItem("access_token") &&
    localStorage.getItem("access_token");
  var resp;
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      // 'Content-Type': 'application/json',
      Authorization: `${access_token}`,
      'Access-Control-Allow-Credentials': "*"
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

// ============================ API functions WITH Auth_Token END =======================

export async function PostCallWithErrorResponseReqBody(url, requestBody) {
  var resp;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function DeleteCallWithErrorResponseWithToken(url, requestBody) {
  var resp;
  // const [progress, setProgress] = useState(0);
  // const { customerData } = useContext(AppContext);

  return await fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token"),
    },
    // body: JSON.stringify({...customerData,...requestBody})
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function deleteWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "DELETE",

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",

      Authorization: "Token " + localStorage.getItem("auth_token"),
    },

    body: requestBody,
  }).then((response) => response.json());
}

export async function multipartPostCallWithErrorResponse(url, requestBody) {
  var resp;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",

      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("Token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function getWithAuthCallWithErrorResponse(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("Token"),
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function getWithRequestBodyWithErrorResponse(url, requestBody) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: "Token " + localStorage.getItem("USER_AUTH_TOKEN"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function postWithAuthCallWithErrorResponse(url, requestBody) {
  var resp;
  return await fetch(url, {
    method: "POST",
    // mode: "cors",
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("Token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function putMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      //'Content-Type': 'multipart/form-data',
      Authorization: "Token " + localStorage.getItem("USER_AUTH_TOKEN"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function postMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "POST",

    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      //  'Content-Type': 'multipart/form-data',
      Authorization: "Bearer " + localStorage.getItem("Token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

// export async function simplePostCall (url, requestBody) {
//   return await fetch(url, {
//     method: 'POST',
//     // mode: "cors",
//     headers: {
//       Accept: 'application/json'
//       // "Content-Type": "application/json",
//       // Origin: window.location.origin,
//       //'Access-Control-Allow-Credentials': "*"
//     },
//     withCredentials: true,
//     body: requestBody
//   })
//     .then(response => response.text())
//     .then(result => getResult(result))
//   //.then(data=>data.json());
// }

export async function simplePostCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage?.getItem("auth_token"),
    },
    withCredentials: true,
    body: JSON.stringify(requestBody),
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((err) => console.error("catch error,", err))
}
export async function multipartPostCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      //'Content-Type': 'multipart/form-data',
      // 'Authorization': 'Token ' + await AsyncStorage.getItem(AppStrings.TOKEN)
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function getWithAuthCall(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function postWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      // Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function putWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function postMultipartWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function putMultipartWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}
export async function updateProfile(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      // Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.token)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

//-------------------------------------
export async function getResult(data) {
  return JSON.parse(data.trim());
}
//-------------------------------------

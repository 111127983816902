import { configureStore } from '@reduxjs/toolkit'
import rootReducer, { rootReducerWithLogout } from './rootReducer'
import { persistStore } from 'redux-persist'

const store = configureStore({
  reducer: rootReducer,
  // reducer: rootReducerWithLogout,
  devTools: process.env.NODE_ENV !== 'production'
})

const persistor = persistStore(store)

export { store, persistor }

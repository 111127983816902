import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Subheader from '../../sharedComponent/Subheader';
import MediImg from "../../assets/images/medicinesImg.svg";
import plusIco from "../../assets/images/plusIcon.svg";
import minusIco from "../../assets/images/minusIcon.svg";
import failedStatus from "../../assets/images/failedStatus.svg";
import SideArrow from "../../assets/images/accordianArrow.svg";
import coupon from "../../assets/images/coupon.svg";
import Modal from 'react-bootstrap/Modal';
import GreenAxe from "../../assets/images/greenCrossAxe.svg";
import Bell from "../../assets/images/hurrayBell.svg";

const PetCart = () => {

    const [couponModal, setCouponModal] = useState(false);
    const [bell, setBell] = useState(false);

    return (
        <>
            <div className="medicineCart" id="cx-main">
                <Subheader />
                {/*Content Cards */}
                <div className="mainCartBox">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="cartTable">
                                <div className="scroller">
                                    <table className="table">
                                        <thead className='tHead'>
                                            <tr>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tBody'>
                                            <tr>
                                                <td>
                                                    <div className="imgFlex">
                                                        <img src={MediImg} className='MediImg' alt="" />
                                                        <label htmlFor="" className='Prdctname'>Pet Supplements for Dog</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="qntyBox">
                                                        <label htmlFor=""><img src={minusIco} alt="" /></label>
                                                        <label htmlFor="" className='value'>10</label>
                                                        <label htmlFor=""><img src={plusIco} alt="" /></label>
                                                    </div>
                                                </td>
                                                <td ><label className="rateTag">₹ 40:00</label></td>
                                                <td ><label className="rateTag">₹ 400:00</label></td>
                                                <td><img src={failedStatus} className='failedStatus' alt="" /></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="imgFlex">
                                                        <img src={MediImg} className='MediImg' alt="" />
                                                        <label htmlFor="" className='Prdctname'>Pet Supplements for Dog</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="qntyBox">
                                                        <label htmlFor=""><img src={minusIco} alt="" /></label>
                                                        <label htmlFor="" className='value'>10</label>
                                                        <label htmlFor=""><img src={plusIco} alt="" /></label>
                                                    </div>
                                                </td>
                                                <td ><label className="rateTag">₹ 40:00</label></td>
                                                <td ><label className="rateTag">₹ 400:00</label></td>
                                                <td><img src={failedStatus} className='failedStatus' alt="" /></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="imgFlex">
                                                        <img src={MediImg} className='MediImg' alt="" />
                                                        <label htmlFor="" className='Prdctname'>Pet Supplements for Dog</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="qntyBox">
                                                        <label htmlFor=""><img src={minusIco} alt="" /></label>
                                                        <label htmlFor="" className='value'>10</label>
                                                        <label htmlFor=""><img src={plusIco} alt="" /></label>
                                                    </div>
                                                </td>
                                                <td ><label className="rateTag">₹ 40:00</label></td>
                                                <td ><label className="rateTag">₹ 400:00</label></td>
                                                <td><img src={failedStatus} className='failedStatus' alt="" /></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="imgFlex">
                                                        <img src={MediImg} className='MediImg' alt="" />
                                                        <label htmlFor="" className='Prdctname'>Pet Supplements for Dog</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="qntyBox">
                                                        <label htmlFor=""><img src={minusIco} alt="" /></label>
                                                        <label htmlFor="" className='value'>10</label>
                                                        <label htmlFor=""><img src={plusIco} alt="" /></label>
                                                    </div>
                                                </td>
                                                <td ><label className="rateTag">₹ 40:00</label></td>
                                                <td ><label className="rateTag">₹ 400:00</label></td>
                                                <td><img src={failedStatus} className='failedStatus' alt="" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="couponCard">
                                <div className="headingFlex">
                                    <h1>Best Coupons For you</h1>
                                    <label htmlFor="">All Coupons <img src={SideArrow} alt="" /></label>
                                </div>
                                <div className="applyCouponBox">
                                    <h1><img src={coupon} alt="" />Apply Coupons</h1>
                                    {
                                        couponModal ? <span className='text-danger'>Remove</span> : <button className='applyBtn'
                                            onClick={() => setCouponModal(true)}>Apply</button>
                                    }

                                </div>
                                <div className="cartSummaryBox">
                                    <div className="headingFlex">
                                        <h1>Cart Summary</h1>
                                        <label htmlFor="">(4 Items )</label>
                                    </div>
                                    <div className="contentFlex">
                                        <div className="keyValueFlex">
                                            <label htmlFor="" className='key'>Subtotal</label>
                                            <label htmlFor="" className='value'>₹ 400:00</label>
                                        </div>
                                        <div className="keyValueFlex">
                                            <label htmlFor="" className='key'>Shipping</label>
                                            <label htmlFor="" className='value'>₹ 20:00</label>
                                        </div>
                                        <div className="keyValueFlex">
                                            <label htmlFor="" className='key'>Tax</label>
                                            <label htmlFor="" className='value'>₹ 0:00</label>
                                        </div>
                                    </div>
                                    <div className="bottomCard">
                                        <label htmlFor="" className='Total'>Total</label>
                                        <label htmlFor="" className='TotalAmnt'>₹ 400:00</label>
                                    </div>
                                </div>
                            </div>
                            <div className="checkoutBtn">
                                <Link to="/PetCheckout">
                                    <button className='checkBtn'>Proceed To Checkout</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* APply coupon Modal */}
            {
                couponModal ? <>
                    <Modal
                        size='md'
                        show={coupon}
                        onHide={() => setCouponModal(false)}
                        centered
                    >
                        <Modal.Body id='couponModalBody'>
                            <div className="modalCouponBox">
                                <div className="headingFlex">
                                    <h1 className='modalName'>Apply Coupon</h1>
                                    <label htmlFor="" className='CrossImg'
                                        onClick={() => setCouponModal(false)}
                                    >
                                        <img src={GreenAxe}
                                            alt="" />
                                    </label>
                                </div>
                                <div className="couponBoxBorder">
                                    <label htmlFor="" className='animalPer'>ANIMAL20%</label>
                                    <label htmlFor="" className='Check'>Check</label>
                                </div>
                                <div className="checkAndDiscount">
                                    <div className="checkboxIn">
                                        <input class="form-check-input" type="checkbox" value="" checked id="flexCheckDefault" />
                                    </div>
                                    <div className="discountBadge">
                                        <label className='innBadge' htmlFor='flexCheckDefault'>ANIMAL20%</label>
                                        <p className='paraPara'>Expires on : 31st December 2023 , 11:59 PM</p>
                                    </div>
                                    <div className="savingDetails">
                                        <p className='savePara'>Save ₹200</p>
                                        <p className='belowPaara'>₹200 off on minimum purchase of ₹2000.</p>
                                    </div>
                                </div>
                                <div className="checkAndDiscount">
                                    <div className="checkboxIn">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="discountBadge">
                                        <label className='innBadge' htmlFor='flexCheckDefault'>ANIMAL20%</label>
                                        <p className='paraPara'>Expires on : 31st December 2023 , 11:59 PM</p>
                                    </div>
                                    <div className="savingDetails">
                                        <p className='savePara'>Save ₹200</p>
                                        <p className='belowPaara'>₹200 off on minimum purchase of ₹2000.</p>
                                    </div>
                                </div>
                                <div className="checkAndDiscount">
                                    <div className="checkboxIn">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="discountBadge">
                                        <label className='innBadge' htmlFor='flexCheckDefault'>ANIMAL20%</label>
                                        <p className='paraPara'>Expires on : 31st December 2023 , 11:59 PM</p>
                                    </div>
                                    <div className="savingDetails">
                                        <p className='savePara'>Save ₹200</p>
                                        <p className='belowPaara'>₹200 off on minimum purchase of ₹2000.</p>
                                    </div>
                                </div>
                                <div className="belowBottom">
                                    <div className="savingsTxt">
                                        <p>Maximum Savings</p>
                                        <label htmlFor="">₹200 </label>
                                    </div>
                                    <div className="applyBox">
                                        <button className='applyBtn' onClick={() => {
                                            setBell(true)
                                            setCouponModal(false)
                                        }}>Apply</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </> : null
            }
            {/* Bell Modal */}
            {
                bell ?
                    <>
                        <Modal
                            size='sm'
                            show={bell}
                            onHide={() => setBell(false)}
                            centered
                        >
                            <Modal.Body >
                                <div className="bellBox">
                                    <img src={Bell} alt="" />
                                    <p>Coupon Applied Successfully !</p>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                    :
                    null
            }
        </>
    )
}

export default PetCart;

import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

export default function RotatingLoader () {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '20vh'
      }}
    >
      <RotatingLines
        strokeColor='#1d7c7c'
        strokeWidth='4'
        animationDuration='0.75'
        width='65'
        visible={true}
      />
    </div>
  )
}

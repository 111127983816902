import React, { useContext, useEffect, useState } from "react";
import yourLogo from "../../assets/images/logo.png";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landingicon.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passBox from "../../assets/images/icons/lock.svg";
import login_img from "../../assets/images/login_img.png";
import eye from "../../assets/images/icons/eye.svg";
import eyeHide from "../../assets/images/icons/hidePassword.svg";
import facebook from "../../assets/images/icons/Facebook.svg";
import apple from "../../assets/images/icons/apple.svg";
import google from "../../assets/images/icons/google.svg";
import doodleRed from "../../assets/images/icons/doodle-red.svg";
import doodleSmall from "../../assets/images/icons/doodle-small.svg";
import doodle from "../../assets/images/icons/doodle-5 2.svg";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import {
  PostCallWithErrorResponse,
  simpleGetCall,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { submitLoginForm } from "../../redux/Slices/authSlice";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import OtpTimer from "../../sharedComponent/OtpTimer";
import telephone1 from "../../assets/images/icons/telephone1.svg";
import animalAppQrCode from "../../assets/googleimages/animal-app-QR-code.png";
import playstore from "../../assets/googleimages/playstore.png";

const Login = () => {
  const [loginAction, setLoginAction] = useState("login_with_OTP");
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const {
    dispatch,
    isTimeExpired,
    setIsTimeExpired,
    validNumber,
    setBkdropLdr,
  } = useContext(AppContext);

  const { loading } = useSelector((state) => state.auth);
  const [loginOTPData, setLoginOTPData] = useState({
    phone_number: "",
    otp: "",
  });

  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    return () => {
      setIsTimeExpired(false);
    };
  }, []);

  const loginHandler = async (event) => {
    event.preventDefault();
    if (
      loginAction?.includes("login_with_OTP") &&
      !loginOTPData?.otp &&
      loginOTPData?.phone_number?.length !== 10
    ) {
      notifyError("Please request for OTP");
      return;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const loginPayLoad =
        loginAction === "login_with_email" ? loginFormData : loginOTPData;
      dispatch(submitLoginForm({ ...loginPayLoad, loginAction }))
        .then((res) => {
          const { data, error } = res.payload;
          const { success, message, token } = data?.json;
          if (!error) {
            if (success && success === true) {
              localStorage.setItem("access_token", token);
            } else if (success === false) {
              notifyError(message);
            }
          } else {
            notifyError("Something went wrong");
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
        });
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setLoginFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const requestOTP = async () => {
    if (loginOTPData?.phone_number?.length !== 10) {
      notifyError("Please enter valid mobile number");
      return;
    }

    setBkdropLdr(true);
    try {
      const res = await PostCallWithErrorResponse(
        ApiConfig.SEND_OTP_FOR_LOGIN,
        { phone_number: loginOTPData?.phone_number }
      );
      const { success } = res?.json;
      if (success) {
        notifySuccess("OTP sent your mobile number");
        setIsTimeExpired(true);
      }
    } catch (error) {
      console.log("catch error from request OTP API,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <div className="authMain loginMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} alt="" style={{ height: "60px" }} />
            <div className="signinForm">
              <h1 className="signInTxt">Sign in</h1>
              {/* <h6 className="signInPara">
                If you don’t have an account register
                <p className="mt-2 youCan">
                  You can{" "}
                  <span>
                    <Link to="/Registration" className="registerLink">
                      Register Here !
                    </Link>
                  </span>
                </p>
              </h6> */}
              <Form noValidate validated={validated} onSubmit={loginHandler}>
                {/* <div>
                  <label htmlFor="email_sign">
                    {" "}
                    <input type="radio" id="email_sign" /> Sign In with Email
                  </label>{" "}
                  <label htmlFor="mobile_sign">
                    {" "}
                    <input id="mobile_sign" type="radio" /> Sign In with Mobile
                    Number
                  </label>
                </div> */}
                {loginAction === "login_with_email" ? (
                  <>
                    <div className="inputBox">
                      <label htmlFor="" className="emailLabel">
                        Email
                      </label>
                      <img src={mailBox} className="mailImg" alt="" />
                      <input
                        required={
                          loginAction === "login_with_email" ? true : false
                        }
                        type="text"
                        className="form-control formInput"
                        placeholder="Enter your email address"
                        name="email"
                        onChange={onChangeHandler}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter valid email
                      </Form.Control.Feedback>
                    </div>
                    <div className="inputBox">
                      <label htmlFor="" className="emailLabel">
                        Password
                      </label>
                      <img src={passBox} className="mailImg" alt="" />
                      <img
                        src={showPassword ? eye : eyeHide}
                        onClick={() => {
                          if (showPassword) {
                            setShowPassword(false);
                          } else {
                            setShowPassword(true);
                          }
                        }}
                        className="eyeImg"
                        alt=""
                      />
                      <input
                        required={
                          loginAction === "login_with_email" ? true : false
                        }
                        type={showPassword ? "text" : "password"}
                        className="form-control formInput"
                        placeholder="Enter your password"
                        name="password"
                        onChange={onChangeHandler}
                      />
                      <Form.Control.Feedback type="invalid">
                        Password is required
                      </Form.Control.Feedback>
                      <div className="belowChecks">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label checkLbl"
                            for="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="forgetPass">
                          <Link to="/ForgotPassword" className="forgetLab">
                            Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div style={{ marginBottom: "20px", marginTop: "-39px" }}>
                      <img
                        onClick={() => {
                          setLoginAction("login_with_email");
                          setIsTimeExpired(false);
                          setLoginOTPData({
                            phone_number: "",
                            otp: "",
                          });
                        }}
                        src={ic_left_green_arrow}
                        alt=""
                      />
                    </div> */}
                    <div className="inputBox">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label htmlFor="" className="emailLabel">
                          Mobile Number
                        </label>
                        {!isTimeExpired && (
                          <button
                            type="button"
                            className="requestBtn"
                            // disabled={!forgotData?.phone_number ? true : false}
                            onClick={requestOTP}
                          >
                            Request OTP
                          </button>
                        )}

                        {/* <label htmlFor="" style={{ color: "#1D7C7C" }}>
                          Send OTP
                        </label> */}
                      </div>

                      <img src={telephone1} className="mailImg" alt="" />
                      <input
                        style={{ background: "#fff" }}
                        // required={
                        //   loginAction === "login_with_OTP" ? true : false
                        // }
                        required
                        type="tel"
                        className="form-control formInput"
                        placeholder="Enter your mobile number"
                        name="phone_number"
                        value={loginOTPData?.phone_number}
                        onChange={(e) => {
                          if (validNumber.test(e.target.value)) {
                            setLoginOTPData({
                              ...loginOTPData,
                              phone_number: e.target.value,
                            });
                          }
                        }}
                        // onChange={onChangeHandler}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your number
                      </Form.Control.Feedback>
                    </div>
                    {isTimeExpired && <OtpTimer requestOTP={requestOTP} />}
                    {/* {isTimeExpired && (
                    )} */}
                      <div className="inputBox">
                        <label htmlFor="" className="emailLabel">
                          OTP
                        </label>
                        <img src={passBox} className="mailImg" alt="" />
                        <input
                        required
                          // required={
                          //   loginAction === "login_with_OTP" ? true : false
                          // }
                          type="text"
                          className="form-control formInput"
                          placeholder="Enter OTP..."
                          name="otp"
                          value={loginOTPData?.otp}
                          onChange={(e) => {
                            if (
                              validNumber.test(e.target.value) &&
                              e.target.value.length < 7
                            ) {
                              setLoginOTPData({
                                ...loginOTPData,
                                otp: e.target.value,
                              });
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter OTP
                        </Form.Control.Feedback>
                        <div style={{justifyContent : "end"}} className="belowChecks">
                          {/* <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label checkLbl"
                              for="flexCheckDefault"
                            >
                              Remember me
                            </label>
                          </div> */}
                          {/* <div className="forgetPass">
                            <Link to="/ForgotPassword" className="forgetLab">
                              Forgot Password ?
                            </Link>
                          </div> */}
                        </div>
                      </div>
                  </>
                )}
                {/* Navigate To Get Started */}
                {/* {isTimeExpired ||
                  (loginAction === "login_with_email" || loginAction === "login_with_OTP" && ( */}
                <div className="login">
                  {/* <Link to="/Home" className="loginBtn"> */}
                  <button
                    type="submit"
                    className="loginBtn"
                    style={loading ? { backgroundColor: "#7fa4a4" } : {}}
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <ClipLoader color="#fff" size={30} />
                    ) : (
                      <span>Login</span>
                    )}
                  </button>
                  {/* </Link> */}
                </div>
                {/* {loginAction === "login_with_email" && (
                  <div
                    style={{
                      textAlign: "center",
                      color: "#1D7C7C",
                      marginTop: "25px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLoginAction("login_with_OTP");
                    }}
                  >
                    or continue with OTP
                  </div>
                )} */}
              </Form>

              {/* <div className="othermain">
                <p className="otherOptions">or continue with</p>
              </div>
              <div className="socialotherIcons">
                <img src={facebook} alt="" />
                <img src={apple} alt="" />
                <img src={google} alt="" />
              </div> */}
              <div className="download-app-container">
                <p>Download App </p>
                <div className="download-app-img">
                  <Link
                    target="_blank"
                    to="https://play.google.com/store/apps/details?id=com.animal.customer"
                  >
                    <img
                      className="google-play-store-img"
                      src={playstore}
                      alt="dr im"
                    />
                  </Link>

                  <div className="verticle-line"></div>
                  <Link>
                    <img className="qr-img" src={animalAppQrCode} alt="dr im" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="mobileNumber">
                <span className="callNumber">
                  <img src={teleCall} height="15" width="15" alt="" /> +91
                  9509932234
                </span>
              </div>
              <div className="insiderIcon">
                <img src={login_img} alt="" />
              </div>
              <div className="landingTxt">
                <div className="d-flex flex-column justify-content-start">
                  <h1>Sign in</h1>
                  <p>Lets get started !</p>
                </div>
                <div className="bottomBox d-flex flex-wrap justify-content-center align-items-center">
                  <Link to="/TermsAndCondition" className="anchTag">
                    Terms & Condition
                  </Link>
                  <Link to="/PrivacyPolicy" className="anchTag">
                    Privacy Policy
                  </Link>
                  <Link to="/ReturnAnRefund" className="anchTag">
                    Return & Refund Policy
                  </Link>
                  <Link to="/ShippingPolicy" className="anchTag">
                    Shipping Policy
                  </Link>
                  <Link to="/Contact" className="anchTag">
                    Contact Us
                  </Link>
                </div>
                <div className="d-flex flex-column justify-content-start privacy">
                <label htmlFor="" className="anchTag">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M10.08 11.0944C10.13 10.7644 10.24 10.4744 10.38 10.2244C10.52 9.97437 10.72 9.76437 10.97 9.60437C11.21 9.45437 11.51 9.38438 11.88 9.37438C12.11 9.38438 12.32 9.42438 12.51 9.50438C12.71 9.59438 12.89 9.71438 13.03 9.86438C13.17 10.0144 13.28 10.1944 13.37 10.3944C13.46 10.5944 13.5 10.8144 13.51 11.0344H15.3C15.28 10.5644 15.19 10.1344 15.02 9.74438C14.85 9.35438 14.62 9.01438 14.32 8.73438C14.02 8.45437 13.66 8.23438 13.24 8.07438C12.82 7.91438 12.36 7.84438 11.85 7.84438C11.2 7.84438 10.63 7.95437 10.15 8.18437C9.67 8.41437 9.27 8.71438 8.95 9.10437C8.63 9.49437 8.39 9.94437 8.24 10.4644C8.09 10.9844 8 11.5244 8 12.1044V12.3744C8 12.9544 8.08 13.4944 8.23 14.0144C8.38 14.5344 8.62 14.9844 8.94 15.3644C9.26 15.7444 9.66 16.0544 10.14 16.2744C10.62 16.4944 11.19 16.6144 11.84 16.6144C12.31 16.6144 12.75 16.5344 13.16 16.3844C13.57 16.2344 13.93 16.0244 14.24 15.7544C14.55 15.4844 14.8 15.1744 14.98 14.8144C15.16 14.4544 15.27 14.0744 15.28 13.6644H13.49C13.48 13.8744 13.43 14.0644 13.34 14.2444C13.25 14.4244 13.13 14.5744 12.98 14.7044C12.83 14.8344 12.66 14.9344 12.46 15.0044C12.27 15.0744 12.07 15.0944 11.86 15.1044C11.5 15.0944 11.2 15.0244 10.97 14.8744C10.72 14.7144 10.52 14.5044 10.38 14.2544C10.24 14.0044 10.13 13.7044 10.08 13.3744C10.03 13.0444 10 12.7044 10 12.3744V12.1044C10 11.7544 10.03 11.4244 10.08 11.0944ZM12 2.23438C6.48 2.23438 2 6.71438 2 12.2344C2 17.7544 6.48 22.2344 12 22.2344C17.52 22.2344 22 17.7544 22 12.2344C22 6.71438 17.52 2.23438 12 2.23438ZM12 20.2344C7.59 20.2344 4 16.6444 4 12.2344C4 7.82438 7.59 4.23438 12 4.23438C16.41 4.23438 20 7.82438 20 12.2344C20 16.6444 16.41 20.2344 12 20.2344Z"
                    fill="#F2FBFA"
                  />
                </svg>
                {/* 2022 Animal App, Inc. */}
                <label >   {new Date().getFullYear()} A Venture By ANIMALSOMATIC HEALTH CLINIC PRIVATE LIMITED   </label>
              </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

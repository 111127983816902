import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import AppState from './context/AppState'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import RotatingLoader from './sharedComponent/RotatingLoader'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Suspense fallback={<RotatingLoader />}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppState>
            <App />
          </AppState>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </Suspense>
)

// production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  console.warn = () => {}
  console.info = () => {}
  console.message = () => {}
  console.verbose = () => {}
}

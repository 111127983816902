import React from 'react'

const ShippingPolicy = () => {
    return (
        <>
            <div className="authMain">
                <div className="tAcPage">
                    <>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    textAlign: "center",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 27, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    Shipping &amp; Delivery Policy&nbsp;
                                </span>
                            </p>
                        </div>
                        <p
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "175%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif'
                            }}
                        >
                            <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                &nbsp;
                            </span>
                        </p>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    Shipping &amp; Delivery Policy for Animalsomatic Clinic - The Animal's
                                    App
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    Last updated: [6th-Feb-2024]
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    Thank you for choosing Animalsomatic Clinic's services through The
                                    Animal's App. This Shipping &amp; Delivery Policy outlines our
                                    procedures regarding the shipping and delivery of products ordered
                                    through our platform.
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    1. Shipping Methods
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    1.1 Delivery Partners:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        We partner with trusted delivery services to ensure timely and
                                        reliable shipping of products to our customers.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    1.2 Shipping Locations:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        We offer shipping within India. Additional charges may apply for
                                        international shipping.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    2. Processing Time
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    2.1 Order Processing:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Orders are typically processed within [7] business days after payment
                                        confirmation.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    2.2 Service Appointments:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Appointment bookings are confirmed instantly or within [1] hours of
                                        submission, depending on availability.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    3. Shipping Costs
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    3.1 Shipping Fees:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Shipping fees are calculated based on the destination, package weight,
                                        and selected shipping method.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    3.2 Free Shipping:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        We may offer free shipping promotions for orders meeting specific
                                        criteria, such as minimum order value.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    4. Delivery Timeframes
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    4.1 Domestic Shipping:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Estimated delivery times for domestic orders are typically [7] to [9]
                                        business days after dispatch, depending on the destination.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    4.2 International Shipping:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        International delivery times vary depending on the destination and
                                        customs clearance processes. Customers will be provided with estimated
                                        delivery times during checkout.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    5. Tracking Orders
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    5.1 Order Tracking:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Customers will receive a tracking number via email or SMS once their
                                        order is dispatched. This allows them to track the status of their
                                        delivery.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    5.2 Appointment Notifications:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Customers will receive confirmation notifications for booked
                                        appointments, along with reminders as the appointment date approaches.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    6. Delivery Attempts and Re-Delivery
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    6.1 Delivery Attempts:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Delivery attempts will be made as per the courier's standard
                                        procedures. Customers may be contacted to arrange delivery if
                                        unsuccessful on the first attempt.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    6.2 Re-Delivery Charges:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Additional charges may apply for re-delivery attempts due to customer
                                        unavailability or incorrect address information provided.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    7. Address Accuracy
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    7.1 Customer Responsibility:
                                </span>
                            </p>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm",
                                marginLeft: "18.0pt",
                                marginRight: "0cm"
                            }}
                        >
                            <ul style={{ listStyleType: "undefined", marginLeft: 8 }}>
                                <li>
                                    <span style={{ lineHeight: "115%", fontFamily: "Roboto" }}>
                                        Customers are responsible for providing accurate shipping and delivery
                                        information during checkout or appointment booking to ensure
                                        successful delivery.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            style={{
                                margin: "0cm",
                                marginBottom: ".0001pt",
                                lineHeight: "115%",
                                fontSize: 15,
                                fontFamily: '"Arial",sans-serif',
                                border: "none #D9D9E3 1.0pt",
                                padding: "0cm 0cm 0cm 0cm"
                            }}
                        >
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    8. Contact Us
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm",
                                    borderBottom: "0cm none #D9D9E3"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    If you have any questions or concerns regarding our Shipping &amp;
                                    Delivery Policy, please contact us at [support@animalsapp.in] or [ +91
                                    9509932234].
                                </span>
                            </p>
                            <p
                                style={{
                                    margin: "0cm",
                                    marginBottom: "15.0pt",
                                    lineHeight: "175%",
                                    fontSize: 15,
                                    fontFamily: '"Arial",sans-serif',
                                    marginTop: "15.0pt",
                                    marginRight: "0cm",
                                    marginLeft: "0cm",
                                    border: "none",
                                    padding: "0cm"
                                }}
                            >
                                <span style={{ fontSize: 16, lineHeight: "175%", fontFamily: "Roboto" }}>
                                    By using The Animal's App, you agree to abide by our Shipping &amp;
                                    Delivery Policy.
                                </span>
                            </p>
                        </div>
                    </>

                </div>
            </div>
        </>
    )
}

export default ShippingPolicy;

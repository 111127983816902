import React from 'react'

const NoDataMsg = ({ MSG }) => {
  return (
    <div style={{ color: '#ef3535', textAlign: 'center', margin: '10%' }}>
      {MSG ? MSG : 'No Data Found'}
    </div>
  )
}

export default NoDataMsg

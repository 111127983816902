import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  cartList: [],
  cartItemCount: 0
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cartList = action.payload
    },
    removeFromCart: (state, action) => {
      state.cartList = action.payload
    },
    increaseCartCount: (state, action) => {
      state.cartItemCount = action.payload
    },
    decreaseCartCount: (state, action) => {
      state.cartItemCount = action.payload
    }
  }
})

export const {
  addToCart,
  removeFromCart,
  increaseCartCount,
  decreaseCartCount
} = cartSlice.actions

export default cartSlice.reducer

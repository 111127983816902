import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext'
import { simpleGetCallWithAuthToken } from '../api/ApiServices'
import { ApiConfig } from '../api/ApiConfig'
import { useReduxStore } from '../customHooks/useReduxStore'
import moment from 'moment'
import NoDataMsg from './NoDataMsg'
import RotatingLoader from './RotatingLoader'

const Notification = () => {
  // DATA FROM REDUX STORE
  const { customer_id } = useReduxStore()
  // DATA FROM REDUX STORE
  const {
    sidebar,
    setSidebar,
    notificationCount,
    setNotificationCount,
    notifications,
    setNotifications,
    socket,
    setBkdropLdr
  } = useContext(AppContext)
  //   FETCH_NOTIFICATION_LIST
  const notificationsIdsArr = notifications ? notifications.map(ele => ele.notification_id) : []
  // READ_NOTIFICATION
  const readNotification = notification_ids => {
    socket &&
      socket.emit('notification read', { notification_id: notification_ids })
  }


  return (
    <>
      <div id='cx-main' style={{ padding: '20px' }}>
        <div className='notificationWrapper'>
          {
            // loader ? <RotatingLoader /> :
             <div className='insideTable'>
              
              {notifications && notifications.length > 0 ? (
                <table className='tableNoti'>
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Name</th>
                      <th>Message</th>
                      <th>Date / Time</th>
                      <th><span 
                      onClick={() => {
                        readNotification(notificationsIdsArr)
                        setTimeout(() => {
                          setNotifications([])
                          setNotificationCount(0)
                          setBkdropLdr(false)
                        }, 1000)
                      }}
                      >Mark as Read All</span></th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((item, idx) => {
                      return (
                        <tr key={item?.notification_id}>
                          <td>{idx + 1}</td>
                          <td>{item?.notification_sender_name}</td>
                          <td>
                            <div
                              className='scrollerHeight text-truncate'
                              style={{ maxWidth: '400px' }}
                            >
                              {item?.notification_text}
                            </div>
                          </td>
                          <td>
                           { item?.notification_date && moment(item?.notification_date).format('DD-MM-YYYY') + ','}
                            {' '}
                            {item?.notification_time && moment(item?.notification_time, 'HH:mm:ss').format(
                              'hh:mm A'
                            )}
                          </td>
                          <td>
                            {' '}
                            <span
                              onClick={() => {
                                setBkdropLdr(true)
                                readNotification([item?.notification_id])
                                setTimeout(() => {
                                  const filteredData = notifications.filter(innerItem => innerItem.notification_id !== item?.notification_id)
                                  setNotifications(filteredData)
                                  setNotificationCount(filteredData.length)
                                  setBkdropLdr(false)
                                }, 1000)
                              }}
                            >
                              Mark as read
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <NoDataMsg MSG={'No Notifications found'} />
              )}
            </div>
          }

        </div>
      </div>
    </>
  )
}

export default Notification

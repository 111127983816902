import React, { useContext, useEffect, useState } from "react";
import ic_location_light from "../../assets/images/ic_location_light.svg";
import ic_location_green from "../../assets/images/ic_location_green.svg";
import ic_star_active from "../../assets/images/ic_star_active.svg";
import ic_right_arrow_white from "../../assets/images/ic_right_arrow_circle.svg";
import ic_complete from "../../assets/images/ic_complete.svg";
import ic_progress from "../../assets/images/ic_progress.svg";
import ic_start from "../../assets/images/ic_start.svg";

import ambulance_img from "../../assets/images/ambulance_img.png";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";
import RotatingLoader from "../../sharedComponent/RotatingLoader";

const RecentAmbulanceBookings = () => {
  const navigate = useNavigate();
  const { customer_id } = useReduxStore();

  // DATA FROM CONTEXT
  const { setEventKey } = useContext(AppContext);
  const [filterData, setFilterData] = useState({
    search: "",
    lat: "",
    lng: "",
    //     lat: 18.5827328,
    // lng: 73.7017856
    // lat: 19.897911,
    // lng: 75.36191
  });

  const [validated, setValidated] = useState(false);
  const [ambulanceList, setAmbulanceList] = useState([]);
  const [completedAmbulanceList, setCompletedAmbulanceList] = useState([]);
  const [reviewData, setReviewData] = useState({
    isOpen: false,
    customer_id: customer_id,
    ambulance_id: "",
    ambulance_driver_id: "",
    rating: 0,
    review_title: "",
    review: "",
  });

  useEffect(() => {
    fetchAmblanceList();
    fetchCompletedAmblanceList();
  }, [filterData]);

  useEffect(() => {
    fetchCompletedAmblanceList();
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setFilterData({
        ...filterData,
        lat: latitude ? latitude : "",
        lng: longitude ? longitude : "",
      });
    });
  }, []);

  const fetchAmblanceList = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_AMBULANCE_LIST}?km=${3000}&lat=${
          filterData.lat
        }&lng=${filterData.lng}&ambulance_driver_name=${filterData.search}`
      );

      const { success, data } = res.json;
      if (success && data) {
        setAmbulanceList(data);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  const [ldr, setLdr] = useState(false);
  const fetchCompletedAmblanceList = async () => {
    try {
      setLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_COMPLETED_AMBULANCE_LIST}?customer_id=${customer_id}`
      );

      const { success, message } = res.json;
      if (success && message) {
        setCompletedAmbulanceList(message);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setLdr(false);
    }
  };

  // ADD_AMBULANCE_RATING

  const [ratingPayload, setRatingPayload] = useState({
    customer_id: customer_id,
    ambulance_id: "",
    ambulance_driver_id: "",
    rating: 1,
    review_title: "",
    review: "",
  });

  const submitRatingAndRaview = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      try {
        const res = await PostCallWithAuthToken(
          ApiConfig.ADD_AMBULANCE_RATING,
          ratingPayload
        );
        const { success, message } = res?.json;
        if (success) {
          notifySuccess(message);
          setReviewData({
            ...reviewData,
            isOpen: false,
          });
          const filterList =
            completedAmbulanceList &&
            completedAmbulanceList.filter((ele) => {
              return ele.ambulance_driver_id !==
                ratingPayload?.ambulance_driver_id
                ? {
                    ...ele,
                    rating_and_review_id: true,
                  }
                : ele;
            });
          filterList && setCompletedAmbulanceList(filterList);
        } else {
          notifyError(message);
        }
      } catch (error) {
        console.log("catch error,", error);
      }
    }
  };

  return (
    <div id="RequestAmbulancComp" className="appointment-details-wrapper">
      <div className="wrapper-cards" style={{ color: "#1D7C7C" }}>
        Recent Bookings
      </div>
      <div>
        {/* <div className="ambulance-card-wrapper">
          <div className="row">
            {ambulanceList &&
              ambulanceList.length > 0 &&
              ambulanceList.map((item, idx) => {
                return (
                  <div
                    className="col-md-4 mb-3"
                    key={item?.ambulance_driver_id}
                  >
                    <div className="ambulance-card">
                      <div className="img">
                        <img
                          src={
                            item?.vehicle_image_path
                              ? item?.vehicle_image_path
                              : ""
                          }
                          onError={(e) => {
                            e.target.src = ambulance_img;
                          }}
                          alt=""
                        />
                      </div>
                      <div className="pharma-name-wrapper">
                        <div className="left">
                          <label htmlFor="">
                            {item?.ambulance_driver_name}
                          </label>
                        </div>
                        <div className="right">
                          {item?.review && (
                            <label htmlFor="">
                              <img src={ic_star_active} alt="" />
                              {item?.review && item?.review[0]?.rating}
                            </label>
                          )}
                          <p>₹{item?.per_km_charge} / km</p>
                        </div>
                      </div>
                      <button
                        className="book-btn"
                        onClick={() =>
                          {
                            navigate(`/AmbulanceBook/${item.ambulance_driver_id}`)
                          }
                        }
                      >
                        Book Now <img src={ic_right_arrow_white} alt="" />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div> */}
      </div>

      {/* ============ Ambulance & Map Details ============= */}
      {ldr ? (
        <RotatingLoader />
      ) : (
        <div className="map-with-details-wrapper">
          {completedAmbulanceList &&
            completedAmbulanceList.length > 0 &&
            completedAmbulanceList.map((item, idx) => {
              const imagePath = item?.vehicle_image_path
                ? item?.vehicle_image_path
                : null;
              return (
                <div
                  //   style={{ cursor: "pointer" }}
                  key={"completedAmbulanceList" + idx}
                >
                  <div className="ambulance-date-wrapper">
                    <label htmlFor="">{item?.date}</label>
                    <div className="line"></div>
                  </div>

                  <div className="wrapper-cards">
                    <div className="left-amb-img">
                      <img src={imagePath ? imagePath : ambulance_img} alt="" />
                    </div>
                    <div className="row details-amb">
                      {/*
                      <div className="col-md-3 ">
                      <p className="key">David Pharmacy</p>
                      <p className="value">Vishrantwadi</p>
                    </div>
                    */}

                      <div className="col-md-3 ">
                        <p className="key">Driver Name</p>
                        <p className="value">{item?.ambulance_driver_name}</p>
                      </div>
                      <div className="col-md-3 ">
                        <p className="key">Contact No.</p>
                        <p className="value">
                          {item?.ambulance_driver_contact_no}
                        </p>
                      </div>
                      <div className="col-md-3 ">
                        <p className="key">Vehicle No.</p>
                        <p className="value">{item?.vehicle_no}</p>
                      </div>

                      <div className="col-md-3 ">
                        <p className="key">Animal</p>
                        <p className="value">{item?.animal_name}</p>
                      </div>
                      <div className="col-md-3 ">
                        <p className="key">Fees</p>
                        <p className="value">
                          ₹{item?.total_charges}{" "}
                          {/*<img src={ic_complete} className="ms-1" alt="" />*/}
                        </p>
                      </div>
                      {/*
                        <div className="col-md-3 ">
                        <p className="key">ETA</p>
                        <p className="value">Reached at 09:58s</p>
                       </div>
                      */}

                      {item?.status && (
                        <div className="col-md-3 ">
                          <p className="key">Status</p>
                          <p className="value">
                            {/*<img src={ic_complete} className="me-1" alt="" /> */}

                            {item?.status}
                          </p>
                        </div>
                      )}
                      <div className="col-12 ">
                        <p className="key">Description</p>
                        <p className="value">
                          {item?.ambulance_booking_reason}
                        </p>
                      </div>
                      <button
                      style={{
                        border : "none",
                        backgroundColor : "#1d7c7c",
                        maxWidth : "180px",
                        color : "#fff",
                        padding : "10px",
                        borderRadius : "10px"
                      }}
                        onClick={() => {
                          navigate("/TrackAmbulance");
                          sessionStorage.setItem(
                            "ambulance_Request_id",
                            item?.ambulance_Request_id
                          );
                        }}
                      >
                        Track Ambulance
                      </button>
                      {item?.review && item?.rating && (
                        <div
                          className="col-12"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <p className="key">Ratings & Review</p>
                          <div className="review-details">
                            <div className="heading-rating">
                              <div className="left mb-2">
                                {item?.review && (
                                  <label htmlFor="" className="value">
                                    {item?.review}
                                  </label>
                                )}
                              </div>
                              {item?.rating && (
                                <div className="right">
                                  <img src={ic_star_active} alt="" />{" "}
                                  <span>{item?.rating}</span>
                                </div>
                              )}
                            </div>
                            {/*<div className="value" style={{ fontSize: "14px" }}>
                        Ut enim ad minima veniam, quis nostrum exercitationem
                        ullam corporis suscipit laboriosam, nisi ut al Ut enim
                        ad minima veniam, quis nostrum exercitationem ullam
                        corporis suscipit laboriosam, nisi ut al
                      </div>*/}
                          </div>
                        </div>
                      )}
                      {item?.status == "Completed" &&
                        !item?.rating_and_review_id && (
                          <div
                            className="review-write-btn-wrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              setReviewData({
                                ...reviewData,
                                isOpen: true,
                              });
                              setRatingPayload({
                                ...ratingPayload,
                                ambulance_id: item?.ambulance_id,
                                ambulance_driver_id: item?.ambulance_driver_id,
                                customer_id: customer_id,
                              });
                            }}
                          >
                            <button type="button">Write a Review...</button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}

          {reviewData.isOpen && (
            <div className="wrapper-cards mt-3 d-block">
              <Form
                noValidate
                validated={validated}
                onSubmit={submitRatingAndRaview}
              >
                <h6>Write a Review</h6>
                <div className="row mt-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="" className="key">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title goes here..."
                      required
                      onChange={(e) => {
                        setRatingPayload({
                          ...ratingPayload,
                          review_title: e.target.value,
                        });
                      }}
                    />
                    <div className="invalid-feedback">Title is required</div>
                  </div>
                  <div className="col-md-6 mb-3 text-end">
                    <Link className="ms-2">
                      <img
                        src={
                          ratingPayload?.rating == 1
                            ? ic_star_active
                            : ic_star_active
                        }
                        alt=""
                        onClick={() => {
                          setRatingPayload({
                            ...ratingPayload,
                            rating: 1,
                          });
                        }}
                      />
                    </Link>
                    <Link className="ms-2">
                      <img
                        src={
                          ratingPayload?.rating >= 2 ? ic_star_active : ic_start
                        }
                        alt=""
                        onClick={() => {
                          setRatingPayload({
                            ...ratingPayload,
                            rating: 2,
                          });
                        }}
                      />
                    </Link>
                    <Link className="ms-2">
                      <img
                        src={
                          ratingPayload?.rating >= 3 ? ic_star_active : ic_start
                        }
                        onClick={() => {
                          setRatingPayload({
                            ...ratingPayload,
                            rating: 3,
                          });
                        }}
                        alt=""
                      />
                    </Link>
                    <Link className="ms-2">
                      <img
                        src={
                          ratingPayload?.rating >= 4 ? ic_star_active : ic_start
                        }
                        onClick={() => {
                          setRatingPayload({
                            ...ratingPayload,
                            rating: 4,
                          });
                        }}
                        alt=""
                      />
                    </Link>
                    <Link className="ms-2">
                      <img
                        src={
                          ratingPayload?.rating >= 5 ? ic_star_active : ic_start
                        }
                        onClick={() => {
                          setRatingPayload({
                            ...ratingPayload,
                            rating: 5,
                          });
                        }}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="" className="key">
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write your review"
                      required
                      onChange={(e) => {
                        setRatingPayload({
                          ...ratingPayload,
                          review: e.target.value,
                        });
                      }}
                    />
                    <div className="invalid-feedback">
                      Description is required
                    </div>
                  </div>

                  <div className="btn-wrapper">
                    <>
                      <button
                        type="button"
                        className="btn-blank-green"
                        onClick={() => {
                          setReviewData({
                            ...reviewData,
                            isOpen: false,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-blank-green"
                        style={{
                          backgroundColor: "#1D7C7C",
                          color: "#E9F5FB",
                        }}
                      >
                        Submit
                      </button>
                    </>
                  </div>
                </div>
              </Form>
            </div>
          )}

          {/*
              <div className="ambulance-date-wrapper">
              <label htmlFor="">21 July, 2023</label>
              <div className="line"></div>
            </div>

            <div className="wrapper-cards">
              <div className="left-amb-img">
                <img src={ambulance_img} alt="" />
              </div>
              <div className="row details-amb">
                <div className="col-md-3 ">
                  <p className="key">David Pharmacy</p>
                  <p className="value">Vishrantwadi</p>
                </div>
                <div className="col-md-3 ">
                  <p className="key">Driver Name</p>
                  <p className="value">Ronald Riles</p>
                </div>
                <div className="col-md-3 ">
                  <p className="key">Contact No.</p>
                  <p className="value">+91 999 999 9999</p>
                </div>
                <div className="col-md-3 ">
                  <p className="key">Vehicle No.</p>
                  <p className="value">MH-12-5022</p>
                </div>

                <div className="col-md-3 ">
                  <p className="key">Animal</p>
                  <p className="value">Bruno | Cat</p>
                </div>
                <div className="col-md-3 ">
                  <p className="key">Fees</p>
                  <p className="value">
                    ₹100 <img src={ic_complete} className="ms-1" alt="" />
                  </p>
                </div>
                <div className="col-md-3 ">
                  <p className="key">ETA</p>
                  <p className="value">Reached at 09:58s</p>
                </div>
                <div className="col-md-3 ">
                  <p className="key">Status</p>
                  <p className="value">
                    <img src={ic_complete} className="me-1" alt="" />
                    Complete
                  </p>
                </div>
                <div className="col-12 ">
                  <p className="key">Description</p>
                  <p className="value">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque sdasda, laudantium,
                  </p>
                </div>
                <div className="col-12">
                  <p className="key">Ratings & Review</p>
                  <div className="review-details">
                    <div className="heading-rating">
                      <div className="left mb-2">
                        <label htmlFor="" className="value">
                          Best Service
                        </label>
                      </div>
                      <div className="right">
                        <img src={ic_star_active} alt="" /> <span>4.1</span>
                      </div>
                    </div>
                    <div className="value" style={{ fontSize: "14px" }}>
                      Ut enim ad minima veniam, quis nostrum exercitationem ullam
                      corporis suscipit laboriosam, nisi ut al Ut enim ad minima
                      veniam, quis nostrum exercitationem ullam corporis suscipit
                      laboriosam, nisi ut al
                    </div>
                  </div>
                </div>
              </div>
            </div>
          */}
        </div>
      )}
    </div>
  );
};

export default RecentAmbulanceBookings;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: {}
}

const resetPassword = createSlice({
  name: 'root',
  initialState,
  reducers: {
    forgotPassword: (state, action) => {
      state.userData = action.payload
    }
  }
})

export const { forgotPassword } = resetPassword.actions
export default resetPassword.reducer

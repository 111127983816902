import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'
import authReducer from '../redux/Slices/authSlice'
import passwordSlice from '../redux/Slices/resetPasswordSlice'
import cartReducer from '../redux/Slices/cartSlice'
import stateTransform from './stateTransForm'

const rootPersistConfig = {
  key: 'root'
}

const authPersistConfig = {
  key: 'login',
  storage
  // transforms: [stateTransform]
}

const passwordPersistConfig = {
  key: 'password',
  storage: storageSession
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  password: persistReducer(passwordPersistConfig, passwordSlice),
  cart: cartReducer
})

export const rootReducerWithLogout = (state, action) => {
  if (action.type === 'auth/logout') {
    // Clear the persisted state when logout is dispatched
    state = undefined
  }
  return rootReducer(state, action)
}

export default rootReducer

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import Accordion from "react-bootstrap/Accordion";
import MultiRangeSlider from "multi-range-slider-react";
import FilterImg from "../../assets/images/filterIcon.svg";
import CouponBadge from "../../assets/images/redCouponBadge.svg";
import Pedigree from "../../assets/images/pedigreeImg.svg";
import heartBorder from "../../assets/images/heartBorder.svg";
import rightBin from "../../assets/images/rightCheckBin.svg";
import dustbinImg from "../../assets/images/dustbinImg.svg";
import BlueHeart from "../../assets/images/blueFilledHeart.svg";
import ic_rating_start from "../../assets/images/ic_rating_start.svg";
import Dropdown from "react-bootstrap/Dropdown";
import {
  PostCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { AppContext } from "../../context/AppContext";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import { notifySuccess } from "../../ToastMessages/notify";
import { increaseCartCount } from "../../redux/Slices/cartSlice";
import featured from "../../assets/images/featured.svg";

const PetShops = () => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(45);
  const [like1, setlike1] = useState(false);
  const [like2, setlike2] = useState(false);
  const [like3, setlike3] = useState(false);
  const [like4, setlike4] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);

  // DATA FROM REDUX STORE
  const { customer_id, cartItemCount } = useReduxStore();
  // DATA FROM REDUX STORE

  //   DATA FROM CONTEXT
  const {
    petShopPayLoad,
    setPetShopPayLoad,
    currentPage,
    setCurrentPage,
    setBkdropLdr,
    dispatch,
  } = useContext(AppContext);
  console.log("petShopPayLoad,", petShopPayLoad);

  const [filtereData, setFiltereData] = useState({
    animalGrooming: [],
    animalTypes: [],
    productType: [],
    brands: [],
  });
  console.log("filtereData,", filtereData);

  useEffect(() => {
    fetchFilterData();
  }, []);

  const fetchFilterData = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        ApiConfig.FETCH_PET_SHOP_FILTER
      );
      console.log("res,", res);
      const { result, data } = res?.json;
      if (result && data) {
        setFiltereData({
          animalGrooming: data?.animal_groomings ? data?.animal_groomings : [],
          animalTypes: data?.animals_type ? data?.animals_type : [],
          productType: data?.product_types ? data?.product_types : [],
          brands: data?.product_brands ? data?.product_brands : [],
        });
      }
    } catch (error) {
      console.log("catch error:,", error);
    }
  };
  const [petShopList, setPetShopList] = useState([]);
  console.log("petShopList,", petShopList);
  const [currentPageData, setCurrentPageData] = useState({});
  //   console.log('currentPageData,', currentPageData)
  useEffect(() => {
    setCurrentPage(1);
    fetchPetShopList();
  }, [petShopPayLoad]);

  const [isLoading, setIsLoading] = useState(false);

  // pet-products
  const fetchPetShopList = async () => {
    try {
      setIsLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.FETCH_PET_SHOP_LIST,
        petShopPayLoad
      );
      const { result, data, count, current_page, total_pages, last_page } =
        res?.json;
      if (result) {
        setPetShopList(data);
        setCurrentPageData({
          count,
          current_page,
          total_pages,
          last_page,
        });
      } else {
        setPetShopList([]);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentPage > 1) {
      fetchMorePetShops();
    }
  }, [currentPage]);

  const fetchMorePetShops = async () => {
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.FETCH_PET_SHOP_LIST,
        {...petShopPayLoad, page : currentPage}
      );
      const { result, data, count, current_page, total_pages, last_page } =
        res?.json;
      if (result) {
        setPetShopList((prev) => [...prev, ...data]);
        setCurrentPageData({
          count,
          current_page,
          total_pages,
          last_page,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const addToBagCart = async (
    product_id,
    product_rate,
    pet_shop_id,
    entity_type_id
  ) => {
    const cartPayLoad = {
      customer_id: customer_id,
      product_id: product_id,
      product_quantity: 1,
      product_rate: product_rate,
      shop_id: pet_shop_id,
      entity_type_id: entity_type_id,
    };
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        dispatch(increaseCartCount(cartItemCount + 1));
        const tempArr = petShopList.map((item, i) => {
          return item?.product_id === product_id
            ? { ...item, customer_card_id: true }
            : item;
        });
        setPetShopList(tempArr);
        notifySuccess("Added to cart successfully");
      }
    } catch (error) {
      console.log("catch error:", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  //   ADD_TO_PET_FAVORITE
  const addToFavourite = async (product_id, entity_type_id, actionType) => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        customer_id: customer_id,
        product_id: product_id,
        entity_type_id: entity_type_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_PET_FAVORITE,
        payLoad
      );
      const { result, message } = res?.json;
      if (result) {
        if (actionType === "like") {
          const tempArr = petShopList.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: true }
              : item;
          });
          setPetShopList(tempArr);
        } else if (actionType === "dislike") {
          const tempArr = petShopList.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: false }
              : item;
          });
          setPetShopList(tempArr);
        }
      }
    } catch (error) {
      console.log("catch Error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <div className="medicinesList" id="cx-main">
        <Subheader />
        {/* main Cards Content */}
        <div className="row">
          {/* Left Menu's Accordians */}
          <div className="col-md-3 colmain3">
            <div id="accordiansTab">
              <div className="mainHeadTxt">
                <h1 className="headName">Filter</h1>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  {filtereData?.animalGrooming &&
                  filtereData?.animalGrooming.length > 0 ? (
                    <Accordion.Header>Animal Grooming</Accordion.Header>
                  ) : null}

                  <Accordion.Body>
                    <div className="checksPortion">
                      {filtereData?.animalGrooming &&
                        filtereData?.animalGrooming.map((item, idx) => {
                          return (
                            <div
                              class="form-check checkNlabel"
                              key={item?.animal_grooming_id + idx}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={`flexCheckDefault${item?.animal_grooming}${idx}`}
                                checked={petShopPayLoad?.animal_grooming_id.includes(
                                  item?.animal_grooming_id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setPetShopPayLoad({
                                      ...petShopPayLoad,
                                      animal_grooming_id: [
                                        ...petShopPayLoad?.animal_grooming_id,
                                        item?.animal_grooming_id,
                                      ],
                                    });
                                  } else {
                                    let newArr =
                                      petShopPayLoad?.animal_grooming_id.filter(
                                        (innerItem, i) =>
                                          innerItem !== item?.animal_grooming_id
                                      );
                                    setPetShopPayLoad({
                                      ...petShopPayLoad,
                                      animal_grooming_id: newArr,
                                    });
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                for={`flexCheckDefault${item?.animal_grooming}${idx}`}
                              >
                                {item?.animal_grooming}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  {filtereData?.animalTypes &&
                    filtereData?.animalTypes?.length > 0 && (
                      <Accordion.Header>Animal Type</Accordion.Header>
                    )}
                  <Accordion.Body>
                    <div className="checksPortion">
                      {filtereData?.animalTypes.map((item, idx) => {
                        return (
                          <div
                            class="form-check checkNlabel"
                            key={item?.type_of_animal_id + idx}
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id={`flexCheckDefault11${item?.type_of_animal_id}${idx}`}
                              checked={petShopPayLoad?.type_of_animal_id.includes(
                                item?.type_of_animal_id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setPetShopPayLoad({
                                    ...petShopPayLoad,
                                    type_of_animal_id: [
                                      ...petShopPayLoad?.type_of_animal_id,
                                      item?.type_of_animal_id,
                                    ],
                                  });
                                } else {
                                  let newArr =
                                    petShopPayLoad?.type_of_animal_id.filter(
                                      (innerItem, i) =>
                                        innerItem !== item?.type_of_animal_id
                                    );
                                  setPetShopPayLoad({
                                    ...petShopPayLoad,
                                    type_of_animal_id: newArr,
                                  });
                                }
                              }}
                            />
                            <label
                              class="form-check-label"
                              for={`flexCheckDefault11${item?.type_of_animal_id}${idx}`}
                            >
                              {item?.type_of_animal}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  {filtereData?.productType &&
                    filtereData?.productType?.length > 0 && (
                      <Accordion.Header>Product Type</Accordion.Header>
                    )}

                  <Accordion.Body>
                    <div className="checksPortion">
                      {filtereData?.productType &&
                        filtereData?.productType.map((item, idx) => {
                          return (
                            <div
                              class="form-check checkNlabel"
                              key={item?.product_type_id + idx}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={`flexCheckDefault15${item?.product_type}${idx}`}
                                checked={petShopPayLoad?.product_type_id.includes(
                                  item?.product_type_id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setPetShopPayLoad({
                                      ...petShopPayLoad,
                                      product_type_id: [
                                        ...petShopPayLoad?.product_type_id,
                                        item?.product_type_id,
                                      ],
                                    });
                                  } else {
                                    let newArr =
                                      petShopPayLoad?.product_type_id.filter(
                                        (innerItem, i) =>
                                          innerItem !== item?.product_type_id
                                      );
                                    setPetShopPayLoad({
                                      ...petShopPayLoad,
                                      product_type_id: newArr,
                                    });
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                for={`flexCheckDefault15${item?.product_type}${idx}`}
                              >
                                {item?.product_type}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  {filtereData?.brands && filtereData?.brands?.length > 0 && (
                    <Accordion.Header>Brands</Accordion.Header>
                  )}
                  <Accordion.Body>
                    <div className="checksPortion">
                      {filtereData?.brands &&
                        filtereData?.brands.map((item, idx) => {
                          return (
                            <div
                              class="form-check checkNlabel"
                              key={item?.product_brand + idx}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={`flexCheckDefault15${item?.product_brand}${idx}`}
                                checked={petShopPayLoad?.product_brand_id.includes(
                                  item?.product_brand_id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setPetShopPayLoad({
                                      ...petShopPayLoad,
                                      product_brand_id: [
                                        ...petShopPayLoad?.product_brand_id,
                                        item?.product_brand_id,
                                      ],
                                    });
                                  } else {
                                    let newArr =
                                      petShopPayLoad?.product_brand_id.filter(
                                        (innerItem, i) =>
                                          innerItem !== item?.product_brand_id
                                      );
                                    setPetShopPayLoad({
                                      ...petShopPayLoad,
                                      product_brand_id: newArr,
                                    });
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                for={`flexCheckDefault15${item?.product_brand}${idx}`}
                              >
                                {item?.product_brand}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey='4'>
                  <Accordion.Header>Top Selling Food Products</Accordion.Header>
                  <Accordion.Body></Accordion.Body>
                </Accordion.Item> */}
                {/* <Accordion.Item eventKey='5'>
                  <Accordion.Header>Top Discount Offers</Accordion.Header>
                  <Accordion.Body>
                    <div className='sliderAreas'>
                      <MultiRangeSlider
                        min={1}
                        max={9}
                        step={3}
                        minValue={minValue}
                        maxValue={maxValue}
                        onInput={e => {
                          setMinValue(e.minValue)
                          setMaxValue(e.maxValue)
                        }}
                      ></MultiRangeSlider>
                      <div className='belowSteps'>
                        <label htmlFor=''>0 %</label>
                        <label htmlFor=''>25 %</label>
                        <label htmlFor=''>80 %</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </div>
          </div>
          {/* Cards Content Section */}

          <div className="col-md-9 colmain9">
            <div className="topFilterSection">
              <div className="txtSec">
                <h1 className="mainTxt">
                  Products{" "}
                  {petShopList.length > 0 && currentPageData?.count ? (
                    <span className="mutedTxt">
                      (Showing 1-{`${petShopList.length}`} of{" "}
                      {`${currentPageData?.count}`} Products)
                    </span>
                  ) : null}
                </h1>
              </div>
              <div className="filterImg">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={FilterImg} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div className="insdieFLex">
                        <label className="labelSort">Sort By</label>
                        <label
                          className="labelClear"
                          onClick={() => {
                            setPetShopPayLoad({
                              ...petShopPayLoad,
                              price: "",
                            });
                          }}
                        >
                          Clear All
                        </label>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setPetShopPayLoad({
                          ...petShopPayLoad,
                          price: "popular",
                        });
                      }}
                    >
                      <Link to="#">Most Popular</Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setPetShopPayLoad({
                          ...petShopPayLoad,
                          price: "Low",
                        });
                      }}
                    >
                      <Link to="#">Price Low to High</Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setPetShopPayLoad({
                          ...petShopPayLoad,
                          price: "High",
                        });
                      }}
                    >
                      <Link to="#">Price High to Low</Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setPetShopPayLoad({
                          ...petShopPayLoad,
                          price: "",
                        });
                      }}
                    >
                      <Link to="#">Newest First</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {isLoading ? (
              <RotatingLoader />
            ) : (
              <div className="cardsSections">
                <div className="row">
                  {petShopList && petShopList.length > 0 ? (
                    petShopList.map((item, i) => {
                      const imagePath =
                        item?.product_image &&
                        item?.product_image[0]?.product_image_path
                          ? item?.product_image[0]?.product_image_path
                          : null;
                      return (
                        <div
                          className="col-md-4 colmain4"
                          key={item?.product_id}
                        >
                          <div className="doctors-card">
                            {
                              item?.is_featured && <img src={featured} className="feturedUImg" alt="im" />
                            }
                            <div className="img-section">
                              <span className="heart">
                                {item?.customer_favourite_id ? (
                                  <img
                                    src={BlueHeart}
                                    alt=""
                                    onClick={() => {
                                      addToFavourite(
                                        item?.product_id,
                                        item?.entity_type_id,
                                        "dislike"
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={heartBorder}
                                    alt=""
                                    onClick={() => {
                                      addToFavourite(
                                        item?.product_id,
                                        item?.entity_type_id,
                                        "like"
                                      );
                                    }}
                                  />
                                )}
                              </span>
                              <img
                                src={imagePath ? imagePath : Pedigree}
                                alt=""
                                className="img-dr"
                              />
                              {item?.customer_card_id ? (
                                <button
                                  className="book-btn darkColor"
                                  id="btn-added"
                                  onClick={() => setItemAdded(!itemAdded)}
                                >
                                  <img src={rightBin} alt="" /> Item Added
                                </button>
                              ) : (
                                <button
                                  className="book-btn"
                                  onClick={() => {
                                    addToBagCart(
                                      item?.product_id,
                                      item?.product_rate,
                                      item?.pet_shop_id,
                                      item?.entity_type_id
                                    );
                                    // setItemAdded(!itemAdded)
                                  }}
                                >
                                  <img src={dustbinImg} alt="" /> Add to Bag
                                </button>
                              )}
                              {/* <img
                              src={CouponBadge}
                              className='CouponBadge'
                              alt=''
                            /> */}
                            </div>

                            <div className="name-rating">
                              <div className="wrapper">
                                <div className="left">
                                  <Link
                                    to={`/PetShopDetails?product_id=${item?.product_id}&pet_shop_id=${item?.pet_shop_id}&entity_type_id=${item?.entity_type_id}`}
                                  >
                                    <label htmlFor="" className="name">
                                      {item?.product}
                                    </label>
                                  </Link>
                                </div>
                                {item?.avg_rating && (
                                  <div className="right">
                                    <span className="name">
                                      {item?.avg_rating}
                                    </span>
                                    <img src={ic_rating_start} alt="" />
                                  </div>
                                )}
                              </div>
                              <span className="cr-price">
                                ₹{parseFloat(item?.product_rate).toFixed(2)}
                              </span>
                              {/* <span className='price'>₹50.00</span> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <NoDataMsg />
                  )}

                  {/* <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={Pedigree} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/PetShopDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div> */}
                  {/* <div className='col-md-4 colmain4'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like1 ? (
                          <img
                            src={BlueHeart}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        ) : (
                          <img
                            src={heartBorder}
                            alt=''
                            onClick={() => setlike1(!like1)}
                          />
                        )}
                      </span>
                      <img src={Pedigree} alt='' className='img-dr' />
                      {itemAdded ? (
                        <button
                          className='book-btn darkColor'
                          id='btn-added'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={rightBin} alt='' /> Item Added
                        </button>
                      ) : (
                        <button
                          className='book-btn'
                          onClick={() => setItemAdded(!itemAdded)}
                        >
                          <img src={dustbinImg} alt='' /> Add to Bag
                        </button>
                      )}
                      <img src={CouponBadge} className='CouponBadge' alt='' />
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <Link to='/PetShopDetails'>
                            <label htmlFor='' className='name'>
                              Pet Supplements for Dog
                            </label>
                          </Link>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='cr-price'>₹40.00</span>
                      <span className='price'>₹50.00</span>
                    </div>
                  </div>
                </div> */}
                </div>
                {!isLoading &&
                  !currentPageData?.last_page &&
                  petShopList &&
                  petShopList.length > 0 && (
                    <div className="showmoreBtnBox">
                      <button
                        className="ShowMore"
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      >
                        Show more
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PetShops;

import React, { useState } from 'react';
import Email from "../../assets/images/envelope-fill.svg"
import Call from "../../assets/images/geo-alt-fill.svg"
import Telephone from "../../assets/images/telephone-fill.svg"
import Form from 'react-bootstrap/Form';

const Contact = () => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <>
            <div className="authMain">
                <div className="contactPg">
                    <div className="contactCardBox">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadCrumb">
                                    <h1>CONTACT US</h1>
                                    <h2>We are here to help.</h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contactDetails">
                                    <div className="contactCard">
                                        <div className="blocks">
                                            <div className="iconBox">
                                                <img src={Email} height="20" width="20" alt="" />
                                            </div>
                                            <div className="contentBox">
                                                <h1 className='key'>EMAIL US</h1>
                                                <a href="mailto:info@animalsapp.in" className='value'>info@animalsapp.in</a>
                                                {/* <h1 className="value">info@animalsapp.in</h1> */}
                                            </div>
                                        </div>
                                        <div className="blocks">
                                            <div className="iconBox">
                                                <img src={Telephone} height="20" width="20" alt="" />
                                            </div>
                                            <div className="contentBox">
                                                <h1 className='key'>CONTACT US</h1>
                                                <a href='tel:+917368012154' className="value">+917368012154</a> <br />
                                                <a href='tel:+919509932234' className="value">+919509932234</a>
                                            </div>
                                        </div>
                                        <div className="blocks">
                                            <div className="iconBox">
                                                <img src={Call} height="20" width="20" alt="" />
                                            </div>
                                            <div className="contentBox">
                                                <h1 className='key'>OUR ADDRESS</h1>
                                                <h1 className="value mb-3">Animals App, <br /> Animalsomatic clinic <br /> Near Ravi Steel, Kamde, Ratu Road, Ranchi, Jharkhand, <br /> India 835222 </h1>
                                                <h1 className="value mb-3">Rajasthan Headquarter <br />JI Pet Clinic , Vaishali Nagar, Ajmer, Rajasthan, India</h1>
                                                <h1 className="value">Bihar Headquarter <br />Pets Planet, Boring Road, Patna, Bihar</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="formBox">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <Form.Group className="formConBox">
                                                    <Form.Control required type="text" className='form-control formControl' placeholder='First Name' />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <Form.Group className="formConBox">
                                                    <Form.Control required type="text" className='form-control formControl' placeholder='Last Name' />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <Form.Group className="formConBox">
                                                    <Form.Control required type="text" className='form-control formControl' placeholder='Email' />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <Form.Group className="formConBox">
                                                    <Form.Control required type="text" className='form-control formControl' placeholder='Phone Number' />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <Form.Group className="formConBox">
                                                    <Form.Control required type="text" className='form-control formControl' placeholder='Subject' />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-4">
                                                <Form.Group className="formConBox">
                                                    <Form.Control required as="textarea" rows={3} type="text" className='form-control' placeholder='Comment Or Message' />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <input type="submit" value="Submit" className='submitBtn' />
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;

import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import ic_doc_download_light from "../../assets/images/ic_doc_download_light.svg";
import ic_view_light from "../../assets/images/ic_view_light.svg";
import ic_repeat from "../../assets/images/ic_repeat.svg";
import ic_repeat_green from "../../assets/images/ic_repeat_green.svg";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import chatting_dummy from "../../assets/images/chatting_dummy.png";
import video_dummy from "../../assets/images/video_dummy.png";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { saveAs } from "file-saver";
import btnSend from "../../assets/images/send_chat.svg";
// MODAL
import ic_call from "../../assets/images/ic_call.svg";
import ic_location from "../../assets/images/ic_location.svg";
import ic_mail from "../../assets/images/ic_mail.svg";
import DoctorSymbol from "../../assets/images/DoctorSymbol.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import sign from "../../assets/images/sign.png";
import ic_add_plus from "../../assets/images/ic_add_plus.svg";
import ic_arrow_right_white from "../../assets/images/ic_right_arrow_circle.svg";
import dr_1 from "../../assets/images/dr_1.png";
import ic_doc_download from "../../assets/images/ic_doc_download.svg";
import ic_view from "../../assets/images/ic_view.svg";
import ic_video from "../../assets/images/ic_video.svg";
import ic_calender_green from "../../assets/images/ic_calender_green.svg";
import dog_1 from "../../assets/images/dog_1.png";
import dog_3 from "../../assets/images/dog_3.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ic_progress from "../../assets/images/ic_progress.svg";
import ic_complete from "../../assets/images/ic_complete.svg";
import ic_reject from "../../assets/images/ic_reject.svg";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import ic_star_active from "../../assets/images/ic_star_active.svg";
import ic_start from "../../assets/images/ic_start.svg";
import { toPng } from "html-to-image";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "./PrescriptionComp";
import {
  PostCallWithAuthToken,
  postWithAuthCall,
  simpleGetCall,
  simpleGetCallWithAuthToken,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { useDateFormats } from "../../customHooks/useDateFormats";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { Modal } from "react-bootstrap";
import DoctorPrecription from "./PDF/DoctorPrecription.jsx";
import moment from "moment";
import AgoraUIKit from "agora-react-uikit";
import "agora-react-uikit/dist/index.css";
import VaccinesModal from "./VaccineModal.js/VaccinesModal.js";
import PetFoodModal from "./VaccineModal.js/PetFoodModal.js";
import Spinner from "../../sharedComponent/Spinner.js";
import requestaccept from "../../assets/images/requestaccept.svg";

const VaccinatorAppointments = ({
  setTotalAppointMents,
  totalAppointMents,
  setTotalVacAppointMents,
  totalVacAppointMents,
}) => {
  const doctorAppointmentIdFromSessionStorage = sessionStorage.getItem(
    "doctorAppointmentId"
  );
  const { convertWithDateAndDay, convertWithMonthAndDay } = useDateFormats();

  // DATA FROM CONTEXT
  const { setBkdropLdr, VideoChatting } =
    useContext(AppContext);
  // DATA FROM CONTEXT

  // DATA FROM REDUX STORE
  const { customer_id } = useReduxStore();
  // DATA FROM REDUX STORE

  // STATES
  const [VacDetailsView, setVacDetailsView] = useState(true)
  const [isLoading, setIsLoading] = useState(false);
  const [viewVccAppointment, setViewVccAppointment] = useState({});
  // STATES

  // my-appointment-view
  const fetchVacAppointDetails = async (vaccinator_appointment_id) => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.VACCINATORS_APPOINTMENT_DETAILS}?vaccinator_appointment_id=${vaccinator_appointment_id}`
      );

      const { success, current_appointment } = res?.json;
      if (success) {
        setVacDetailsView(false);
        setViewVccAppointment(current_appointment);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [vaccAppointmentList, setVaccAppointmentList] = useState([]);

  useEffect(() => {
    fetchVaccinatorAppointmentListList();
  }, []);

  const fetchVaccinatorAppointmentListList = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.VACCINATORS_APPOINTMENT_LIST}?customer_id=${customer_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        const arr = [];
        data &&
          data.length &&
          data
            // ?.sort((a, b) => new Date(a.order_date) - new Date(b.order_date))
            .forEach((item) => {
              let appointmentDate = item.date ? item.date?.split("T")[0] : "";
              let existingObj = arr.find(
                (obj) => obj.appointment_date === appointmentDate
              );
              if (existingObj) {
                existingObj.appointments.push(item);
              } else {
                arr.push({
                  appointment_date: appointmentDate,
                  date: item.date,
                  appointments: [item],
                });
              }
            });
        const tempArray =
          arr &&
          arr.map((ele) => {
            return {
              appointment_date: ele?.appointmentDate,
              date: ele?.date,
              appointments: ele?.appointments,
              // appointments: ele?.appointments.reverse(),
            };
          });
        tempArray && setVaccAppointmentList(tempArray);
        setTotalVacAppointMents(data.length);
      }
    } catch (error) {
      console.log("catch error vaccinator appointment list API,", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="my-appointment-wrapper">
      {VideoChatting ? (
        <div className="right-section">
          {vaccAppointmentList && vaccAppointmentList.length > 0 && (
            <section className="wrapper-cards ">
              <div className="heading-close-btn">
                <h5>Vaccinator Appointments ({totalVacAppointMents}) </h5>
                {/* <Link >
                <img src={ic_calender_green} alt='' />
              </Link> */}
              </div>
            </section>
          )}
          {VacDetailsView ? (
            isLoading ? (
              <RotatingLoader />
            ) : (
              <div className="appointment">
                {vaccAppointmentList.length > 0 ? (
                  vaccAppointmentList &&
                  vaccAppointmentList.map((item, idx) => {
                    return (
                      <div key={item?.doctor_appointment_id}>
                        <div className="appointment-date-wrapper">
                          <label htmlFor="">
                            {item?.date &&
                              convertWithDateAndDay(item?.date) &&
                              convertWithDateAndDay(item?.date)}
                            {/* 21 July, 2023 */}
                          </label>
                          <div className="line"></div>
                        </div>
                        {item?.appointments?.map((innerItem, idx) => {
                          return (
                            <section
                              className="wrapper-cards "
                              key={"innerItem" + idx}
                            >
                              <div className="app-details-wrapper">
                                <div className="img-dr">
                                  <img
                                    src={
                                      innerItem?.vaccinator_profile_image_path
                                        ? innerItem?.vaccinator_profile_image_path
                                        : ""
                                    }
                                    onError={(e) => {
                                      e.target.src = dummydoctor;
                                    }}
                                    alt=""
                                  />
                                  <div className="right-dr">
                                    {innerItem?.vaccinator_name && (
                                      <label htmlFor="" className="key">
                                        {innerItem?.vaccinator_name}
                                      </label>
                                    )}
                                  </div>
                                </div>

                                <div className="detail">
                                  <label htmlFor="" className="key">
                                    Animal
                                  </label>
                                  {innerItem?.animal_name && (
                                    <p className="value">
                                      {innerItem?.animal_name}
                                    </p>
                                  )}
                                </div>

                                <div className="detail">
                                  <label htmlFor="" className="key">
                                    Appointment Type
                                  </label>
                                  <p className="value">
                                    {innerItem?.appointment_type}
                                  </p>
                                </div>

                                <div className="detail">
                                  <label htmlFor="" className="key">
                                    Fees
                                  </label>
                                  <p className="value">
                                    ₹ {innerItem?.total_charges}
                                  </p>
                                </div>
                                {innerItem?.status_id && (
                                  <div className="detail status-part">
                                    <label htmlFor="" className="key">
                                      Status
                                    </label>
                                    <p className="value" id="progress-status">
                                      <img
                                        src={
                                          innerItem?.status_id === 25
                                            ? ic_progress
                                            : innerItem?.status_id === 28
                                            ? ic_complete
                                            : innerItem?.status_id === 27
                                            ? ic_reject
                                            : innerItem?.status_id === 26
                                            ? requestaccept
                                            : ""
                                        }
                                        alt=""
                                        className="me-1"
                                      />
                                      {innerItem?.status_id === 25
                                        ? "Pending"
                                        : innerItem?.status_id === 28
                                        ? "Completed"
                                        : innerItem?.status_id === 28
                                        ? "Rejected"
                                        : innerItem?.status_id === 26
                                        ? "Accepted"
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="app-btn-wrapper">
                                <button className="b-c me-2">
                                  <img
                                    src={ic_view}
                                    onClick={() => {
                                      fetchVacAppointDetails(
                                        innerItem?.vaccinator_appointment_id
                                      );
                                    }}
                                    alt=""
                                  />
                                </button>
                              </div>
                            </section>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <>
                    <NoDataMsg MSG="No Appointments Found" />
                  </>
                )}

                {/* Request Accepted */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='accepted-status'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='21'
                        height='21'
                        viewBox='0 0 21 21'
                        fill='none'
                      >
                        <path
                          d='M2.3291 10.6751C2.3291 6.08346 6.07077 2.3418 10.6624 2.3418C15.2541 2.3418 18.9958 6.08346 18.9958 10.6751C18.9958 15.2668 15.2541 19.0085 10.6624 19.0085C6.07077 19.0085 2.3291 15.2668 2.3291 10.6751Z'
                          fill='#05CD99'
                        />
                      </svg>
                      Request Accepted
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='b-c '>
                    <img src={ic_view} alt='' />
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='full-btn green-btn'>
                    <img src={ic_video} alt='' />
                    Start
                  </button>
                </div>
              </section> */}
                {/* Request Accepted */}

                {/* In Progress */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='progress-status'>
                      <img src={ic_progress} alt='' className='me-1' />
                      In Progress
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='full-btn green-btn '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='25'
                      viewBox='0 0 25 25'
                      fill='none'
                      className='me-1'
                    >
                      <path
                        d='M16.1405 12.6796C16.1405 14.6596 14.5405 16.2596 12.5605 16.2596C10.5805 16.2596 8.98047 14.6596 8.98047 12.6796C8.98047 10.6996 10.5805 9.09961 12.5605 9.09961C14.5405 9.09961 16.1405 10.6996 16.1405 12.6796Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M12.5604 20.9504C16.0904 20.9504 19.3804 18.8704 21.6704 15.2704C22.5704 13.8604 22.5704 11.4904 21.6704 10.0804C19.3804 6.48039 16.0904 4.40039 12.5604 4.40039C9.03039 4.40039 5.74039 6.48039 3.45039 10.0804C2.55039 11.4904 2.55039 13.8604 3.45039 15.2704C5.74039 18.8704 9.03039 20.9504 12.5604 20.9504Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    View
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='b-c'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                    >
                      <path
                        d='M11.9697 22.0918C17.4927 22.0918 21.9697 17.6148 21.9697 12.0918C21.9697 6.5688 17.4927 2.0918 11.9697 2.0918C6.44673 2.0918 1.96973 6.5688 1.96973 12.0918C1.96973 17.6148 6.44673 22.0918 11.9697 22.0918Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M8.74023 12.3224V10.6524C8.74023 8.57241 10.2102 7.72241 12.0102 8.76241L13.4602 9.60241L14.9102 10.4424C16.7102 11.4824 16.7102 13.1824 14.9102 14.2224L13.4602 15.0624L12.0102 15.9024C10.2102 16.9424 8.74023 16.0924 8.74023 14.0124V12.3224Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </section> */}
                {/* In Progress */}

                {/* Completed */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='progress-status'>
                      <img src={ic_complete} alt='' className='me-1' />
                      Completed
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='full-btn green-btn '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='25'
                      viewBox='0 0 25 25'
                      fill='none'
                      className='me-1'
                    >
                      <path
                        d='M16.1405 12.6796C16.1405 14.6596 14.5405 16.2596 12.5605 16.2596C10.5805 16.2596 8.98047 14.6596 8.98047 12.6796C8.98047 10.6996 10.5805 9.09961 12.5605 9.09961C14.5405 9.09961 16.1405 10.6996 16.1405 12.6796Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M12.5604 20.9504C16.0904 20.9504 19.3804 18.8704 21.6704 15.2704C22.5704 13.8604 22.5704 11.4904 21.6704 10.0804C19.3804 6.48039 16.0904 4.40039 12.5604 4.40039C9.03039 4.40039 5.74039 6.48039 3.45039 10.0804C2.55039 11.4904 2.55039 13.8604 3.45039 15.2704C5.74039 18.8704 9.03039 20.9504 12.5604 20.9504Z'
                        stroke='white'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    View
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='b-c'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                    >
                      <path
                        d='M11.9697 22.0918C17.4927 22.0918 21.9697 17.6148 21.9697 12.0918C21.9697 6.5688 17.4927 2.0918 11.9697 2.0918C6.44673 2.0918 1.96973 6.5688 1.96973 12.0918C1.96973 17.6148 6.44673 22.0918 11.9697 22.0918Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M8.74023 12.3224V10.6524C8.74023 8.57241 10.2102 7.72241 12.0102 8.76241L13.4602 9.60241L14.9102 10.4424C16.7102 11.4824 16.7102 13.1824 14.9102 14.2224L13.4602 15.0624L12.0102 15.9024C10.2102 16.9424 8.74023 16.0924 8.74023 14.0124V12.3224Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </section> */}
                {/* Completed */}

                {/* Rejected */}
                {/* <div className='appointment-date-wrapper mt-4'>
                <label htmlFor=''>21 July, 2023</label>
                <div className='line'></div>
              </div>

              <section className='wrapper-cards '>
                <div className='app-details-wrapper'>
                  <div className='img-dr'>
                    <img src={dr_1} alt='' />
                    <div className='right-dr'>
                      <label htmlFor='' className='key'>
                        Dr. Floyd Miles
                      </label>
                      <p className='value'>Orthopedic | 5 years</p>
                    </div>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Animal
                    </label>
                    <p className='value'>Bruno | Cat</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Appointment Type
                    </label>
                    <p className='value'>Video Consultation</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Fees
                    </label>
                    <p className='value'>₹100</p>
                  </div>

                  <div className='detail'>
                    <label htmlFor='' className='key'>
                      Status
                    </label>
                    <p className='value' id='progress-status'>
                      <img src={ic_reject} alt='' className='me-1' />
                      Rejected
                    </p>
                  </div>
                </div>
                <div className='app-btn-wrapper'>
                  <button className='full-btn green-btn '>
                    <img src={ic_view_light} alt='' />
                    View
                  </button>
                  <button className='b-c mx-4'>
                    <img src={ic_doc_download} alt='' />
                  </button>
                  <button className='b-c'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                    >
                      <path
                        d='M11.9697 22.0918C17.4927 22.0918 21.9697 17.6148 21.9697 12.0918C21.9697 6.5688 17.4927 2.0918 11.9697 2.0918C6.44673 2.0918 1.96973 6.5688 1.96973 12.0918C1.96973 17.6148 6.44673 22.0918 11.9697 22.0918Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M8.74023 12.3224V10.6524C8.74023 8.57241 10.2102 7.72241 12.0102 8.76241L13.4602 9.60241L14.9102 10.4424C16.7102 11.4824 16.7102 13.1824 14.9102 14.2224L13.4602 15.0624L12.0102 15.9024C10.2102 16.9424 8.74023 16.0924 8.74023 14.0124V12.3224Z'
                        stroke='#1D7C7C'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </section> */}
                {/* Rejected */}
              </div>
            )
          ) : (
            <div className="appointment-details-wrapper">
              <div className="show-back-heading">
                <Link
                  onClick={() => {
                    setVacDetailsView(true);
                  }}
                >
                  <img src={ic_left_green_arrow} alt="" />
                </Link>
                <label htmlFor="">
                  Showing result for :{" "}
                  <span>{viewVccAppointment?.vaccinator_name}</span>
                </label>
              </div>
              <div className="wrapper-cards" id="pdfcontent">
                <h6>Appointment Details</h6>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="" className="key">
                      Animal Name
                    </label>
                    {viewVccAppointment?.animal_name && (
                      <span className="value">
                        {viewVccAppointment?.animal_name}
                      </span>
                    )}
                  </div>
                  {viewVccAppointment?.date && (
                    <div className="col-md-4">
                      <label htmlFor="" className="key">
                        Appointment Date & Time
                      </label>
                      <span className="value">
                        {moment(viewVccAppointment?.date).format("MMM Do YY")},{" "}
                        {moment(viewVccAppointment?.time, "hh:mm").format(
                          "hh:mm A"
                        )}
                        {/* {convertWithDataAndTime(viewVccAppointment?.date)} */}
                        {/* 02-02-2023, 04:30 PM */}
                      </span>
                    </div>
                  )}
                  {viewVccAppointment?.appointment_type && (
                    <div className="col-md-4 mb-3">
                      <label htmlFor="" className="key">
                        Appointment Type
                      </label>
                      <span className="value capital">
                        {viewVccAppointment?.appointment_type}
                        {/* <img src={ic_complete} alt='' className='mx-1' />
                        <span id='completed'>Paid</span> */}
                      </span>
                    </div>
                  )}
                  {viewVccAppointment?.appointment_desc && (
                    <div className="col-12 mb-3">
                      <label htmlFor="" className="key">
                        Description
                      </label>
                      <span className="value">
                        {viewVccAppointment?.appointment_desc}{" "}
                      </span>
                    </div>
                  )}

                  {viewVccAppointment?.animalDetails &&
                  viewVccAppointment?.animalDetails?.photos &&
                  viewVccAppointment?.animalDetails?.photos.length > 0 ? (
                    <div className="col-12 mb-3">
                      <label htmlFor="" className="key">
                        Photos
                      </label>
                      <div className="img-wrapper">
                        {viewVccAppointment?.animalDetails?.photos.map(
                          (item, i) => {
                            const imagePath = item?.animal_photograph_path;
                            return (
                              <div
                                className="img me-3"
                                key={item?.animal_photograph_id}
                              >
                                <img
                                  src={imagePath ? imagePath : dog_1}
                                  alt="Not found"
                                  className="animal-img"
                                />
                              </div>
                            );
                          }
                        )}

                        {/* <div className='img me-3'>
                          <img src={dog_3} alt='' className='animal-img' />
                          <Link>
                            <img src={ic_video} alt='' className='ic-video' />
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VaccinatorAppointments;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Subheader from "../../sharedComponent/Subheader";
import { Carousel } from "react-responsive-carousel";
import MediImg from "../../assets/images/medicinesImg.svg";
import CouponBadge from "../../assets/images/redCouponBadge.svg";
import heartBorder from "../../assets/images/heartBorder.svg";
import BlueHeart from "../../assets/images/blueFilledHeart.svg";
import plusIco from "../../assets/images/plusIcon.svg";
import minusIco from "../../assets/images/minusIcon.svg";
import dustbinImg from "../../assets/images/dustbinImg.svg";
import Star from "../../assets/images/Star.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Slider from "react-slick";
import Steps from "../../assets/images/footSteps.svg";
import { PostCallWithAuthToken, postWithAuthCall } from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useReduxStore } from "../../customHooks/useReduxStore";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import dummymedicene from "../../assets/images/dummymedicene.jpg";
import { increaseCartCount } from "../../redux/Slices/cartSlice";
import ReactImageMagnify from "react-image-magnify";

const ProductDetails = () => {
  // DATA FROM CONTEXT
  const { navigate, setBkdropLdr, dispatch } = useContext(AppContext);
  // DATA FROM CONTEXT
  const { customer_id, cartItemCount } = useReduxStore();
  // FROM PARAMS DATA
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const productId = parseInt(searchParams.get("product_id"));
  const pharmacyShopId = parseInt(searchParams.get("pharmacy_shop_id"));
  const entityTypeId = parseInt(searchParams.get("entity_type_id"));
  // FROM PARAMS DATA

  const [heartClicked, setHeartClicked] = useState(false);
  const [heartClick, setHeartClick] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const breakpoints = {
    // Define the number of cards to display at different screen sizes.
    440: 1,
    768: 2, // Display 2 cards on screens larger than 768px.
    992: 3, // Display 3 cards on screens larger than 992px.
    1200: 4, // Display 4 cards on screens larger than 1200px.
  };

  const cardCount = breakpoints[window.innerWidth] || 1;

  useEffect(() => {
    fecthMedicineDetails();
    fecthRecommendedProduct();
    fetchRecentViewedProduct();
  }, []);

  const [loading, setLoading] = useState(false);
  const [medicineDetails, setMedicineDetails] = useState({});
  const [ratingPercentage, setRatingPercentage] = useState([]);

  const fecthMedicineDetails = async () => {
    try {
      const payLoad = {
        product_id: productId,
        pharmacy_shop_id: pharmacyShopId,
        entity_type_id: entityTypeId,
        customer_id: customer_id,
      };
      setLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.MEDICINE_DETAILS,
        payLoad
      );
      const { success, ratingPercentage, data } = res?.json;
      if (success) {
        data && setMedicineDetails(data);
        ratingPercentage &&
          ratingPercentage.length &&
          setRatingPercentage(ratingPercentage.reverse());
      }
    } catch (error) {
      console.log("fecthMedicineDetails catch error :", error);
    } finally {
      setLoading(false);
    }
  };

  const [recommendedProducts, setRecommendedProducts] = useState([]);
  //   GET_RECOMMENDED_LIST
  const fecthRecommendedProduct = async () => {
    try {
      const payLoad = {
        product_id: productId,
        pharmacy_shop_id: pharmacyShopId,
        customer_id: customer_id,
      };
      setLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_RECOMMENDED_LIST,
        payLoad
      );
      const { success, pharmacyProduct } = res?.json;
      if (success) {
        pharmacyProduct && setRecommendedProducts(pharmacyProduct);
      }
    } catch (error) {
      console.log("fecthMedicineDetails catch error :", error);
    } finally {
      setLoading(false);
    }
  };
  const [recentViewedProducts, setRecentViewedProducts] = useState([]);
  const fetchRecentViewedProduct = async () => {
    try {
      const payLoad = {
        customer_id: customer_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.RECENT_VIEWD_MEDICINES,
        payLoad
      );
      const { result, personalInfo } = res?.json;
      result && personalInfo && setRecentViewedProducts(personalInfo);
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  const [productQty, setProductQty] = useState(1);
  const addToBagCart = async () => {
    // ADD_TO_CART
    const cartPayLoad = {
      customer_id: customer_id,
      // amount: 180,
      product_id: productId,
      product_quantity: productQty,
      product_rate: medicineDetails?.product_rate,
      entity_type_id: entityTypeId,
      shop_id: pharmacyShopId,
    };
    try {
      setLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        dispatch(increaseCartCount(cartItemCount + 1));
        notifySuccess("Medicine Added to cart successfully");
        navigate("/cart");
      } else {
        notifyError("This product already added to your cart");
      }
    } catch (error) {
      console.log("catch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const ratingsOutOf5 = ratingPercentage.map((percent) => (percent / 100) * 5);
  const sumOfRatings = ratingsOutOf5.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const averageRating = sumOfRatings / ratingPercentage.length;

  const addToFavourite = async (
    product_id,
    entity_type_id,
    actionType,
    listType
  ) => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        customer_id: customer_id,
        product_id: product_id,
        entity_type_id: entity_type_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_MEDICINE_TO_FAVOURITE,
        payLoad
      );
      console.log("res,", res);
      const { result, message } = res?.json;
      if (result) {
        // notifySuccess(message)
        console.log("actionType,", actionType);
        if (actionType === "like") {
          // 'recommend'
          // recentViewed
          if (listType === "recommend") {
            const tempArr = recommendedProducts.map((item, i) => {
              return item?.product_id === product_id
                ? { ...item, customer_favourite_id: true }
                : item;
            });
            setRecommendedProducts(tempArr);
          } else {
            const tempArr = recentViewedProducts.map((item, i) => {
              return item?.product_id === product_id
                ? { ...item, customer_favourite_id: true }
                : item;
            });
            setRecentViewedProducts(tempArr);
          }
        } else if (actionType === "dislike") {
          if (listType === "recommend") {
            const tempArr = recommendedProducts.map((item, i) => {
              return item?.product_id === product_id
                ? { ...item, customer_favourite_id: false }
                : item;
            });
            setRecommendedProducts(tempArr);
          } else {
            const tempArr = recentViewedProducts.map((item, i) => {
              return item?.product_id === product_id
                ? { ...item, customer_favourite_id: false }
                : item;
            });
            setRecentViewedProducts(tempArr);
          }
        }
      }
    } catch (error) {
      console.log("catch Error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleCarouselChange = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <>
      {/* {loading && <BackDropLoader />} */}
      <div className="medicinesDetails" id="cx-main">
        <Subheader />
        {/* Product Description */}
        {loading ? (
          <RotatingLoader />
        ) : (
          <div className="ProductDescriptionmain">
            <div className="row">
              <div className="col-md-9 colMain9">
                <div className="productDescriptionBox">
                  <div className="row">
                    {Array.isArray(medicineDetails?.product_img) && (
                      <div className="col-md-4">
                        <div className="innerCarousel">
                          {/* <img src={CouponBadge} className='CouponBadge' alt='' /> */}
                          <Carousel
                            showArrows={
                              medicineDetails?.product_img?.length > 1
                                ? true
                                : false
                            }
                            infiniteLoop={
                              medicineDetails?.product_img?.length > 1
                                ? true
                                : false
                            }
                            onChange={handleCarouselChange}
                          >
                            {medicineDetails?.product_img.map((img, idx) => {
                              const imagePath = img?.product_image_path
                                ? img?.product_image_path
                                : null;

                              return (
                                <div
                                  key={img?.product_image_id}
                                  style={{ padding: "20px" }}
                                >
                                  <img
                                    style={{
                                      width: "-webkit-fill-available",
                                      borderRadius: "20px",
                                    }}
                                    src={imagePath ? imagePath : dummymedicene}
                                    alt="Not found"
                                  />
                                  {/* <ReactImageMagnify
                                    {...{
                                      smallImage: {
                                        alt: "Small Image Alt",
                                        isFluidWidth: true,
                                        src: imagePath ? imagePath : dummymedicene,
                                      },
                                      largeImage: {
                                        src: imagePath ? imagePath : dummymedicene,
                                        width: 1200,
                                        height: 1800,
                                      },
                                      // enlargedImagePosition: "over",
                                      lensStyle: {
                                        backgroundColor: "rgba(0,0,0,.6)",
                                        zIndex : "9999"
                                      },
                                      isHintEnabled: true,
                                      shouldHideHintAfterFirstActivation: false,
                                    }}
                                  /> */}

                                  {/* <img src={MediImg} alt='' /> */}
                                </div>
                              );
                            })}

                            {/* <div>
                            <img src={MediImg} />
                          </div>
                          <div>
                            <img src={MediImg} />
                          </div> */}
                          </Carousel>
                          {medicineDetails?.product_img[selectedImageIndex] && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                // zIndex: 9999,
                              }}
                            >
                              <ReactImageMagnify
                                smallImage={{
                                  alt: "Small Image Alt",
                                  isFluidWidth: true,
                                  src: medicineDetails.product_img[
                                    selectedImageIndex
                                  ]?.product_image_path
                                    ? medicineDetails.product_img[
                                        selectedImageIndex
                                      ].product_image_path
                                    : dummymedicene,
                                }}
                                largeImage={{
                                  src: medicineDetails.product_img[
                                    selectedImageIndex
                                  ]?.product_image_path
                                    ? medicineDetails.product_img[
                                        selectedImageIndex
                                      ].product_image_path
                                    : dummymedicene,
                                  width: 1200,
                                  height: 1800,
                                }}
                                enlargedImageContainerDimensions={{
                                  width: 800,
                                  height: 600, 
                                }}
                                lensStyle={{
                                  // backgroundColor: "rgba(0,0,0,.6)",
                                }}
                                isHintEnabled
                                shouldHideHintAfterFirstActivation={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-md-8">
                      <div className="contentBox">
                        <div className="flexBox">
                          <div className="heading">
                            <h1 className="headinTxt">
                              {medicineDetails?.product}
                            </h1>
                            <label className="productLabl">
                              {medicineDetails?.product_type}
                            </label>
                          </div>
                          <div className="actionIcon">
                            <img
                              onClick={() => setHeartClicked(!heartClicked)}
                              src={heartClicked ? BlueHeart : heartBorder}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="paraBox">
                          <div className="insidePara">
                            <p className="detailPara">
                              {medicineDetails?.product_desc}
                            </p>
                          </div>
                        </div>
                        <div className="paraBox">
                          <div className="insidePara">
                            <p className="detailPara">
                              Quantity per package :{" "}
                              {medicineDetails?.qauntity_per_package}
                            </p>
                          </div>
                        </div>
                        {/* <div className='useBox'>
                          <h1 className='useTxt'>Direction for use</h1>
                          <div className='belowTxtFlex'>
                            <label htmlFor='' className='key'>
                              Adult Dog :
                            </label>
                            <label htmlFor='' className='value'>
                              {' '}
                              Take 2 tablespoons once in glass full of water.
                            </label>
                          </div>
                          <div className='belowTxtFlex'>
                            <label htmlFor='' className='key'>
                              Puppy :
                            </label>
                            <label htmlFor='' className='value'>
                              {' '}
                              Take 2 tablespoons once in glass full of water.
                            </label>
                          </div>
                        </div> */}
                        {/* <div className='storageBox'>
                          <h1 className='headTxt'>Storage</h1>
                          <ul className='storeList'>
                            <li>
                              Store this syrup at room temperature protected
                              from sunlight, heat, and moisture.
                            </li>
                            <li> Keep away from reaching out of childrens. </li>
                            <li>
                              Ensure that the unused medicine is disposed of
                              properly
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 colMain3">
                <div className="addToBagBox">
                  <div className="amountDetails">
                    <label className="accAmnt">
                      ₹ {medicineDetails?.product_rate}
                    </label>
                    {/* <label className='discountAmnt'>
                      ₹ {medicineDetails?.product_rate}
                    </label> */}
                  </div>
                  <div className="availablityStatus">
                    {medicineDetails?.pharmacy_shop_product_quantity !== "0" ? (
                      <label htmlFor="">In stock</label>
                    ) : (
                      <span style={{ color: "red" }}>Out of stock</span>
                    )}
                  </div>
                  <div className="quantityMeter">
                    <label htmlFor="">
                      <img
                        src={minusIco}
                        alt=""
                        onClick={() => {
                          productQty > 1 && setProductQty(productQty - 1);
                        }}
                      />
                    </label>
                    <label htmlFor="">{productQty}</label>
                    <label htmlFor="">
                      <img
                        src={plusIco}
                        alt=""
                        onClick={() => {
                          // medicineDetails?.pharmacy_shop_product_quantity
                          if (
                            parseInt(
                              medicineDetails?.pharmacy_shop_product_quantity
                            ) >=
                            productQty + 1
                          ) {
                            setProductQty(productQty + 1);
                          } else {
                            notifyError("Quantity exceeds available stock!");
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div className="addTObag">
                    <button
                      type="button"
                      className="bagBtn"
                      onClick={() => {
                        if (
                          medicineDetails?.customer_card_id &&
                          medicineDetails?.customer_card_id
                        ) {
                          navigate("/cart");
                          return;
                        }
                        addToBagCart();
                      }}
                    >
                      <img src={dustbinImg} alt="" /> Add to Bag
                    </button>
                  </div>
                  {/* <div className='deliveryStatus'>
                    <div className='heaqdingBox'>
                      <h1>Delivery</h1>
                    </div>
                    <div className='statusInfoBox'>
                      <h1 className='delieryTxt'>
                        Delivery by 5 Aug, Saturday
                      </h1>
                      <h1 className='couponDetail'>
                        Free <span>₹50</span>{' '}
                      </h1>
                      <label htmlFor=''>If Ordered Today</label>
                    </div>
                  </div> */}
                  {/* <div className="sellerBox">
                    <div className="heaqdingBox">
                      <h1>Seller</h1>
                    </div>
                    <div className="sellerCont">
                      <h1 className="headTxt">
                        {medicineDetails?.pharmacy_shop_name}
                      </h1>
                      <h1 className="ratingTxt">
                        <img src={Star} className="Star" alt="" /> 4.3
                      </h1>
                    </div>
                    <p className='paraTxt'>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Dolores tempora est culpa
                    </p>
                    <Link
                      to={`/AllSellers?product_id=${productId}&pharmacy_shop_id=${pharmacyShopId}`}
                      className="seeMoreLink"
                    >
                      See Other Sellers
                    </Link>
                  </div> */}
                </div>
              </div>

              {medicineDetails?.reviews && (
                <div className="col-md-12">
                  <div className="reviewsBox">
                    <div className="row">
                      {ratingPercentage.length > 0 && (
                        <div className="col-md-3 rateCol3">
                          <div className="ratingBarBox">
                            <div className="headingBox">
                              <h1>Customer Reviews</h1>
                            </div>
                            <div className="starFlex">
                              <div className="starsFlex">
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                              </div>
                              {averageRating && (
                                <h1 className="starText">
                                  {parseFloat(averageRating).toFixed(1)} Out Of
                                  5
                                </h1>
                              )}
                            </div>
                            <div className="ratingBar">
                              <h1 className="ratingTxt">
                                {medicineDetails?.reviews &&
                                  medicineDetails?.reviews.length}{" "}
                                Global Rating
                              </h1>
                              {ratingPercentage &&
                                ratingPercentage.map((rate, i) => {
                                  return (
                                    <div
                                      className="barsFlex"
                                      key={"ratingPercentage" + i}
                                    >
                                      <label htmlFor="" className="laberate">
                                        {5 - i} Star
                                      </label>
                                      <ProgressBar now={rate} />
                                      <label
                                        htmlFor=""
                                        className="labelPercent"
                                      >
                                        {parseFloat(rate).toFixed(1)}%
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* ====================== PRevies ====================== */}

                      <div className="col-md-9 rateCol9">
                        <div className="reviewBoxInner">
                          <div className="headingBox">
                            <h1>Top Customer Reviews</h1>
                          </div>
                          {medicineDetails?.reviews.length > 0 &&
                            medicineDetails?.reviews.map((item, idx) => {
                              return (
                                <div className="contentReviewBoxInside">
                                  <div className="flexviewTwocards">
                                    <div className="imgHolder">
                                      <img
                                        src={MediImg}
                                        className="imgInside"
                                        alt=""
                                      />
                                    </div>
                                    <div className="contentHolder">
                                      <div className="headingFlex">
                                        <h1 className="headTxt">
                                          {item?.review}
                                        </h1>
                                        <h1 className="rateStar">
                                          <img
                                            src={Star}
                                            className="starMargin"
                                            alt=""
                                          />{" "}
                                          {item?.product_rating_and_review_id}
                                        </h1>
                                      </div>
                                      <div className="nameAndDomain">
                                        {item?.first_name &&
                                          item?.last_name && (
                                            <h1 className="daomainNdate">
                                              {`${item?.first_name} ${item?.last_name},`}{" "}
                                              {/* 16 July 2023 */}
                                            </h1>
                                          )}
                                        <p className="domainPara">
                                          Lorem, ipsum dolor sit amet
                                          consectetur adipisicing elit. Officiis
                                          culpa similique ratione aspernatur
                                          nesciunt eveniet hic corporis,
                                          voluptatem consequatur pariatur eius?
                                          In, fugit sunt? Optio accusantium
                                          maxime cupiditate modi alias eligendi
                                          velit voluptatum repellat recusandae
                                          error, porro esse soluta officia.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {/* <div className='showMOreBox'>
                                      <button className='showMoreBtn'>
                                        Show All Reviews (100)
                                      </button>
                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {recommendedProducts && recommendedProducts.length > 0 && (
                <div className="col-md-12">
                  <div className="CardsCarousel ">
                    <div className="carouselHeading">
                      <h1 className="headTxt">Recommended Products</h1>
                      <img src={Steps} className="Steps" alt="" />
                    </div>
                    <div className="carosuelInside">
                      <Slider
                        dots={true}
                        slidesToScroll={4}
                        speed={500}
                        infinite={true}
                        // slidesToShow={recommendedProducts.length}
                        // initialSlide={0}
                        slidesToShow={
                          recommendedProducts.length < 4
                            ? recommendedProducts.length
                            : 4
                        }
                        initialSlide={recommendedProducts.length}
                      >
                        {recommendedProducts &&
                          recommendedProducts.map((item, idx) => {
                            const imagePath =
                              item?.product_image &&
                              item?.product_image[0]?.product_image_path
                                ? item?.product_image[0]?.product_image_path
                                : null;

                            return (
                              <div
                                className="medicineCardsCarosel"
                                key={"recommended" + idx}
                              >
                                <div className="innerContent">
                                  <img
                                    src={imagePath ? imagePath : MediImg}
                                    className="mediImg"
                                    alt=""
                                  />
                                  <img
                                    onClick={() => {
                                      // setHeartClick(!heartClick)
                                      if (
                                        !item?.customer_favourite_id &&
                                        !item?.customer_favourite_id
                                      ) {
                                        addToFavourite(
                                          item?.product_id,
                                          item?.entity_type_id,
                                          "like",
                                          "recommend"
                                        );
                                      } else {
                                        addToFavourite(
                                          item?.product_id,
                                          item?.entity_type_id,
                                          "dislike",
                                          "recommend"
                                        );
                                      }
                                    }}
                                    src={
                                      item?.customer_favourite_id &&
                                      item?.customer_favourite_id
                                        ? BlueHeart
                                        : heartBorder
                                    }
                                    className="heartBor"
                                    alt=""
                                  />
                                  {/* <img
                                    src={CouponBadge}
                                    className='couponBadge'
                                    alt=''
                                  /> */}
                                </div>
                                <div className="detailsSec">
                                  <Link
                                    target="_blank"
                                    to={`/ProductDetails?product_id=${item?.product_id}&pharmacy_shop_id=${item?.pharmacy_shop_id}&entity_type_id=${item?.entity_type_id}`}
                                  >
                                    <label htmlFor="" className="headName">
                                      {item?.product}
                                      {/* Pet Supplements for Dog */}
                                    </label>
                                  </Link>
                                  {item?.avg_rating && (
                                    <label htmlFor="" className="flexStar">
                                      <img
                                        src={Star}
                                        className="starImg"
                                        alt=""
                                      />{" "}
                                      {item?.avg_rating}
                                    </label>
                                  )}
                                </div>
                                <div className="rateBox">
                                  <label htmlFor="" className="amntActive">
                                    ₹{item?.product_rate}
                                  </label>

                                  <label htmlFor="" className="mutedTxt">
                                    ₹{item?.product_rate}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>
              )}

              {recentViewedProducts && recentViewedProducts.length > 0 && (
                // <div>
                <div className="col-md-12">
                  <div className="CardsCarousel noMargin">
                    <div className="carouselHeading">
                      <h1 className="headTxt">Recently Viewed</h1>
                      <img src={Steps} className="Steps2" alt="" />
                    </div>
                    <div className="carosuelInside">
                      <Slider
                        slidesToScroll={4}
                        speed={500}
                        infinite={true}
                        // slidesToShow={4}
                        // initialSlide={recentViewedProducts.length}
                        slidesToShow={
                          recentViewedProducts.length < 4
                            ? recentViewedProducts.length
                            : 4
                        }
                        initialSlide={recentViewedProducts.length}
                      >
                        {recentViewedProducts.map((item, i) => {
                          const imagePath =
                            item?.product_img &&
                            item?.product_img[0]?.product_image_path
                              ? item?.product_img[0]?.product_image_path
                              : null;

                          return (
                            <div
                              className="medicineCardsCarosel"
                              key={item?.customer_recent_search_id}
                            >
                              <div className="innerContent">
                                <img
                                  src={imagePath ? imagePath : MediImg}
                                  className="mediImg"
                                  alt=""
                                />
                                <img
                                  onClick={() => {
                                    // setHeartClick(!heartClick)
                                    if (
                                      !item?.customer_favourite_id &&
                                      !item?.customer_favourite_id
                                    ) {
                                      addToFavourite(
                                        item?.product_id,
                                        item?.entity_type_id,
                                        "like",
                                        "recentViewed"
                                      );
                                    } else {
                                      addToFavourite(
                                        item?.product_id,
                                        item?.entity_type_id,
                                        "dislike",
                                        "recentViewed"
                                      );
                                    }
                                  }}
                                  src={
                                    item?.customer_favourite_id &&
                                    item?.customer_favourite_id
                                      ? BlueHeart
                                      : heartBorder
                                  }
                                  className="heartBor"
                                  alt=""
                                />
                                {/* <img
                                  src={CouponBadge}
                                  className='couponBadge'
                                  alt=''
                                /> */}
                              </div>
                              <div className="detailsSec">
                                <Link
                                  target="_blank"
                                  to={`/ProductDetails?product_id=${item?.product_id}&pharmacy_shop_id=${item?.pharmacy_shop_id}&entity_type_id=${item?.entity_type_id}`}
                                >
                                  <label htmlFor="" className="headName">
                                    {item?.product}
                                  </label>
                                </Link>
                                {item?.avg_rating && (
                                  <label htmlFor="" className="flexStar">
                                    <img
                                      src={Star}
                                      className="starImg"
                                      alt=""
                                    />{" "}
                                    {item?.avg_rating}
                                  </label>
                                )}
                              </div>
                              <div className="rateBox">
                                <label htmlFor="" className="amntActive">
                                  ₹ {parseFloat(item?.product_rate).toFixed(2)}
                                </label>
                                <label htmlFor="" className="mutedTxt">
                                  ₹ {parseFloat(item?.product_rate).toFixed(2)}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
                // </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDetails;

import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useReduxStore } from "../customHooks/useReduxStore";
import io from "socket.io-client";
import { ApiConfig, BASE_URL } from "../api/ApiConfig";
import useSound from "use-sound";
import callingRingtone from "../assets/sound/night-owl-26908.mp3";
import PopupOverlay from "../pages/Home/PopupOverlay";
import { loadCheckoutScript } from "../paymentCheckout/loadScript";
import { simpleGetCallWithAuthToken } from "../api/ApiServices";

const AppState = (props) => {
  useEffect(() => {
    const scriptLoaded = loadCheckoutScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyC54XwzhinpG_gbIq_leD4kvg2i0_oIJv0&libraries=places"
    );
    if (!scriptLoaded) {
      alert("Please check your internet connection or reload the page");
      return;
    }
  }, []);

  const location = useLocation();
  let currentRoute = location.pathname;
  const [userCalling, setUserCalling] = useState(false);
  const navigate = useNavigate();
  // DATA FROM REDUX STORE
  const {
    customer_id,
    customerLoggedInData: {
      loginData: { user_id },
    },
  } = useReduxStore();

  // DATA FROM REDUX STORE
  const [isAppointmentTabClicked, setIsAppointmentTabClicked] = useState(false);
  const [isAmbulanceTabClicked, setIsAmbulanceTabClicked] = useState(false);
  const [isPatientDet, setIsPatientDet] = useState(false);
  const [isSlot, setIsSlot] = useState(true);
  const [sidebar, setSidebar] = useState(false);
  const [Dark, setDark] = useState("lightMode");
  const [Prescription, setPrescription] = useState(false);
  const [medicalPrescriptionDataModal, setMedicalPrescriptionDataModal] =
    useState(false);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [bkdropLdr, setBkdropLdr] = useState(false);
  const [eventKeyy, setEventKey] = useState("11");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isJoineCall, setIsJoineCall] = useState(false);
  const [doctorListPayLoad, setDoctorListPayLoad] = useState({
    specialist_id: [],
    page_limit: 10,
    page: currentPage,
    customer_id: customer_id,
    startPrice: 0,
    endPrice: 9999,
    startExperience: 0,
    endExperience: 50,
    rating: 0,
    search: "",
  });
  // PHARMACY OR MEDICINE PAYLOAD
  const [medcinePayLoad, setMedcinePayLoad] = useState({
    customer_id: customer_id,
    page: currentPage,
    symptom_id: [],
    type_of_animal_id: [],
    product_brand_id: [],
    product_type_id: [],
    product: "",
    price: "",
    page_limit: 10,
    // lat: 19.898128,
    // lng: 75.3761838,
  });

  const [petShopPayLoad, setPetShopPayLoad] = useState({
    price: "", // High, Low, "" for newest first
    customer_id: customer_id,
    product_type_id: [],
    type_of_animal_id: [],
    animal_grooming_id: [],
    product_brand_id: [],
    page_limit: 10,
    pet_product: "",
    km: 50,
  });
  const [viewAppDetails, setViewAppDetails] = useState(true);
  const [viewAppointment, setViewAppointment] = useState({});
  const [completedAppointment, setCompletedAppointment] = useState([]);
  const [reviewPayLoad, setReviewPayLoad] = useState({
    appointment_id: viewAppointment?.doctor_appointment_id,
    appointment_customer_rating: "1",
    review_title: "",
    appointment_customer_review: "",
  });
  const [AmbulanceFormCharge, setAmbulanceFormCharge] = useState(true);
  const [VideoChatting, setVideoChatting] = useState(true);
  const [CallAccepted, setCallAccepted] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [modalOpen, setModalOpen] = useState(true);
  const [roomId, setRoomid] = useState("");
  const [callId, setCallId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderNameImg, setSenderImg] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [drocterCallOpen, setdrocterCallOpen] = useState(false);
  const [UserCall, setUserCall] = useState(false);
  const dispatch = useDispatch();
  const [drDoctorId, setdrDoctorId] = useState("");
  const [customerIDcall, setcustomerIDcall] = useState("");
  const [socket, setSocket] = useState();
  const joinCallDetails = localStorage.joinCallDetails
    ? JSON.parse(localStorage.joinCallDetails)
    : {};

  const customerID = user_id ? user_id : "";
  let option = {
    timeout: 20000,
    query: {
      UID: customerID,
    },
  };

  useEffect(() => {
    if (customerID) {
      setSocket(io(`${BASE_URL}?UID=${customerID}`, option));
    }
  }, [customerID]);

  useEffect(() => {
    const ambulanceTrackingDetails = (data) => {
      console.log("ambulance track data,", data);
    };
    socket && socket.on("ambulance track", ambulanceTrackingDetails);
  }, [socket]);
  // const [audio, setAudio] = useState(null);

  const audioRef = useRef(null);

  useEffect(() => {
    // setAudio(new Audio(callingRingtone));
    audioRef.current = new Audio(callingRingtone);
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const playAudio = () => {
    try {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.removeEventListener("ended", handleAudioEnded);
        audioRef.current.play();
        audioRef.current.addEventListener("ended", handleAudioEnded);
      }
    } catch (error) {
      console.log("Audio catch error", error);
    }
  };

  const handleAudioEnded = () => {
    audioRef.current.removeEventListener("ended", handleAudioEnded);
    playAudio();
  };

  const stopAudio = () => {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    } catch (error) {
      console.log("Audio catch error", error);
    }
  };

  useEffect(() => {
    socket &&
      socket.on("usercalling", (data) => {
        try {
          playAudio("play");
          sessionStorage.setItem(
            "doctorAppointmentId",
            data.doctor_appointment_id
          );
          setUserCall(true);
          setdrocterCallOpen(true);
          setModalOpen(true);
          setCallId(data.call_id);
          setSenderId(data.senderId);
          setdrDoctorId(data.senderId);
          setReceiverId(data.receiverId);
          setcustomerIDcall(data.receiverId);
          setRoomid(data.room_id);
          setSenderName(data.SenderName);
          setSenderImg(data.SenderProfile);
          sessionStorage.setItem("room_id", data.room_id);
          sessionStorage.setItem("SenderName", data.SenderName);
          sessionStorage.setItem("isCalling", JSON.stringify(false));
          sessionStorage.removeItem("isCallRejected");
        } catch (error) {
          console.log("catch error of usercalling issue,", error);
        }
      });
  }, [socket]);

  useEffect(() => {
    socket &&
      socket.on("endedcall", (data) => {
        // console.log("endedcalldata,", data);
        setdrocterCallOpen(false);
        stopAudio();
        viewAppointMents(data.doctorAppoinmentID);
        sessionStorage.setItem("isCalling", JSON.stringify(true));
        navigate("/ProfileSetting");
        setEventKey("33");
        setIsJoineCall(false);
        sessionStorage.removeItem("doctorAppointmentId");
        sessionStorage.removeItem("SenderName");
        // sessionStorage.removeItem("isCallReceived", JSON.stringify(true));
        const requestBody = {
          call_id: data.call_id,
          room_id: data.room_id,
        };
        socket.emit("callended", requestBody);
      });

    // return () => {
    //   socket && socket.off("endedcall");
    // };
  }, [socket]);
  // socket, callId, roomId

  // useEffect(() => {
  //   socket &&
  //     socket.on("notification", (data) => {
  //       if (data?.notification_type) {
  //         if(data.notification_type?.includes("missedCall")){
  //           setdrocterCallOpen(false);
  //           stopAudio()
  //           // const requestBody = {
  //           //   call_id: callId,
  //           //   room_id: roomId,
  //           // };
  //           // socket.emit("callended", requestBody);
  //         }
  //       }
  //     });
  //   return () => {
  //     socket && socket.off("notification");
  //   };
  // }, [socket]);

  const viewAppointMents = async (appointment_id) => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${
          ApiConfig.VIEW_APPOINTMENT
        }?appointment_id=${appointment_id}&customer_id=${customer_id}&status=${5}`
      );

      const { success, currentAppointment, completedAppointments, videoCall } =
        res?.json;
      if (success) {
        setViewAppDetails(false);
        currentAppointment && setViewAppointment(currentAppointment);
        setCompletedAppointment(completedAppointments);
        currentAppointment &&
          setReviewPayLoad({
            ...reviewPayLoad,
            appointment_customer_rating: "1",
            appointment_id: currentAppointment?.doctor_appointment_id,
          });
        setdrDoctorId(currentAppointment.doctorDetails.user_id);
        setIsCallAccepted(false);
        localStorage.setItem("joinCallDetails", JSON.stringify(videoCall));
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const rejectCall = () => {
    setUserCalling(false);
    stopAudio();
    const requestBody = {
      senderId: senderId,
      receiverId: receiverId,
      call_id: callId,
      room_id: roomId,
      rejectedBy: user_id ? user_id : "",
    };

    socket.emit("rejectcall", requestBody);
    // sessionStorage.setItem("isCallRejected", JSON.stringify(true));
    setModalOpen(false);
    setdrocterCallOpen(false);
  };

  const answerCall = () => {
    stopAudio();
    const requestBody = {
      senderId: receiverId,
      receiverId: senderId,
      call_id: callId,
      room_id: roomId,
    };

    socket.emit("answercall", requestBody);
    sessionStorage.setItem("isCallReceived", JSON.stringify(true));
    navigate("/chatcomponent");
    setdrocterCallOpen(false);
    setIsCallAccepted(true);
    setModalOpen(false);
    setEventKey(33);
    setCallAccepted(true);
  };

  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (currentRoute.includes("DoctorsList")) {
      setSelectedCategory(currentRoute);
    } else if (currentRoute.includes("Medicines")) {
      setSelectedCategory(currentRoute);
    } else if (currentRoute.includes("PetShops")) {
      setSelectedCategory(currentRoute);
    } else {
      setSelectedCategory("");
    }
  }, [selectedCategory, currentRoute]);

  var validNumber = new RegExp(/^\d*\.?\d*$/);
  return (
    <div>
      <AppContext.Provider
        value={{
          isPatientDet,
          setIsPatientDet,
          isSlot,
          setIsSlot,
          sidebar,
          setSidebar,
          Dark,
          setDark,
          Prescription,
          setPrescription,
          medicalPrescriptionDataModal,
          setMedicalPrescriptionDataModal,
          isTimeExpired,
          setIsTimeExpired,
          navigate,
          dispatch,
          bkdropLdr,
          setBkdropLdr,
          eventKeyy,
          setEventKey,
          searchValue,
          setSearchValue,
          doctorListPayLoad,
          setDoctorListPayLoad,
          currentPage,
          setCurrentPage,
          medcinePayLoad,
          setMedcinePayLoad,
          petShopPayLoad,
          setPetShopPayLoad,
          modalOpen,
          setModalOpen,
          socket,
          setSocket,
          drocterCallOpen,
          setdrocterCallOpen,
          roomId,
          setRoomid,
          callId,
          setCallId,
          senderId,
          setSenderId,
          receiverId,
          setReceiverId,
          VideoChatting,
          setVideoChatting,
          CallAccepted,
          setCallAccepted,
          rejectCall,
          answerCall,
          // handleMouseLeave,
          senderName,
          setSenderName,
          senderNameImg,
          setSenderImg,
          drDoctorId,
          setdrDoctorId,
          isCallAccepted,
          setIsCallAccepted,
          customerIDcall,
          setcustomerIDcall,
          UserCall,
          setUserCall,
          notificationCount,
          setNotificationCount,
          notifications,
          setNotifications,
          validNumber,
          playAudio,
          // handleMouseLeave,
          viewAppDetails,
          setViewAppDetails,
          viewAppointment,
          setViewAppointment,
          completedAppointment,
          setCompletedAppointment,
          reviewPayLoad,
          setReviewPayLoad,
          // stop,
          // play,
          customerID,
          option,
          isJoineCall,
          setIsJoineCall,
          currentRoute,
          selectedCategory,
          setSelectedCategory,
          joinCallDetails,
          AmbulanceFormCharge,
          setAmbulanceFormCharge,
          isAppointmentTabClicked,
          setIsAppointmentTabClicked,
          isAmbulanceTabClicked,
          setIsAmbulanceTabClicked,
          stopAudio,
        }}
      >
        {props.children}
        {drocterCallOpen && <PopupOverlay />}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;

import React, { useContext, useEffect, useRef, useState } from "react";
import AgoraUIKit from "agora-react-uikit";
import moment from "moment";
import { AppContext } from "../../context/AppContext";
import { useReduxStore } from "../../customHooks/useReduxStore";
import {
  simpleGetCallWithAuthToken,
  simplePostCall,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { notifyError } from "../../ToastMessages/notify";
import btnSend from "../../assets/images/send_chat.svg";
import Subheader from "../../sharedComponent/Subheader";
import { useNavigate } from "react-router";

const Chatcomponent = () => {
  const {
    roomId,
    setRoomid,
    callId,
    setCallId,
    VideoChatting,
    setVideoChatting,
    socket,
    drDoctorId,
    setdrDoctorId,
    CallAccepted,
    isCallAccepted,
    setIsCallAccepted,
    viewAppointment,
    setViewAppointment,
    completedAppointment,
    setCompletedAppointment,
    reviewPayLoad,
    setReviewPayLoad,
    setEventKey,
    setBkdropLdr,
    setViewAppDetails,
    setdrocterCallOpen,
    stop,
    setIsJoineCall,
  } = useContext(AppContext);
  const SenderName = sessionStorage.SenderName ? sessionStorage.SenderName : "";
  const doctor_id = sessionStorage.doctor_id ? sessionStorage.doctor_id : "";
  const docAppointMentId = sessionStorage.doctorAppointmentId
    ? sessionStorage.doctorAppointmentId
    : "";

  const { customer_id, customerLoggedInData } = useReduxStore();

  const [recentChatdata, setRecentChatdata] = useState([]);
  const lastMessageRef = useRef(null);
  const customerID = customerLoggedInData.loginData.user_id;

  const [message, setMessage] = useState("");
  const [newmessage, setnewmessage] = useState([]);
  const navigate = useNavigate();
  const [ChatID, setChatID] = useState("");

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [recentChatdata, newmessage]);

  useEffect(() => {
    socket &&
      socket.on("message created", (value) => {
        console.log("message created,", value);
        setnewmessage((prev) => [...prev, value]);
      });
    return () => {
      socket && socket.off("message created");
    };
  }, [socket]);

  const sendMessage = async () => {
    setMessage("");
    const messageSendPayload = {
      senderId: customerID,
      receiverId: drDoctorId || doctor_id,
      chat_to_entity_type_id: 1,
      chat_from_entity_type_id: 2,
      message: message,
      chat_id: ChatID,
    };
    console.log("messageSendPayload,", messageSendPayload);
    socket && socket.emit("new message", messageSendPayload);
  };

  useEffect(() => {
    handleHistery();
  }, [drDoctorId || doctor_id]);

  // Chat Histery  ///
  const handleHistery = async () => {
    // sethasmore(true);

    setnewmessage([]);

    // setIndex(index + 1)
    let newRequestBody = {
      doctor_id: drDoctorId || doctor_id,
      customer_id: customerID,
      chat_to_entity_type_id: 1,
      chat_from_entity_type_id: 2,
      page: 1,
    };
    simplePostCall(ApiConfig.CHAT_HISTRY, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          var lastmsg = data.chat_data[0].chat_id;
          setChatID(lastmsg);

          setRecentChatdata([...data.chat_data.reverse()]);
          if (data.total_pages - data.current_page === 0) {
            // sethasmore(false);
          }
        } else {
          setRecentChatdata([]);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  // const [isCallAccepted, setIsCallAccepted] = useState(false);
  const room_id = sessionStorage.room_id ? sessionStorage.room_id : "";
  console.log("room_id,", room_id);
  const rtcProps = {
    appId: "bc1dec31c1f1414089c89d44f29b9267",
    channel: roomId || room_id,
    token: null,
    role: "host",
    uId: customerID,
  };

  useEffect(() => {
    room_id && setIsCallAccepted(true);
  }, [room_id]);

  useEffect(() => {
    if (VideoChatting === false) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        var constraints = {
          audio: true,
          video: true,
        };

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            /* use the stream */
            // setIsCallAccepted(true);
          })
          .catch(function (err) {
            /* handle the error */
            notifyError("Camera not accessible");
            // setIsCallAccepted(false);
          });
      } else {
        // Handle the case where getUserMedia is not supported
        notifyError("getUserMedia is not supported in this browser.");
      }
    }
  }, [VideoChatting]);

  // Sender initiates the call
  console.log("customerID,", customerID);
  console.log("doctor_id,", doctor_id);
  console.log("docAppointMentId,", docAppointMentId);
  const initiateCall = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
        video: true,
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          const requestBody = {
            senderId: customerID,
            receiverId: drDoctorId || doctor_id,
            call_from_entity_type_id: 1,
            call_to_entity_type_id: 2,
            doctor_appointment_id: docAppointMentId,
          };
          socket && socket.emit("calluser", requestBody);
        })
        .catch(function (err) {
          notifyError("Camera not accessible");
          setIsCallAccepted(false);
        });
    } else {
      notifyError("User Media is not supported in this browser!");
    }
  };

  useEffect(() => {
    socket &&
      socket.on("callcreated", (data) => {
        console.log("Received message call callcreated:", data);
        setCallId(data?.call_id);
        setRoomid(data?.room_id);

        // Determine your role based on some criteria
        if (data.senderId === 1) {
          // You are the sender
          // Handle sender-specific logic here
        } else if (data.receiverId === 1) {
          // You are the receiver
          // Handle receiver-specific logic here
        }
      });

    return () => {
      socket && socket.off("callcreated");
    };
  }, [socket]);

  const endCall = () => {
    navigate("/ProfileSetting");
    setEventKey("33");
    setIsJoineCall(true);
    // const requestBody = {
    //   // senderId: customerID,
    //   // receiverId: drDoctorId,
    //   call_id: callId,
    //   room_id: roomId,
    // };

    // socket.emit("callended", requestBody);
    viewAppointMents(docAppointMentId);
    setIsCallAccepted(false);
    localStorage.removeItem("joinCallDetails");
    localStorage.removeItem("room_id");
    sessionStorage.setItem("isCalling", JSON.stringify(false));
    // setVideoChatting(false)
  };

  const rtcCallbacks = {
    EndCall: () => [endCall(), setIsCallAccepted(false)],
  };

  const viewAppointMents = async (appointment_id) => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${
          ApiConfig.VIEW_APPOINTMENT
        }?appointment_id=${appointment_id}&customer_id=${customer_id}&status=${5}`
      );

      const { success, currentAppointment, completedAppointments, videoCall } =
        res?.json;
      if (success) {
        setViewAppDetails(false);
        currentAppointment && setViewAppointment(currentAppointment);
        setCompletedAppointment(completedAppointments);
        currentAppointment &&
          setReviewPayLoad({
            ...reviewPayLoad,
            appointment_customer_rating: "1",
            appointment_id: currentAppointment?.doctor_appointment_id,
          });
        setdrDoctorId(currentAppointment.doctorDetails.user_id);
        setIsCallAccepted(false);
        localStorage.setItem("joinCallDetails", JSON.stringify(videoCall));
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  useEffect(() => {
    
    socket && socket.on("endedcall", (data) => {
      console.log("endedcalldata,", data)
      try {
        const requestBody = {
          call_id: data.call_id,
          room_id: data.room_id,
        };
        socket.emit("callended", requestBody);
        viewAppointMents(data.doctorAppoinmentID);
        sessionStorage.setItem("isCalling", JSON.stringify(true));
        navigate("/ProfileSetting");
        setEventKey("33");
        setIsJoineCall(false);
        sessionStorage.removeItem("doctorAppointmentId");
        sessionStorage.removeItem("SenderName");
        setdrocterCallOpen(false);
      } catch (error) {
        console.log("catch error,", error);
      }
    });

    return () => {
      socket && socket.off("endedcall");
      sessionStorage.removeItem("doctor_id");
      sessionStorage.removeItem("docAppointMentId");
    };
  }, [socket]);

  useEffect(() => {
    // Check and request permissions for microphone and camera
    socket &&
      socket.on("callrejected", (data) => {
        // setIsCallAccepted(false);
        // notifyError("Call rejected!");
        // Handle the response from the "usercalling" event here
      });

    return () => {
      socket && socket.off("callrejected");
    };
  }, [socket]);

  return (
    <div className="my-appointment-wrapper" id="cx-main">
      {/* <Subheader /> */}
      <div className="video-chatting-wrapper" id="ChattingVideoComp">
        <div className="row">
          <div className="col-md-8">
            <div className="left-video">
              <h4>Appointment with : {SenderName ? SenderName : ""}</h4>
              <div className="video-section">
                <div className="left-video   heightCalc">
                  {/* <img src={video_dummy} alt='' className='w-100' /> */}

                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "50vh",
                    }}
                  >
                    {isCallAccepted ? (
                      <AgoraUIKit
                        rtcProps={rtcProps}
                        callbacks={rtcCallbacks}
                        style={{
                          resizeMode: "cover",
                          width: "100%",
                          overflow: "hidden",
                          borderRadius: 10,
                          borderWidth: 2,
                          borderColor: "red",
                        }}
                      />
                    ) : (
                      <>
                        {""}
                        <div className="userCallSec mb-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            fill="#000"
                            class="bi bi-person-video3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2Z" />
                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783.059-.187.09-.386.09-.593V4a2 2 0 0 0-2-2H2Z" />
                          </svg>
                        </div>
                        <p className="text-center">
                          <button
                            className="btn btn-danger"
                            onClick={initiateCall}
                          >
                            Start Call
                          </button>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="right-chating">
              <h4>Chat</h4>
              <div className="chatting-section text-center">
                <div className="message-main">
                  {recentChatdata.map((chatData, i) => {
                    return customerID == chatData.conversation_from ? (
                      <>
                        <div className="send-msg">
                          <div className="msg-with-img">
                            <img
                              src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                              alt=""
                            />
                          </div>
                          <div className="send-msg-main">
                            <div className="msg-bg">
                              <span className="senderName">
                                {chatData.conversation_from_name}
                              </span>

                              <p className="msg-text">
                                {chatData.conversation_message}
                                {/* Jane wala */}
                              </p>
                            </div>
                            <div className="time-date-sms-send">
                              {moment(chatData.conversation_datetime).fromNow()}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={i} className="receive-msg">
                          <div className="msg-with-img">
                            <img
                              src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                              alt=""
                            />
                          </div>
                          <div className="right-receive-msg">
                            <div className="msg-bg">
                              <span className="senderName">
                                {chatData.conversation_from_name}
                              </span>
                              <div className="receive-msg-main">
                                <p className="msg-text">
                                  {chatData.conversation_message}
                                </p>
                              </div>
                            </div>
                            <div className="time-date-sms-recieve">
                              {moment(chatData.conversation_datetime).fromNow()}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {newmessage.map((chatData, i) => {
                    return customerID == chatData.conversation_from ? (
                      <>
                        <div className="send-msg">
                          <div className="msg-with-img">
                            <img
                              src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                              alt=""
                            />
                          </div>
                          <div className="send-msg-main">
                            <div className="msg-bg">
                              <span className="senderName">
                                {chatData.conversation_from_name}
                              </span>

                              <p className="msg-text">
                                {chatData.conversation_message}
                                {/* Jane wala */}
                              </p>
                            </div>
                            <div className="time-date-sms-send">
                              {moment(chatData.conversation_datetime).fromNow()}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={i} className="receive-msg">
                          <div className="msg-with-img">
                            <img
                              src="https://api.wifiattendance.com/assets/uploads/icon-user-default.png"
                              alt=""
                            />
                          </div>
                          <div className="right-receive-msg">
                            <div className="msg-bg">
                              <span className="senderName">
                                {chatData.conversation_from_name}
                              </span>
                              <div className="receive-msg-main">
                                <p className="msg-text">
                                  {/* {chatData.conversation_message} */}
                                  {chatData.conversation_message}
                                </p>
                              </div>
                            </div>
                            <div className="time-date-sms-recieve">
                              {moment(chatData.conversation_datetime).fromNow()}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div ref={lastMessageRef} />
              </div>

              <div className="send-text-sec">
                <div className="wrap-btn-send">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Type Something..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={(e) => {
                      if (message && e.key == "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                  {/* <button>
                      <img src="" alt="" />
                      <img src={btnSend} alt="" />
                    </button> */}
                  <button>
                    <img
                      onClick={() => message && sendMessage()}
                      onKeyUp={sendMessage}
                      src={btnSend}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            {/* <div className='right-chatting wrapper-cards'>
                <img src={chatting_dummy} alt='' className='w-100' />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatcomponent;

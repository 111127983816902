import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../sharedComponent/Header";
import Subheader from "../../sharedComponent/Subheader";
import ic_support from "../../assets/images/ic_support.svg";
import ic_req_ambulance from "../../assets/images/ic_req_ambulance.svg";
import ic_animal_prfl from "../../assets/images/ic_animal_prfl.svg";
import ic_my_apmt from "../../assets/images/ic_my_apmt.svg";
import ic_my_order from "../../assets/images/ic_my_order.svg";
import ic_my_transaction from "../../assets/images/ic_my_transaction.svg";
import ic_my_ac from "../../assets/images/ic_my_ac.svg";
import Star from "../../assets/images/Star.svg";
import BlueImgArow from "../../assets/images/darkIcon.svg";
import liteImgArow from "../../assets/images/lightIcon.svg";
import MyProfileComp from "./MyProfileComp";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MyAnimalProfileComp from "./MyAnimalProfileComp";
import MyAppointmentsComp from "./MyAppointmentsComp";
import RequestAmbulancComp from "./RequestAmbulancComp";
import MyTransactionComp from "./MyTransactionComp";
import SupportComp from "./SupportComp";
import MyOrders from "./MyOrders";
import { simpleGetCallWithAuthToken } from "../../api/ApiServices";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { ApiConfig } from "../../api/ApiConfig";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import { AppContext } from "../../context/AppContext";
import PopupOverlay from "../Home/PopupOverlay";
import Accordion from "react-bootstrap/Accordion";
import arrowAActive from "../../assets/images/icons/ArrowActive.svg";
import VaccinatorAppointments from "./VaccinatorAppointments";
import RecentAmbulanceBookings from "./RecentAmbulanceBookings";
import { loadCheckoutScript } from "../../paymentCheckout/loadScript";

const ProfileSetting = () => {
  const {
    bkdropLdr,
    eventKeyy,
    setEventKey,
    drocterCallOpen,
    isAppointmentTabClicked,
    setIsAppointmentTabClicked,
    isAmbulanceTabClicked,
    setIsAmbulanceTabClicked,
    setViewAppDetails
  } = useContext(AppContext);

  const [totalAppointMents, setTotalAppointMents] = useState("");
  const [totalVacAppointMents, setTotalVacAppointMents] = useState("");
  const [totalOrders, setTotalOrders] = useState("");

  useEffect(() => {
    const scriptLoaded = loadCheckoutScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyC54XwzhinpG_gbIq_leD4kvg2i0_oIJv0&libraries=places"
    );
    if (!scriptLoaded) {
      alert("Please check your internet connection or reload the page");
      return;
    }

    return () => {
      setIsAppointmentTabClicked(false);
      setIsAmbulanceTabClicked(false);
      setViewAppDetails(false)
    };
  }, []);

  return (
    <>
      <div className="DoctorsList ProfileSetting" id="cx-main">
        <Subheader />

        <Tab.Container id="left-tabs-example" defaultActiveKey={eventKeyy}>
          <div className="row wrapper gx-3">
            <div className="col-md-3">
              <div className="left-section wrapper-cards">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="11">
                      <div
                        className="menu-wrapper b-b"
                        onClick={() => setEventKey("11")}
                      >
                        <Link>
                          <img src={ic_my_ac} alt="" />
                          My Profile
                        </Link>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="22">
                      <div
                        className="menu-wrapper b-b"
                        onClick={() => setEventKey("22")}
                      >
                        <Link>
                          <img src={ic_animal_prfl} alt="" />
                          My Animal Profile
                        </Link>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={{ marginBottom: "10px" }}>
                    <Accordion
                      defaultActiveKey={isAppointmentTabClicked && "0"}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <span>
                            <img src={ic_my_apmt} alt="" />
                            My Appointments
                          </span>{" "}
                          <div className="appointment-acc">
                            {/* <img src={arrowAActive} alt="im" /> */}
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <Nav.Link eventKey="33">
                            <div
                              className="menu-wrapper b-b count-main"
                              onClick={() => setEventKey("33")}
                            >
                              <Link>
                                <img src={ic_my_apmt} alt="" />
                                Doctors
                              </Link>
                              {totalAppointMents && (
                                <div className="count">{totalAppointMents}</div>
                              )}
                            </div>
                          </Nav.Link>
                          <Nav.Link eventKey="888">
                            <div
                              className="menu-wrapper b-b count-main"
                              onClick={() => setEventKey("888")}
                            >
                              <Link>
                                <img src={ic_my_apmt} alt="" />
                                Vaccinators
                              </Link>
                              {totalVacAppointMents && (
                                <div className="count">
                                  {totalVacAppointMents}
                                </div>
                              )}
                            </div>
                          </Nav.Link>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Item>
                  <Nav.Item style={{ marginBottom: "10px" }}>
                    <Accordion defaultActiveKey={isAmbulanceTabClicked && "01"}>
                      <Accordion.Item eventKey="01">
                        <Accordion.Header>
                          <span>
                            <img src={ic_my_apmt} alt="" />
                            Request Ambulance
                          </span>{" "}
                          <div className="appointment-acc">
                            {/* <img src={arrowAActive} alt="im" /> */}
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <Nav.Link eventKey="44">
                            <div
                              className="menu-wrapper b-b count-main"
                              onClick={() => setEventKey("44")}
                            >
                              <Link>
                                <img src={ic_req_ambulance} alt="" />
                                Ambulance List
                              </Link>
                            </div>
                          </Nav.Link>
                          <Nav.Link eventKey="454">
                            <div
                              className="menu-wrapper b-b count-main"
                              onClick={() => setEventKey("454")}
                            >
                              <Link>
                                <img src={ic_req_ambulance} alt="" />
                                Recent Bookings
                              </Link>
                            </div>
                          </Nav.Link>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="44">
                      <div
                        className="menu-wrapper b-b"
                        onClick={() => setEventKey("44")}
                      >
                        <Link>
                          <img src={ic_req_ambulance} alt="" />
                          Request Ambulance
                        </Link>
                      </div>
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="55">
                      <div
                        className="menu-wrapper b-b count-main"
                        onClick={() => setEventKey("55")}
                      >
                        <Link>
                          <img src={ic_my_order} alt="" />
                          My Orders
                        </Link>
                        {totalOrders && (
                          <div className="count">{totalOrders}</div>
                        )}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="66">
                      <div
                        className="menu-wrapper b-b"
                        onClick={() => setEventKey("66")}
                      >
                        <Link>
                          <img src={ic_my_transaction} alt="" />
                          My Transactions
                        </Link>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="77">
                      <div
                        className="menu-wrapper "
                        onClick={() => setEventKey("77")}
                      >
                        <Link>
                          <img src={ic_support} alt="" />
                          Support
                        </Link>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>

            <div className="col-md-9">
              {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}
              <Tab.Content>
                <Tab.Pane eventKey="11">
                  <MyProfileComp />
                </Tab.Pane>
                <Tab.Pane eventKey="22">
                  <MyAnimalProfileComp />
                </Tab.Pane>
                <Tab.Pane eventKey="33">
                  <MyAppointmentsComp
                    setTotalAppointMents={setTotalAppointMents}
                    totalAppointMents={totalAppointMents}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="888">
                  <VaccinatorAppointments
                    setTotalVacAppointMents={setTotalVacAppointMents}
                    totalVacAppointMents={totalVacAppointMents}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="44">
                  <RequestAmbulancComp />
                </Tab.Pane>
                <Tab.Pane eventKey="454">
                  <RecentAmbulanceBookings />
                </Tab.Pane>
                <Tab.Pane eventKey="55">
                  <MyOrders setTotalOrders={setTotalOrders} />
                </Tab.Pane>
                <Tab.Pane eventKey="66">
                  <MyTransactionComp />
                </Tab.Pane>
                <Tab.Pane eventKey="77">
                  <SupportComp />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default ProfileSetting;

import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ic_bell_green from "../assets/images/ic_bell_green.svg";
import ic_account_green from "../assets/images/ic_account_green.svg";
import ic_bag_green from "../assets/images/ic_bag_green.svg";
import ic_bell_light from "../assets/images/ic_bell_light.svg";
import ic_account_light from "../assets/images/ic_account_light.svg";
import ic_bag_light from "../assets/images/ic_bag_light.svg";
import ic_search_green from "../assets/images/ic_search_green.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import appointment_icon_green from "../assets/images/appointment_icon_green.svg";
import appointment_icon_white from "../assets/images/appointment_icon_white.svg";
import ic_ac_setting from "../assets/images/ic_ac_setting.png";
import ic_logout from "../assets/images/ic_logout.svg";
import user_img from "../assets/images/user_img.png";
import ic_req_ambulance from "../assets/images/ic_req_ambulance.svg";
import Logo from "../assets/images/mainLogo.svg";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import { AppContext } from "../context/AppContext";
import { logoutUser } from "../redux/logoutAction";
import { logout } from "../redux/Slices/authSlice";
import { Modal } from "react-bootstrap";
import { useReduxStore } from "../customHooks/useReduxStore";
import { simpleGetCallWithAuthToken } from "../api/ApiServices";
import { ApiConfig } from "../api/ApiConfig";
import close from "../assets/images/icons/close.svg";
import dummyprofile from "../assets/images/dummyprofile.jpeg";
import { notificationMsg } from "../ToastMessages/notify";
import useSound from "use-sound";
import notificationRing from "../assets/sound/Notifica.mp3";
import { addToCart, increaseCartCount } from "../redux/Slices/cartSlice";
import { useDebounce } from "../customHooks/useDebounce";
import ic_my_order from "../assets/images/ic_my_order.svg";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const {
    dispatch,
    navigate,
    searchPayLoad,
    searchValue,
    setSearchValue,
    doctorListPayLoad,
    setDoctorListPayLoad,
    medcinePayLoad,
    setMedcinePayLoad,
    petShopPayLoad,
    setPetShopPayLoad,
    eventKeyy,
    setEventKey,
    socket,
    notificationCount,
    setNotificationCount,
    notifications,
    setNotifications,
    selectedCategory,
    setSelectedCategory,
    setIsAppointmentTabClicked,
    setIsAmbulanceTabClicked,
    setdrocterCallOpen,
    stopAudio
  } = useContext(AppContext);

  useEffect(() => {
    fetchNotifications();
  }, []);
  const [loader, setLoader] = useState(false);

  const fetchNotifications = async () => {
    try {
      setLoader(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_NOTIFICATION_LIST}/cus_${customer_id}`
      );

      const { success, data } = res?.json;
      if (success) {
        setNotifications(data);
        setNotificationCount(data.length);
      }
    } catch (error) {
      console.log("catch error response,", error);
    } finally {
      setLoader(false);
    }
  };

  const [play] = useSound(notificationRing);

  useEffect(() => {
    socket &&
      socket.on("notification", (data) => {
        if (data?.text) {
          play();
          notificationMsg(data?.text);
          fetchNotifications();
          // setNotificationCount(notificationCount + 1)
        }
      });
    return () => {
      socket && socket.off("notification");
    };
  }, [socket]);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // DATA FROM REDUX STORE
  const { cartItemCount, cartList, customerLoggedInData, customer_id } =
    useReduxStore();
  // DATA FROM REDUX STORE

  const { loginData } = customerLoggedInData && customerLoggedInData;

  useEffect(() => {
    getAnimalInformation();
    fetchCartList();
  }, []);

  const [animals, setAnimals] = useState("");

  const getAnimalInformation = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_ANIMAL_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      const names = data && data.map((item) => item.name && item.name);
      setAnimals(names.join(", "));

      if (success === true) {
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  // my-cart-list
  const fetchCartList = async () => {
    try {
      setLoader(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_CART_LIST}?customer_id=${customer_id}`
      );
      const { data, result } = res.json;
      if (result && data) {
        // setCartList(data)
        dispatch(addToCart(data));
        dispatch(increaseCartCount(data.length));
      } else {
        dispatch(addToCart([]));
        dispatch(increaseCartCount(0));
      }
    } catch (error) {
      console.log("catch error :,", error);
    } finally {
      setLoader(false);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    localStorage.clear();
    socket && socket.disconnect();
  };
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedInputValue = useDebounce(searchTerm, 400);

  useEffect(() => {
    setDoctorListPayLoad({
      ...doctorListPayLoad,
      search: debouncedInputValue,
    });

    setMedcinePayLoad({
      ...medcinePayLoad,
      product: debouncedInputValue,
    });

    setPetShopPayLoad({
      ...petShopPayLoad,
      pet_product: debouncedInputValue,
    });
  }, [debouncedInputValue]);

  return (
    <div className="cx-header-wrapper">
      <div className="header-top-section">
        <div className="text-info">
          <span className="circle-filled"></span>
          <label htmlFor="">Top vets from all over country</label>
        </div>
        <div className="text-info">
          <span className="circle-filled"></span>
          <label htmlFor="">24/7 veterinarian assistance</label>
        </div>
        <div className="text-info">
          <span className="circle-filled"></span>
          <label htmlFor="">Fast medicine delivery</label>
        </div>
        {/* <div className="text-info">
          <span className="circle-filled"></span>
          <label htmlFor="">Easy Return Policy</label>
        </div> */}
      </div>
      <div
        className={
          scrolled
            ? " scrollActive header-bottom-section"
            : "header-bottom-section"
        }
      >
        <div className="first">
          <div className="logo-wrapper">
            <Link to="/Home">
              <img src={Logo} alt="" />
            </Link>
            {/* <label htmlFor="">Your Logo</label> */}
          </div>
        </div>
        <div className="second">
          <div className="search-select-wrapper">
            <Form.Select
              aria-label="Default select example"
              value={selectedCategory}
              onChange={(e) => {
                const { value } = e.target;
                if (value) {
                  navigate(value);
                  setSelectedCategory(value);
                }
              }}
            >
              <option value="">Select Type</option>
              <option value="/DoctorsList">Doctors</option>
              <option value="/Medicines">Medicines</option>
              <option value="/PetShops">Animal Foods and Products </option>
              {/* <option value='/vaccinators'>Vaccinators</option> */}
            </Form.Select>
            <Form.Control
              disabled={!selectedCategory ? true : false}
              type="text"
              placeholder="Search for Doctors, Animal Foods, Pharmacy..."
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="third">
          <div
            className="appointment-btn"
            onClick={() => {
              navigate("/ProfileSetting");
              setEventKey("33");
              setIsAppointmentTabClicked(true)
            }}
          >
            {scrolled ? (
              <img src={appointment_icon_white} alt="" />
            ) : (
              <img src={appointment_icon_green} alt="" />
            )}
            <label className={scrolled ? "label-white" : "label-green"}>
              My Appointments
            </label>{" "}
          </div>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <div className="my-ac-wrapper" id="dropdown-basic">
                {scrolled ? (
                  <img src={ic_account_light} alt="" />
                ) : (
                  <img src={ic_account_green} alt="" />
                )}
                <label htmlFor="">My Account</label>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {loginData?.first_name && loginData?.last_name && (
                <div className="name-img-user">
                  <div className="left">
                    <img
                      src={
                        loginData?.profile_image_path
                          ? loginData?.profile_image_path
                          : dummyprofile
                      }
                      onError={(e) => {
                        e.target.src = dummyprofile;
                      }}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div className="right">
                    <label htmlFor="" className="name">
                      {`${loginData?.first_name} ${loginData?.last_name}`}
                    </label>
                    <p className="animal-name">{animals && animals}</p>
                  </div>
                </div>
              )}

              <div
                className="menu"
                onClick={() => {
                  navigate("/ProfileSetting");
                  setEventKey("11");
                }}
              >
                <Dropdown.Item>
                  {/* <Link to='/ProfileSetting'> */}
                  <span>
                    <img src={ic_ac_setting} alt="" />
                    Account Settings
                  </span>
                  {/* </Link> */}
                </Dropdown.Item>
              </div>
              <div
                className="menu"
                onClick={() => {
                  setEventKey("55");
                  navigate("/ProfileSetting");
                }}
              >
                <Dropdown.Item>
                  <span>
                    <img src={ic_my_order} alt="" />
                    My Orders
                  </span>
                </Dropdown.Item>
              </div>
              <div
                className="menu"
                onClick={() => {
                  setEventKey("44");
                  navigate("/ProfileSetting");
                  setIsAmbulanceTabClicked(true)
                }}
              >
                <Dropdown.Item>
                  {/* <Link to='/AmbulanceBook'> */}
                  <span>
                    <img src={ic_req_ambulance} alt="" />
                    Request Ambulance
                  </span>
                  {/* </Link> */}
                </Dropdown.Item>
              </div>
              {customer_id && (
                <div
                  className="menu"
                  id="logout"
                  onClick={() => {
                    // handleLogout();
                    setShowLogoutModal(true);
                  }}
                >
                  {/* <Link to="/"> */}
                  <span>
                    <img
                      // className='logoutImg'
                      src={ic_logout}
                      alt=""
                    />
                    Logout
                  </span>

                  {/* </Link> */}
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>

          <div
            className="my-blog-wrapper"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/cart");
            }}
          >
            <div className="img-badg">
              {scrolled ? (
                <img src={ic_bag_light} alt="" />
              ) : (
                <img src={ic_bag_green} alt="" />
              )}

              {/* {cartList && cartList.length > 0 && (
                <span>{cartList?.length}</span>
              )} */}
              <span>{cartItemCount}</span>
            </div>
            <label style={{ cursor: "pointer" }}>My Bag</label>
          </div>
          <div className="bell-wrapper">
            <div className="img-badg">
              {scrolled ? (
                <div
                  className="clickerNaviwhite"
                  onClick={() => navigate("/Notification")}
                >
                  <img src={ic_bell_light} alt="" />
                  <div>
                    <span>{notificationCount}</span>
                  </div>
                </div>
              ) : (
                <div
                  className="clickerNavigreen"
                  onClick={() => navigate("/Notification")}
                >
                  <img src={ic_bell_green} alt="" />
                  <div>
                    <span>{notificationCount}</span>
                  </div>
                </div>
              )}
              {/*<span>5</span>*/}
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLogoutModal}
        className="create-workflow-main modal-sm"
        onHide={() => setShowLogoutModal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              User Logout
            </label>
          </Modal.Title>
          <img
            style={{ cursor: "pointer" }}
            src={close}
            alt=""
            onClick={() => setShowLogoutModal(false)}
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body className="text-center">
          Are you sure you want to Logout ?
        </Modal.Body>
        <div className="text-center" style={{ padding: "15px" }}>
          <button
            className="modal-cancel-btn sm-btn-delete"
            onClick={() => setShowLogoutModal(false)}
          >
            Cancel
          </button>
          <button
            className="modal-create-btn sm-btn-delete"
            onClick={handleLogout}
          >
            Yes
          </button>
        </div>
        {/* </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Header;

import React, { useContext, useEffect, useRef, useState } from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// MODAL
import ic_call from "../../../assets/images/ic_call.svg";
import ic_location from "../../../assets/images/ic_location.svg";
import sign from "../../../assets/images/sign.png";
import ic_mail from "../../../assets/images/ic_mail.svg";
// MODAL

import { ApiConfig } from "../../../api/ApiConfig";
import { simpleGetCallWithAuthToken } from "../../../api/ApiServices";

const DoctorPrecription = ({
  pdfExportComponent,
  setPrescriptionData,
  viewAppointment,
  prescriptionData,
  isPrescription,
  hidden,
  setHidden,
  divToPdf,
}) => {
  // const pdfExportComponent = useRef(null)

  const downLoadPDF = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_PRESCRIPTION_DATA}?doctor_appointment_id=${viewAppointment?.doctor_appointment_id}`
      );
      const { success, data } = res?.json;
      if (success && data) {
        setPrescriptionData(data[0]);
        setTimeout(() => {
          if (pdfExportComponent.current) {
            const contentDiv = document.getElementById("prescription-modal");
            contentDiv.style.visibility = "visible";
            pdfExportComponent.current.save();
            contentDiv.style.visibility = "hidden";
          }
        }, 100);
      }
    } catch (error) {
      console.log("catch error:,", error);
    }
  };
  // const originalURL = prescriptionData?.doctor?.doctor_signature
  // const url = new URL(originalURL);

  // url.pathname = '/new/path';
  // url.searchParams.set('key', 'value');

  // const modifiedURL = url.toString();

  // console.log('Modified URL:', modifiedURL);

  return (
    <div
      // id='prescription-modal'
      className="prescription-wrapper-main"
      // style={!hidden ? { visibility: "hidden" } : {}}
      style={{ display: hidden, marginTop: "800px" }}
      id={divToPdf}
    >
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        // margin={40}
        fileName={"Medicines"}
        author="KendoReact Team"
      >
        <div className="orderModalInvoice">
          <div className="modalHeading">
            <div className="upperBlue">
              {isPrescription && (
                <h1 className="docName">
                  {prescriptionData?.doctor?.doctor_name
                    ?.toLowerCase()
                    ?.includes("dr")
                    ? ""
                    : "Dr."}
                  {prescriptionData?.doctor?.doctor_name}{" "}
                </h1>
              )}
              {isPrescription && (
                <label className="domainLab">
                  {prescriptionData?.doctor?.doctor_specialization
                    ? prescriptionData?.doctor?.doctor_specialization
                    : ""}{" "}
                </label>
              )}
              <label className="certificateLab">Prescription</label>
            </div>
            <div className="docSymbolBox">
              {/* <img src={DoctorSymbol} className='docSymImg' alt='' /> */}
            </div>
          </div>
          <div className="modalBody">
            <div className="detailsFill">
              <div className="labelTxt">
                <label className="widthFixed">Customer Name:</label>
              </div>
              <input
                style={{ background: "#fff", width: "91%" }}
                disabled
                type="text"
                className="form-control formSolution"
                value={
                  prescriptionData?.patient?.customer_name
                    ? prescriptionData?.patient?.customer_name
                    : ""
                }
              />
            </div>
            <div className="detailsFill">
              <div className="labelTxt">
                <label className="widthFixed">Patient Name:</label>
              </div>
              <input
                style={{ background: "#fff" }}
                disabled
                type="text"
                className="form-control formSolution"
                value={
                  prescriptionData?.patient?.name
                    ? prescriptionData?.patient?.name
                    : ""
                }
              />
            </div>
            <div className="addressFlex">
              <div className="labelTxt">
                <label className="keyQuestion">Address:</label>
              </div>
              <input
                style={{ background: "#fff" }}
                disabled
                type="text"
                className="form-control formSolution"
                value={
                  prescriptionData?.patient?.address
                    ? prescriptionData?.patient?.address
                    : ""
                }
              />
            </div>
            <div className="dateNdAgeFlex">
              <div className="ageBox">
                <label className="ageLab">Age:</label>
                <input
                  style={{ background: "#fff" }}
                  disabled
                  type="text"
                  className="form-control formSolution"
                  value={
                    prescriptionData?.patient?.age
                      ? prescriptionData?.patient?.age
                      : ""
                  }
                />
              </div>
              <div className="dateBox">
                <label className="ageLab">Date:</label>
                <input
                  style={{ background: "#fff" }}
                  disabled
                  type="text"
                  className="form-control formSolution"
                  value={
                    prescriptionData?.patient?.date
                      ? prescriptionData?.patient?.date
                      : ""
                  }
                />
              </div>
            </div>
            <div className="addressFlex">
              <div className="labelTxt">
                <label className="keyQuestion">Diagnosis:</label>
              </div>
              <input
                style={{ background: "#fff" }}
                disabled
                type="text"
                className="form-control formSolution"
                value={
                  prescriptionData?.patient?.diagnosis
                    ? prescriptionData?.patient?.diagnosis
                    : ""
                }
              />
            </div>

            <div className="tableAndLandingBg">
              <div className="backGroundPic"></div>
              {Object.keys(prescriptionData)?.length > 0 &&
                prescriptionData?.prescriptions?.length > 0 && (
                  <div className="innerTable">
                    <table className="modalTable">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Prescription</th>
                          <th>Quantity</th>
                          <th>Dose</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prescriptionData?.prescriptions.map((item, i) => {
                          return (
                            <tr key={"prescriptionData" + i}>
                              <td>{i + 1}</td>
                              <td>
                                {item?.prescription ? item?.prescription : ""}
                              </td>
                              <td>{item?.prescription_quantity}</td>
                              <td>
                                {item?.prescription_frequency
                                  ? item?.prescription_frequency
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {/* <div>
                <button>Submit</button>
              </div> */}
                  </div>
                )}
            </div>
            {prescriptionData?.imageData && (
              <div className="signatureContent">
                <div className="sigBox">
                  <div className="line">
                    <img
                      src={`data:image/${prescriptionData?.imageType};base64,${prescriptionData?.imageData}`}
                      alt=""
                    />
                  </div>
                  <label className="belowTxt">SIGNATURE</label>
                </div>
              </div>
            )}
          </div>

          <div className="belowDetails">
            <div className="imgWrapper">
              <div className="row heightAuto">
                <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                  <div className="txt">
                    <div className="innerTxt">Contact</div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 p-0">
                  <div className="allCOntact">
                    <div className="emailAndCall">
                      <div className="callNnumber">
                        <img src={ic_call} alt="" />
                        <label className="numberInn">+91 9509932234</label>
                      </div>
                      <div className="emialInn">
                        <img src={ic_mail} alt="" />
                        <label className="numberInn">info@animalsapp.in</label>
                      </div>
                    </div>

                    <div className="locationInn" style={{ marginTop: "-12px" }}>
                      <img src={ic_location} alt="" />
                      <label className="locationTxt">
                        Animals App, Animalsomatic clinic Near Ravi Steel,
                        Kamde, Ratu Road, Ranchi, Jharkhand, India 835222
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PDFExport>
    </div>
  );
};

export default DoctorPrecription;

import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Subheader from '../../sharedComponent/Subheader'
import Star from '../../assets/images/Star.svg'
import dustbinImg from '../../assets/images/dustbinImg.svg'
import { ApiConfig } from '../../api/ApiConfig'
import { PostCallWithAuthToken } from '../../api/ApiServices'
import { AppContext } from '../../context/AppContext'
import Pagination from '../../sharedComponent/Pagination'
import NoDataMsg from '../../sharedComponent/NoDataMsg'
import { notifyError, notifySuccess } from '../../ToastMessages/notify'
import { useReduxStore } from '../../customHooks/useReduxStore'
import RotatingLoader from '../../sharedComponent/RotatingLoader'

const AllSellers = () => {
  // DATA FROM REDUX STORE
  const { customerLoggedInData } = useReduxStore()
  const customer_id =
    customerLoggedInData && customerLoggedInData?.loginData?.customer_id
  // DATA FROM REDUX STORE

  // DATA FROM CONTEXT
  const { setBkdropLdr } = useContext(AppContext)
  // DATA FROM CONTEXT

  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search)
  const productId = parseInt(searchParams.get('product_id'))
  const pharmacyShopId = parseInt(searchParams.get('pharmacy_shop_id'))

  // STATE
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationData, setPaginationData] = useState({})
  const [sellerList, setSellerList] = useState([])
  console.log('sellerList,', sellerList)
  // STATE

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  useEffect(() => {
    fetchSellerList()
  }, [currentPage])
  const [loader, setLoader] = useState(true)
  const fetchSellerList = async () => {
    try {
      const payLoad = {
        product_id: productId,
        pharmacy_shop_id: pharmacyShopId,
        page: currentPage
      }
      setLoader(true)
      const res = await PostCallWithAuthToken(
        ApiConfig.MEDICINE_SELLER_LIST,
        payLoad
      )
      console.log('res,', res)
      const {
        result,
        data,
        total_count,
        total_pages,
        last_page,
        current_page,
        total_quantity
      } = res?.json

      if (result) {
        data && setSellerList(data)
        setPaginationData({
          total_count,
          total_pages,
          last_page,
          current_page,
          total_quantity
        })
      }
    } catch (error) {
      console.log('fetchSellerList catch error: ', error)
    } finally {
      setLoader(false)
    }
  }
  const { navigate } = useContext(AppContext)
  const addToBagCart = async (pharmacy_shop_id, product_rate, btnType) => {
    // ADD_TO_CART
    const cartPayLoad = {
      customer_id: customer_id,
      product_id: productId,
      product_quantity: 1,
      product_rate: product_rate,
      shop_id: pharmacy_shop_id,
      entity_type_id: 3
    }
    //   {
    //     "customer_id": 16,
    //     "product_id": 28,
    //     "product_quantity": 2,
    //     "product_rate": "788",
    //     "entity_type_id": 3,
    //     "shop_id": 15
    // }
    try {
      setBkdropLdr(true)
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      )
      const { result, message } = res?.json
      if (result) {
        navigate('/cart')
        if (!btnType) {
          notifySuccess('Medicine Added to cart successfully')
        }
      } else {
        navigate('/cart')
        // notifyError('This product already added to your cart')
      }
    } catch (error) {
      console.log('catch error:', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <div className='allSellers' id='cx-main'>
        <Subheader />
        {loader ? (
          <RotatingLoader />
        ) : (
          <div className='innerSellerTab'>
            <div className='scroller'>
              {sellerList && sellerList?.length > 0 ? (
                <table className='tableMain'>
                  <thead className='tableHead'>
                    <tr>
                      <th>Seller</th>
                      <th>Price</th>
                      {/* <th>Delivery</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='tBody'>
                    {Array.isArray(sellerList) &&
                      sellerList.map((item, idx) => {
                        return (
                          <tr key={item?.pharmacy_shop_id}>
                            <td>
                              <div className='medicalDetailsFlex'>
                                <div className='ratingNname'>
                                  <label htmlFor='' className='name'>
                                    {item?.pharmacy_shop_name}
                                  </label>
                                  <label htmlFor='' className='starNreview'>
                                    <img src={Star} alt='' /> 4.3
                                  </label>
                                  {/* <p className='paraTag'>
                                    tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim{' '}
                                  </p> */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='priceBox'>
                                <label htmlFor='' className='activePrice'>
                                  ₹{parseFloat(item?.product_rate).toFixed(2)}
                                </label>
                                {/* <label htmlFor='' className='mutedTxt'>
                                  ₹ {parseFloat(item?.product_rate).toFixed(2)}
                                </label> */}
                                {/* <p className='paraTag'>
                                  10% Instant Discount on UPI Payment
                                </p> */}
                              </div>
                            </td>
                            {/* <td>
                              <div className='insidePara'>
                                <p>
                                  Lorem ipsum dolor sit amet consectetur,
                                  adipisicing elit. Iste repudiandae rerum
                                  aspernatur velit facilis, impedit odit,
                                </p>
                              </div>
                            </td> */}
                            <td>
                              <div className='twoBtns'>
                                <button
                                  className='addBtn'
                                  onClick={() =>
                                    item?.product_rate &&
                                    addToBagCart(
                                      item?.pharmacy_shop_id,
                                      item?.product_rate
                                    )
                                  }
                                >
                                  <img src={dustbinImg} alt='' /> Add to Bag
                                </button>

                                <button
                                  className='buyBtn'
                                  onClick={() =>
                                    item?.product_rate &&
                                    addToBagCart(
                                      item?.pharmacy_shop_id,
                                      item?.product_rate,
                                      'buynow'
                                    )
                                  }
                                >
                                  Buy Now
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              ) : (
                <NoDataMsg />
              )}

              {paginationData?.total_count && (
                <Pagination
                  data={sellerList}
                  pageChangeHandler={pageChangeHandler}
                  usersPerPage={10}
                  pageCount={
                    paginationData?.total_pages
                      ? paginationData?.total_pages
                      : 0
                  }
                  currentPage={currentPage - 1}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AllSellers

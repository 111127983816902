export const useDateFormats = () => {
  const convertWithDateAndDay = (dateString) => {
    const inputDate = new Date(dateString);
    const date = new Date(dateString);
    // const previousDate = new Date(inputDate)
    // date.setDate(previousDate.getDate() - 1)

    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sept",
      "Octo",
      "Nov",
      "Dec",
    ];
    const day = date.getDate() 
    const monthIndex = date.getMonth()
    const year = date.getFullYear()
    const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;

    // Check if the input date is the same as the current date
    if (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
      return "Today";
    }

    return formattedDate ? formattedDate : "";
  };

  const convertWithMonthAndDay = (dateString) => {
    const [date, month, fullYear] = dateString.split("-").map(Number);

    const inputDate = new Date(fullYear, month - 1, date);
    // Check if the date is invalid
    if (isNaN(date)) {
      return "";
    }

    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sept",
      "Octo",
      "Nov",
      "Dec",
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();

    const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;

    // Check if the input date is the same as the current date
    if (inputDate.toDateString() === currentDate.toDateString()) {
      return "Today";
    }

    return formattedDate;
  };

  const convertWithDataAndTime = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const amOrPm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;

    const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${amOrPm}`;

    return formattedDate;
  };

  function formatDateToDdMmYyyy(dateString) {
    if(!dateString){
      return ""
    }
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const convertTimeTo12hr = (time24hr) => {
    if(!time24hr){
      return ""
    }
    const inputTime = time24hr;
    const [hours, minutes] = inputTime.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const time12hr = date.toLocaleTimeString(undefined, options);

    return time12hr ? time12hr : "";
  };

  const dateAndMonthOnly = (dateString) => {
    if(!dateString){
      return ""
    }
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });

    // Function to add ordinal suffix to day
    const addOrdinalSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDate = `${addOrdinalSuffix(day)} ${month}`;
    return formattedDate;
  };

  const dateWithMonthAndYear = (date) => {
    if(!date){
      return ""
    }
    const inputDate = new Date(date);
    const options = { day: "2-digit", year: "numeric", month: "long" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      inputDate
    );
    return formattedDate;
  };

  const getAllDatesOfWeek = () => {
    const today = new Date();
    const currentDay = today.getDay();

    const startDate = new Date(today);
    startDate.setDate(today.getDate() - currentDay);

    const allDates = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      allDates.push(currentDate);
    }

    return allDates;
  };

  const dateWithWeekdayandMonth = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Parse the input date string
    const inputDate = new Date(dateString);

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison

    // Check if the input date is today
    const isToday =
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear();

    // Return 'Today' if it is today, otherwise format the date
    return isToday ? "Today" : inputDate.toLocaleDateString("en-US", options);
  };

  return {
    convertWithDateAndDay,
    convertWithMonthAndDay,
    convertWithDataAndTime,
    formatDateToDdMmYyyy,
    convertTimeTo12hr,
    dateAndMonthOnly,
    dateWithMonthAndYear,
    getAllDatesOfWeek,
    dateWithWeekdayandMonth,
  };
};

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Star from '../assets/images/Star.svg'
import MediImg from '../assets/images/medicinesImg.svg'

const Subheader = ({ doctorId }) => {
  let currentRoute = useLocation().pathname
  return (
    <>
      {currentRoute === '/AllSellers' || currentRoute === '/PetAllSellers' ? (
        <>
          <div className='allSellersBox'>
            <div className='headingTxt'>
              <h1>All Sellers</h1>
            </div>
            {/* <div className='rightSideBox'>
              <div className='txt'>
                <label htmlFor='' className='nameIn'>
                  Pet Supplements for Dog
                </label>
                <label htmlFor='' className='rating'>
                  <img src={Star} alt='' /> 4.3 (1200)
                </label>
              </div>
              <div className='imgHolder'>
                <img src={MediImg} className='MediImg' alt='' />
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <div
          className={
            currentRoute.includes('DoctorDetails') ||
            currentRoute === '/BookAppoitment' ||
            currentRoute === '/ProductDetails' ||
            currentRoute === '/cart' ||
            currentRoute === '/MedicineCheckout' ||
            currentRoute === '/PetShopDetails' ||
            currentRoute === '/PetCart' ||
            currentRoute === '/PetCheckout'
              ? 'subHeaderMainsmall'
              : 'subHeaderMainBig'
          }
        >
          <div className='landingImg'></div>
          {
            // Doctor's Routes
            (currentRoute === '/DoctorsList' && <h1>Doctor's</h1>) ||
              (currentRoute === '/BookAppoitment' && (
                <h1>Book Appointment</h1>
              )) ||
              (currentRoute.includes('DoctorDetails') && <h1>Doctor Details</h1>) ||
              // Medicines Routes
              (currentRoute === '/Medicines' && <h1>Medicines</h1>) ||
              (currentRoute === '/ProductDetails' ||
              currentRoute === '/PetShopDetails' ? (
                <h1>Product Description</h1>
              ) : null) ||
              (currentRoute === '/cart' || currentRoute === '/PetCart' ? (
                <h1>Cart</h1>
              ) : null) ||
              (currentRoute === '/MedicineCheckout' ||
              currentRoute === '/PetCheckout' ? (
                <h1> Checkout</h1>
              ) : null) ||
              (currentRoute === '/PetShops' && <h1>Animal Foods and products</h1>) ||
              (currentRoute === '/AmbulanceBook' && (
                <h1>Request Ambulance</h1>
              )) ||
              (currentRoute === '/ProfileSetting' && <h1>My Account</h1>) ||
              (currentRoute === '/MyOrderDetails' && <h1>My Order’s</h1>)
          }
        </div>
      )}

      {/* BreadCrumbs For Ux */}
      <div div className='breakCrumbs'>
        {
          // Doctors
          (currentRoute === '/DoctorsList' && (
            <>
              <Link to='/Home' className='BackLink'>
                Home<span className='slashPower'>/</span>
              </Link>
              <Link to='#' className='frontLink'>
                Doctor
              </Link>
            </>
          )) ||
            (currentRoute.includes('DoctorDetails') && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>
                <Link to='/DoctorsList' className='BackLink'>
                  Doctor<span className='slashPower'>/</span>
                </Link>
                <Link
                  to={`/DoctorDetails/${doctorId}`}
                  // to={`/DoctorDetails/${item?.doctor_id}`}
                  className='frontLink'
                >
                  Doctor Details
                </Link>
              </>
            )) ||
            (currentRoute === '/book-vaccinator' && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>
                <Link to='/vaccinators' className='BackLink'>
                  Vaccinators
                </Link>
              </>
            )) ||
            (currentRoute === '/BookAppoitment' && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>
                <Link to='/DoctorsList' className='BackLink'>
                  Doctor<span className='slashPowerSame'>/</span>
                </Link>
                <Link to={`/DoctorDetails/${doctorId}`} className='BackLink'>
                  Doctor Details<span className='slashPower'>/</span>
                </Link>
                <Link
                  to='#'
                  className='frontLink'
                  onClick={e => e.preventDefault()}
                >
                  Book Appointment
                </Link>
              </>
            )) ||
            // Medicines
            (currentRoute === '/Medicines' && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPower'>/</span>
                </Link>
                <Link to='#' className='frontLink'>
                  Medicines
                </Link>
              </>
            )) ||
            (currentRoute === '/ProductDetails' ||
            currentRoute === '/AllSellers' ? (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>
                <Link to='/Medicines' className='BackLink'>
                  Medicines<span className='slashPower'>/</span>
                </Link>
                <Link to='#' className='frontLink'>
                  Product Description
                </Link>
              </>
            ) : null) ||
            (currentRoute === '/cart' || currentRoute === '/PetCart' ? (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPower'>/</span>
                </Link>
                <Link to='#' className='frontLink'>
                  Cart
                </Link>
              </>
            ) : null) ||
            (currentRoute === '/MedicineCheckout' ||
            currentRoute === '/PetCheckout' ? (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPower'>/</span>
                </Link>
                <Link to='#' className='frontLink'>
                  Checkout
                </Link>
              </>
            ) : null) ||
            (currentRoute === '/PetShops' && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPower'>/</span>
                </Link>
                <Link to='#' className='frontLink'>
                  Pet Shop Products
                </Link>
              </>
            )) ||
            (currentRoute === '/PetShopDetails' ||
            currentRoute === '/PetAllSellers' ? (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>
                <Link to='/PetShops' className='BackLink'>
                  Pet or Vet Shop<span className='slashPower'>/</span>
                </Link>
                {/* <Link to='#' className='frontLink'> */}
                <span className='frontLink'>Product Description</span>

                {/* </Link> */}
              </>
            ) : null) ||
            (currentRoute === '/ProfileSetting' && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>

                <Link to='#' className='frontLink'>
                  My Account
                </Link>
              </>
            )) ||
            (currentRoute === '/AmbulanceBook' && (
              <>
                <Link to='/Home' className='BackLink'>
                  Home<span className='slashPowerSame'>/</span>
                </Link>
                <Link to='/ProfileSetting' className='BackLink'>
                  My Accounts<span className='slashPower'>/</span>
                </Link>
                <Link to='#' className='frontLink'>
                  Request Ambulance
                </Link>
              </>
            ))
        }
      </div>
    </>
  )
}

export default Subheader

import React, { useContext, useRef } from "react";
import ic_call from "../../assets/images/ic_call_green.svg";
import ic_location from "../../assets/images/ic_location_greenMedical.svg";
import ic_mail from "../../assets/images/ic_email_green.svg";
import DoctorSymbol from "../../assets/images/medical_symbol.svg";
import sign from "../../assets/images/sign.png";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import { useDateFormats } from "../../customHooks/useDateFormats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFExport } from "@progress/kendo-react-pdf";
import ic_doc_download from "../../assets/images/ic_doc_download.svg";
import { formatCurrency } from "../../constants/commonFunctons";

const MedicalPrescriptionComp = ({
  orderPrescriptionData,
  isOrderPrescriptionData,
  entity_type_id,
}) => {
  const totalAmount =
    orderPrescriptionData?.prescriptions &&
    orderPrescriptionData?.prescriptions.length > 0
      ? orderPrescriptionData?.prescriptions.reduce((acc, currentItem) => {
          return (
            acc +
            parseInt(
              entity_type_id == 1
                ? Number(currentItem?.charges)
                : entity_type_id == 6
                ? Number(currentItem.charges)
                : entity_type_id == 12
                ? Number(currentItem.charges)
                : Number(currentItem.amount)
            )
          );
        }, 0)
      : 0;

  const { Prescription, setPrescription } = useContext(AppContext);
  const { formatDateToDdMmYyyy } = useDateFormats();

  const pdfExportComponent = useRef(null);

  const discountAmt = orderPrescriptionData?.patient?.discount_amount
    ? Number(orderPrescriptionData?.patient?.discount_amount)
    : 0;

  return (
    <div className="prescription-wrapper-main">
      <Modal
        show={Prescription}
        onHide={() => setPrescription(false)}
        size="lg"
        id="prescription-modal"
      >
        {/* <div className="top-btns">
          <button>Submit</button>
        </div> */}
        <Modal.Body>
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={20}
            fileName={"Petfoods"}
            author="KendoReact Team"
          >
            <div className="orderModalInvoice" id="medical-prescription">
              <div className="modalHeading">
                <div className="upperBlue">
                  {isOrderPrescriptionData &&
                    orderPrescriptionData?.pharmacy && (
                      <h1 className="docName">
                        {entity_type_id == 3
                          ? "Animal Pharmacy"
                          : entity_type_id == 1
                          ? "Animal Clinic"
                          : entity_type_id == 6 // Entity type for Ambulance
                          ? "Ambulance"
                          : entity_type_id == 12 // Entity type for Vaccinator
                          ? "Vaccine"
                          : "Animal Shop"}
                        {/* {orderPrescriptionData?.pharmacy?.pharmacy_shop_name
                          ? orderPrescriptionData?.pharmacy?.pharmacy_shop_name
                          : orderPrescriptionData?.pharmacy?.pet_shop_name} */}
                      </h1>
                    )}
                  {/* {isOrderPrescriptionData &&
                    orderPrescriptionData?.pharmacy && (
                      <label className="domainLab">
                        {orderPrescriptionData?.pharmacy?.pharmacy_shop_type
                          ? orderPrescriptionData?.pharmacy?.pharmacy_shop_type
                          : orderPrescriptionData?.pharmacy?.pet_shop_type}
                      </label>
                    )} */}

                  <label className="certificateLab">Invoice Details</label>
                  <label
                    className="certificateLab"
                    style={{ marginTop: "20px", marginRight: "2px" }}
                  >
                    Transaction Id :{" "}
                    {orderPrescriptionData?.patient?.transaction_id}{" "}
                  </label>
                  <label
                    className="certificateLab"
                    style={{
                      marginTop: "40px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    Order Id : {orderPrescriptionData?.order_id}{" "}
                  </label>
                  {/* <label className="certificateLab">Certifcation 12548-20</label> */}
                </div>
                {/* <div className="docSymbolBox">
                  <img src={DoctorSymbol} className="docSymImg" alt="" />
                </div> */}
              </div>
              <div className="modalBody">
                <div className="detailsFill">
                  <div className="labelTxt" style={{width : "30%"}}>
                    <label className="widthFixed">Animal Owner :</label>
                  </div>
                  <input
                    style={{ background: "#fff" }}
                    disabled
                    type="text"
                    className="form-control formSolution"
                    value={
                      orderPrescriptionData?.patient?.first_name
                        ? `${orderPrescriptionData?.patient?.first_name} ${orderPrescriptionData?.patient?.last_name}`
                        : ""
                    }
                  />
                </div>
                {/* <div className="detailsFill">
                <div className="labelTxt">
                  <label className="widthFixed">Patient Name:</label>
                </div>
                <input
                  style={{ background: "#fff" }}
                  disabled
                  type="text"
                  className="form-control formSolution"
                  value={
                    isOrderPrescriptionData &&
                    orderPrescriptionData?.patient?.name
                      ? orderPrescriptionData?.patient?.name
                      : ""
                  }
                />
              </div> */}
                <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Address:</label>
                  </div>
                  <p className="text-para" style={{borderBottom: "1px solid #21c896"}}>
                    {isOrderPrescriptionData &&
                    orderPrescriptionData?.patient?.address
                      ? orderPrescriptionData?.patient?.address
                      : ""}
                  </p>
                  {/* <input
                    style={{ background: "#fff" }}
                    disabled
                    type="text"
                    className="form-control formSolution"
                    value={
                      isOrderPrescriptionData &&
                      orderPrescriptionData?.patient?.address
                        ? orderPrescriptionData?.patient?.address
                        : ""
                    }
                  /> */}
                </div>
                <div className="dateNdAgeFlex">
                  {/* <div className="ageBox">
                  <label className="ageLab">Age:</label>
                  <input
                    style={{ background: "#fff" }}
                    disabled
                    type="text"
                    className="form-control formSolution"
                    value={
                      isOrderPrescriptionData &&
                      orderPrescriptionData?.patient?.age
                        ? orderPrescriptionData?.patient?.age
                        : ""
                    }
                  />
                </div> */}
                  <div className="dateBox">
                    <label className="ageLab">Date:</label>
                    <input
                      style={{ background: "#fff" }}
                      disabled
                      type="text"
                      className="form-control formSolution"
                      value={
                        isOrderPrescriptionData &&
                        orderPrescriptionData?.patient?.order_date
                          ? formatDateToDdMmYyyy(
                              orderPrescriptionData?.patient?.order_date
                            )
                          : formatDateToDdMmYyyy(
                              orderPrescriptionData?.patient
                                ?.pet_shop_order_date
                            )
                      }
                    />
                  </div>
                </div>
                {/**
                  <div className="addressFlex">
                  <div className="labelTxt">
                    <label className="keyQuestion">Diagnosis:</label>
                  </div>
                  <input
                    style={{ background: "#fff" }}
                    disabled
                    type="text"
                    className="form-control formSolution"
                    value={
                      isOrderPrescriptionData &&
                      orderPrescriptionData?.patient?.diagnosis
                        ? orderPrescriptionData?.patient?.diagnosis
                        : ""
                    }
                  />
                </div>
                */}

                <div className="tableAndLandingBg">
                  <div className="invoice-no">
                    {/* <label htmlFor="">Invoice No. 1012345</label> */}
                  </div>
                  <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" />
                  {/* <i class="fa-duotone fa-file-arrow-down"></i> */}
                  {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-file-earmark-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                </svg> */}
                  <div className="backGroundPic"></div>
                  {isOrderPrescriptionData &&
                    orderPrescriptionData?.prescriptions &&
                    orderPrescriptionData?.prescriptions.length > 0 && (
                      <div className="innerTable">
                        <table className="modalTable">
                          <thead>
                            {entity_type_id == 1 ? (
                              <tr>
                                <th>Sr No.</th>
                                <th>Doctor Name</th>
                                <th>Appointment type</th>
                                <th>Fess</th>
                              </tr>
                            ) : entity_type_id == 6 ? (
                              <tr>
                                <th>Sr No.</th>
                                <th>Driver Name</th>
                                <th>Rate</th>
                              </tr>
                            ) : entity_type_id == 12 ? (
                              <tr>
                                <th>Sr No.</th>
                                <th>Vaccinator Name</th>
                                <th>Appointment Type</th>
                                <th>Rate</th>
                              </tr>
                            ) : (
                              <tr>
                                <th>Sr No.</th>
                                <th>Prescription</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Amount</th>
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {orderPrescriptionData?.prescriptions.map(
                              (item, i) => {
                                return entity_type_id == 1 ? (
                                  <tr key={"orderPrescriptionData" + i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.doctor_name}</td>
                                    <td>{item?.appointment_type}</td>
                                    <td>
                                      {item?.charges &&
                                        formatCurrency(item?.charges)}
                                    </td>
                                  </tr>
                                ) : entity_type_id == 6 ? (
                                  <tr key={"orderPrescriptionData" + i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.ambulance_driver_name}</td>
                                    <td>
                                      {item?.charges &&
                                        formatCurrency(item?.charges)}
                                    </td>
                                  </tr>
                                ) : entity_type_id == 12 ? (
                                  <tr key={"orderPrescriptionData" + i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.vaccinator_name}</td>
                                    <td>{item?.appointment_type}</td>
                                    <td>
                                      {item?.charges &&
                                        formatCurrency(item?.charges)}
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={"orderPrescriptionData" + i}>
                                    <td>{i + 1}</td>
                                    <td>{item?.prescription}</td>
                                    <td>{item?.quantity}</td>
                                    <td>{item?.rate}</td>
                                    <td>
                                      {item?.amount &&
                                        formatCurrency(item?.amount)}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
                <div className="total-amount-pay-wrapper">
                  <div className="left-method">
                    <p>Payment Method:</p>
                    {/*  <p>UPI / Credit Card / Debit Card/ Cash</p> */}
                    <p>Online</p>
                  </div>
                  <div className="right-total">
                    <div className="sub-t">
                      <p>Sub Total</p>
                      {isOrderPrescriptionData && (
                        <p>
                          {formatCurrency(totalAmount)}
                          {/** {parseFloat(
                            orderPrescriptionData?.orderTotal?.subTotal
                          ).toFixed(2)} */}
                        </p>
                      )}
                    </div>
                    <div className="tax">
                      <p>Tax</p> <p>-</p>
                      {/* 
                   {isOrderPrescriptionData &&
                      orderPrescriptionData?.orderTotal?.taxAmount && (
                        <p>
                          ₹{" "}
                          {parseFloat(
                            orderPrescriptionData?.orderTotal?.taxAmount
                          ).toFixed(2)}
                        </p>
                      )}
                  */}
                    </div>
                    <div className="tax">
                      <p>Discount Amount</p>{" "}
                      <p>
                        {discountAmt
                          ? formatCurrency(totalAmount - discountAmt)
                          : 0}
                      </p>
                      {/*{isOrderPrescriptionData &&
                      orderPrescriptionData?.orderTotal?.total && (
                        <p>
                          ₹{" "}
                          {parseFloat(
                            orderPrescriptionData?.orderTotal?.total
                          ).toFixed(2)}
                        </p>
                      )} */}
                    </div>
                    <div className="total">
                      <p>Total</p>{" "}
                      <p>
                        {discountAmt
                          ? formatCurrency(discountAmt)
                          : formatCurrency(totalAmount)}
                      </p>
                      {/*{isOrderPrescriptionData &&
                      orderPrescriptionData?.orderTotal?.total && (
                        <p>
                          ₹{" "}
                          {parseFloat(
                            orderPrescriptionData?.orderTotal?.total
                          ).toFixed(2)}
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="belowDetails">
                <div className="imgWrapper">
                  <div className="row heightAuto">
                    <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                      <div className="txt">
                        <div className="innerTxt">Contact</div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 p-0">
                      <div className="allCOntact">
                        <div className="emailAndCall">
                          {/* <div className="callNnumber">
                            <img src={ic_call} alt="" />
                            <label className="numberInn">+91 9509932234</label>
                          </div> */}

                          <div className="emialInn">
                            <img src={ic_mail} alt="" />
                            <label className="numberInn">
                              info@animalsapp.in
                            </label>
                          </div>
                        </div>
                        {/* <div className="locationInn">
                          <img src={ic_location} alt="" />
                          <label className="locationTxt">
                            Animals App, Animalsomatic clinic Near Ravi Steel,
                            Kamde, Ratu Road, Ranchi, Jharkhand, India 835222
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PDFExport>
          <div
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
              }
            }}
          >
            <button
              type="button"
              className="btn-blank-green"
              style={{
                position: "absolute",
                top: "1%",
                right: "10px",
                border: "1px solid #1b5354",
                borderRadius: "8px",
                backgroundColor: "transparent",
                color: "#1b5354",
              }}
            >
              Download
              <img src={ic_doc_download} className="ic_doc_download" alt="" />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MedicalPrescriptionComp;

import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ic_doc_download_light from "../../assets/images/ic_doc_download_light.svg";
import ic_view_light from "../../assets/images/ic_view_light.svg";
import ic_repeat from "../../assets/images/ic_repeat.svg";
import ic_repeat_green from "../../assets/images/ic_repeat_green.svg";
import chatting_dummy from "../../assets/images/chatting_dummy.png";
import video_dummy from "../../assets/images/video_dummy.png";

import ic_invoice from "../../assets/images/ic_invoice.svg";
import ic_arrow_right_white from "../../assets/images/ic_right_arrow_circle.svg";
import dr_1 from "../../assets/images/dr_1.png";
import ic_doc_download from "../../assets/images/ic_doc_download.svg";
import ic_view from "../../assets/images/ic_view.svg";
import ic_video from "../../assets/images/ic_video.svg";
import ic_calender_green from "../../assets/images/ic_calender_green.svg";
import dog_1 from "../../assets/images/dog_1.png";
import dog_3 from "../../assets/images/dog_3.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ic_progress from "../../assets/images/ic_progress.svg";
import ic_complete from "../../assets/images/ic_complete.svg";
import ic_reject from "../../assets/images/ic_reject.svg";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import ic_star_active from "../../assets/images/ic_star_active.svg";
import ic_start from "../../assets/images/ic_start.svg";
import Pagination from "../../sharedComponent/Pagination";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "./PrescriptionComp";
import MedicalPrescriptionComp from "./MedicalPrescriptionComp";
import { simpleGetCallWithAuthToken } from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { useDateFormats } from "../../customHooks/useDateFormats";
import moment from "moment";
import { notifyError } from "../../ToastMessages/notify";

const MyTransactionComp = () => {
  const { Prescription, setPrescription } = useContext(AppContext);
  const { formatDateToDdMmYyyy } = useDateFormats();

  //STATES
  const [isLoading, setIsLoading] = useState(false);
  const [transactionsList, setTransactionsList] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [searchedTerm, setSearchedTerm] = useState("");
  //STATES

  useEffect(() => {
    fetchTransactionDetails();
  }, [currentPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchTransactionDetails(1);
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchedTerm]);

  const fetchTransactionDetails = async (pageNumber) => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.FETCH_TRANSACTION_API}?page=${
          pageNumber ? pageNumber : currentPage
        }&limit=${pageLimit}&q=${searchedTerm}`
      );
      const { success, data, total, current_page, total_pages, last_page } =
        res?.json;
      if (success && data) {
        data && setTransactionsList(data);
        setPaginationData({
          total,
          current_page,
          total_pages,
          last_page,
        });
        pageNumber && setCurrentPage(1);
      } else {
        setTransactionsList([]);
      }
    } catch (error) {
      console.log("catch Error,", error);
    } finally {
      setIsLoading(false);
    }
  };
  const { bkdropLdr, setBkdropLdr } = useContext(AppContext);
  const [orderPrescriptionData, setOrderPrescriptionData] = useState({});
  const isOrderPrescriptionData = Object.keys(orderPrescriptionData).length
    ? true
    : false;
  // my-order-invoice-prescription
  const [entity_type_id, setEntity_type_id] = useState(null);
  const getOrderPrescriptionData = async (order_id, type, entity_type_id) => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.MY_ORDER_PRESCRIPTION}?order_id=${order_id}&type=${type}`
      );

      const { success, data } = res?.json;
      if (success) {
        data && setOrderPrescriptionData(data);
        setPrescription(true);
        setEntity_type_id(entity_type_id);
      } else {
        notifyError("Invoice is not available");
      }
    } catch (error) {
      console.log("catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      <div className="main-MyTransactionComp">
        <section className="wrapper-cards ">
          <div className="heading-close-btn">
            <h5>My Transactions ({paginationData?.total})</h5>
            <div className="d-flex" id="transaction-search">
              <input
                type="text"
                className="form-control"
                placeholder="Search Transaction"
                onChange={(e) => setSearchedTerm(e.target.value)}
              />
              {/* <Link>
                <img src={ic_calender_green} alt='' />
              </Link> */}
            </div>
          </div>
        </section>
        {transactionsList && transactionsList?.length > 0 ? (
          <div className="table-wrapper-main wrapper-cards mt-3">
            <div className="t-wrapper-inner" style={{ height: "280px" }}>
              {isLoading ? (
                <RotatingLoader />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Transaction Date</th>
                      <th>Time</th>
                      <th>Patient</th>
                      <th>Animal Type</th>
                      <th>Invoice</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactionsList.map((item, i) => {
                      const imagePath =
                        item?.animal_details?.animal_photos &&
                        item?.animal_details?.animal_photos[0]
                          ?.animal_photograph_path
                          ? item?.animal_details?.animal_photos[0]
                              ?.animal_photograph_path
                          : null;
                      let isMedicineOrder =
                        item?.entity_type_id == 3
                          ? "med"
                          : item?.entity_type_id == 1
                          ? "doc"
                          : item?.entity_type_id == 6
                          ? "amb"
                          : item?.entity_type_id == 12
                          ? "vac"
                          : "";
                      let orderId =
                        "order_id" in item
                          ? item.order_id
                          : item.pet_shop_order_id;
                      return (
                        <tr key={"transactionsList" + item?.order_id}>
                          <td>{item?.srNo}</td>
                          <td>
                            {item?.transaction_date
                              ? formatDateToDdMmYyyy(item?.transaction_date)
                              : ""}
                          </td>
                          <td>
                            {moment(item?.transaction_date).format("h:mm:ss a")}
                            {/* {new Date(item?.transaction_date).getTime()} */}
                            {/* 04:58:58s */}
                          </td>
                          <td>
                            <img
                              src={imagePath ? imagePath : dog_1}
                              alt=""
                              className="t-img"
                            />
                            {item?.animal_details?.animal_name}
                          </td>
                          <td>Dog</td>
                          <td>
                            <button
                              className="inv-btn"
                              onClick={() => {
                                getOrderPrescriptionData(
                                  orderId,
                                  isMedicineOrder,
                                  item?.entity_type_id
                                );
                              }}
                            >
                              <img src={ic_invoice} alt="" />
                            </button>
                          </td>
                          <td>
                            ₹
                            {Number(item?.discount_amount)
                              ? item?.discount_amount
                              : item?.total_amount}
                          </td>
                          <td>
                            <img
                              src={
                                item?.payment_status === "successful"
                                  ? ic_complete
                                  : item?.payment_status === "pending"
                                  ? ic_progress
                                  : ic_reject
                              }
                              alt=""
                              className="me-1"
                            />
                            {item?.payment_status === "successful"
                              ? "Paid"
                              : item?.payment_status === "pending"
                              ? "Pending"
                              : "Failed"}
                          </td>
                        </tr>
                      );
                    })}

                    {/* <tr>
                  <td>1</td>
                  <td>12-06-2023</td>
                  <td>04:58:58s</td>
                  <td>
                    <img src={dog_1} alt='' className='t-img' />
                    Drogo
                  </td>
                  <td>Dog</td>
                  <td>
                    <button
                      className='inv-btn'
                      onClick={() => setPrescription(true)}
                    >
                      <img src={ic_invoice} alt='' />
                    </button>
                  </td>
                  <td>₹500</td>
                  <td>
                    <img src={ic_reject} alt='' className='me-1' />
                    Faild
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12-06-2023</td>
                  <td>04:58:58s</td>
                  <td>
                    <img src={dog_1} alt='' className='t-img' />
                    Drogo
                  </td>
                  <td>Dog</td>
                  <td>
                    <button
                      className='inv-btn'
                      onClick={() => setPrescription(true)}
                    >
                      <img src={ic_invoice} alt='' />
                    </button>
                  </td>
                  <td>₹500</td>
                  <td>
                    <img src={ic_complete} alt='' className='me-1' />
                    Paid
                  </td>
                </tr> */}
                  </tbody>
                </table>
              )}
            </div>
            <MedicalPrescriptionComp
              orderPrescriptionData={orderPrescriptionData}
              isOrderPrescriptionData={isOrderPrescriptionData}
              entity_type_id={entity_type_id}
            />
            {/* <div className='pagination-wrapper'>
                <label htmlFor=''>Showing 1- 10 of 20 </label>
              </div> */}
          </div>
        ) : (
          <NoDataMsg MSG="No Transactions found" />
        )}
        {transactionsList && transactionsList?.length > 0 && (
          <Pagination
            data={transactionsList}
            pageChangeHandler={pageChangeHandler}
            pageCount={
              paginationData?.total_pages ? paginationData?.total_pages : 0
            }
            usersPerPage={10}
            currentPage={currentPage - 1}
          />
        )}
      </div>
    </>
  );
};

export default MyTransactionComp;

import React, { useContext, useState } from "react";
import yourLogo from "../../assets/images/logo.png";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/lockforgotpassword.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passBox from "../../assets/images/icons/lock.svg";
import eye from "../../assets/images/icons/eye.svg";
import facebook from "../../assets/images/icons/Facebook.svg";
import apple from "../../assets/images/icons/apple.svg";
import google from "../../assets/images/icons/google.svg";
import doodleRed from "../../assets/images/icons/doodle-red.svg";
import doodleSmall from "../../assets/images/icons/doodle-small.svg";
import doodle from "../../assets/images/icons/doodle-5 2.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import phone from "../../assets/images/icons/phone.svg";
import otpshield from "../../assets/images/icons/otpShiled.svg";
import { Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { ApiConfig } from "../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import { ClipLoader } from "react-spinners";
import OtpTimer from "../../sharedComponent/OtpTimer";
import { forgotPassword } from "../../redux/Slices/resetPasswordSlice";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";

const ForgotPassword = () => {
  // DATA FROM CONTEXT
  const { isTimeExpired, setIsTimeExpired, dispatch, navigate } =
    useContext(AppContext);
  // DATA FROM CONTEXT

  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpLoader, setotpLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotData, setForgotData] = useState({
    phone_number: "",
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      try {
        const res = await PostCallWithErrorResponse(
          ApiConfig.FORGOT_PASSWORD,
          forgotData
        );
        if (res?.json?.success === true) {
          navigate("/ResetPassword");
          dispatch(forgotPassword(res?.json?.user));
        }
      } catch (error) {
        console.log("catch error response,", error);
      } finally {
        setLoading(true);
      }
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setForgotData((prev) => ({ ...prev, [name]: value }));
  };

  const requestOTP = async () => {
    let payLoad = {
      phone_number: forgotData?.phone_number,
    };
    try {
      setotpLoader(true);
      const res = await PostCallWithErrorResponse(
        ApiConfig.REQUEST_OTP,
        payLoad
      );
      if (res?.json.success === true) {
        res?.json.otp && setOTP(res?.json?.otp ? res?.json?.otp : "");
        setIsTimeExpired(true);
        setTimeout(() => {
          res?.json.otp && varifyOTP(res?.json.otp);
        }, 1500);
        notifySuccess("OTP sent your mobile number");
      } else if (res?.json.success === false) {
        notifyError(res.json.message);
      }
    } catch (error) {
      notifyError("Something went wrong");
    } finally {
      setotpLoader(false);
    }
  };

  const varifyOTP = async (otp) => {
    let payLoad = {
      phone_number: forgotData?.phone_number,
      otp: otp ? otp : OTP,
    };
    try {
      const res = await PostCallWithErrorResponse(
        ApiConfig.VARIFY_OTP,
        payLoad
      );
      if (res?.json?.success === true) {
        notifySuccess("OTP verified successfully");
      } else if (res?.json?.success === false) {
        notifyError(res?.json?.message);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <>
      {otpLoader && <BackDropLoader />}
      <div className="authMain forgetPassword">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} alt="" style={{ height: "80px" }} />
            <div style={{display : "flex", marginTop :"35px"}}>
              <div>
                <Link to={'/'}>
                <img src={ic_left_green_arrow} alt="" />
                </Link>
              </div>
              <div className="signinForm">
                <h1 className="signInTxt">Forgot your password ?</h1>
                <h6 className="signInPara">
                  If you forgot your password, Don’t Worry !
                  <p className="mt-2 youCan">
                    You can reset your password here
                    {/*
                  <Link to='/Registration' className='registerLink ms-1'>
                    New here !
                  </Link>
                */}
                  </p>
                </h6>
                <Form noValidate validated={validated} onSubmit={submitHandler}>
                  <div className="inputBox">
                    <div className="numberRegistration">
                      <label htmlFor="" className="emailLabel">
                        Phone Number
                      </label>
                      <button
                        type="button"
                        className="requestBtn"
                        disabled={!forgotData?.phone_number ? true : false}
                        onClick={requestOTP}
                      >
                        Request OTP
                      </button>
                    </div>
                    <img src={phone} className="mailImg" alt="" />
                    <input
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your phone number"
                      required
                      name="phone_number"
                      onChange={onChangeHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter mobile number
                    </Form.Control.Feedback>
                  </div>
                  {isTimeExpired && <OtpTimer requestOTP={requestOTP} />}
                  {/* <div className='resendOtpBtn'>
                  <button>Re send OTP (26)</button>
                </div> */}
                  <div className="inputBox">
                    <label htmlFor="" className="emailLabel">
                      OTP
                    </label>
                    <img src={otpshield} className="mailImg" alt="" />
                    <input
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter OTP here..."
                      required={
                        forgotData?.phone_number && forgotData?.phone_number
                          ? true
                          : false
                      }
                      name="phonenumber"
                      value={OTP && OTP}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter OTP
                    </Form.Control.Feedback>
                  </div>
                  {/* Navigate To Get Started */}
                  <div className="login">
                    {/* <Link to="/ResetPassword" className="loginBtn"> */}
                    <button
                      className="loginBtn"
                      style={loading ? { backgroundColor: "#7fa4a4" } : {}}
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <ClipLoader color="#fff" size={30} />
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                    {/* </Link> */}
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <div className="mobileNumber">
                <span className="callNumber">
                  <img src={teleCall} height="15" width="15" alt="" /> +91
                  9509932234
                </span>
              </div>

              <div className="insiderIcon">
                <img src={insiderImg} className="lockImg" alt="" />
              </div>

              <div className="landingTxt">
                <div className="d-flex flex-column justify-content-start">
                  <h1>Forgot Password ?</h1>
                  <p>No Worries ! We got your back.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Header from "../../sharedComponent/Header";
import Subheader from "../../sharedComponent/Subheader";
import DoctorSahab from "../../assets/images/DoctorImg.svg";
import Star from "../../assets/images/Star.svg";
import hospital from "../../assets/images/hospital.svg";
import video from "../../assets/images/video.svg";
import borderHeart from "../../assets/images/borderHeart.svg";
import filledHeart from "../../assets/images/filledHeart.svg";
import liteImgArow from "../../assets/images/lightIcon.svg";
import GreenTick from "../../assets/images/greenTick.svg";
import CatImg from "../../assets/images/catImg.svg";
import Reply from "../../assets/images/ReplyIcon.svg";
import ReplyArrow from "../../assets/images/replyArrow.svg";
import CancelTick from "../../assets/images/cancel.svg";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { PostCallWithAuthToken } from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { AppContext } from "../../context/AppContext";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import dummyprofile from "../../assets/images/dummyprofile.jpeg";
import { useDateFormats } from "../../customHooks/useDateFormats";
import moment from "moment";
import RotatingLoader from "../../sharedComponent/RotatingLoader";

const DoctorDetails = () => {
  const { getAllDatesOfWeek, dateWithWeekdayandMonth } = useDateFormats();
  const { customer_id, customer_animal, customer_address, customer_details } =
    useReduxStore();
  const allDatesOfWeek = getAllDatesOfWeek();

  const weekDates = allDatesOfWeek.map((date) => {
    const inputDate = new Date(date);
    const dayName = inputDate.toLocaleDateString("en-US", { weekday: "long" });
    return {
      date: dateWithWeekdayandMonth(date),
      day:
        dayName === "Sunday"
          ? "is_weekend_sun"
          : dayName === "Monday"
          ? "is_weekend_mon"
          : dayName === "Tuesday"
          ? "is_weekend_tue"
          : dayName === "Wednesday"
          ? "is_weekend_wed"
          : dayName === "Thursday"
          ? "is_weekend_thu"
          : dayName === "Friday"
          ? "is_weekend_fri"
          : dayName === "Saturday"
          ? "is_weekend_sat"
          : "",
    };
  });

  const { setBkdropLdr, navigate, setEventKey } = useContext(AppContext);
  // DATA FROM PARAMS
  const params = useParams();
  const doctorId = parseInt(params?.id);
  // DATA FROM PARAMS

  // STATES
  const [doctorDetails, setDoctorDetails] = useState();
  // STATES

  useEffect(() => {
    fetchDoctorDetails();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const fetchDoctorDetails = async () => {
    try {
      const payLoad = {
        doctor_id: doctorId,
        // doctor_id: 6
      };
      setIsLoading(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_DOCTOR_INFO,
        payLoad
      );

      const { result, data } = res?.json;
      if (result) {
        data && setDoctorDetails(data[0]);
      }
    } catch (error) {
      console.log("catch error ", error);
    } finally {
      setIsLoading(false);
    }
  };

  // AddFavDoc
  const addFavourite = async (doctorId) => {
    try {
      const payLoad = {
        customer_id: customer_id,
        doctor_id: doctorId,
      };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_FAVOURITE_DOCTOR,
        payLoad
      );
      const { result, message } = res?.json;
      if (result) {
        // notifySuccess("Doctor added into favourites list");
        setDoctorDetails({
          ...doctorDetails,
          customer_favourite_id: true,
        });
      } else if (result === false) {
        notifyError("Already added into favourites list");
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  const specialisations = doctorDetails?.specialization_details
    .map((item, i) => {
      return item?.specialization;
    })
    .join(", ");
  return (
    <>
      <div className="DoctorsDetails" id="cx-main">
        <Subheader />
        {isLoading ? (
          <RotatingLoader />
        ) : (
          <>
            {/* Doctor Details Card */}
            <div className="headingCard">
              <div className="leftContent">
                <div className="imgHolder">
                  {/* dummydoctor */}
                  <img
                    src={
                      doctorDetails?.doctor_profile_image_path
                        ? doctorDetails?.doctor_profile_image_path
                        : dummydoctor
                    }
                    className="imgInside"
                    alt=""
                  />
                </div>
                <div className="txtHolder">
                  <p className="headingTxt">
                    {doctorDetails?.doctor_name && doctorDetails?.doctor_name}
                  </p>
                  {doctorDetails?.specialization_details && (
                    <p className="timeTxt">
                      {specialisations ? specialisations : ""} |{" "}
                      {doctorDetails?.specialization_details[0]?.experience &&
                        doctorDetails?.specialization_details[0]
                          ?.experience}{" "}
                      years
                      {/* {doctorDetails?.specialization_details[0]?.experience}{' '}
                {!doctorDetails?.specialization_details[0]?.experience
                  ?.toLowerCase()
                  .includes('year') && 'years'} */}
                    </p>
                  )}
                  {doctorDetails?.rating && (
                    <p className="rateTxt">
                      <img src={Star} alt="" />
                      {parseFloat(doctorDetails?.rating).toFixed(1)} |{" "}
                      {doctorDetails?.total_reviews} reviews
                    </p>
                  )}
                  {/* <div className='imgFlex'>
                <img src={dummydoctor} alt='' />
                <img src={dummydoctor} alt='' />
                <img src={dummydoctor} alt='' />
              </div> */}
                </div>
              </div>
              <div className="rightContent">
                <p className="hospitalTxt">
                  <img src={hospital} className="hopsital" alt="" />₹
                  {doctorDetails?.schedule_details &&
                    doctorDetails?.schedule_details[0]
                      ?.slot_morning_doctor_charge}{" "}
                  / Morning
                </p>
                <p className="videoTxt">
                  {/* <img src={video} className='video' alt='' /> */}
                  <img src={hospital} className="hopsital" alt="" />₹
                  {doctorDetails?.schedule_details &&
                    doctorDetails?.schedule_details[0]
                      ?.slot_evening_doctor_charge}{" "}
                  / Evening
                </p>
                <p
                  className="videoTxt"
                  onClick={() => {
                    !doctorDetails?.customer_favourite_id &&
                      addFavourite(doctorId);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={
                      doctorDetails?.customer_favourite_id
                        ? filledHeart
                        : borderHeart
                    }
                    className="borderHeart"
                    alt=""
                  />
                  Mark as favorite
                </p>
                {doctorDetails?.schedule_details &&
                  doctorDetails?.schedule_details[0]?.doctor_clinic_id && (
                    // <Link
                    //   to={`/BookAppoitment?doctorId=${doctorDetails?.doctor_id}&clinic_id=${doctorDetails?.schedule_details[0]?.doctor_clinic_id}`}
                    //   className="bookBtn"
                    // >
                    <button
                      className="bookBtn"
                      onClick={() => {
                        if (!customer_address || !customer_details) {
                          navigate("/ProfileSetting");
                          setEventKey("11");
                          if(!customer_details){
                            notifyError(
                              "Please update your profile details and address"
                            );
                          } else if (!customer_address) {
                            notifyError(
                              "Please add your address"
                            );
                          }
                          sessionStorage.setItem(
                            "navigationDetails",
                            JSON.stringify({
                              isNavigateFromDoctorPage: true,
                              doctor_id: doctorDetails?.doctor_id,
                              clinic_id:
                                doctorDetails?.schedule_details[0]
                                  ?.doctor_clinic_id,
                            })
                          );
                        } else {
                          navigate(
                            `/BookAppoitment?doctorId=${doctorDetails?.doctor_id}&clinic_id=${doctorDetails?.schedule_details[0]?.doctor_clinic_id}`
                          );
                        }
                      }}
                    >
                      Book Appointment <img src={liteImgArow} alt="" />{" "}
                    </button>
                    //  </Link>
                  )}
              </div>
            </div>

            {/* Content Tabs */}
            <div className="tabsContent">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Overview">
                <Nav variant="pills" defaultActiveKey="/home">
                  {doctorDetails?.specialization_details &&
                    doctorDetails?.specialization_details.length > 0 && (
                      <Nav.Item>
                        <Nav.Link eventKey="Overview">Overview</Nav.Link>
                      </Nav.Item>
                    )}
                  {doctorDetails?.schedule_details[0] && (
                    <Nav.Item>
                      <Nav.Link eventKey="BusinessHours">
                        Business Hours
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {doctorDetails?.schedule_details &&
                    doctorDetails?.schedule_details.length > 0 && (
                      <Nav.Item>
                        <Nav.Link eventKey="Locations">Locations</Nav.Link>
                      </Nav.Item>
                    )}

                  {doctorDetails?.reviews && doctorDetails?.reviews.length && (
                    <Nav.Item>
                      <Nav.Link eventKey="RatingsReviews">
                        Ratings & Reviews
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {/* 
                  <Nav.Item>
                                  <Nav.Link eventKey='FAQs'>FAQ’s</Nav.Link>
                                </Nav.Item>
                  */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Overview">
                    <div className="overviewCard">
                      {/* <div className='headerIntro'>
                    <h1 className='headTxt'>About Me</h1>
                    <p className='insidePara'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Animi, voluptas aspernatur aliquid inventore nihil quae
                      tempore officia hic laborum earum sint non excepturi.
                      Perspiciatis esse doloremque fugiat, quibusdam unde qui
                      atque cum nemo quia! Porro veritatis minima quasi tempora
                      inventore.earum sint non excepturi. Perspiciatis esse
                      doloremque fugiat, quibusdam unde qui atque cum nemo quia!
                      Porro veritatis minima quasi tempora inventore.
                    </p>
                  </div> */}
                      <div className="row">
                        <div className="col-md-3">
                          {doctorDetails?.specialization_details &&
                            doctorDetails?.specialization_details[0]
                              ?.education && (
                              <div className="relatedDetails">
                                <h1 className="keyTxt">Education</h1>
                                <ul className="listingValues">
                                  <li>
                                    {
                                      doctorDetails?.specialization_details[0]
                                        ?.education
                                    }
                                  </li>
                                </ul>
                              </div>
                            )}
                        </div>
                        {doctorDetails?.known_languages && (
                          <div className="col-md-3">
                            <div className="relatedDetails">
                              <h1 className="keyTxt">Languages Known</h1>
                              <ul className="listingValues">
                                <li>
                                  {doctorDetails?.known_languages &&
                                    doctorDetails?.known_languages.map(
                                      (item, i, allArr) => {
                                        return (
                                          <span>
                                            {item?.language}
                                            {allArr.length !== i + 1
                                              ? ","
                                              : ""}{" "}
                                          </span>
                                        );
                                      }
                                    )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {doctorDetails?.doctor_registration_no && (
                          <div className="col-md-3">
                            <div className="relatedDetails">
                              <h1 className="keyTxt">Registration No.</h1>
                              <ul className="listingValues">
                                <li>{doctorDetails?.doctor_registration_no}</li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <div className="col-md-3"></div>
                        {doctorDetails?.specialization_details && (
                          <div className="col-md-12">
                            <div className="relatedDetails">
                              <h1 className="keyTxt">Experience</h1>
                              <ul className="listingValues">
                                <li>
                                  {
                                    doctorDetails?.specialization_details[0]
                                      ?.experience
                                  }{" "}
                                  years
                                  {/* {!doctorDetails?.specialization_details[0]?.experience
                                ?.toLowerCase()
                                .includes('year') && 'years'} */}
                                </li>
                                {/* <li>100+ Successful Appointments</li> */}
                              </ul>
                            </div>
                          </div>
                        )}
                        {doctorDetails?.specialization_details &&
                          doctorDetails?.specialization_details.length > 0 && (
                            <div className="col-md-12">
                              <div className="relatedDetails">
                                <h1 className="keyTxt">Specialization</h1>
                                <div className="d-flex">
                                  <ul className="listingValues">
                                    {doctorDetails?.specialization_details?.map(
                                      (item, i) => {
                                        return <li>{item?.specialization}</li>;
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}

                        {/* <div className='col-md-12'>
                      <div className='relatedDetails mgBelowNone'>
                        <h1 className='keyTxt'>Services</h1>
                        <div className='d-flex'>
                          <ul className='listingValues'>
                            <li>Orthopedic & Research</li>
                            <li>Orthopedic & Research</li>
                          </ul>
                          <ul className='listingValues'>
                            <li>Orthopedic & Research</li>
                            <li>Orthopedic & Research</li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="BusinessHours">
                    <div className="BusinessHours">
                      <div className="detailsCard">
                        {weekDates &&
                          weekDates
                            .filter((ele) => ele.date === "Today")
                            .map((item, idx) => {
                              const scheduledDetailsObj =
                                doctorDetails?.schedule_details[0];
                              let datKey = item?.day;
                              const isHoliday =
                                scheduledDetailsObj &&
                                scheduledDetailsObj[datKey];
                              return (
                                <div className="headerDetails">
                                  <div className="date">
                                    <h1 className="dayTxt">{item?.date}</h1>
                                    <label className="dateTxt">
                                      {new Date().toLocaleDateString("en-US", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                    </label>
                                  </div>
                                  <div className="status">
                                    {isHoliday ? (
                                      <label
                                        htmlFor=""
                                        className="insideValue text-danger"
                                      >
                                        <img src={CancelTick} alt="" /> Closed /
                                        Holiday
                                      </label>
                                    ) : (
                                      <>
                                        <h1 className="statusTxt">
                                          <img src={GreenTick} alt="" />
                                          Open Now
                                        </h1>
                                        <label className="dateTxt">
                                          {moment(
                                            doctorDetails?.schedule_details[0]
                                              ?.clinic_morning_start_time,
                                            "hh:mm A"
                                          ).format("hh:mm A")}{" "}
                                          to{" "}
                                          {moment(
                                            doctorDetails?.schedule_details[0]
                                              ?.clinic_evening_end_time,
                                            "hh:mm A"
                                          ).format("hh:mm A")}{" "}
                                        </label>
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}

                        <div className="contentTxt">
                          {weekDates &&
                            weekDates
                              .filter((ele) => ele?.date !== "Today")
                              .map((item, idx) => {
                                const scheduledDetailsObj =
                                  doctorDetails?.schedule_details[0];
                                let datKey = item?.day;
                                const isHoliday =
                                  scheduledDetailsObj &&
                                  scheduledDetailsObj[datKey];
                                return (
                                  <div className="timingStatusSignals">
                                    <label htmlFor="" className="insideKey">
                                      {item?.date}
                                    </label>
                                    {isHoliday ? (
                                      <label
                                        htmlFor=""
                                        className="insideValue text-danger"
                                      >
                                        <img src={CancelTick} alt="" /> Closed /
                                        Holiday
                                      </label>
                                    ) : (
                                      <label htmlFor="" className="insideValue">
                                        {moment(
                                          doctorDetails?.schedule_details[0]
                                            ?.clinic_morning_start_time,
                                          "hh:mm A"
                                        ).format("hh:mm A")}{" "}
                                        to{" "}
                                        {moment(
                                          doctorDetails?.schedule_details[0]
                                            ?.clinic_evening_end_time,
                                          "hh:mm A"
                                        ).format("hh:mm A")}{" "}
                                      </label>
                                    )}
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Locations">
                    {doctorDetails?.schedule_details &&
                    doctorDetails?.schedule_details.length > 0 ? (
                      <div className="LocationsTab">
                        {doctorDetails?.schedule_details.map((item, i) => {
                          return (
                            <div
                              className="row"
                              key={
                                item?.doctor_clinic_id && item?.doctor_clinic_id
                              }
                            >
                              <div className="col-md-12">
                                <div className="headingTxtNrate">
                                  <label htmlFor="" className="headTxt">
                                    Vet & Pet Clinics
                                  </label>
                                  {doctorDetails?.rating && (
                                    <label htmlFor="" className="reviewTxt">
                                      <img
                                        src={Star}
                                        className="starImg"
                                        alt=""
                                      />{" "}
                                      {parseFloat(
                                        Number(doctorDetails?.rating)
                                      ).toFixed(1)}
                                      {/* | reviews{" "} */}
                                    </label>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="relatedDetails">
                                  <h1 className="keyTxt">Clinic Name</h1>
                                  <label htmlFor="" className="TabValues">
                                    {item?.doctor_clinic_name}
                                  </label>
                                </div>
                              </div>
                              {item?.clinic_facilities &&
                                item?.clinic_facilities?.length > 0 && (
                                  <div className="col-md-3">
                                    <div className="relatedDetails">
                                      <h1 className="keyTxt">Facilities</h1>
                                      <ul className="listingValues">
                                        {item?.clinic_facilities.map(
                                          (item, i) => {
                                            return (
                                              <li>{item?.clinic_facility}</li>
                                            );
                                          }
                                        )}

                                        {/* <li>Surgery</li>
                                <li>24 x 7 Oxygen</li>
                                <li>Free Wifi</li>
                                <li>Ambulance Support</li> */}
                                      </ul>
                                    </div>
                                  </div>
                                )}

                              <div className="col-md-3">
                                <div className="relatedDetails">
                                  <h1 className="keyTxt">Clinic Address</h1>
                                  <label htmlFor="" className="TabValues">
                                    {item?.doctor_clinic_address}
                                  </label>
                                  {/* <div>
                                <Link to='#' className='directionsLink'>
                                  <label htmlFor=''>Get Directions</label>
                                </Link>
                              </div> */}
                                </div>
                              </div>
                              {item?.clinic_images &&
                                item?.clinic_images.length > 0 && (
                                  <div className="col-md-3">
                                    <div className="relatedDetails">
                                      <h1 className="keyTxt">Photos</h1>
                                      <div className="photosGallery">
                                        {item?.clinic_images.map((item, i) => {
                                          // const splittedArr =
                                          //   item?.image_path.split('\\')
                                          // const imagePath =
                                          //   splittedArr[splittedArr.length - 1]
                                          return (
                                            <img
                                              src={
                                                item?.image_path
                                                  ? item?.image_path
                                                  : DoctorSahab
                                              }
                                              className="clinicImgs"
                                              alt="Not Found"
                                            />
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })}

                        {/* <div className='col-md-12'>
                        <div className='headingTxtNrate'>
                          <label htmlFor='' className='headTxt'>
                            Vet & Pet Clinics
                          </label>
                          <label htmlFor='' className='reviewTxt'>
                            <img src={Star} className='starImg' alt='' /> 4.3 |
                            reviews{' '}
                          </label>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='relatedDetails'>
                          <h1 className='keyTxt'>Clinic Name</h1>
                          <label htmlFor='' className='TabValues'>
                            David’s Animal Care
                          </label>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='relatedDetails'>
                          <h1 className='keyTxt'>Facilities</h1>
                          <ul className='listingValues'>
                            <li>OPD</li>
                            <li>Surgery</li>
                            <li>24 x 7 Oxygen</li>
                            <li>Free Wifi</li>
                            <li>Ambulance Support</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='relatedDetails'>
                          <h1 className='keyTxt'>Clinic Name</h1>
                          <label htmlFor='' className='TabValues'>
                            125/2, Kalas Road, Vishrantwadi, Pune - 411041.
                            Maharashtra, India
                          </label>
                          <Link to='#' className='directionsLink'>
                            <label htmlFor=''>Get Directions</label>
                          </Link>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='relatedDetails'>
                          <h1 className='keyTxt'>Photos</h1>
                          <div className='photosGallery'>
                            <img
                              src={DoctorSahab}
                              className='clinicImgs'
                              alt=''
                            />
                            <img
                              src={DoctorSahab}
                              className='clinicImgs'
                              alt=''
                            />
                            <img
                              src={DoctorSahab}
                              className='clinicImgs'
                              alt=''
                            />
                            <img
                              src={DoctorSahab}
                              className='clinicImgs'
                              alt=''
                            />
                          </div>
                        </div>
                      </div> */}
                      </div>
                    ) : (
                      <div style={{ margin: "10%" }}>
                        <NoDataMsg />
                      </div>
                    )}
                  </Tab.Pane>

                  <Tab.Pane eventKey="RatingsReviews">
                    <div className="RatingsReviews">
                      <div className="row g-0 reviewRow">
                        {doctorDetails?.reviews &&
                        doctorDetails?.reviews.length > 0
                          ? doctorDetails?.reviews.map((item, i) => {
                              return (
                                <>
                                  <div className="col-md-1 p-0 reviewCol1">
                                    <div className="catImg">
                                      <img
                                        src={item?.customer_image_path}
                                        onError={(e) => {
                                          e.target.src = dummyprofile;
                                        }}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-11 p-0 reviewCol11">
                                    <div className="reviewsNchats">
                                      <div className="headingDeta">
                                        <div className="docName">
                                          <h1 className="headTxt">
                                            {item?.title}
                                          </h1>
                                          <label className="labDEt">
                                            Dr. {item?.customer_name}
                                          </label>
                                        </div>
                                        <div className="rates">
                                          <label className="starNreview">
                                            <img
                                              src={Star}
                                              className="starImg"
                                              alt=""
                                            />
                                            {item?.rating}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="chatsNtxt">
                                        <p className="innerPara">
                                          {item?.description}
                                        </p>
                                      </div>
                                      {/* <div className='chatsNtxt'>
                            <label htmlFor='' className='ReplyTxt'>
                              <img src={Reply} className='Reply' alt='' /> Reply{' '}
                            </label>
                            <div className='mainTxtArea'>
                              <Form.Control
                                as='textarea'
                                placeholder='Type your comments...'
                                style={{ height: '60px' }}
                              />
                            </div>
                            <div className='belowBtns'>
                              <Link className='cancelLink' to='#'>
                                <button className='cancleBtn'>Cancel</button>
                              </Link>
                              <Link className='replyLink' to='#'>
                                <button className='replyBtn'>
                                  Reply{' '}
                                  <img
                                    src={ReplyArrow}
                                    className='replyArrow'
                                    alt=''
                                  />{' '}
                                </button>
                              </Link>
                            </div>
                          </div> */}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : null}

                        {/* <div className='col-md-1 p-0'>
                      <div className='catImg'>
                        <img src={CatImg} alt='' />
                      </div>
                    </div>
                    <div className='col-md-11 p-0 reviewCol11'>
                      <div className='reviewsNchats'>
                        <div className='headingDeta'>
                          <div className='docName'>
                            <h1 className='headTxt'>
                              Regular health check up.
                            </h1>
                            <label className='labDEt'>Dr. Rohit Sharma</label>
                          </div>
                          <div className='rates'>
                            <label className='starNreview'>
                              <img src={Star} className='starImg' alt='' />
                              4.3
                            </label>
                          </div>
                        </div>
                        <div className='chatsNtxt'>
                          <p className='innerPara'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Saepe, vero doloremque deleniti obcaecati cum
                            totam assumenda quaerat, doloribus minus quidem
                            minima incidunt architecto esse aliquid magnam culpa
                            labore. Expedita assumenda blanditiis dolor enim
                            iure voluptatibus placeat quos adipisci!
                          </p>
                        </div>
                        <div className='doctorReview'>
                          <div className='imgWrapper'>
                            <img
                              src={DoctorSahab}
                              className='imsideDoc'
                              alt=''
                            />
                          </div>
                          <div className='textWrapper'>
                            <h1 className='description'>
                              Regular health check up.
                            </h1>
                            <label className='NameDOc'>Dr. Rohit Sharma</label>
                            <p className='ineerPara'>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Illo amet dolore id nam
                              laboriosam cupiditate odit ea est, rerum itaque
                              nulla praesentium repellat pariatur eum
                              repellendus consequatur quia inventore incidunt
                              beatae. Obcaecati at, error nisi facilis rem odit
                              voluptatum incidunt.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                        {/* <div className='col-md-12'>
                      <div className='showMoreCol'>
                        <button className='showMoreBtn'>
                          Show All Feedbacks (100)
                        </button>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="FAQs">
                    <div className="FAQs">
                      <div className="accordiansTab">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Q. What is the best medicine to treat dog hairs ?
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="solutionFlex">
                                <label htmlFor="">Answer: </label>
                                <label htmlFor="" className="mx-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua
                                  incididunt ut labore et dolore magna aliqua Ut
                                  enim ad minim veniam,{" "}
                                </label>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Q. What is the best medicine to treat dog hairs ?
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="solutionFlex">
                                <label htmlFor="">Answer: </label>
                                <label htmlFor="" className="mx-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua
                                  incididunt ut labore et dolore magna aliqua Ut
                                  enim ad minim veniam,{" "}
                                </label>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              Q. What is the best medicine to treat dog hairs ?
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="solutionFlex">
                                <label htmlFor="">Answer: </label>
                                <label htmlFor="" className="mx-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua
                                  incididunt ut labore et dolore magna aliqua Ut
                                  enim ad minim veniam,{" "}
                                </label>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Q. What is the best medicine to treat dog hairs ?
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="solutionFlex">
                                <label htmlFor="">Answer: </label>
                                <label htmlFor="" className="mx-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua
                                  incididunt ut labore et dolore magna aliqua Ut
                                  enim ad minim veniam,{" "}
                                </label>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              Q. What is the best medicine to treat dog hairs ?
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="solutionFlex">
                                <label htmlFor="">Answer: </label>
                                <label htmlFor="" className="mx-2">
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua
                                  incididunt ut labore et dolore magna aliqua Ut
                                  enim ad minim veniam,{" "}
                                </label>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DoctorDetails;

import React from "react";
import survey from "../../assets/Artcles_Images/survey.jpg";
import { Navigate } from "react-router-dom";
let isShow = false;

let tableTd = {
  border: "1px solid black",
  padding: "5px 8px",
};
const Article = () => {
    const isDisplay = sessionStorage.articleType ? sessionStorage.articleType : ""
  return (
  isDisplay ? <>
      {isDisplay === "camel" ? (
        <div
          className="camel_article"
          style={{
            padding: "0px 12px",
          }}
        >
          <div
            style={{
              padding: "10px 5px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <h3 style={{ color: "#1D7C7C" }}>
              Feeding Management System of Camels in Rajasthan
            </h3>
            <p>Dr. A.K. Limba, Veterinary officer</p>
          </div>
          <div style={{ fontSize: "larger", padding: "20px" }}>
            <p>
              Camel plays a crucial role in Indian economy by providing gainful
              employment and regular flow of income to the farmers of arid zone.
              The camel, ship of desert have many qualities which make it
              distinctly superior to other domesticated animals in the hot and
              arid desert ecosystem. Camel nutrition is an important area of
              attention as the old rearing system is changing with reduction in
              grazing area and restriction on moment of animals for grazing from
              one place to another for grazing. In desert area camel can survive
              on poor quality feed by its unique ability to digest them. Three
              types of feeding management system were adopted by camel owners as
              extensive, semi intensive and intensive according to herd size
              i.e. more than 10 camels, 2-10 camels and 1-2 camels,
              respectively. In Extensive feeding management system, camels are
              let loose for grazing in the range land i.e. camels feed khejri
              leaves, neem leaves, ker, phog etc. and none of the feed
              supplements are given. In Semi Intensive feeding management
              system, camel owners partly graze their camels for 4-5 hours per
              day and give stall feeding i.e. crop residues, dry grasses and top
              feeds. Some of the camel owners feeding mixer of bajra flour,
              jeggary, alum, moth flour and bajra flour as concentrate. In
              Intensive feeding management system, stall feeding is done.
              Concentrate 1-2 kg given to camels as per working load of animals
              during night hours. Majority of the camel owners feeding mixer of
              bajra flour, jeggary, alum,moth flour and bajra flour as
              concentrate. Roughage i.e. moogphali chara, guar phalgati and mix
              bhusa i.e. moth chara, moong chara etc. were given to camels by
              camel owners. In this system camel owners use camels as
              agricultural operations, transportation of people and materials.
            </p>
          </div>
        </div>
      ) : isDisplay === "pigeon" ? (
        <div
          style={{
            padding: "0px 12px",
          }}
        >
          <div
            style={{
              padding: "10px 5px",
              marginTop: "20px",
            }}
          >
            <h6
              style={{
                color: "#1D7C7C",
              }}
            >
              The Yellow-footed Green Pigeon: A Jewel of the Indian Subcontinent
            </h6>

            <div>
              <p style={{ marginLeft: "20px" }}>
                The Yellow-footed Green Pigeon (Treron phoenicopterus), also
                recognized as the yellow-legged green pigeon, stands as a
                testament to the rich avian diversity gracing the Indian
                subcontinent and parts of Southeast Asia. This species, adorned
                with striking hues, holds cultural significance and ecological
                importance, painting the skies with its presence.
              </p>
            </div>
          </div>

          <div
            style={{
              padding: "10px 5px",
            }}
          >
            <h6
              style={{
                color: "#1D7C7C",
              }}
            >
              Appearance and Distribution
            </h6>

            <div>
              <p style={{ marginLeft: "20px" }}>
                Resplendent in shades of green with vibrant yellow feet, the
                Yellow-footed Green Pigeon captivates onlookers with its beauty.
                It traverses the skies, gracing the landscapes of the Indian
                subcontinent and extending its reach into Southeast Asia. With
                its distinctive appearance, it stands out amongst its avian
                counterparts, earning admiration wherever it goes.
              </p>
            </div>
          </div>

          <div
            style={{
              padding: "10px 5px",
            }}
          >
            <h6
              style={{
                color: "#1D7C7C",
              }}
            >
              Cultural Significance
            </h6>

            <div>
              <p style={{ marginLeft: "20px" }}>
                In Maharashtra, the Yellow-footed Green Pigeon holds a
                prestigious title as the state bird, embodying the essence of
                the region’s natural heritage. Known as “Haroli” or “Hariyal” in
                Marathi, its presence resonates deeply with the local culture,
                weaving itself into the fabric of traditions and folklore.
                Beyond Maharashtra, it bears various names such as “Haitha” in
                Upper Assam and “Haitol” in Lower Assam, further emphasizing its
                cultural significance across different regions.
              </p>
            </div>
          </div>

          <div
            style={{
              padding: "10px 5px",
            }}
          >
            <h6
              style={{
                color: "#1D7C7C",
              }}
            >
              Ecological Role
            </h6>

            <div>
              <p style={{ marginLeft: "20px" }}>
                Feeding primarily on fruit, including the bountiful offerings of
                Ficus species, the Yellow-footed Green Pigeon plays a crucial
                role in seed dispersal, contributing to the regeneration of
                forests. These birds exhibit a gregarious nature, foraging in
                flocks as they move through their habitat, ensuring their
                survival while also benefiting the ecosystem they inhabit.
              </p>
            </div>
          </div>

          <div
            style={{
              padding: "10px 5px",
            }}
          >
            <h6
              style={{
                color: "#1D7C7C",
              }}
            >
              Habitat and Behavior
            </h6>

            <div>
              <p style={{ marginLeft: "20px" }}>
                Yellow-footed Green Pigeons display habitat flexibility,
                adapting to various environments. At dawn, they can often be
                spotted basking in the sunlight atop emergent trees in dense
                forest areas, particularly favoring the majestic presence of
                Banyan trees. Despite their affinity for natural habitats, they
                have also been observed in urban areas, highlighting their
                adaptability to human-modified landscapes.
              </p>
            </div>
          </div>

          <div
            style={{
              padding: "10px 5px",
            }}
          >
            <h6
              style={{
                color: "#1D7C7C",
              }}
            >
              Conservation Status
            </h6>

            <div>
              <p style={{ marginLeft: "20px", marginBottom: "20px" }}>
                Despite facing threats such as habitat loss and fragmentation,
                the population of Yellow-footed Green Pigeons is currently on
                the rise. Conservation efforts aimed at preserving their
                habitats and raising awareness about their importance play a
                vital role in ensuring their continued existence.
              </p>
              <p style={{ marginLeft: "20px" }}>
                In conclusion, the Yellow-footed Green Pigeon stands as a symbol
                of beauty, cultural significance, and ecological resilience. Its
                presence enriches the landscapes it graces, reminding us of the
                intricate connections between humans and the natural world. As
                stewards of the environment, it is our collective responsibility
                to safeguard the habitats that sustain these magnificent
                creatures, ensuring a harmonious coexistence for generations to
                come.
              </p>
            </div>
          </div>
        </div>
      ) : isDisplay === "dog" ? (
        <div
          style={{
            padding: "5px 12px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              padding: "10px 5px",
              marginTop: "10px",
            }}
          >
            <h5 style={{ textDecoration: "underline", fontStyle: "italic" }}>
              Can Your Dog Eat Raw Eggs?
            </h5>
            <span>
              Dr. Amit Sharma, Veterinary Officer, Directorate Animal Husbandry,
              Rajasthan
            </span>
          </div>
          <div style={{ padding: "10px 10px" }}>
            <p>
              “EGG - NATURE’S ORIGINAL FUNCTIONAL FOOD.” Eggs are one of
              nature's most perfectly balanced foods, containing all the
              proteins, vitamins (except vitamin C), fat and minerals essential
              for good health. One whole, large raw egg contains:
            </p>
            <div
              style={{
                display: "flex",
                gap: "70px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <table>
                  <tr>
                    <td style={tableTd}>Water</td>
                    <td style={tableTd}>76.1 %</td>
                  </tr>
                  <tr>
                    <td style={tableTd}>Protein</td>
                    <td style={tableTd}>12.56 %</td>
                  </tr>
                  <tr>
                    <td style={tableTd}>Fat</td>
                    <td style={tableTd}>9.51%</td>
                  </tr>
                  <tr>
                    <td style={tableTd}>Carbohydrate</td>
                    <td style={tableTd}>0.72%</td>
                  </tr>
                  <tr>
                    <td style={tableTd}>Fat</td>
                    <td style={tableTd}>1.1%</td>
                  </tr>
                </table>
              </div>
              <div>
                <img src={survey} />
              </div>
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <p>
              This makes them a yummy and healthy snack when cooked or boiled.
              In fact, eggs may even be able to help settle your dog's upset
              stomach and can also be an important source of protein in some
              commercial dog foods.
            </p>
            <p>
              Although cooked eggs can be a nutritious treat for your dog, raw
              eggs are dangerous. As with humans, raw eggs can expose dogs to
              harmful salmonella bacteria infection, which can then spread back
              to their pet parents (especially those with a compromised immune
              system). But beyond bacteria, there's another threat raw eggs pose
              to your dog's health: biotin deficiency. Raw eggs contain an
              enzyme avidin. Avidin is known to bind to biotin in the small
              intestine, preventing its absorption into the body. Because biotin
              is a vitamin responsible for supporting critical bodily functions
              (including digestion, skin health and metabolism), deficiency puts
              your dog at risk for serious health complications
            </p>
          </div>

          <div>
            <h4>How to Prepare Eggs for Your Dog</h4>
            <div style={{ marginLeft: "20px" }}>
              <p>
                When preparing eggs for your dog, you'll want to think about
                simplicity and safety. Dogs can eat scrambled eggs, but whole
                boiled eggs can pose a choking hazard. When feeding your dog a
                boiled egg, make sure you cut the egg into bitesized pieces that
                are easy for them to chew and swallow.
              </p>
              <p>
                Unlike in your own breakfast, your dog's eggs should be prepared
                without any of the additives we use to bolster taste, such as
                oil, salt and butter. Salt can increase water retention, which
                is potentially fatal to dogs prone to heart disease. Meanwhile,
                fats like oil and butter can contribute to weight gain, which
                puts your dog at risk for obesity-related health issues such as
                diabetes, heart disease, kidney disease, liver disease,
                osteoarthritis and respiratory problems.
              </p>
              <p>
                It's also worth noting that you should always let the eggs cool
                down before you provide them to your dog. Most dogs don't have
                the same reservations for thinking about how hot the food is
                before gobbling it down and could burn their mouths if they eat
                them right off the stove. Also, always remember to consider
                calories as part of your dog's daily meals. If you introduce
                eggs to their meal plans, make sure that these "extra snacks" do
                not account for more than 10 percent of their daily caloric
                intake without proper permission from your veterinarian. While
                eggs can be nutritious for your dog, they still need plenty of
                other nutrients to keep them happy and healthy, which is why a
                completely and properly balanced premium dog food is always
                recommended.
              </p>
              <p>
                When properly cooked and served in moderation, eggs can be an
                excellent addition to your dog's meal plan. By keeping your
                recipes simple and ensuring that the eggs are cut into small
                pieces, you could introduce your dog to a brand-new, healthy
                treat they'll love the rest of their lives.
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </> : <Navigate to="/Home" />
  );
};

export default Article;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { AppContext } from "../../context/AppContext";
import callerImg from "../../assets/images/dummyprofile.jpeg";
import { useNavigate } from "react-router-dom";
import { ApiConfig } from "../../api/ApiConfig";
import { notifyError } from "../../ToastMessages/notify";

const PopupOverlay = () => {
  const {
    senderNameImg,
    senderName,
    modalOpen,
    setModalOpen,
    socket,
    rejectCall,
    answerCall,
    setdrocterCallOpen,
  } = useContext(AppContext);

  // const rejectCall = () => {
  //   console.log('rejectCall call to sender...',senderId,receiverId)
  //   stop()
  //   const requestBody = {
  //     senderId: receiverId,
  //     receiverId: senderId,
  //     call_id: callId,
  //     room_id: roomId,
  //   }

  //   socket.emit('rejectcall', requestBody)

  //   setModalOpen(false)
  //   setdrocterCallOpen(false)
  // }

  // const answerCall = () => {
  //   console.log("answering call to sender...");
  //   stop()
  //   const requestBody = {
  //     senderId: receiverId,
  //     receiverId: senderId,
  //     call_id: callId,
  //     room_id: roomId,
  //   };

  //   socket.emit("answercall", requestBody);
  //   navigate("/ProfileSetting");
  //   setdrocterCallOpen(false)
  //   setModalOpen(false)
  //   setEventKey(33)
  //   setCallAccepted(true)
  //   stop()

  //   // setVideoChatting(false)

  // };

  useEffect(() => {
    socket &&
      socket.on("callrejected", (data) => {
        console.log("Received user calling callrejected callrejected:", data);
        setdrocterCallOpen(false);
        notifyError("Call disconnected");
      });

    return () => {
      socket && socket.off("callrejected");
    };
  }, [socket]);

  return (
    <div id="popupPrime">
      {modalOpen ? (
        <Dialog
          header="Incoming Call"
          visible={modalOpen}
          position={"top-right"}
          style={{ width: "27vw" }}
          onHide={() => setModalOpen(false)}
          // footer={footerContent}
          draggable={false}
          resizable={false}
        >
          <div className="insideCallSec">
            <img src={senderNameImg} alt="" />
            <div className="detailsSec">
              <p
                style={{
                  marginTop: "30px",
                  color: "black",
                }}
              >
                Name : <span>{senderName}</span>
              </p>
              <p
                style={{
                  marginTop: "10px",
                  marginLeft: "45px",
                  fontSize: "21px",
                  color: "black",
                }}
              >
                Incoming call
                {/* Date : <span>09/10/2023</span> */}
              </p>
            </div>
            <div className="buttonsFlex">
              <div
                className="acceptBox"
                onClick={() => {
                  answerCall();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="#fff"
                  class="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  />
                </svg>
              </div>
              <div
                className="rejectBox"
                onClick={() => {
                  rejectCall();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="#fff"
                  class="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Dialog>
      ) : null}
    </div>
  );
};

export default PopupOverlay;

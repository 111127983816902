import React, { useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";

const AutocompleteGoogleInput = ({
  required,
  className,
  placeholder,
  onPlaceSelect,
  currentIndex,
  name,
  value,
}) => {
  const autocompleteRef = useRef(null);
  const handlePlaceSelect = (event) => {
    const autocompleteInstance = autocompleteRef.current;
    const place = autocompleteInstance?.getPlace();
    const lat = place?.geometry?.location.lat();
    const lng = place?.geometry?.location.lng();
    const address = place?.formatted_address;
    if (onPlaceSelect) {
      onPlaceSelect(event, { lat, lng, address }, currentIndex, name);
    }
  };

  return (
    <>
      <Autocomplete
        onLoad={(auto) => (autocompleteRef.current = auto)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          defaultValue={value}
          required={required}
          type="text"
          className={className ? className : "google-autocomlete-input"}
          placeholder={placeholder}
          onChange={(e) => handlePlaceSelect(e)}
        />
      </Autocomplete>
      <div className="invalid-feedback font-11">Please enter your address</div>
    </>
  );
};

export default AutocompleteGoogleInput;

import { useSelector } from "react-redux";

export const useReduxStore = () => {
  const customerLoggedInData = useSelector((state) => state?.auth);
  const customer_id =
    customerLoggedInData && customerLoggedInData?.loginData?.customer_id;
  const customer_user_id =
    customerLoggedInData && customerLoggedInData?.loginData?.user_id;
  const loginData = customerLoggedInData && customerLoggedInData?.loginData;
  const { customer_address, customer_animal, customer_details } = loginData;
  const userData = useSelector((state) => state?.password?.userData);
  const { cartList, cartItemCount } = useSelector((state) => state?.cart);
  return {
    customerLoggedInData,
    userData,
    customer_id,
    customer_user_id,
    loginData,
    cartList,
    cartItemCount,
    customer_address,
    customer_animal,
    customer_details,
  };
};

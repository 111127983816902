import React from "react";
import { useReduxStore } from "../customHooks/useReduxStore";
import { Navigate, Outlet } from "react-router-dom";

const AuthRoutes = () => {
  const { customerLoggedInData } = useReduxStore();
  const isLoggedIn =
    customerLoggedInData && customerLoggedInData?.isLoggedIn ? true : false;
  if (isLoggedIn) {
    return <Navigate to="/Home" />;
  }

  return <Outlet />;
};

export default AuthRoutes;

import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../../sharedComponent/Header";
import Subheader from "../../sharedComponent/Subheader";
import Client from "../../assets/images/clientImg.svg";
import liteImgArow from "../../assets/images/lightIcon.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../assets/images/calendar.svg";
import ActiveCgeck from "../../assets/images/greenCheckRight.svg";
import CLock from "../../assets/images/Clock.svg";
import ActCLock from "../../assets/images/whiteCLock.svg";
import Clinic from "../../assets/images/clininc.svg";
import ClinicAct from "../../assets/images/clinicActive.svg";
import VideoCam from "../../assets/images/videoCam.svg";
import VideoCamAct from "../../assets/images/videoCamActive.svg";
import Puppys from "../../assets/images/puppys.svg";
import ThumbsUp from "../../assets/images/thumbsUp.svg";
import successStatus from "../../assets/images/greenTick.svg";
import pendingStatus from "../../assets/images/pendingStatus.svg";
import failedStatus from "../../assets/images/failedStatus.svg";
import GreenCross from "../../assets/images/greenCross.svg";
import CLockDis from "../../assets/images/clockDisable.svg";
import GalleryImg from "../../assets/images/gallery.svg";
import { Form } from "react-bootstrap";
import DoctorSahab from "../../assets/images/DoctorImg.svg";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import {
  PostCallWithAuthToken,
  postCallWithFormDataWithToken,
  postMultipartWithAuthCall,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { useDateFormats } from "../../customHooks/useDateFormats";
import { AppContext } from "../../context/AppContext";
import RotatingLoader from "../../sharedComponent/RotatingLoader";
import moment from "moment";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import NoDataMsg from "../../sharedComponent/NoDataMsg";
import { checkoutHandler } from "../../paymentCheckout/checkout";

const BookAppoitment = () => {
  const params = useParams();
  // const doctorId = parseInt(params?.id);
  // DATA FROM CONTEXT
  const {
    setBkdropLdr,
    navigate,
    setEventKey,
    isPatientDet,
    setIsPatientDet,
    isSlot,
    setIsSlot,
  } = useContext(AppContext);
  // DATA FROM CONTEXT

  const { convertTimeTo12hr } = useDateFormats();
  const {
    customer_id,
    customer_user_id,
    loginData,
    customer_address,
    customer_animal,
    customer_details,
  } = useReduxStore();
  // FROM PARAMS DATA
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const doctorId = parseInt(searchParams.get("doctorId"));
  const clinic_id = parseInt(searchParams.get("clinic_id"));
  // FROM PARAMS DATA

  // All Four Stepper States

  const [isConsultationType, setIsConsultationType] = useState(false);
  const [isPaymentForm, setIsPaymentForm] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isACtive, setIsACtive] = useState(true);
  const [isACtiveEve, setIsACtiveEve] = useState(false);
  const [availableSlot, setAvailableSlot] = useState({
    morningAvailableSlot: [],
    eveningAvailableSlot: [],
  });
  const [slotDetails, setSlotDetails] = useState({});
  const [morningTime, setMorningTime] = useState({});
  const [eveningTime, setEveningTime] = useState({});
  const [animals, setAnimals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [bookAppPayLoad, setBookAppPayLoad] = useState({
    date: "",
    time: "",
    endTime: "",
    shift: "",
    clinic_id: clinic_id,
    doctor_id: doctorId,
    customer_animal_id: "",
    charges: 500,
    appointment_type: 1,
    appointment_desc: "",
    transaction_id: "",
    disease: "",
    pet_images: [],
    customer_user_id: customer_user_id ? customer_user_id : "",
    doctor_user_id: "",
    sender_name: `${loginData?.first_name} ${loginData?.last_name}`,
  });

  useEffect(() => {
    getAvailableSlots(new Date());
    getAnimalInformation();

    return () => {
      setIsPatientDet(false);
      setIsSlot(true);
    };
  }, []);

  // getDoctorLookupData API
  const getAvailableSlots = async (selectedDate) => {
    let payLoad = {
      doctor_id: doctorId,
      date: selectedDate,
      clinic_id: clinic_id,
      customer_id: customer_id,
    };
    setIsLoading(true);
    try {
      const res = await PostCallWithAuthToken(
        ApiConfig.DOCTORS_AVAILABLE_SLOTS,
        payLoad
      );
      const {
        success,
        data,
        morning_start_time,
        evening_start_time,
        specilization,
      } = res?.json;
      if (success) {
        morning_start_time && setMorningTime(morning_start_time);
        evening_start_time && setEveningTime(evening_start_time);

        if (typeof morning_start_time === "object") {
          setIsACtive(true);
          setIsACtiveEve(false);
        } else {
          setIsACtiveEve(true);
          setIsACtive(false);
        }
        setBookAppPayLoad({
          ...bookAppPayLoad,
          time: morning_start_time?.formattedTime
            ? morning_start_time?.formattedTime
            : evening_start_time?.formattedTime,
          endTime: morning_start_time?.formattedEndTime
            ? morning_start_time?.formattedEndTime
            : evening_start_time?.formattedEndTime,
          date: selectedDate,
          charges: morning_start_time?.formattedTime
            ? data?.slot_morning_doctor_charge
            : data?.slot_evening_doctor_charge,
          shift: morning_start_time?.formattedTime ? "Morning" : "Evening",
          doctor_user_id: data?.user_id ? data?.user_id : "",
        });
        data && setSlotDetails({ ...data, specilization: specilization });
      }
    } catch (error) {
      console.log("catch error res,", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAnimalInformation = async () => {
    try {
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_ANIMAL_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      console.log("data,", data);
      const tempArr =
        data &&
        data.length &&
        data.map((item) => {
          return {
            animal_id: item?.customer_animal_id,
            name: item?.name,
          };
        });
      if (success === true) {
        Array.isArray(data) && setAnimals(tempArr);
      }
    } catch (error) {
      console.log("catch error response,", error);
    }
  };

  const convertInFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        object[key].forEach((item, index) => {
          formData.append(`${key}`, item);
        });
      } else {
        formData.append(key, object[key]);
      }
    });
    return formData;
  };
  const [validated, setValidated] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const afterPaymentSuccess = () => {
    // notifySuccess("Appointment booked successfully");
    setIsFormFilled(true);
  };
  // bookDoctorAppointment
  const bookAppointMent = async (e) => {
    e.preventDefault();
    if (
      bookAppPayLoad.appointment_desc === "" ||
      bookAppPayLoad.customer_animal_id === "" 
      // bookAppPayLoad.pet_images.length === 0 ||
      // bookAppPayLoad.disease === ""
    ) {
      notifyError("Please fill all required fields");
      setIsPatientDet(true);
      setIsPaymentForm(false);
      setIsConsultationType(false);
    } else {
      try {
        const formData = convertInFormData({
          ...bookAppPayLoad,
        });
        setBkdropLdr(true);
        const res = await postCallWithFormDataWithToken(
          ApiConfig.BOOK_APPOINTMENT,
          formData
        );
        const { success, message, orderDetails } = res?.json;
        if (success) {
          if (bookAppPayLoad?.charges) {
            checkoutHandler(
              bookAppPayLoad?.charges,
              orderDetails,
              "doc",
              afterPaymentSuccess
            );
          }
        } else if (success === false) {
          notifyError("Something went wrong");
        } else {
          notifyError("Something went wrong");
        }
      } catch (error) {
        console.log("catch Error res,", error);
        notifyError("Something went wrong");
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  // const getAvailableAppoint = async () => {
  //   try {
  //     const payLoad = {
  //       type: 'bookAppointmentAvailableSlots',
  //       doctor_id: doctorId
  //       // doctor_id: 6
  //     }
  //     setBkdropLdr(true)
  //     const res = await PostCallWithAuthToken(
  //       ApiConfig.GET_AVAILABLE_SLOTS,
  //       payLoad
  //     )
  //     const { result, data } = res?.json
  //     if (result) {
  //       const morningSlot =
  //         data &&
  //         data.map((item, i) => {
  //           return {
  //             start_time: item?.slot_morning_start_time
  //               ? convertTimeTo12hr(item?.slot_morning_start_time)
  //               : '',
  //             end_time: item?.slot_morning_end_time
  //               ? convertTimeTo12hr(item?.slot_morning_end_time)
  //               : ''
  //           }
  //         })

  //       const eveningSlot =
  //         data &&
  //         data.map((item, i) => {
  //           return {
  //             start_time: item?.slot_evening_start_time
  //               ? convertTimeTo12hr(item?.slot_evening_start_time)
  //               : '',
  //             end_time: item?.slot_evening_end_time
  //               ? convertTimeTo12hr(item?.slot_evening_end_time)
  //               : ''
  //           }
  //         })
  //       setAvailableSlot({
  //         eveningAvailableSlot: eveningSlot,
  //         morningAvailableSlot: morningSlot
  //       })
  //     }
  //   } catch (error) {
  //     console.log('catch error,', error)
  //   } finally {
  //     setBkdropLdr(false)
  //   }
  // }

  return (
    <>
      <div className="bookApppointment" id="cx-main">
        <Subheader doctorId={doctorId} clinic_id={clinic_id} />

        {/* Main Content */}
        {isLoading ? (
          <RotatingLoader />
        ) : (
          <div className="row">
            {isFormFilled ? (
              <>
                <div className="col-md-12 col12Media">
                  <div className="boxModelInside">
                    <div className="appointmentStatusSlip">
                      <div className="headnigStatus">
                        <img src={ThumbsUp} className="ThumbsUp" alt="" />
                        <h1 className="wellcomeTxt">
                          Your Appointment has been booked Successfully
                        </h1>
                      </div>
                      <div className="statusContentSlip">
                        <div className="headingSumarry">
                          <div className="imageNname">
                            <img
                              src={
                                slotDetails?.doctor_profile_image_path
                                  ? slotDetails?.doctor_profile_image_path
                                  : dummydoctor
                              }
                              onError={(e) => {
                                e.target.src = dummydoctor;
                              }}
                              className="DoctorSahab"
                              alt=""
                            />
                            <div className="nameNdomain">
                              {slotDetails?.doctor_name && (
                                <h1>
                                  {slotDetails?.doctor_name
                                    ?.toLowerCase()
                                    ?.includes("dr")
                                    ? ""
                                    : "Dr."}{" "}
                                  {slotDetails?.doctor_name}
                                </h1>
                              )}
                              <label className="domain">
                                {slotDetails?.specilization &&
                                slotDetails?.specilization?.json_agg &&
                                slotDetails?.specilization?.json_agg[0]
                                  ?.specialization
                                  ? slotDetails?.specilization &&
                                    slotDetails?.specilization?.json_agg &&
                                    slotDetails?.specilization?.json_agg[0]
                                      ?.specialization
                                  : ""}{" "}
                                |{" "}
                                {slotDetails?.specilization &&
                                slotDetails?.specilization?.json_agg &&
                                slotDetails?.specilization?.json_agg[0]
                                  ?.specialization
                                  ? slotDetails?.specilization &&
                                    slotDetails?.specilization?.json_agg &&
                                    slotDetails?.specilization?.json_agg[0]
                                      ?.experience
                                  : ""}{" "}
                                years
                              </label>
                            </div>
                          </div>
                          <div className="statusSignal">
                            <label className="greenStatus">
                              <img src={successStatus} alt="" /> Paid
                            </label>
                            {/* <label className='greenStatus'>
                              <img src={pendingStatus} alt='' /> Pending
                            </label>
                            <label className='greenStatus'>
                              <img src={failedStatus} alt='' /> Failed
                            </label> */}
                          </div>
                        </div>
                        <div className="slipDetailsFlex">
                          <div className="keyNvalues">
                            <label htmlFor="" className="key">
                              Booking Date:{" "}
                            </label>
                            <label htmlFor="" className="value">
                              {startDate.toDateString()}
                              {/* Monday, 02 July, 2023 */}
                            </label>
                          </div>
                          <div className="keyNvalues">
                            <label htmlFor="" className="key">
                              Booking Time:{" "}
                            </label>
                            {bookAppPayLoad?.time &&
                              bookAppPayLoad?.endTime && (
                                <label htmlFor="" className="value">
                                  {bookAppPayLoad?.shift}{" "}
                                  {moment(bookAppPayLoad?.time, [
                                    "HH:mm",
                                  ]).format("hh:mm a")}{" "}
                                  to{" "}
                                  {moment(bookAppPayLoad?.endTime, [
                                    "HH:mm",
                                  ]).format("hh:mm a")}
                                  {/* Morning 07:00 to 09:00 */}
                                </label>
                              )}
                          </div>
                          <div className="keyNvalues">
                            <label htmlFor="" className="key">
                              Consultation Type :{" "}
                            </label>
                            <label htmlFor="" className="value">
                              {bookAppPayLoad?.appointment_type === 1
                                ? "Clinic Visit"
                                : "Video Consultation"}
                            </label>
                          </div>
                          {/* <div className='keyNvalues'>
                            <label htmlFor='' className='key'>
                              Payment Method :{' '}
                            </label>
                            <label htmlFor='' className='value'>
                              Online
                            </label>
                          </div> */}
                          <div className="clinic-address">
                            <label htmlFor="" className="key">
                              Clinic Address:{" "}
                            </label>
                            <span className="LinkInside">
                              {slotDetails?.doctor_clinic_address}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="belowBtns">
                      <Link to="/DoctorsList">
                        <button type="button" className="homeBtn">
                          Home
                        </button>
                      </Link>
                      {/* <Link to='#'> */}
                      <button
                        type="button"
                        className="appointmentBtn"
                        onClick={() => {
                          navigate("/ProfileSetting");
                          setEventKey("33");
                        }}
                      >
                        My Appointments
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-7 outerCol7">
                  <Form
                    noValidate
                    validated={validated}
                    // onSubmit={bookAppointMent}
                  >
                    {/* Slot Details 1*/}
                    {isSlot ? (
                      <>
                        <div className="selectedSlots">
                          <div className="slotsHeading">
                            <h1 className="slotsTxt">Select Available Slots</h1>
                          </div>
                          <div className="slotContent">
                            <div className="row">
                              <div className="col-md-12 dateCol12">
                                <div className="chooseDateSec">
                                  <label className="dateLabel">
                                    Choose Date
                                  </label>
                                  <div className="datePicker">
                                    <img
                                      src={Calendar}
                                      className="innerDateImg"
                                      alt=""
                                    />
                                    <DatePicker
                                      selected={startDate}
                                      placeholderText="Select the date for appointment"
                                      value={startDate}
                                      onChange={(date) => {
                                        setMorningTime({});
                                        setEveningTime({});
                                        getAvailableSlots(date);
                                        setStartDate(date);
                                      }}
                                      minDate={new Date()}
                                      showYearDropdown
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                              </div>
                              {Object.keys(slotDetails).length > 0 &&
                              (Object.keys(morningTime).length > 0 ||
                                Object.keys(eveningTime).length > 0) ? (
                                <div className="slotCards">
                                  <label className="slotSelect">
                                    Select Slot
                                  </label>
                                  <div className="row">
                                    {Object.keys(morningTime).length > 0 && (
                                      <div className="col-md-6 innerCard5">
                                        <div
                                          onClick={() => {
                                            setIsACtive(true);
                                            setIsACtiveEve(false);
                                            setBookAppPayLoad({
                                              ...bookAppPayLoad,
                                              time: morningTime?.formattedTime
                                                ? morningTime?.formattedTime
                                                : "",
                                              endTime:
                                                morningTime?.formattedEndTime
                                                  ? morningTime?.formattedEndTime
                                                  : "",
                                              shift: "Morning",
                                              charges:
                                                slotDetails?.slot_morning_doctor_charge,
                                            });
                                          }}
                                          className="cardActive"
                                        >
                                          {isACtive ? null : (
                                            <div className="wrapperBg"></div>
                                          )}
                                          <div className="cardHeading">
                                            <h1 className="headTxt">Morning</h1>
                                            {isACtive ? (
                                              <img
                                                src={ActiveCgeck}
                                                className="checkSig"
                                                alt=""
                                              />
                                            ) : null}
                                          </div>

                                          <div className="cardContentRadio">
                                            <div>
                                              <input
                                                type="radio"
                                                className="d-none"
                                                name="radios"
                                                id={`clockOne`}
                                                disabled
                                              />
                                              <label
                                                htmlFor={`clockOne`}
                                                className="clockActiveDisabled"
                                              >
                                                <img
                                                  src={CLockDis}
                                                  className="clockImg"
                                                  alt=""
                                                />
                                                {moment(
                                                  morningTime?.formattedTime,
                                                  ["HH:mm"]
                                                ).format("hh:mm a")}{" "}
                                                to{" "}
                                                {moment(
                                                  morningTime?.formattedEndTime,
                                                  ["HH:mm"]
                                                ).format("hh:mm a")}
                                                {/*  07:00 to 07:30 */}
                                              </label>
                                            </div>
                                            {/* <input
                                    type='radio'
                                    className='d-none'
                                    name='radios'
                                    id='clockTwo'
                                  />
                                  <label
                                    htmlFor='clockTwo'
                                    className='clockActive'
                                  >
                                    <img
                                      src={CLock}
                                      className='clockImg'
                                      alt=''
                                    />
                                    <img
                                      src={ActCLock}
                                      className='ActclockImg'
                                      alt=''
                                    />
                                    07:00 to 07:30
                                  </label>
                                  <input
                                    type='radio'
                                    className='d-none'
                                    name='radios'
                                    id='clockThree'
                                  />
                                  <label
                                    htmlFor='clockThree'
                                    className='clockActive'
                                  >
                                    <img
                                      src={CLock}
                                      className='clockImg'
                                      alt=''
                                    />
                                    <img
                                      src={ActCLock}
                                      className='ActclockImg'
                                      alt=''
                                    />
                                    07:00 to 07:30
                                  </label>
                                  <input
                                    type='radio'
                                    className='d-none'
                                    name='radios'
                                    id='clockFour'
                                  />
                                  <label
                                    htmlFor='clockFour'
                                    className='clockActive'
                                  >
                                    <img
                                      src={CLock}
                                      className='clockImg'
                                      alt=''
                                    />
                                    <img
                                      src={ActCLock}
                                      className='ActclockImg'
                                      alt=''
                                    />
                                    07:00 to 07:30
                                  </label>
                                  <input
                                    type='radio'
                                    className='d-none'
                                    name='radios'
                                    id='clockFive'
                                  />
                                  <label
                                    htmlFor='clockFive'
                                    className='clockActive'
                                  >
                                    <img
                                      src={CLock}
                                      className='clockImg'
                                      alt=''
                                    />
                                    <img
                                      src={ActCLock}
                                      className='ActclockImg'
                                      alt=''
                                    />
                                    07:00 to 07:30
                                  </label> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {Object.keys(eveningTime).length > 0 && (
                                      <div className="col-md-6">
                                        <div
                                          onClick={() => {
                                            setIsACtiveEve(true);
                                            setIsACtive(false);
                                            setBookAppPayLoad({
                                              ...bookAppPayLoad,
                                              time: eveningTime?.formattedTime
                                                ? eveningTime?.formattedTime
                                                : "",
                                              endTime:
                                                eveningTime?.formattedEndTime
                                                  ? eveningTime?.formattedEndTime
                                                  : "",
                                              shift: "Evening",
                                              charges:
                                                slotDetails?.slot_evening_doctor_charge,
                                            });
                                          }}
                                          className="cardActive"
                                        >
                                          {isACtiveEve ? null : (
                                            <div className="wrapperBg"></div>
                                          )}
                                          <div className="cardHeading">
                                            <h1 className="headTxt">Evening</h1>
                                            {isACtiveEve ? (
                                              <img
                                                src={ActiveCgeck}
                                                className="checkSig"
                                                alt=""
                                              />
                                            ) : null}
                                          </div>
                                          <div className="cardContentRadio">
                                            <div>
                                              <input
                                                type="radio"
                                                className="d-none"
                                                name="radios"
                                                id={`clockOneEve`}
                                                disabled
                                              />
                                              <label
                                                htmlFor="clockOneEve"
                                                className="clockActiveDisabled"
                                              >
                                                <img
                                                  src={CLockDis}
                                                  className="clockImg"
                                                  alt=""
                                                />
                                                {moment(
                                                  eveningTime?.formattedTime,
                                                  ["HH:mm"]
                                                ).format("hh:mm a")}{" "}
                                                to{" "}
                                                {moment(
                                                  eveningTime?.formattedEndTime,
                                                  ["HH:mm"]
                                                ).format("hh:mm a")}
                                                {/* 07:00 to 07:30 */}
                                              </label>
                                            </div>

                                            {/* <input
                                      type='radio'
                                      className='d-none'
                                      name='radios'
                                      id='clockTwoEve'
                                    />
                                    <label
                                      htmlFor='clockTwoEve'
                                      className='clockActive'
                                    >
                                      <img
                                        src={CLock}
                                        className='clockImg'
                                        alt=''
                                      />
                                      <img
                                        src={ActCLock}
                                        className='ActclockImg'
                                        alt=''
                                      />
                                      07:00 to 07:30
                                    </label>
                                    <input
                                      type='radio'
                                      className='d-none'
                                      name='radios'
                                      id='clockThreeEve'
                                    />
                                    <label
                                      htmlFor='clockThreeEve'
                                      className='clockActive'
                                    >
                                      <img
                                        src={CLock}
                                        className='clockImg'
                                        alt=''
                                      />
                                      <img
                                        src={ActCLock}
                                        className='ActclockImg'
                                        alt=''
                                      />
                                      07:00 to 07:30
                                    </label>
                                    <input
                                      type='radio'
                                      className='d-none'
                                      name='radios'
                                      id='clockFourEve'
                                    />
                                    <label
                                      htmlFor='clockFourEve'
                                      className='clockActive'
                                    >
                                      <img
                                        src={CLock}
                                        className='clockImg'
                                        alt=''
                                      />
                                      <img
                                        src={ActCLock}
                                        className='ActclockImg'
                                        alt=''
                                      />
                                      07:00 to 07:30
                                    </label>
                                    <input
                                      type='radio'
                                      className='d-none'
                                      name='radios'
                                      id='clockFiveEve'
                                    />
                                    <label
                                      htmlFor='clockFiveEve'
                                      className='clockActive'
                                    >
                                      <img
                                        src={CLock}
                                        className='clockImg'
                                        alt=''
                                      />
                                      <img
                                        src={ActCLock}
                                        className='ActclockImg'
                                        alt=''
                                      />
                                      07:00 to 07:30
                                    </label> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <NoDataMsg MSG={"No slots available"} />
                              )}
                            </div>
                          </div>
                        </div>
                        {Object.keys(slotDetails).length > 0 &&
                          (Object.keys(morningTime).length > 0 ||
                            Object.keys(eveningTime).length > 0) && (
                            <div className="belowBtns">
                              {/* <Link
                          // to='/DoctorDetails'
                          to='#'
                          className='backLink'
                        > */}
                              {/* <button className='backBtn'>Back</button> */}
                              {/* </Link> */}
                              {/* <Link
                          to='#'
                          className='nextLink'
                          onClick={() => {
                            setIsPatientDet(true)
                            setIsSlot(false)
                          }}
                        > */}
                              <button
                                type="button"
                                className="nextBtn"
                                onClick={() => {
                                  setIsPatientDet(true);
                                  setIsSlot(false);
                                }}
                              >
                                Next
                                <img src={liteImgArow} alt="" />{" "}
                              </button>
                              {/* </Link> */}
                            </div>
                          )}
                      </>
                    ) : null}

                    {/* Patient Details 2*/}
                    {isPatientDet
                     ? (
                      <>
                        <div className="patientDetails">
                          <div className="patientHeading">
                            <h1 className="patientTxt">
                              Patient Details{" "}
                              <span>(*All fields are mandatory)</span>{" "}
                            </h1>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="patientContent">
                                <div className="SelectBox">
                                  <label className="selectLabel">
                                    Appointment for
                                  </label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                      if (e.target.value == "new") {
                                        navigate("/ProfileSetting");
                                        setEventKey("22");
                                        sessionStorage.setItem(
                                          "navigationDetails",
                                          JSON.stringify({
                                            isNavigateFromDoctorPage: true,
                                            doctor_id: doctorId,
                                            clinic_id: clinic_id,
                                          })
                                        );
                                        return;
                                      }
                                      setBookAppPayLoad({
                                        ...bookAppPayLoad,
                                        customer_animal_id: Number(
                                          e.target.value
                                        ),
                                      });
                                    }}
                                    value={
                                      bookAppPayLoad?.customer_animal_id
                                        ? bookAppPayLoad?.customer_animal_id
                                        : ""
                                    }
                                    required
                                  >
                                    <option value={""}>Select your animal</option>
                                    {animals &&
                                      animals.map((item, idx) => {
                                        return (
                                          <option
                                            key={item?.animal_id}
                                            value={item?.animal_id}
                                          >
                                            {item?.name}
                                          </option>
                                        );
                                      })}
                                    <option
                                      value={"new"}
                                      className="add-new-pet-option"
                                    >
                                      + Add new animal
                                    </option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please select animal type
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="reasonBox">
                                <label className="reasonLabel">Reason / Symptoms</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your reason or symptoms here..."
                                  onChange={(e) => {
                                    setBookAppPayLoad({
                                      ...bookAppPayLoad,
                                      appointment_desc: e.target.value,
                                    });
                                  }}
                                  value={bookAppPayLoad?.appointment_desc}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter reason or symptoms 
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="attachmentPicBox">
                                <label className="attachmentPic">
                                  Attachment (Photo)
                                </label>
                                <label
                                  htmlFor="imgHolder"
                                  className="imgHolderLab"
                                >
                                  Browse...
                                  <img src={GalleryImg} alt="" />
                                  <input
                                    type="file"
                                    className="d-none"
                                    id="imgHolder"
                                    onChange={(e) => {
                                      setBookAppPayLoad({
                                        ...bookAppPayLoad,
                                        pet_images: [
                                          ...bookAppPayLoad?.pet_images,
                                          e.target.files[0],
                                        ],
                                      });
                                      setErrMsg("");
                                    }}
                                  />
                                </label>
                              </div>
                              {errMsg && <div>{errMsg}</div>}
                            </div> */}
                            {/* <div className="col-md-6">
                              <div className="symptomnsVox">
                                <label className="attachmentPic">
                                  Symptoms
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter symptoms"
                                  onChange={(e) => {
                                    setBookAppPayLoad({
                                      ...bookAppPayLoad,
                                      disease: e.target.value,
                                    });
                                  }}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter symptoms
                                </Form.Control.Feedback>
                              </div>
                            </div> */}
                            <div className="col-md-12">
                              <div className="imageGallery">
                                {bookAppPayLoad?.pet_images.length > 0 &&
                                  bookAppPayLoad?.pet_images.map((item, i) => {
                                    return (
                                      <div
                                        className="imgGrids"
                                        key={"bookAppPayLoad" + i}
                                      >
                                        <img
                                          src={GreenCross}
                                          className="checkCross"
                                          alt=""
                                          onClick={() => {
                                            const filterData =
                                              bookAppPayLoad?.pet_images.filter(
                                                (ele) => ele !== item
                                              );
                                            setBookAppPayLoad({
                                              ...bookAppPayLoad,
                                              pet_images: filterData,
                                            });
                                          }}
                                        />
                                        <img
                                          src={
                                            item
                                              ? item &&
                                                URL.createObjectURL(item)
                                              : Puppys
                                          }
                                          className="dogImg"
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="belowBtns">
                          <button
                            type="button"
                            className="backBtn"
                            onClick={() => {
                              setIsSlot(true);
                              setIsPatientDet(false);
                            }}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="nextBtn"
                            onClick={() => {
                              setIsConsultationType(true);
                              setIsPatientDet(false);
                            }}
                          >
                            Next <img src={liteImgArow} alt="" />{" "}
                          </button>
                        </div> */}
                        <div className="consultationDetails">
                          <div className="consultHeading">
                            <h1 className="consultTxt">Type of Consultation</h1>
                          </div>
                          <div className="consultCOntent">
                            <div className="cunsultationBox">
                              <label className="labelInn">
                                Select your Consultation type
                              </label>
                            </div>
                            <div className="TypeSelection">
                              <input
                                type="radio"
                                name="flexRadioDefault1"
                                className="d-none"
                                id="clinic"
                                checked={bookAppPayLoad.appointment_type === 1}
                                onChange={(e) => {
                                  setBookAppPayLoad({
                                    ...bookAppPayLoad,
                                    appointment_type: 1,
                                  });
                                }}
                              />
                              <label htmlFor="clinic" className="selectedLabel">
                                <img
                                  src={ClinicAct}
                                  className="clinicImgAct"
                                  alt=""
                                />
                                <img
                                  src={Clinic}
                                  className="clinicImg"
                                  alt=""
                                />
                                Clinic Visit
                              </label>
                              <input
                                type="radio"
                                name="flexRadioDefault1"
                                className="d-none"
                                id="video"
                                checked={bookAppPayLoad.appointment_type === 2}
                                onChange={(e) => {
                                  setBookAppPayLoad({
                                    ...bookAppPayLoad,
                                    appointment_type: 2,
                                  });
                                }}
                              />
                              <label htmlFor="video" className="selectedLabel">
                                <img
                                  src={VideoCam}
                                  className="videoImg"
                                  alt=""
                                />
                                <img
                                  src={VideoCamAct}
                                  className="videoImgAct"
                                  alt=""
                                />
                                Video Consultation
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          {/* <Link
                          to=''
                          className='backLink'
                          onClick={() => {
                            setIsPaymentForm(false)
                            setIsConsultationType(false)
                            setIsPatientDet(true)
                          }}
                        > */}
                          <button
                            type="button"
                            className="backBtn"
                            onClick={() => {
                              // setIsPaymentForm(false);
                              // setIsConsultationType(false);
                              setIsPatientDet(false);
                              setIsSlot(true)
                            }}
                          >
                            Back
                          </button>
                          {/* </Link> */}
                          {/* temporary changes */}
                          {/* <Link
                          to=''
                          className='nextLink'
                          onClick={() => {
                            
                            // setIsPaymentForm(true)
                            // setIsConsultationType(false)
                          }}
                        >
                          <button className='nextBtn'>
                            Book <img src={liteImgArow} alt='' />{' '}
                          </button>
                        </Link> */}
                          {/* <Link
                          to='#'
                          className='payLink'
                          onClick={() => {
                            bookAppointMent()
                            // setIsPaymentMethod(true)
                            // setIsPaymentForm(false)
                            // setIsConsultationType(false)
                          }}
                        > */}
                          <button
                            type="button"
                            className="payBtn"
                            onClick={(e) => {
                              bookAppointMent(e);
                              // setIsPaymentMethod(true)
                              // setIsPaymentForm(false)
                              // setIsConsultationType(false)
                              // setIsPaymentForm(true);
                              // setIsConsultationType(false);
                              // if(bookAppPayLoad?.charges){
                              //   checkoutHandler(bookAppPayLoad?.charges, bookAppointMent)
                              // }
                            }}
                          >
                            Book Appointment
                            <img src={liteImgArow} alt="" />
                          </button>
                          {/* </Link> */}
                          {/* temporary changes */}
                        </div>
                      </>
                    ) : null}

                    {/* Consultation Details 3*/}
                    {/* {isConsultationType ? (
                      <>
                        <div className="consultationDetails">
                          <div className="consultHeading">
                            <h1 className="consultTxt">Type of Consultation</h1>
                          </div>
                          <div className="consultCOntent">
                            <div className="cunsultationBox">
                              <label className="labelInn">
                                Select your Consultation type
                              </label>
                            </div>
                            <div className="TypeSelection">
                              <input
                                type="radio"
                                name="flexRadioDefault1"
                                className="d-none"
                                id="clinic"
                                checked={bookAppPayLoad.appointment_type === 1}
                                onChange={(e) => {
                                  setBookAppPayLoad({
                                    ...bookAppPayLoad,
                                    appointment_type: 1,
                                  });
                                }}
                              />
                              <label htmlFor="clinic" className="selectedLabel">
                                <img
                                  src={ClinicAct}
                                  className="clinicImgAct"
                                  alt=""
                                />
                                <img
                                  src={Clinic}
                                  className="clinicImg"
                                  alt=""
                                />
                                Clinic Visit
                              </label>
                              <input
                                type="radio"
                                name="flexRadioDefault1"
                                className="d-none"
                                id="video"
                                checked={bookAppPayLoad.appointment_type === 2}
                                onChange={(e) => {
                                  setBookAppPayLoad({
                                    ...bookAppPayLoad,
                                    appointment_type: 2,
                                  });
                                }}
                              />
                              <label htmlFor="video" className="selectedLabel">
                                <img
                                  src={VideoCam}
                                  className="videoImg"
                                  alt=""
                                />
                                <img
                                  src={VideoCamAct}
                                  className="videoImgAct"
                                  alt=""
                                />
                                Video Consultation
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          <button
                            type="button"
                            className="backBtn"
                            onClick={() => {
                              setIsPaymentForm(false);
                              setIsConsultationType(false);
                              setIsPatientDet(true);
                            }}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="payBtn"
                            onClick={(e) => {
                              bookAppointMent(e);
                              // setIsPaymentMethod(true)
                              // setIsPaymentForm(false)
                              // setIsConsultationType(false)
                              // setIsPaymentForm(true);
                              // setIsConsultationType(false);
                              // if(bookAppPayLoad?.charges){
                              //   checkoutHandler(bookAppPayLoad?.charges, bookAppointMent)
                              // }
                            }}
                          >
                            Book Appointment
                            <img src={liteImgArow} alt="" />
                          </button>
                        </div>
                      </>
                    ) : null} */}
                    
                    {false ? (
                      // isPaymentForm
                      <>
                        <div className="paymentOptions">
                          <div className="paymentHeadin">
                            <h1 className="headingTxt">Payment Methods</h1>
                          </div>
                          <div className="optionList">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault41"
                                id="flexRadioDefault41"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault41"
                              >
                                Debit or Credit Card
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="nameDetails">
                                <label className="NameLabel">
                                  Name on the card
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter the name"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="nameDetails">
                                <label className="NameLabel">Card Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="xxxx - xxxx - xxxx - xxxx"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="nameDetails">
                                <label className="NameLabel">
                                  Expiry Month
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="MM"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="nameDetails">
                                <label className="NameLabel">Expiry Year</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="YYYY"
                                  value={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="nameDetails">
                                <label className="NameLabel">CVV</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="***"
                                  value={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="optionList">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault42"
                                    id="flexRadioDefault22"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault22"
                                  >
                                    Net Banking
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault42"
                                    id="flexRadioDefault23"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault23"
                                  >
                                    UPI
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          {/* <Link
                          to='#'
                          className='backLink'
                          onClick={() => {
                            setIsConsultationType(true)
                            setIsPatientDet(false)
                            setIsPaymentForm(false)
                            setIsPaymentMethod(false)
                          }}
                        > */}
                          <button
                            type="button"
                            onClick={() => {
                              setIsConsultationType(true);
                              setIsPatientDet(false);
                              setIsPaymentForm(false);
                              setIsPaymentMethod(false);
                            }}
                            className="backBtn"
                          >
                            Back
                          </button>
                          {/* </Link> */}
                          <button
                            type="submit"
                            className="payBtn"
                            onClick={() => {
                              // setIsPaymentMethod(true)
                              // setIsPaymentForm(false)
                              setIsConsultationType(false);
                            }}
                          >
                            Book Appointment
                            {/* Proceed to Pay  */}
                            <img src={liteImgArow} alt="" />
                          </button>
                          {/* <Link
                            to='#'
                            className='payLink'
                            onClick={() => {
                              setIsPaymentMethod(true)
                              setIsPaymentForm(false)
                              setIsConsultationType(false)
                            }}
                          >
                            
                          </Link> */}
                        </div>
                      </>
                    ) : null}

                    {/* Payment Details (2) 5*/}
                    {isPaymentMethod ? (
                      <>
                        <div className="paymentOptions">
                          <div className="paymentHeadin">
                            <h1 className="headingTxt">Payment Methods</h1>
                          </div>
                          <div className="optionList">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Debit or Credit Card
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Net Banking
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault3"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault3"
                              >
                                UPI
                              </label>
                            </div>
                            <div className="upiServices">
                              <div className="formsSection">
                                <label className="labelName">UPI ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your UPI ID"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="belowBtns">
                          {/* <Link
                          to='#'
                          className='backLink'
                          onClick={() => {
                            setIsPaymentForm(true)
                            setIsConsultationType(false)
                            setIsPatientDet(false)
                            setIsPaymentMethod(false)
                          }}
                        > */}
                          <button
                            type="button"
                            className="backBtn"
                            onClick={() => {
                              setIsPaymentForm(true);
                              setIsConsultationType(false);
                              setIsPatientDet(false);
                              setIsPaymentMethod(false);
                            }}
                          >
                            Back
                          </button>
                          {/* </Link> */}
                          <Link
                            to="#"
                            className="payLink"
                            onClick={() => setIsFormFilled(true)}
                          >
                            <button type="button" className="payBtn">
                              Proceed to Pay <img src={liteImgArow} alt="" />
                            </button>
                          </Link>
                        </div>
                      </>
                    ) : null}
                  </Form>
                </div>
                <div className="col-md-5 outerCol5">
                  <div className="bookingSummary">
                    <div className="bookingHeadin">
                      <h1 className="headingTxt">Booking Summary</h1>
                    </div>
                    <div className="cardCOntent">
                      <div className="clientDetail">
                        <div className="clientImgHolder">
                          <img
                            src={
                              slotDetails?.doctor_profile_image_path
                                ? slotDetails?.doctor_profile_image_path
                                : dummydoctor
                            }
                            onError={(e) => {
                              e.target.src = dummydoctor;
                            }}
                            // src={Client}
                            className="clientImg"
                            alt=""
                          />
                        </div>
                        <div className="clientDetHolder">
                          {slotDetails?.doctor_name && (
                            <h1 className="clientName">
                              {" "}
                              {`${
                                slotDetails?.doctor_name &&
                                slotDetails?.doctor_name
                                  ?.toLowerCase()
                                  .includes("dr")
                                  ? ""
                                  : "Dr."
                              } ${slotDetails?.doctor_name}`}
                            </h1>
                          )}
                          <label className="ClientDomain">
                            {slotDetails?.specilization &&
                            slotDetails?.specilization?.json_agg &&
                            slotDetails?.specilization?.json_agg[0]
                              ?.specialization
                              ? slotDetails?.specilization &&
                                slotDetails?.specilization?.json_agg &&
                                slotDetails?.specilization?.json_agg[0]
                                  ?.specialization
                              : ""}{" "}
                            {slotDetails?.specilization &&
                            slotDetails?.specilization?.json_agg &&
                            slotDetails?.specilization?.json_agg[0]
                              ?.specialization
                              ? "|" + slotDetails?.specilization &&
                                slotDetails?.specilization?.json_agg &&
                                slotDetails?.specilization?.json_agg[0]
                                  ?.experience + "years"
                              : ""}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="bookingDetails">
                        <div className="headFlex">
                          <label className="bdTxt">Booking Details</label>
                          {/* <Link to='#' className='EditlInk'> */}
                          <span
                            className="EditlInk"
                            onClick={() => {
                              setIsSlot(true);
                              setIsPatientDet(false);
                              setIsPaymentForm(false);
                              setIsPaymentMethod(false);
                              setIsConsultationType(false);
                            }}
                          >
                            Edit
                          </span>
                          {/* </Link> */}
                        </div>
                        <div className="belowFlex">
                          <label className="BookTime">
                            Booking Date <span> : </span>
                          </label>
                          <label className="BookDay">
                            {startDate.toDateString()}
                            {/* Monday, 02 July, 2023 */}
                          </label>
                        </div>
                        <div className="belowFlex">
                          <label className="BookTime">
                            Booking Time <span> : </span>
                          </label>
                          {bookAppPayLoad?.time && bookAppPayLoad?.endTime && (
                            <label className="BookDay">
                              {bookAppPayLoad?.shift}{" "}
                              {moment(bookAppPayLoad?.time, ["HH:mm"]).format(
                                "hh:mm a"
                              )}{" "}
                              to{" "}
                              {moment(bookAppPayLoad?.endTime, [
                                "HH:mm",
                              ]).format("hh:mm a")}
                              {/* Morning 07:00 to 09:00 */}
                            </label>
                          )}
                        </div>
                        <div className="belowFlexy">
                          <div className="insiderFlex">
                            <label className="BookTime">
                              Consultation Type <span> : </span>
                            </label>
                            <label className="BookDay">
                              {bookAppPayLoad?.appointment_type === 1
                                ? "Clinic Visit"
                                : "Video Consultation"}
                            </label>
                          </div>
                          {/* <Link to='#' className='EditlInk'>
                            Edit
                          </Link> */}
                          {/* setIsConsultationType */}
                          <span
                            className="EditlInk"
                            onClick={() => {
                              setIsConsultationType(true);
                              setIsSlot(false);
                              setIsPatientDet(false);
                              setIsPaymentForm(false);
                              setIsPaymentMethod(false);
                            }}
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bookingCharges">
                    <div className="bookingHeadin">
                      <h1 className="headingTxt">Booking Charges</h1>
                    </div>
                    <div className="bookingcontent">
                      <div className="bookingFlexin">
                        <label className="Key">Consultation Fees</label>
                        {bookAppPayLoad?.charges !== 0 && (
                          <label className="Value">
                            ₹ {bookAppPayLoad?.charges}
                          </label>
                        )}
                      </div>
                      <div className="bookingFlexin">
                        <label className="Key">Booking Fees</label>
                        <label className="Value">₹0</label>
                      </div>
                      <div className="bookingFlexin">
                        <label className="Key">Tax</label>
                        <label className="Value">₹0</label>
                      </div>
                    </div>
                    <div className="bookingBottom">
                      <div className="bookingFlexin">
                        <label className="Key">Total</label>
                        {bookAppPayLoad?.charges && (
                          <label className="Value">
                            ₹ {bookAppPayLoad?.charges}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BookAppoitment;

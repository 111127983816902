import { ClipLoader } from 'react-spinners'

const BtnLoader = () => {
  return (
    <div>
      <ClipLoader color='#fff' size={30} />
    </div>
  )
}

export default BtnLoader

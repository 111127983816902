import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Header from '../../sharedComponent/Header'
import Subheader from '../../sharedComponent/Subheader'
import Accordion from 'react-bootstrap/Accordion'
import FilterImg from '../../assets/images/filterIcon.svg'
import DoctorSahab from '../../assets/images/DoctorImg.svg'
import Heart from '../../assets/images/heart.svg'
import HeartAct from '../../assets/images/heartActive.svg'
import Location from '../../assets/images/location.svg'
import Watch from '../../assets/images/watch.svg'
import Dollar from '../../assets/images/dollar.svg'
import Star from '../../assets/images/Star.svg'
import LitStar from '../../assets/images/liteStar.svg'
import BlueImgArow from '../../assets/images/darkIcon.svg'
import liteImgArow from '../../assets/images/lightIcon.svg'
import MultiRangeSlider from 'multi-range-slider-react'
import {
  PostCallWithAuthToken,
  postCallWithFormDataWithToken,
  simpleGetCallWithAuthToken
} from '../../api/ApiServices'
import { ApiConfig } from '../../api/ApiConfig'
import InfiniteScroll from 'react-infinite-scroll-component'
import RotatingLoader from '../../sharedComponent/RotatingLoader'
import { useReduxStore } from '../../customHooks/useReduxStore'
import NoData from '../../sharedComponent/NoDataMsg'
import NoDataMsg from '../../sharedComponent/NoDataMsg'
import { notifyError, notifySuccess } from '../../ToastMessages/notify'
import { AppContext } from '../../context/AppContext'
import dummydoctor from '../../assets/images/dummydoctor.jpg'
import moment from 'moment'
import PopupOverlay from '../Home/PopupOverlay'

const VaccinatorsList = () => {
  // DATA FROM CONTEXT
  const {
    setBkdropLdr,
    drocterCallOpen,
    doctorListPayLoad,
    setDoctorListPayLoad,
    currentPage,
    setCurrentPage,
    navigate
  } = useContext(AppContext)
  // DATA FROM CONTEXT
  const { customerLoggedInData } = useReduxStore()
  const { customer_id } =
    customerLoggedInData?.loginData && customerLoggedInData?.loginData
  const location = useLocation()
  const { vaccineData, doctor_appointment_id } = location.state ? location.state : {}
  //STATES
  const [click, setClick] = useState(false)
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(45);
  const [specialistCheckList, setSpecialistCheckList] = useState([])
  const [specialistFetched, setSpecialistFetched] = useState(false)
  const [allDoctors, setAllDoctors] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPageData, setCurrentPageData] = useState({})

  //STATES
  console.log('doctorListPayLoad,', doctorListPayLoad)
  useEffect(() => {
    getSpecialist()
  }, [])

  useEffect(() => {
    fetchAllVaccinators()
  }, [doctorListPayLoad])

  // GET_DOCTORS_SPECIALIST API
  const getSpecialist = async () => {
    try {
      const payLoad = {
        type: 'specializations'
      }
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_DOCTORS_SPECIALIST,
        payLoad
      )
      const { result, data } = res?.json
      const specialist_id = [data[0]?.specialization_id]

      if (result) {
        data && setSpecialistCheckList(data)
        // setDoctorListPayLoad({
        //   ...doctorListPayLoad,
        //   specialist_id: specialist_id
        // })
      }
    } catch (error) {
      console.log('catch error response,', error)
    }
  }

  const [vaccinator, setVaccinator] = useState([])
  console.log('vaccinator,', vaccinator)
  // GET_ALL_DOCTORS API
  const fetchAllVaccinators = async () => {
    try {
      setLoading(true)
      const res = await simpleGetCallWithAuthToken(ApiConfig.FETCH_VACCINATORS)
      const {
        success,
        vaccinatorList,
        total_count,
        current_page,
        total_pages,
        last_page
      } = res?.json
      if (success) {
        setVaccinator(vaccinatorList)
      }
    } catch (error) {
      console.log('Catch Error:', error)
    } finally {
      setLoading(false)
    }
  }

  // GET_ALL_DOCTORS API
  const fetchMoreDoctors = async () => {
    try {
      const payLoad = { ...doctorListPayLoad, page: currentPage }
      setBkdropLdr(true)
      const res = await PostCallWithAuthToken(
        ApiConfig.GET_ALL_DOCTORS,
        payLoad
      )
      const { data, result, total, current_page, total_pages, last_page } =
        res?.json
      if (result) {
        data && setAllDoctors(prev => [...prev, ...data])
        setCurrentPageData({
          total,
          current_page,
          total_pages,
          last_page
        })
      }
    } catch (error) {
      console.log('Catch Error:', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchMoreDoctors()
    }
  }, [currentPage])

  const handleInfiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (!currentPageData.last_page) {
        setCurrentPage(currentPage + 1)
      }
    }
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleInfiniteScroll)
  //   return () => window.removeEventListener('scroll', handleInfiniteScroll)
  // }, [])

  // ADD_FAVOURITE_DOCTOR API AddFavDoc
  const addFavourite = async (doctorId, action) => {
    try {
      const payLoad = {
        customer_id: customer_id,
        doctor_id: doctorId
      }
      setBkdropLdr(true)
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_FAVOURITE_DOCTOR,
        payLoad
      )

      const { result, message } = res?.json
      if (result) {
        // notifySuccess('Added to favourites list')
        if (action === 'like') {
          const tempArr = allDoctors.map((item, i) => {
            return item?.doctor_id === doctorId
              ? { ...item, customer_favourite_id: true }
              : item
          })
          setAllDoctors(tempArr)
        } else {
          const tempArr = allDoctors.map((item, i) => {
            return item?.doctor_id === doctorId
              ? { ...item, customer_favourite_id: false }
              : item
          })
          setAllDoctors(tempArr)
        }
      } else {
        notifyError('Already added into favourites list')
      }
    } catch (error) {
      console.log('catch error,', error)
    } finally {
      setBkdropLdr(false)
    }
  }

  return (
    <>
      <div className='DoctorsList' id='cx-main'>
        <Subheader />

        {/* main Cards Content */}
        {loading ? (
          <RotatingLoader />
        ) : vaccinator && vaccinator.length > 0 ? (
          <div className='row'>
            {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}

            {/* Left Menu's Accordians */}
            <div className='col-md-3 ColBox30'>
              {/* <div id='accordiansTab'>
              <div className='mainHeadTxt'>
                <h1 className='headName'>Filter</h1>
              </div>
              <Accordion>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>Specialist</Accordion.Header>
                  <Accordion.Body>
                    <div className='checksPortion'>
                      {specialistCheckList &&
                        specialistCheckList.length &&
                        specialistCheckList.map(item => {
                          return (
                            <div
                              key={item?.specialization_id}
                              className='form-check checkNlabel'
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='flexCheckDefault'
                                checked={doctorListPayLoad?.specialist_id.includes(
                                  item?.specialization_id
                                )}
                                onChange={e => {
                                  if (e.target.checked) {
                                    setDoctorListPayLoad({
                                      ...doctorListPayLoad,
                                      specialist_id: [
                                        ...doctorListPayLoad.specialist_id,
                                        item?.specialization_id
                                      ]
                                    })
                                  } else {
                                    const filteredIds =
                                      doctorListPayLoad.specialist_id.filter(
                                        (spId, i) => {
                                          return (
                                            spId !== item?.specialization_id
                                          )
                                        }
                                      )
                                    console.log('filteredIds,', filteredIds)
                                    setDoctorListPayLoad({
                                      ...doctorListPayLoad,
                                      specialist_id: filteredIds
                                    })
                                  }
                                }}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='flexCheckDefault'
                              >
                                {item?.specialization}
                              </label>
                            </div>
                          )
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <Accordion.Header>Experience</Accordion.Header>
                  <Accordion.Body>
                    <div className='sliderAreas'>
                      <MultiRangeSlider
                        min={0}
                        max={50}
                        step={1}
                        minValue={Number(doctorListPayLoad?.startExperience)}
                        maxValue={Number(doctorListPayLoad?.endExperience)}
                        onInput={e => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            startExperience: Number(e.minValue),
                            endExperience: Number(e.maxValue)
                          })
                        }}
                      ></MultiRangeSlider>
                      <div className='belowSteps'>
                        <label htmlFor=''>0 years</label>
                        <label htmlFor=''>50 years</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <Accordion.Header>Area</Accordion.Header>
                  <Accordion.Body>
                    <div className='sliderAreas'>
                      <MultiRangeSlider
                        min={1}
                        max={9}
                        step={3}
                        onInput={e => {
                        }}
                      ></MultiRangeSlider>
                      <div className='belowSteps'>
                        <label htmlFor=''>0 Kms</label>
                        <label htmlFor=''>25 Kms</label>
                        <label htmlFor=''>50 Kms</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <Accordion.Header>Fees</Accordion.Header>
                  <Accordion.Body>
                    <div className='sliderAreas'>
                      <MultiRangeSlider
                        min={1}
                        max={9999}
                        step={50}
                        minValue={doctorListPayLoad?.startPrice}
                        maxValue={doctorListPayLoad?.endPrice}
                        onInput={e => {
                        }}
                      ></MultiRangeSlider>
                      <div className='belowSteps'>
                        <label htmlFor=''>100 &#8377;</label>
                        <label htmlFor=''>2000 &#8377;</label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                  <Accordion.Header>Ratings</Accordion.Header>
                  <Accordion.Body>
                    <div className='ratingStars'>
                      <img
                        src={doctorListPayLoad?.rating >= 1 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: doctorListPayLoad?.rating ? 0 : 1
                          })
                        }}
                        className='me-2'
                        alt=''
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 2 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 2
                          })
                        }}
                        className='me-2'
                        alt=''
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 3 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 3
                          })
                        }}
                        className='me-2'
                        alt=''
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 4 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 4
                          })
                        }}
                        className='me-2'
                        alt=''
                      />
                      <img
                        src={doctorListPayLoad?.rating >= 5 ? Star : LitStar}
                        onClick={() => {
                          setDoctorListPayLoad({
                            ...doctorListPayLoad,
                            rating: 5
                          })
                        }}
                        alt=''
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}
            </div>

            {/* Cards Content Section */}
            <div className='col-md-12 colBox90'>
              <div className='cardsSections'>
                <div className='topFilterSection'>
                  <div className='txtSec'>
                    <h1 className='mainTxt'>
                      Vaccinator’s{' '}
                      <span className='mutedTxt'>
                        {`Showing ${1}-${allDoctors && allDoctors.length} of ${currentPageData?.total ? currentPageData?.total : 0
                          } Doctor’s`}
                      </span>{' '}
                    </h1>
                  </div>
                  {/* <div className='filterImg'>
                  <img src={FilterImg} alt='' />
                </div> */}
                </div>
                <div className='scrollerAbove'>
                  <div className='row'>
                    {vaccinator &&
                      vaccinator.map((item, idx) => {
                        return (
                          <div className='col-md-3' key={'vaccinator' + idx}>
                            <div className='cardsInsider'>
                              <div className='detailsCOntent'>
                                <div className='imgContainer'>
                                  <img
                                    style={{ borderRadius: '10px' }}
                                    src={
                                      item?.vaccinator_profile_image_path
                                        ? item?.vaccinator_profile_image_path
                                        : ''
                                    }
                                    onError={e => {
                                      e.target.src = dummydoctor
                                    }}
                                    className='insideImg'
                                    alt=''
                                  />
                                </div>
                                <div className='detailsInside'>
                                  <div className='detailsTop'>
                                    <div className='detailsTxt'>
                                      <h1 className='NameTxt'>
                                        {item?.vaccinator_name}
                                      </h1>
                                      {/* {item?.doctor_name && (
                                <h1 htmlFor='' className='NameTxt'>
                                  {`${
                                    item?.doctor_name
                                      .toLowerCase()
                                      .includes('dr')
                                      ? ''
                                      : 'Dr.'
                                  } ${item?.doctor_name}`}
                                </h1>
                              )} */}
                                      <label htmlFor='' className='domainTxt'>
                                        {item?.experience} years
                                      </label>
                                    </div>
                                    {/* <div className='detActionBtn'>
                              <img
                                onClick={() => {
                                  // setClick(!click)
                                  if (item?.customer_favourite_id) {
                                    // addFavourite(
                                    //   item?.doctor_id,
                                    //   'dislike'
                                    // )
                                  } else {
                                    addFavourite(item?.doctor_id, 'like')
                                  }
                                }}
                                // src={click ? HeartAct : Heart}
                                src={
                                  item?.customer_favourite_id ? HeartAct : Heart
                                }
                                alt=''
                              />
                            </div> */}
                                  </div>

                                  <div className='belowCOntent'>
                                    {/* <h1
                                    htmlFor=''
                                    className='locationTxt'
                                    // style={{ visibility: 'hidden' }}
                                  >
                                    <img
                                      src={Location}
                                      className='me-2'
                                      alt=''
                                    />
                                    <span>Vaccinator, Aurangabad</span>
                                  </h1> */}

                                    {item?.rating && (
                                      <h1
                                        htmlFor=''
                                        className='belowTxt DiffClr'
                                      >
                                        <img
                                          src={Star}
                                          className='me-2'
                                          alt=''
                                        />
                                        {parseFloat(item?.rating).toFixed(1)}
                                      </h1>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className='detailCardsBtns'>
                                <button
                                  style={{
                                    border: 'none',
                                    width: '205px',
                                    backgroundColor: '#43beba',
                                    height: '40px',
                                    borderRadius: '20px',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={() => {
                                    // book-vaccinator
                                    navigate('/book-vaccinator')
                                    // navigate('/book-vaccinator', {
                                    //   state: {
                                    //     vaccinator_id: item?.vaccinator_id,
                                    //     vaccinator_name: item?.vaccinator_name,
                                    //     vaccinator_profile_image_path:
                                    //       item?.vaccinator_profile_image_path,
                                    //   }
                                    // })
                                    sessionStorage.setItem("vaccinatorCharges", JSON.stringify({
                                      vaccinator_id: item?.vaccinator_id,
                                      vaccinator_name: item?.vaccinator_name,
                                      vaccinator_profile_image_path:
                                        item?.vaccinator_profile_image_path,
                                      charges: Number(item?.vaccinator_fees)
                                    }))
                                  }}
                                >
                                  Book Now{' '}
                                  <img
                                    src={liteImgArow}
                                    alt=''
                                    style={{ marginLeft: '10px' }}
                                  />
                                </button>
                                {/* </Link> */}
                              </div>
                            </div>
                          </div>
                        )
                      })}

                    {/* {!loading &&
                    !currentPageData?.last_page &&
                    allDoctors.length > 0 && (
                      <div
                        className='col-md-12 text-center'
                        onClick={() => {
                          setCurrentPage(currentPage + 1)
                        }}
                      >
                        <button className='showMoreBtn'>Show More</button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NoDataMsg MSG={'No Vaccinator founds'} />
        )}
      </div>
    </>
  )
}

export default VaccinatorsList

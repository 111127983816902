import React, { useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import ic_close_red from "../../assets/images/ic_close_red.svg";
import ic_add_plus from "../../assets/images/ic_add_plus.svg";
import ic_arrow_right_white from "../../assets/images/ic_right_arrow_circle.svg";
import userIcon from "../../assets/images/icons/userIcon.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import { useReduxStore } from "../../customHooks/useReduxStore";
import { ApiConfig } from "../../api/ApiConfig";
import {
  PostCallWithAuthToken,
  PutCallWithAuthToken,
  PutCallWithAuthTokenAndFormData,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { AppContext } from "../../context/AppContext";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";
import { updateLoginData } from "../../redux/Slices/authSlice";
import { filter } from "lodash";
import { checkFileTypeForImages } from "../../constants/commonFunctons";

const MyProfileComp = () => {
  const {
    setBkdropLdr,
    eventKeyy,
    validNumber,
    dispatch,
    navigate,
    setAmbulanceFormCharge,
  } = useContext(AppContext);
  // DATA FROM REDUX
  const {
    customerLoggedInData,
    loginData,
    customer_address,
    customer_animal,
    customer_details,
  } = useReduxStore();
  const navigationDetails = sessionStorage.navigationDetails
    ? JSON.parse(sessionStorage.navigationDetails)
    : {};

  const ambulance_driver_id = sessionStorage.ambulance_driver_id
    ? sessionStorage.ambulance_driver_id
    : "";

  const isNavigateFromCartPage = sessionStorage.isNavigateFromCartPage
    ? sessionStorage.isNavigateFromCartPage
    : "";

  const customerDetails =
    customerLoggedInData?.loginData && customerLoggedInData?.loginData;
  const customer_id = customerDetails && customerDetails?.customer_id;
  // DATA FROM REDUX

  // STATES
  const [isValidated, setIsValidated] = useState(false);
  const [validated, setValidated] = useState(false);
  // STATES

  useEffect(() => {
    fetchCustomerDetails();

    return () => {
      sessionStorage.removeItem("navigationDetails");
      sessionStorage.removeItem("ambulance_driver_id");
      sessionStorage.removeItem("isNavigateFromCartPage");
    };
  }, []);

  const [isInvaild, setIsInvaild] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [updateCustomerPayload, setUpdateCustomerPayload] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: [
      {
        address: "",
        landmark: "",
        is_default: false,
        postal_code: "",
        street_name: "",
        address_type_id: "",
      },
    ],
  });

  const fetchCustomerDetails = async () => {
    try {
      eventKeyy === "11" && setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_CUSTOMER_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        const resData = data[0];
        const tempAddressArr =
          Array.isArray(resData?.address) && resData
            ? resData?.address.map((item, i) => {
                return {
                  ...item,
                  address_type_id: item?.address_type?.address_type_id,
                  index: i,
                };
              })
            : [];

        data &&
          setUpdateCustomerPayload({
            ...updateCustomerPayload,
            first_name: resData?.first_name,
            last_name: resData?.last_name,
            email: resData?.email,
            phone_number: resData?.phone_number,
            customer_id: customer_id,
            address:
              tempAddressArr?.length > 0
                ? tempAddressArr
                : [
                    ...tempAddressArr,
                    {
                      address: "",
                      landmark: "",
                      is_default: false,
                      postal_code: "",
                      street_name: "",
                      address_type_id: "",
                      index: tempAddressArr.length,
                    },
                  ],
          });

        resData?.profile_image_path &&
          setProfileImage(resData?.profile_image_path);
      }
    } catch (error) {
      console.log("error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const passwordHandlerr = (event, index) => {
    const { value, name } = event.target;
    if (index + 1) {
      const tempArr = [...updateCustomerPayload.address];
      tempArr[index][name] = value;
      setUpdateCustomerPayload({
        ...updateCustomerPayload,
        address: tempArr,
      });
    } else {
      setUpdateCustomerPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const contactNumberRef = useRef(null);
  const [addressErrorMsg, setAddressErrorMsg] = useState({
    errMsg: "",
    index: 0,
  });

  const updateCustomerProfile = async (e) => {
    e.preventDefault();
    if (updateCustomerPayload?.address?.length == 0) {
      notifyError("Please fill your address details");
      return;
    }
    if (
      updateCustomerPayload?.address?.some(
        (it) => it.address == "" || it.address == undefined
      )
    ) {
      const tempArr = updateCustomerPayload?.address?.map((item) => {
        return item.address == "" || item.address == undefined
          ? {
              errMsg: "Address is required",
              index: item.index,
            }
          : {};
      });
      tempArr && setAddressErrorMsg(tempArr);
    }
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      setIsInvaild(true);
      if (form.querySelector(':invalid[id="firstname"]')) {
        firstNameRef.current.focus();
      } else if (form.querySelector(':invalid[id="lastName"]')) {
        lastNameRef.current.focus();
      } else if (form.querySelector(':invalid[id="email"]')) {
        emailRef.current.focus();
      } else if (form.querySelector(':invalid[id="contactNumber"]')) {
        contactNumberRef.current.focus();
      }
    } else {
      try {
        setBkdropLdr(true);
        const res = await PutCallWithAuthToken(
          ApiConfig.UPDATE_CUSTOMER_DETAILS,
          updateCustomerPayload
        );
        const { success, data } = res?.json;
        if (success) {
          notifySuccess("Profile details updated successfully");
          dispatch(
            updateLoginData({
              ...loginData,
              customer_details: 1,
              customer_address: 1,
              first_name: updateCustomerPayload?.first_name,
              last_name: updateCustomerPayload?.last_name,
            })
          );
          if (
            navigationDetails?.isNavigateFromDoctorPage &&
            navigationDetails?.doctor_id &&
            navigationDetails?.clinic_id
          ) {
            navigate(
              `/BookAppoitment?doctorId=${navigationDetails?.doctor_id}&clinic_id=${navigationDetails?.clinic_id}`
            );
          }
          if (ambulance_driver_id) {
            navigate(`/AmbulanceBook/${ambulance_driver_id}`);
            setAmbulanceFormCharge(false);
          }
          if (isNavigateFromCartPage) {
            navigate("/cart");
          }
        }
      } catch (error) {
        console.log("Catch error update customer profile:", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  const updateProfileImage = async (profileImage) => {
    try {
      const updateProfileImagePayLoad = {
        customer_id: customer_id,
        profilePath: profileImage,
      };
      const profileImageFormData = new FormData()
      profileImageFormData.append("customer_id", updateProfileImagePayLoad.customer_id)
      profileImageFormData.append("profilePath", updateProfileImagePayLoad.profilePath)
      setBkdropLdr(true)
      const res = await PutCallWithAuthTokenAndFormData(
        ApiConfig.UPDATE_PROFILE_IMAGE,
        profileImageFormData
      );
      console.log("Profile image,", res)
      if(res.json.success){
        notifySuccess("Profile Image Uploaded successfully")
        fetchCustomerDetails()
      }
    } catch (error) {
      console.error("Catch error,", errMsg)
    } finally {
      setBkdropLdr(false)
    }
    
  };

  // PASSWORD SETTINGS SECTION
  const [passwordPayLoad, setPasswordPayLoad] = useState({
    email: loginData?.email ? loginData?.email : "",
    new_password: "",
    confirm_password: "",
  });
  const [otp, setOtp] = useState("");

  const sendOTP = async () => {
    try {
      const payLoad = {
        email: passwordPayLoad?.email,
      };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(ApiConfig.SEND_OTP, payLoad);
      const { success, otp, message } = res?.json;
      if (success) {
        // otp && setOtp(otp)
        notifySuccess(`Varification code sent to your registered phone number`);
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch error : ", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const varifyOTP = async (otp) => {
    const otpPayLoad = {
      email: loginData?.email ? loginData?.email : "",
      otp: otp,
    };
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(ApiConfig.VERIFY_OTP, otpPayLoad);
      if (res?.json?.success) {
        notifySuccess(`OTP varified successfully`);
      } else {
        notifyError(res?.json?.message);
      }
    } catch (error) {
      console.log("errror response,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const passwordHandler = (e) => {
    const { value, name } = e.target;
    setPasswordPayLoad((prevState) => ({ ...prevState, [name]: value }));
  };
  const [errMsg, setErrMsg] = useState("");

  const updatedPasswordSetting = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setIsValidated(true);
    } else {
      if (passwordPayLoad?.new_password !== passwordPayLoad?.confirm_password) {
        setErrMsg("Password does not match");
        return;
      }
      try {
        setBkdropLdr(true);
        const res = await PutCallWithAuthToken(
          ApiConfig.UPDATE_PASSWORD_SETTINGS,
          passwordPayLoad
        );

        const { success, data } = res?.json;
        if (success) {
          notifySuccess("Password settings updated successfully");
        }
      } catch (error) {
        console.log("Catch error,", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  const handlePlaceSelect = (event, { lat, lng, address }, index, name) => {
    const locationData = { lat, lng, address };
    const tempArr = [...updateCustomerPayload.address];
    tempArr[index][name] = locationData.address ? locationData.address : "";
    tempArr[index]["lat"] = locationData.lat ? locationData.lat : "";
    tempArr[index]["lng"] = locationData.lng ? locationData.lng : "";
    setUpdateCustomerPayload({
      ...updateCustomerPayload,
      address: tempArr,
    });
    const res = addressErrorMsg?.map?.((element) => {
      return element?.index == index ? { errMsg: "", index: index } : element;
    });
    res && setAddressErrorMsg(res);
  };

  return (
    <>
      <div className="right-section">
        <Form noValidate validated={validated} onSubmit={updateCustomerProfile}>
          <section className="wrapper-cards">
            <h5>My Profile</h5>
            <div className="row">
              <div className="col-md-12 form-group">
                <div className="inputBox">
                  <div class="profile-pic">
                    <label htmlFor="fileInput">
                      <img
                        alt="User Pic"
                        src={
                          profileImage
                            ? profileImage
                            : "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                        }
                        // id="profile-image1"
                        height="200"
                      />
                    </label>
                  </div>
                  <img src={userIcon} className="mailImg" alt="" />
                  <label htmlFor="fileInput" className="emailLabel">
                    Profile photo
                  </label>
                  <input
                    style={{ opacity: 0 }}
                    accept="image/png, image/jpeg, image/jpg"
                    type="file"
                    id="fileInput"
                    className="form-control formInput"
                    placeholder="Select your profile photo"
                    onChange={(e) => {
                      if (!checkFileTypeForImages(e.target.files[0])) {
                        notifyError(
                          "'Invalid file type. Please choose a PNG, JPEG, or JPG file.'"
                        );
                      } else {
                        updateProfileImage(e.target.files[0])
                        // setRegistrationData({
                        //   ...registrationData,
                        //   customerphotos: [e.target.files[0]],
                        // });
                      }
                    }}
                  />
                  {/* <div className='fileSelectionName'>
                      Please upload profile photo
                    </div> */}
                  <label htmlFor="fileInput" className="file_selection_name">
                    {/* {registrationData.customerphotos[0]?.name
                        ? registrationData.customerphotos[0]?.name
                        : "Please upload profile photo"} */}
                  </label>
                  <div className="filediv"></div>
                </div>
              </div>

              <div className="col-md-6 form-group">
                <Form.Group className="">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="capital"
                    id="firstname"
                    type="text"
                    placeholder="Enter first name..."
                    value={updateCustomerPayload?.first_name}
                    ref={firstNameRef}
                    required
                    name="first_name"
                    onChange={passwordHandlerr}
                  />
                  <Form.Control.Feedback type="invalid">
                    First name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6 form-group">
                <Form.Group className="">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="capital"
                    id="lastName"
                    type="text"
                    placeholder="Enter last name"
                    value={updateCustomerPayload?.last_name}
                    ref={lastNameRef}
                    required
                    name="last_name"
                    onChange={passwordHandlerr}
                  />
                  <Form.Control.Feedback type="invalid">
                    Last name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6 form-group">
                <Form.Group className="">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Enter email..."
                    value={updateCustomerPayload?.email}
                    ref={emailRef}
                    required
                    name="email"
                    onChange={passwordHandlerr}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid email id
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6 form-group">
                <Form.Group className="">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    id="contactNumber"
                    type="tel"
                    pattern="[6789][0-9]{9}"
                    placeholder="Enter contact number"
                    value={updateCustomerPayload?.phone_number}
                    ref={contactNumberRef}
                    required
                    name="phone_number"
                    onChange={(e) => {
                      if (validNumber.test(e.target.value)) {
                        passwordHandlerr(e);
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid contact number
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </section>

          {/* <section className='wrapper-cards mt-3'>
            <h5>Address</h5>
            <dov className='row'>
              <div className='col-md-12 form-group'>
                <Form.Group className=''>
                  <Form.Label>Address (Area, Flat No. Street,...) </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter your Address here...'
                  />
                </Form.Group>
              </div>
              <div className='col-md-6 form-group'>
                <Form.Group className=''>
                  <Form.Label>Pin Code</Form.Label>
                  <Form.Control type='text' placeholder='' value={'12345'} />
                </Form.Group>
              </div>
              <div className='col-md-6 form-group'>
                <Form.Group className=''>
                  <Form.Label>Street Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={'Kalas Road'}
                  />
                </Form.Group>
              </div>
              <div className='col-md-6 form-group'>
                <Form.Group className=''>
                  <Form.Label>Landmark (Optional)</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder=''
                    value={'Redbytes Software'}
                  />
                </Form.Group>
              </div>
              <div className='col-md-6 form-group'>
                <Form.Group className=''>
                  <Form.Label>Address Type</Form.Label>
                  <Form.Select aria-label='Work / Home / Other'>
                    <option value='1'>Work</option>
                    <option value='2'>Home</option>
                    <option value='3'>Other</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </dov>
          </section> */}

          {updateCustomerPayload?.address?.length > 0 && (
            <section className="wrapper-cards mt-3">
              {Array.isArray(updateCustomerPayload?.address) &&
                updateCustomerPayload?.address?.map((ele, idx) => {
                  return (
                    <>
                      <div className="heading-close-btn">
                        <h5>{`Address ${idx + 1}`}</h5>
                        <Link>
                          {idx !== 0 && (
                            <img
                              src={ic_close_red}
                              alt=""
                              onClick={() => {
                                const duplicateArr = [
                                  ...updateCustomerPayload?.address,
                                ];
                                duplicateArr.splice(idx, 1);
                                duplicateArr &&
                                  setUpdateCustomerPayload({
                                    ...updateCustomerPayload,
                                    address: duplicateArr,
                                  });
                              }}
                            />
                          )}
                        </Link>
                      </div>

                      <dov className="row">
                        <div className="col-md-12 form-group">
                          <Form.Group className="">
                            <Form.Label>
                              Address (Area, Flat No. Street...,){" "}
                            </Form.Label>
                            <AutocompleteGoogleInput
                              value={ele?.address ? ele?.address : ""}
                              required={true}
                              className={`form-control formInput`}
                              placeholder={"Enter your address"}
                              onPlaceSelect={handlePlaceSelect}
                              currentIndex={idx}
                              name={"address"}
                            />
                            {/*
                                <Form.Control
                                  type="text"
                                  placeholder="Enter your Address here..."
                                  required
                                  value={ele?.address}
                                  name="address"
                                  onChange={(e) => passwordHandlerr(e, idx)}
                                />
                            */}
                            {addressErrorMsg[idx]?.errMsg &&
                              addressErrorMsg[idx]?.index == idx && (
                                <div className="err-msg">
                                  {addressErrorMsg[idx]?.errMsg}
                                </div>
                              )}
                            {/* <Form.Control.Feedback type="invalid">
                              Address is required
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </div>
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>Pin Code</Form.Label>
                            <Form.Control
                              type="tel"
                              pattern="[0-9]{6}"
                              placeholder=""
                              value={ele?.postal_code}
                              name="postal_code"
                              onChange={(e) => {
                                // pattern="[6789][0-9]{9}"
                                if (
                                  validNumber.test(e.target.value) &&
                                  e.target.value.length < 7
                                ) {
                                  passwordHandlerr(e, idx);
                                }
                              }}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter valid pincode
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>Street Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={ele?.street_name}
                              name="street_name"
                              onChange={(e) => passwordHandlerr(e, idx)}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Street name is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>Landmark (Optional)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={ele?.landmark}
                              name="landmark"
                              onChange={(e) => passwordHandlerr(e, idx)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>Address Type</Form.Label>
                            <Form.Select
                              aria-label="Work / Home / Other"
                              value={ele?.address_type_id}
                              name="address_type_id"
                              onChange={(e) => passwordHandlerr(e, idx)}
                              required
                            >
                              <option value="">Select address type</option>
                              <option value={1}>Work</option>
                              <option value={2}>Home</option>
                              <option value={3}>Other</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Address type is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </dov>
                    </>
                  );
                })}
            </section>
          )}

          <div className="add-btn-wrapper mt-3">
            <button
              type="button"
              onClick={() => {
                setUpdateCustomerPayload({
                  ...updateCustomerPayload,
                  address: [
                    ...updateCustomerPayload.address,
                    {
                      address: "",
                      landmark: "",
                      is_default: false,
                      postal_code: "",
                      street_name: "",
                      address_type_id: "",
                      index: updateCustomerPayload.address.length,
                    },
                  ],
                });
              }}
            >
              <img src={ic_add_plus} alt="" />
              Add New address
            </button>
            <div className="submit-btn-group mt-3" style={{ display: "flex" }}>
              {/* <button type='button' className='blank-btn'>
                Cancel
              </button> */}
              <button className="filled-btn" type="submit">
                Update Profile
                <img src={ic_arrow_right_white} alt="" />
              </button>
            </div>
          </div>
        </Form>

        {/* <Form
          noValidate
          validated={isValidated}
          onSubmit={updatedPasswordSetting}
        >
          <section className="wrapper-cards mt-3">
            <h5>Change Password</h5>
            <div className="">
              <div className="verify-email-otp row">
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      disabled
                      type="tel"
                      value={customerDetails?.phone_number}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <div className="resend-btn-wrapper">
                      <Form.Label>Verification Code</Form.Label>
                      <div className="right-btn">
                        <button
                          type="button"
                          className="resend-btn"
                          onClick={() => {
                            sendOTP();
                          }}
                        >
                          Send OTP
                        </button>
                      </div>
                    </div>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder="Please enter OTP"
                      required
                      value={otp ? otp : ""}
                      maxLength={6}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z]+$/;
                        if (
                          validNumber.test(e.target.value) &&
                          e.target.value.length === 6 &&
                          !regex.test(e.target.value)
                        ) {
                          varifyOTP(e.target.value);
                        }
                        if (validNumber.test(e.target.value)) {
                          setOtp(e.target.value);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter verification code
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="********"
                      required
                      name="new_password"
                      onChange={passwordHandler}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter password
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-6 form-group">
                  <Form.Group className="">
                    <Form.Label>Confirm Password</Form.Label>

                    <Form.Control
                      type="password"
                      placeholder="*********"
                      required
                      name="confirm_password"
                      onChange={(e) => {
                        passwordHandler(e);
                        setErrMsg("");
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter confirm password
                    </Form.Control.Feedback>
                    {errMsg && (
                      <div
                        style={{
                          fontSize: ".875em",
                          color: "#dc3545",
                        }}
                      >
                        {errMsg}
                      </div>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          </section>
          <div className="submit-btn-group mt-3">
            <button className="filled-btn" type="submit">
              Update Password
              <img src={ic_arrow_right_white} alt="" />
            </button>
          </div>
        </Form> */}
      </div>
    </>
  );
};

export default MyProfileComp;

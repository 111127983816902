import { useState, useEffect } from "react";

export const useCurrentLatLng = () => {
  const [coordinates, setCoordinates] = useState({
    latitude: "",
    longitude: ""
  });

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setCoordinates({ latitude, longitude });
  };

  const handleError = (error) => {
    console.error("Error getting geolocation:", error.message);
    // Handle errors or set default coordinates if needed
  };

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000, // Adjust the timeout as needed
      maximumAge: 0
    };

    const watchId = navigator.geolocation.watchPosition(
      handleSuccess,
      handleError,
      options
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return coordinates;
};
